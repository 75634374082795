import { BusinessTypeEnum, ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { CreateServiceItem, ServiceItemList, ServiceItemQuery } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { NormalSelectOptions } from '@/resource/model/common';
import { translation } from '@/utils';
class ServiceItemService {
  public getById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(ResourceUrlEnum.serviceItem, { params: { id } })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: CreateServiceItem): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CreateServiceItem>(ResourceUrlEnum.serviceItem, resource)
        .then((res: ApiResponse<CreateServiceItem>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: CreateServiceItem): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<CreateServiceItem>(ResourceUrlEnum.serviceItem, resource)
        .then((res: ApiResponse<CreateServiceItem>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList
   * @returns
   */
  public batchCurrencyDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.serviceItem}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<ServiceItemQuery>, paging?: Paging): Promise<ApiResponse<Array<ServiceItemList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ServiceItemList>>(`${ResourceUrlEnum.serviceItem}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ServiceItemList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public listForComponents(paging?: Paging): Promise<ApiResponse<Array<ServiceItemList>>> {
    const cloneQuery = {};
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ServiceItemList>>(`${ResourceUrlEnum.serviceItem}/listForComponents`, cloneQuery)
        .then((res: ApiResponse<Array<ServiceItemList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量修状态
   * @param idList
   * @param status
   * @returns
   */
  public batchCurrentUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.serviceItem}/editEnableStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 审批
   * @param idList
   * @returns
   */
  public batchCurrencyApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.serviceItem}/approve`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 反审批
   * @param idList
   * @returns
   */
  public batchCurrencyCancelApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.serviceItem}/cancelApprove`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取业务类型
   * @returns
   */
  public getBusinessType(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in BusinessTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`businessType.${key}`),
          value: BusinessTypeEnum[key]
        });
      }
    }
    return options;
  }
  /**
   * 编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkCode(code: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.serviceItem}/checkCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 名称去重
   * @param name
   * @param id
   * @returns
   */
  public checkName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.serviceItem}/checkName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const exchangeRateService = new ServiceItemService();
export default exchangeRateService;
