import { ApiError } from '@/api/axios';
import i18n from '@/lang';
import { ErrorLevelEnum } from '@/resource/enum';
import { BatchOperationError } from '@/resource/model';

const prefixName = 'requestError';
const constantError: { [prop: number]: { level: ErrorLevelEnum; i18nKey: string } } = {
  // 公共异常
  '-4': { level: ErrorLevelEnum.error, i18nKey: '_4' },
  10001: { level: ErrorLevelEnum.error, i18nKey: '_10001' },
  10002: { level: ErrorLevelEnum.error, i18nKey: '_10002' },
  10003: { level: ErrorLevelEnum.error, i18nKey: '_10003' },
  10004: { level: ErrorLevelEnum.error, i18nKey: '_10004' },
  10005: { level: ErrorLevelEnum.error, i18nKey: '_10005' },
  10006: { level: ErrorLevelEnum.error, i18nKey: '_10006' },
  10007: { level: ErrorLevelEnum.error, i18nKey: '_10007' },
  10008: { level: ErrorLevelEnum.error, i18nKey: '_10008' },
  10009: { level: ErrorLevelEnum.error, i18nKey: '_10009' },
  10010: { level: ErrorLevelEnum.error, i18nKey: '_10010' },
  10011: { level: ErrorLevelEnum.error, i18nKey: '_10011' },
  10012: { level: ErrorLevelEnum.error, i18nKey: '_10012' },
  10013: { level: ErrorLevelEnum.error, i18nKey: '_10013' },
  10014: { level: ErrorLevelEnum.error, i18nKey: '_10014' },
  10015: { level: ErrorLevelEnum.error, i18nKey: '_10015' },
  10016: { level: ErrorLevelEnum.error, i18nKey: '_10016' },
  10017: { level: ErrorLevelEnum.error, i18nKey: '_10017' },
  10018: { level: ErrorLevelEnum.error, i18nKey: '_10018' },
  10019: { level: ErrorLevelEnum.error, i18nKey: '_10019' },
  10020: { level: ErrorLevelEnum.error, i18nKey: '_10020' },
  10021: { level: ErrorLevelEnum.error, i18nKey: '_10021' },
  10022: { level: ErrorLevelEnum.error, i18nKey: '_10022' },
  10023: { level: ErrorLevelEnum.error, i18nKey: '_10023' },
  10024: { level: ErrorLevelEnum.error, i18nKey: '_10024' },
  10025: { level: ErrorLevelEnum.error, i18nKey: '_10025' },
  10026: { level: ErrorLevelEnum.error, i18nKey: '_10026' },
  10027: { level: ErrorLevelEnum.error, i18nKey: '_10027' },
  42301: { level: ErrorLevelEnum.warning, i18nKey: '_42301' },

  // 登录模块
  20101: { level: ErrorLevelEnum.warning, i18nKey: '_20101' },
  20102: { level: ErrorLevelEnum.warning, i18nKey: '_20102' },
  20103: { level: ErrorLevelEnum.warning, i18nKey: '_20103' },
  20104: { level: ErrorLevelEnum.warning, i18nKey: '_20104' },
  20105: { level: ErrorLevelEnum.warning, i18nKey: '_20105' },
  20106: { level: ErrorLevelEnum.warning, i18nKey: '_20106' },
  20107: { level: ErrorLevelEnum.warning, i18nKey: '_20107' },
  20108: { level: ErrorLevelEnum.warning, i18nKey: '_20108' },
  20109: { level: ErrorLevelEnum.warning, i18nKey: '_20109' },
  20110: { level: ErrorLevelEnum.warning, i18nKey: '_20110' },
  20111: { level: ErrorLevelEnum.warning, i18nKey: '_20111' },
  20112: { level: ErrorLevelEnum.warning, i18nKey: '_20112' },
  20113: { level: ErrorLevelEnum.warning, i18nKey: '_20113' },
  20114: { level: ErrorLevelEnum.warning, i18nKey: '_20114' },
  20115: { level: ErrorLevelEnum.warning, i18nKey: '_20115' },
  20116: { level: ErrorLevelEnum.warning, i18nKey: '_20116' },
  // 用户模块
  20201: { level: ErrorLevelEnum.warning, i18nKey: '_20201' },
  20202: { level: ErrorLevelEnum.warning, i18nKey: '_20202' },
  20203: { level: ErrorLevelEnum.warning, i18nKey: '_20203' },
  20204: { level: ErrorLevelEnum.warning, i18nKey: '_20204' },
  20205: { level: ErrorLevelEnum.warning, i18nKey: '_20205' },
  20206: { level: ErrorLevelEnum.warning, i18nKey: '_20206' },
  20207: { level: ErrorLevelEnum.warning, i18nKey: '_20207' },
  // 物料分类管理
  20301: { level: ErrorLevelEnum.warning, i18nKey: '_20301' },
  20302: { level: ErrorLevelEnum.warning, i18nKey: '_20302' },
  // 资源模块
  20401: { level: ErrorLevelEnum.warning, i18nKey: '_20401' },
  20402: { level: ErrorLevelEnum.warning, i18nKey: '_20402' },
  20403: { level: ErrorLevelEnum.warning, i18nKey: '_20403' },
  // 岗位管理
  20502: { level: ErrorLevelEnum.warning, i18nKey: '_20502' },
  20503: { level: ErrorLevelEnum.warning, i18nKey: '_20503' },
  // 部门管理
  20601: { level: ErrorLevelEnum.warning, i18nKey: '_20601' },
  20602: { level: ErrorLevelEnum.warning, i18nKey: '_20602' },
  20603: { level: ErrorLevelEnum.warning, i18nKey: '_20603' },
  20604: { level: ErrorLevelEnum.warning, i18nKey: '_20604' },
  // 设备分类管理
  20701: { level: ErrorLevelEnum.warning, i18nKey: '_20701' },
  20702: { level: ErrorLevelEnum.warning, i18nKey: '_20702' },
  20703: { level: ErrorLevelEnum.warning, i18nKey: '_20703' },
  // 客户分类
  20801: { level: ErrorLevelEnum.warning, i18nKey: '_20801' },
  // 客户管理模块
  20901: { level: ErrorLevelEnum.warning, i18nKey: '_20901' },
  20902: { level: ErrorLevelEnum.warning, i18nKey: '_20902' },
  20903: { level: ErrorLevelEnum.warning, i18nKey: '_20903' },
  20904: { level: ErrorLevelEnum.warning, i18nKey: '_20904' },
  // 客户产品
  21001: { level: ErrorLevelEnum.warning, i18nKey: '_21001' },
  21002: { level: ErrorLevelEnum.warning, i18nKey: '_21002' },
  21003: { level: ErrorLevelEnum.warning, i18nKey: '_21003' },
  21004: { level: ErrorLevelEnum.warning, i18nKey: '_21004' },
  21005: { level: ErrorLevelEnum.warning, i18nKey: '_21005' },
  21006: { level: ErrorLevelEnum.warning, i18nKey: '_21006' },
  // type 产品管理
  21101: { level: ErrorLevelEnum.warning, i18nKey: '_21101' },
  21102: { level: ErrorLevelEnum.warning, i18nKey: '_21102' },
  21103: { level: ErrorLevelEnum.warning, i18nKey: '_21103' },
  21104: { level: ErrorLevelEnum.warning, i18nKey: '_21104' },
  21105: { level: ErrorLevelEnum.warning, i18nKey: '_21105' },
  21106: { level: ErrorLevelEnum.warning, i18nKey: '_21106' },
  21107: { level: ErrorLevelEnum.warning, i18nKey: '_21107' },
  21108: { level: ErrorLevelEnum.warning, i18nKey: '_21108' },
  21109: { level: ErrorLevelEnum.warning, i18nKey: '_21109' },
  21110: { level: ErrorLevelEnum.warning, i18nKey: '_21110' },
  21111: { level: ErrorLevelEnum.warning, i18nKey: '_21111' },
  21112: { level: ErrorLevelEnum.warning, i18nKey: '_21112' },
  21113: { level: ErrorLevelEnum.warning, i18nKey: '_21113' },
  21114: { level: ErrorLevelEnum.warning, i18nKey: '_21114' },
  21115: { level: ErrorLevelEnum.warning, i18nKey: '_21115' },
  21116: { level: ErrorLevelEnum.warning, i18nKey: '_21116' },
  21117: { level: ErrorLevelEnum.warning, i18nKey: '_21117' },
  21118: { level: ErrorLevelEnum.warning, i18nKey: '_21118' },
  // 发票相关异常
  21201: { level: ErrorLevelEnum.warning, i18nKey: '_21201' },
  21301: { level: ErrorLevelEnum.warning, i18nKey: '_21301' },
  // 门店管理
  21401: { level: ErrorLevelEnum.warning, i18nKey: '_21401' },
  21402: { level: ErrorLevelEnum.warning, i18nKey: '_21402' },
  21403: { level: ErrorLevelEnum.warning, i18nKey: '_21403' },
  21404: { level: ErrorLevelEnum.warning, i18nKey: '_21404' },
  21405: { level: ErrorLevelEnum.warning, i18nKey: '_21405' },
  21406: { level: ErrorLevelEnum.warning, i18nKey: '_21406' },
  21407: { level: ErrorLevelEnum.warning, i18nKey: '_21407' },
  21408: { level: ErrorLevelEnum.warning, i18nKey: '_21408' },
  21409: { level: ErrorLevelEnum.warning, i18nKey: '_21409' },
  21410: { level: ErrorLevelEnum.warning, i18nKey: '_21410' },
  21411: { level: ErrorLevelEnum.warning, i18nKey: '_21411' },
  21412: { level: ErrorLevelEnum.warning, i18nKey: '_21412' },
  21413: { level: ErrorLevelEnum.warning, i18nKey: '_21413' },
  // 点位标签
  21501: { level: ErrorLevelEnum.warning, i18nKey: '_21501' },
  21502: { level: ErrorLevelEnum.warning, i18nKey: '_21502' },
  21503: { level: ErrorLevelEnum.warning, i18nKey: '_21503' },
  21504: { level: ErrorLevelEnum.warning, i18nKey: '_21504' },
  21505: { level: ErrorLevelEnum.warning, i18nKey: '_21505' },
  21506: { level: ErrorLevelEnum.warning, i18nKey: '_21506' },
  21507: { level: ErrorLevelEnum.warning, i18nKey: '_21507' },
  21508: { level: ErrorLevelEnum.warning, i18nKey: '_21508' },
  21509: { level: ErrorLevelEnum.warning, i18nKey: '_21509' },
  21510: { level: ErrorLevelEnum.warning, i18nKey: '_21510' },
  21511: { level: ErrorLevelEnum.warning, i18nKey: '_21511' },
  21512: { level: ErrorLevelEnum.warning, i18nKey: '_21512' },
  21513: { level: ErrorLevelEnum.warning, i18nKey: '_21513' },
  21514: { level: ErrorLevelEnum.warning, i18nKey: '_21514' },
  // Minlo错误
  21601: { level: ErrorLevelEnum.warning, i18nKey: '_21601' },
  21602: { level: ErrorLevelEnum.warning, i18nKey: '_21602' },
  // 工艺管理
  21701: { level: ErrorLevelEnum.warning, i18nKey: '_21701' },

  // 角色管理
  21801: { level: ErrorLevelEnum.warning, i18nKey: '_21801' },
  21802: { level: ErrorLevelEnum.warning, i18nKey: '_21802' },

  // 供应商级别
  21901: { level: ErrorLevelEnum.warning, i18nKey: '_21901' },
  21902: { level: ErrorLevelEnum.warning, i18nKey: '_21902' },

  // 供应商管理
  22001: { level: ErrorLevelEnum.warning, i18nKey: '_22001' },
  22002: { level: ErrorLevelEnum.warning, i18nKey: '_22002' },

  // 供应商设备
  22201: { level: ErrorLevelEnum.warning, i18nKey: '_22201' },
  22202: { level: ErrorLevelEnum.warning, i18nKey: '_22202' },

  // 供应商产品
  22301: { level: ErrorLevelEnum.warning, i18nKey: '_22301' },
  22302: { level: ErrorLevelEnum.warning, i18nKey: '_22302' },
  22303: { level: ErrorLevelEnum.warning, i18nKey: '_22303' },
  22304: { level: ErrorLevelEnum.warning, i18nKey: '_22304' },

  // 快递100
  22401: { level: ErrorLevelEnum.warning, i18nKey: '_22401' },
  22402: { level: ErrorLevelEnum.warning, i18nKey: '_22402' },
  22403: { level: ErrorLevelEnum.warning, i18nKey: '_22403' },

  // 项目管理
  22501: { level: ErrorLevelEnum.warning, i18nKey: '_22501' },
  22502: { level: ErrorLevelEnum.warning, i18nKey: '_22502' },

  // 项次管理
  22601: { level: ErrorLevelEnum.warning, i18nKey: '_22601' },
  22602: { level: ErrorLevelEnum.warning, i18nKey: '_22602' },
  22603: { level: ErrorLevelEnum.warning, i18nKey: '_22603' },
  22604: { level: ErrorLevelEnum.warning, i18nKey: '_22604' },
  22605: { level: ErrorLevelEnum.warning, i18nKey: '_22605' },
  22606: { level: ErrorLevelEnum.warning, i18nKey: '_22606' },
  22607: { level: ErrorLevelEnum.warning, i18nKey: '_22607' },
  22608: { level: ErrorLevelEnum.warning, i18nKey: '_22608' },
  22609: { level: ErrorLevelEnum.warning, i18nKey: '_22609' },
  22610: { level: ErrorLevelEnum.warning, i18nKey: '_22610' },
  22611: { level: ErrorLevelEnum.warning, i18nKey: '_22611' },
  22612: { level: ErrorLevelEnum.warning, i18nKey: '_22612' },
  22613: { level: ErrorLevelEnum.warning, i18nKey: '_22613' },
  22614: { level: ErrorLevelEnum.warning, i18nKey: '_22614' },
  22615: { level: ErrorLevelEnum.warning, i18nKey: '_22615' },
  22616: { level: ErrorLevelEnum.warning, i18nKey: '_22616' },
  22617: { level: ErrorLevelEnum.warning, i18nKey: '_22617' },
  22618: { level: ErrorLevelEnum.warning, i18nKey: '_22618' },
  22619: { level: ErrorLevelEnum.warning, i18nKey: '_22619' },
  22620: { level: ErrorLevelEnum.warning, i18nKey: '_22620' },
  22621: { level: ErrorLevelEnum.warning, i18nKey: '_22621' },
  22622: { level: ErrorLevelEnum.warning, i18nKey: '_22622' },
  22623: { level: ErrorLevelEnum.warning, i18nKey: '_22623' },
  22624: { level: ErrorLevelEnum.warning, i18nKey: '_22624' },
  22625: { level: ErrorLevelEnum.warning, i18nKey: '_22625' },
  22626: { level: ErrorLevelEnum.warning, i18nKey: '_22626' },
  22627: { level: ErrorLevelEnum.warning, i18nKey: '_22627' },
  22628: { level: ErrorLevelEnum.warning, i18nKey: '_22628' },
  22629: { level: ErrorLevelEnum.warning, i18nKey: '_22629' },
  22630: { level: ErrorLevelEnum.warning, i18nKey: '_22630' },
  22631: { level: ErrorLevelEnum.warning, i18nKey: '_22631' },
  22632: { level: ErrorLevelEnum.warning, i18nKey: '_22632' },
  22633: { level: ErrorLevelEnum.warning, i18nKey: '_22633' },
  22634: { level: ErrorLevelEnum.warning, i18nKey: '_22634' },
  22635: { level: ErrorLevelEnum.warning, i18nKey: '_22635' },
  22636: { level: ErrorLevelEnum.warning, i18nKey: '_22636' },
  22637: { level: ErrorLevelEnum.warning, i18nKey: '_22637' },
  22638: { level: ErrorLevelEnum.warning, i18nKey: '_22638' },
  22639: { level: ErrorLevelEnum.warning, i18nKey: '_22639' },
  22640: { level: ErrorLevelEnum.warning, i18nKey: '_22640' },
  22641: { level: ErrorLevelEnum.warning, i18nKey: '_22641' },
  22642: { level: ErrorLevelEnum.warning, i18nKey: '_22642' },
  22643: { level: ErrorLevelEnum.warning, i18nKey: '_22643' },

  // 生产订单
  22701: { level: ErrorLevelEnum.warning, i18nKey: '_22701' },
  22702: { level: ErrorLevelEnum.warning, i18nKey: '_22702' },
  22703: { level: ErrorLevelEnum.warning, i18nKey: '_22703' },
  22704: { level: ErrorLevelEnum.warning, i18nKey: '_22704' },
  22705: { level: ErrorLevelEnum.warning, i18nKey: '_22705' },
  22706: { level: ErrorLevelEnum.warning, i18nKey: '_22706' },
  22707: { level: ErrorLevelEnum.warning, i18nKey: '_22707' },
  22708: { level: ErrorLevelEnum.warning, i18nKey: '_22708' },

  // 生产单据管理
  22801: { level: ErrorLevelEnum.warning, i18nKey: '_22801' },
  22802: { level: ErrorLevelEnum.warning, i18nKey: '_22802' },
  22803: { level: ErrorLevelEnum.warning, i18nKey: '_22803' },
  22804: { level: ErrorLevelEnum.warning, i18nKey: '_22804' },
  22805: { level: ErrorLevelEnum.warning, i18nKey: '_22805' },
  22806: { level: ErrorLevelEnum.warning, i18nKey: '_22806' },
  22807: { level: ErrorLevelEnum.warning, i18nKey: '_22807' },
  22808: { level: ErrorLevelEnum.warning, i18nKey: '_22808' },
  22809: { level: ErrorLevelEnum.warning, i18nKey: '_22809' },
  22810: { level: ErrorLevelEnum.warning, i18nKey: '_22810' },
  22811: { level: ErrorLevelEnum.warning, i18nKey: '_22811' },
  22812: { level: ErrorLevelEnum.warning, i18nKey: '_22812' },
  22813: { level: ErrorLevelEnum.warning, i18nKey: '_22813' },

  // 发货通知单
  22901: { level: ErrorLevelEnum.warning, i18nKey: '_22901' },
  22902: { level: ErrorLevelEnum.warning, i18nKey: '_22902' },
  22903: { level: ErrorLevelEnum.warning, i18nKey: '_22903' },
  22904: { level: ErrorLevelEnum.warning, i18nKey: '_22904' },
  22905: { level: ErrorLevelEnum.warning, i18nKey: '_22905' },
  22906: { level: ErrorLevelEnum.warning, i18nKey: '_22906' },
  22907: { level: ErrorLevelEnum.warning, i18nKey: '_22907' },
  22908: { level: ErrorLevelEnum.warning, i18nKey: '_22908' },
  22909: { level: ErrorLevelEnum.warning, i18nKey: '_22909' },
  22910: { level: ErrorLevelEnum.warning, i18nKey: '_22910' },
  22911: { level: ErrorLevelEnum.warning, i18nKey: '_22911' },
  22912: { level: ErrorLevelEnum.warning, i18nKey: '_22912' },
  22913: { level: ErrorLevelEnum.warning, i18nKey: '_22913' },
  22914: { level: ErrorLevelEnum.warning, i18nKey: '_22914' },
  22915: { level: ErrorLevelEnum.warning, i18nKey: '_22915' },
  22916: { level: ErrorLevelEnum.warning, i18nKey: '_22916' },
  22917: { level: ErrorLevelEnum.warning, i18nKey: '_22917' },

  // 生产订单变更单
  23001: { level: ErrorLevelEnum.warning, i18nKey: '_23001' },
  23002: { level: ErrorLevelEnum.warning, i18nKey: '_23002' },
  23003: { level: ErrorLevelEnum.warning, i18nKey: '_23003' },
  23004: { level: ErrorLevelEnum.warning, i18nKey: '_23004' },
  23005: { level: ErrorLevelEnum.warning, i18nKey: '_23005' },
  23006: { level: ErrorLevelEnum.warning, i18nKey: '_23006' },
  23007: { level: ErrorLevelEnum.warning, i18nKey: '_23007' },
  23008: { level: ErrorLevelEnum.warning, i18nKey: '_23008' },

  // 物流单管理
  23101: { level: ErrorLevelEnum.warning, i18nKey: '_23101' },
  23102: { level: ErrorLevelEnum.warning, i18nKey: '_23102' },
  23103: { level: ErrorLevelEnum.warning, i18nKey: '_23103' },
  23104: { level: ErrorLevelEnum.warning, i18nKey: '_23104' },
  23105: { level: ErrorLevelEnum.warning, i18nKey: '_23105' },
  23106: { level: ErrorLevelEnum.warning, i18nKey: '_23106' },
  23107: { level: ErrorLevelEnum.warning, i18nKey: '_23107' },
  23108: { level: ErrorLevelEnum.warning, i18nKey: '_23108' },
  23109: { level: ErrorLevelEnum.warning, i18nKey: '_23109' },
  23110: { level: ErrorLevelEnum.warning, i18nKey: '_23110' },
  23111: { level: ErrorLevelEnum.warning, i18nKey: '_23111' },
  23112: { level: ErrorLevelEnum.warning, i18nKey: '_23112' },
  23113: { level: ErrorLevelEnum.warning, i18nKey: '_23113' },
  23114: { level: ErrorLevelEnum.warning, i18nKey: '_23114' },
  23115: { level: ErrorLevelEnum.warning, i18nKey: '_23115' },
  23116: { level: ErrorLevelEnum.warning, i18nKey: '_23116' },
  23117: { level: ErrorLevelEnum.warning, i18nKey: '_23117' },
  23118: { level: ErrorLevelEnum.warning, i18nKey: '_23118' },
  23119: { level: ErrorLevelEnum.warning, i18nKey: '_23119' },
  23120: { level: ErrorLevelEnum.warning, i18nKey: '_23120' },
  23121: { level: ErrorLevelEnum.warning, i18nKey: '_23121' },
  23122: { level: ErrorLevelEnum.warning, i18nKey: '_23122' },
  23123: { level: ErrorLevelEnum.warning, i18nKey: '_23123' },
  23124: { level: ErrorLevelEnum.warning, i18nKey: '_23124' },
  23125: { level: ErrorLevelEnum.warning, i18nKey: '_23125' },

  // 安装通知单
  23201: { level: ErrorLevelEnum.warning, i18nKey: '_23201' },
  23202: { level: ErrorLevelEnum.warning, i18nKey: '_23202' },
  23203: { level: ErrorLevelEnum.warning, i18nKey: '_23203' },
  23204: { level: ErrorLevelEnum.warning, i18nKey: '_23204' },
  23205: { level: ErrorLevelEnum.warning, i18nKey: '_23205' },
  23206: { level: ErrorLevelEnum.warning, i18nKey: '_23206' },
  23207: { level: ErrorLevelEnum.warning, i18nKey: '_23207' },
  23208: { level: ErrorLevelEnum.warning, i18nKey: '_23208' },
  23209: { level: ErrorLevelEnum.warning, i18nKey: '_23209' },
  23214: { level: ErrorLevelEnum.warning, i18nKey: '_23214' },

  // 电子面单
  23301: { level: ErrorLevelEnum.warning, i18nKey: '_23301' },
  23302: { level: ErrorLevelEnum.warning, i18nKey: '_23302' },

  // 安装单
  23401: { level: ErrorLevelEnum.warning, i18nKey: '_23401' },
  23402: { level: ErrorLevelEnum.warning, i18nKey: '_23402' },
  23403: { level: ErrorLevelEnum.warning, i18nKey: '_23403' },
  23404: { level: ErrorLevelEnum.warning, i18nKey: '_23404' },
  23405: { level: ErrorLevelEnum.warning, i18nKey: '_23405' },
  23406: { level: ErrorLevelEnum.warning, i18nKey: '_23406' },
  23407: { level: ErrorLevelEnum.warning, i18nKey: '_23407' },
  23408: { level: ErrorLevelEnum.warning, i18nKey: '_23408' },
  23409: { level: ErrorLevelEnum.warning, i18nKey: '_23409' },
  23410: { level: ErrorLevelEnum.warning, i18nKey: '_23410' },
  23411: { level: ErrorLevelEnum.warning, i18nKey: '_23411' },
  23412: { level: ErrorLevelEnum.warning, i18nKey: '_23412' },
  23413: { level: ErrorLevelEnum.warning, i18nKey: '_23413' },
  23414: { level: ErrorLevelEnum.warning, i18nKey: '_23414' },
  23415: { level: ErrorLevelEnum.warning, i18nKey: '_23415' },
  23416: { level: ErrorLevelEnum.warning, i18nKey: '_23416' },
  23417: { level: ErrorLevelEnum.warning, i18nKey: '_23417' },

  // 结算单
  23501: { level: ErrorLevelEnum.warning, i18nKey: '_23501' },
  23502: { level: ErrorLevelEnum.warning, i18nKey: '_23502' },
  23503: { level: ErrorLevelEnum.warning, i18nKey: '_23503' },
  23504: { level: ErrorLevelEnum.warning, i18nKey: '_23504' },
  23505: { level: ErrorLevelEnum.warning, i18nKey: '_23505' },
  23506: { level: ErrorLevelEnum.warning, i18nKey: '_23506' },
  23507: { level: ErrorLevelEnum.warning, i18nKey: '_23507' },
  23508: { level: ErrorLevelEnum.warning, i18nKey: '_23508' },
  23509: { level: ErrorLevelEnum.warning, i18nKey: '_23509' },
  23510: { level: ErrorLevelEnum.warning, i18nKey: '_23510' },
  23511: { level: ErrorLevelEnum.warning, i18nKey: '_23511' },
  23512: { level: ErrorLevelEnum.warning, i18nKey: '_23512' },
  23513: { level: ErrorLevelEnum.warning, i18nKey: '_23513' },
  23514: { level: ErrorLevelEnum.warning, i18nKey: '_23514' },
  23515: { level: ErrorLevelEnum.warning, i18nKey: '_23515' },
  23516: { level: ErrorLevelEnum.warning, i18nKey: '_23516' },
  23517: { level: ErrorLevelEnum.warning, i18nKey: '_23517' },
  23602: { level: ErrorLevelEnum.warning, i18nKey: '_23602' },
  // 交易管理
  23701: { level: ErrorLevelEnum.warning, i18nKey: '_23701' },
  23702: { level: ErrorLevelEnum.warning, i18nKey: '_23702' },

  // HM专项
  23901: { level: ErrorLevelEnum.warning, i18nKey: '_23901' },
  23902: { level: ErrorLevelEnum.warning, i18nKey: '_23902' },
  23903: { level: ErrorLevelEnum.warning, i18nKey: '_23903' },
  23904: { level: ErrorLevelEnum.warning, i18nKey: '_23904' },
  23905: { level: ErrorLevelEnum.warning, i18nKey: '_23905' },
  23906: { level: ErrorLevelEnum.warning, i18nKey: '_23906' },
  23907: { level: ErrorLevelEnum.warning, i18nKey: '_23907' },
  23908: { level: ErrorLevelEnum.warning, i18nKey: '_23908' },

  // 耗损
  24001: { level: ErrorLevelEnum.warning, i18nKey: '_24001' },

  // 安装工人管理
  24101: { level: ErrorLevelEnum.warning, i18nKey: '_24101' },
  24105: { level: ErrorLevelEnum.warning, i18nKey: '_24105' },
  24107: { level: ErrorLevelEnum.warning, i18nKey: '_24107' },
  24108: { level: ErrorLevelEnum.warning, i18nKey: '_24108' },
  24109: { level: ErrorLevelEnum.warning, i18nKey: '_24109' },

  // 项目门店管理
  24301: { level: ErrorLevelEnum.warning, i18nKey: '_24301' },
  24302: { level: ErrorLevelEnum.warning, i18nKey: '_24302' },
  24303: { level: ErrorLevelEnum.warning, i18nKey: '_24303' },
  24304: { level: ErrorLevelEnum.warning, i18nKey: '_24304' },
  24305: { level: ErrorLevelEnum.warning, i18nKey: '_24305' },
  24306: { level: ErrorLevelEnum.warning, i18nKey: '_24306' },
  24307: { level: ErrorLevelEnum.warning, i18nKey: '_24307' },
  24308: { level: ErrorLevelEnum.warning, i18nKey: '_24308' },

  // 反馈建议
  24601: { level: ErrorLevelEnum.warning, i18nKey: '_24601' },
  24602: { level: ErrorLevelEnum.warning, i18nKey: '_24602' },
  24603: { level: ErrorLevelEnum.warning, i18nKey: '_24603' },

  // 货币管理
  24701: { level: ErrorLevelEnum.warning, i18nKey: '_24701' },
  24702: { level: ErrorLevelEnum.warning, i18nKey: '_24702' },
  24703: { level: ErrorLevelEnum.warning, i18nKey: '_24703' },
  24704: { level: ErrorLevelEnum.warning, i18nKey: '_24704' },
  24705: { level: ErrorLevelEnum.warning, i18nKey: '_24705' },
  24706: { level: ErrorLevelEnum.warning, i18nKey: '_24706' },
  24707: { level: ErrorLevelEnum.warning, i18nKey: '_24707' },

  // 税率管理
  24801: { level: ErrorLevelEnum.warning, i18nKey: '_24801' },
  24802: { level: ErrorLevelEnum.warning, i18nKey: '_24802' },
  24803: { level: ErrorLevelEnum.warning, i18nKey: '_24803' },
  24804: { level: ErrorLevelEnum.warning, i18nKey: '_24804' },
  24805: { level: ErrorLevelEnum.warning, i18nKey: '_24805' },

  // 计量单位管理
  24901: { level: ErrorLevelEnum.warning, i18nKey: '_24901' },
  24902: { level: ErrorLevelEnum.warning, i18nKey: '_24902' },
  24903: { level: ErrorLevelEnum.warning, i18nKey: '_24903' },
  24904: { level: ErrorLevelEnum.warning, i18nKey: '_24904' },
  24905: { level: ErrorLevelEnum.warning, i18nKey: '_24905' },
  24906: { level: ErrorLevelEnum.warning, i18nKey: '_24906' },

  // 计量单位分组管理
  25007: { level: ErrorLevelEnum.warning, i18nKey: '_25007' },

  // 改色样
  25201: { level: ErrorLevelEnum.warning, i18nKey: '_25201' },
  25202: { level: ErrorLevelEnum.warning, i18nKey: '_25202' },
  25203: { level: ErrorLevelEnum.warning, i18nKey: '_25203' },
  25204: { level: ErrorLevelEnum.warning, i18nKey: '_25204' },
  25205: { level: ErrorLevelEnum.warning, i18nKey: '_25205' },
  25206: { level: ErrorLevelEnum.warning, i18nKey: '_25206' },

  // 仓库仓位关系
  25301: { level: ErrorLevelEnum.warning, i18nKey: '_25301' },
  25302: { level: ErrorLevelEnum.warning, i18nKey: '_25302' },

  // 物料清单
  25401: { level: ErrorLevelEnum.warning, i18nKey: '_25401' },
  25402: { level: ErrorLevelEnum.warning, i18nKey: '_25402' },
  25403: { level: ErrorLevelEnum.warning, i18nKey: '_25403' },
  25404: { level: ErrorLevelEnum.warning, i18nKey: '_25404' },
  25405: { level: ErrorLevelEnum.warning, i18nKey: '_25405' },

  25501: { level: ErrorLevelEnum.warning, i18nKey: '_255501' },
  25502: { level: ErrorLevelEnum.warning, i18nKey: '_255502' },

  // 审批流
  42401: { level: ErrorLevelEnum.warning, i18nKey: '_42401' },
  25601: { level: ErrorLevelEnum.warning, i18nKey: '_25601' },
  25602: { level: ErrorLevelEnum.warning, i18nKey: '_25602' },
  25603: { level: ErrorLevelEnum.warning, i18nKey: '_25603' },
  25604: { level: ErrorLevelEnum.warning, i18nKey: '_25604' }
};

/**
 * 获取api响应结果中的异常信息
 * @param code 异常码
 * @returns 经由国际化处理后的异常信息
 */
export const getErrorObj = (code: number): ApiError => {
  const key = `${prefixName}.${constantError[code]?.i18nKey || 'unknownError'}`;
  return {
    code: code,
    level: constantError[code]?.level || ErrorLevelEnum.error,
    message: i18n.t(key) as string
  };
};

const httpPrefixName = 'httpRequestError';
const constantHttpError: { [prop: number]: { level: ErrorLevelEnum; i18nKey: string } } = {
  400: { level: ErrorLevelEnum.error, i18nKey: 'requestError' },
  401: { level: ErrorLevelEnum.error, i18nKey: 'unAuthorized' },
  403: { level: ErrorLevelEnum.error, i18nKey: 'serverDeniedAccess' },
  404: { level: ErrorLevelEnum.error, i18nKey: 'resourceNotFound' },
  405: { level: ErrorLevelEnum.error, i18nKey: 'methodDisabled' },
  408: { level: ErrorLevelEnum.error, i18nKey: 'timeout' },
  500: { level: ErrorLevelEnum.error, i18nKey: 'serverError' },
  501: { level: ErrorLevelEnum.error, i18nKey: 'serverNotSupport' },
  502: { level: ErrorLevelEnum.error, i18nKey: 'networkError' },
  503: { level: ErrorLevelEnum.error, i18nKey: 'serveDied' },
  504: { level: ErrorLevelEnum.error, i18nKey: 'neworkTimeout' },
  505: { level: ErrorLevelEnum.error, i18nKey: 'httpVersionNotSupport' },
  5001: { level: ErrorLevelEnum.info, i18nKey: 'httpCanceled' },
  5002: { level: ErrorLevelEnum.warning, i18nKey: 'oftenRequest' }
};

/**
 * 根据http响应异常状态码，返回异常信息
 * @param code 异常状态码
 * @returns 经由国际化处理后的异常信息
 */
export const getHttpErrorObj = (code?: number): ApiError => {
  if (!code) {
    return {
      level: ErrorLevelEnum.error,
      message: i18n.t(`${httpPrefixName}.otherError`) as string
    };
  }
  const key = `${httpPrefixName}.${constantHttpError[code]?.i18nKey || 'otherError'}`;
  return {
    code: code,
    level: constantHttpError[code]?.level || ErrorLevelEnum.error,
    message: i18n.t(key) as string
  };
};

export const getBatchOperationErrorObjs = (errors: Array<BatchOperationError>): Array<ApiError> => {
  if (!errors || errors.length === 0) {
    return [
      {
        level: ErrorLevelEnum.error,
        message: i18n.t(`${httpPrefixName}.otherError`) as string
      }
    ];
  }
  return errors.map(x => {
    const obj = getErrorObj(x.code);
    obj.message = `${x.key} : ${obj.message}`;
    return obj;
  });
};
