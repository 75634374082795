/**
 * 项次类型
 */
export enum ProjectItemTypeEnum {
  /**
   * 成品
   */
  finishedProduct = 1,
  /**
   * 改色
   */
  changeColor,
  /**
   * 排版
   */
  designArtis,
  /**
   * 服务
   */
  server,
  /**
   * 费用
   */
  cost,
  /**
   * 耗损项次
   */
  consumed = -1
}
/**
 * 单据类型
 */
export enum OrderTypeEnum {
  /**
   * 成品
   */
  finishedProduct = 10,

  /**
   * 排版
   */
  designArtis = 20
  // /**
  //  * 改色
  //  */
  // changeColor = 30
}
export enum ConfirmResultEnum {
  /**
   * 新建
   */
  new = 10,
  /**
   * 确稿
   */
  confirm = 20,
  /**
   * 改稿
   */
  reedit = 30
}
