import path from 'path';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import { PermissionModule } from '@/store/modules/permission';
import { TagsViewModule, ITagView } from '@/store/modules/tags-view';
import ScrollPane from './scroll-pane/scroll-pane.vue';
import { eventCenter } from '@/utils/event-center';

@Component({
  name: 'TagsView',
  components: {
    ScrollPane
  }
})
export default class extends Vue {
  private visible = false;
  private top = 0;
  private left = 0;
  private selectedTag: ITagView = {};
  private affixTags: ITagView[] = [];

  public get visitedViews(): Array<any> {
    return TagsViewModule.visitedViews;
  }

  public get routes(): Array<RouteConfig> {
    return PermissionModule.routes;
  }

  public created(): void {
    eventCenter.$on('refresh-current-tag', () => {
      // console.log('event:on', 'refresh-current-tag');
      this.refresh(this.$route);
    });
  }

  public mounted(): void {
    this.initTags();
    this.addTags();
  }

  private refresh(view: ITagView): void {
    TagsViewModule.DelCachedView(view);
    const { fullPath } = view;
    this.$nextTick(() => {
      this.$router
        .replace({
          path: '/redirect' + fullPath
        })
        .catch(err => {
          console.warn(err);
        });
    });
  }

  @Watch('$route')
  private onRouteChange(): void {
    this.addTags();
    this.moveToCurrentTag();
  }

  @Watch('visible')
  private onVisibleChange(value: boolean): void {
    if (value) {
      document.body.addEventListener('click', this.closeMenu);
    } else {
      document.body.removeEventListener('click', this.closeMenu);
    }
  }

  private isActive(route: ITagView): boolean {
    return route.path === this.$route.path;
  }

  private isAffix(tag: ITagView): boolean {
    return tag.meta?.affix;
  }

  private filterAffixTags(routes: RouteConfig[], basePath: string = '/'): Array<ITagView> {
    let tags: ITagView[] = [];
    routes.forEach(route => {
      if (route.meta?.affix) {
        const tagPath = path.resolve(basePath, route.path);
        tags.push({
          fullPath: tagPath,
          path: tagPath,
          name: route.name,
          meta: { ...route.meta }
        });
      }
      if (route.children) {
        const childTags = this.filterAffixTags(route.children, route.path);
        if (childTags.length >= 1) {
          tags = [...tags, ...childTags];
        }
      }
    });
    return tags;
  }

  private initTags(): void {
    this.affixTags = this.filterAffixTags(this.routes);
    for (const tag of this.affixTags) {
      // Must have tag name
      if (tag.name) {
        TagsViewModule.AddVisitedView(tag);
      }
    }
  }

  private addTags(): void {
    const { name } = this.$route;
    if (name) {
      TagsViewModule.AddView(this.$route);
    }
  }

  private moveToCurrentTag(): void {
    const tags = this.$refs.tag as any[]; // TODO: better typescript support for router-link
    this.$nextTick(() => {
      for (const tag of tags) {
        if ((tag.to as ITagView).path === this.$route.path) {
          (this.$refs.scrollPane as ScrollPane).moveToTarget(tag as any);
          // When query is different then update
          if ((tag.to as ITagView).fullPath !== this.$route.fullPath) {
            TagsViewModule.UpdateVisitedView(this.$route);
          }
          break;
        }
      }
    });
  }

  private closeSelectedTag(view: ITagView): void {
    TagsViewModule.DelView(view);
    if (this.isActive(view)) {
      this.toLastView(TagsViewModule.visitedViews, view);
    }
  }

  private closeOthersTags(): void {
    if (this.selectedTag.fullPath !== this.$route.path && this.selectedTag.fullPath !== undefined) {
      this.$router.push(this.selectedTag.fullPath).catch(err => {
        console.warn(err);
      });
    }
    TagsViewModule.DelOthersViews(this.selectedTag);
    this.moveToCurrentTag();
  }

  private closeAllTags(view: ITagView): void {
    TagsViewModule.DelAllViews();
    if (this.affixTags.some(tag => tag.path === this.$route.path)) {
      return;
    }
    this.toLastView(TagsViewModule.visitedViews, view);
  }

  private toLastView(visitedViews: ITagView[], view: ITagView): void {
    const latestView = visitedViews.slice(-1)[0];
    if (latestView?.fullPath !== undefined) {
      this.$router.push(latestView.fullPath).catch(err => {
        console.warn(err);
      });
    } else {
      // Default redirect to the home page if there is no tags-view, adjust it if you want
      if (view.name === 'Dashboard') {
        // to reload home page
        this.$router.replace({ path: '/redirect' + view.fullPath }).catch(err => {
          console.warn(err);
        });
      } else {
        this.$router.push('/').catch(err => {
          console.warn(err);
        });
      }
    }
  }

  private openMenu(tag: ITagView, e: MouseEvent): void {
    const menuMinWidth = 105;
    const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
    const offsetWidth = (this.$el as HTMLElement).offsetWidth; // container width
    const maxLeft = offsetWidth - menuMinWidth; // left boundary
    const left = e.clientX - offsetLeft + 15; // 15: margin right
    if (left > maxLeft) {
      this.left = maxLeft;
    } else {
      this.left = left;
    }
    this.top = e.clientY;
    this.visible = true;
    this.selectedTag = tag;
  }

  private closeMenu(): void {
    this.visible = false;
  }

  private handleScroll(): void {
    this.closeMenu();
  }
}
