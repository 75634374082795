import Vue, { VNode } from 'vue';
import 'normalize.css';
import ElementUI from 'element-ui';
import '@/styles/element-variables.scss';
// import '@/styles/ag-grid-variables.scss';
// 解决饿了么ui字体乱码
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss';
import '@/icons';
import App from '@/app.vue';
import store from '@/store';
import router from '@/router';
import i18n from '@/lang';
import * as echarts from 'echarts';
import '@/permission';
import * as directives from '@/directives';
import * as filters from '@/filters';
import { DirectiveOptions } from 'vue/types/umd';
import * as components from '@/components';
import Ellipsis from '@/views/system-setting/approval-setting/components/ellipsis/ellipsis.vue';
import moment from 'moment';

Vue.use(ElementUI, {
  i18n: (key: string, value: string) => i18n.t(key, value)
});
// 全局引入echarts
Vue.prototype.$echarts = echarts;

// 注册全局指令
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key]);
});

// 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string]: Function })[key]);
});

// 注册公共组件
Object.keys(components).forEach(key => {
  Vue.component(key, (components as any)[key]);
});

Vue.component('Ellipsis', Ellipsis);

Vue.config.productionTip = false;

moment.locale(i18n.locale);

new Vue({
  router,
  store,
  i18n,
  render: (h): VNode => h(App)
}).$mount('#app');
