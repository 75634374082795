/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

/**
 * 财务管理路由配置
 */
export const financeRoutes: RouteConfig = {
  path: '/finance',
  component: Layout,
  redirect: 'billing',
  name: 'Finance',
  children: [
    {
      path: '/todo',
      name: 'Todo',
      component: async () => import(/* webpackChunkName: "todo" */ '@/views/finance-management/todo/todo.vue'),
      meta: {
        title: 'route.todo',
        id: 1583
      }
    },
    {
      path: '/billing',
      name: 'Billing',
      component: async () => import(/* webpackChunkName: "billing" */ '@/views/finance-management/billing/billing.vue'),
      meta: {
        title: 'route.billing',
        id: 1585
      },
      children: [
        {
          path: '/add-billing',
          name: 'AddBilling',
          component: async () =>
            import(
              /* webpackChunkName: "add-billing" */ '@/views/finance-management/billing/add-billing/add-billing.vue'
            ),
          meta: {
            title: 'route.addBilling',
            hidden: true,
            id: 1588,
            parentId: 1585
          }
        }
      ]
    },

    {
      path: '/billing-details',
      name: 'BillingDetails',
      component: async () =>
        import(
          /* webpackChunkName: "billing-details" */ '@/views/finance-management/billing/billing-details/billing-details.vue'
        ),
      meta: {
        title: 'route.billingDetails',
        hidden: true,
        id: 1608,
        parentId: 1585
      }
    },
    {
      path: '/finance-invoice',
      name: 'FinanceInvoice',
      component: async () =>
        import(
          /* webpackChunkName: "finance-invoice" */ '@/views/finance-management/finance-invoice/finance-invoice.vue'
        ),
      meta: {
        title: 'route.financeInvoice',
        id: 1584
      },
      children: [
        {
          path: '/invoice-details',
          name: 'InvoiceDetails',
          component: async () =>
            import(
              /* webpackChunkName: "finance-invoice-details" */ '@/views/finance-management/finance-invoice/invoice-details/invoice-details.vue'
            ),
          meta: {
            title: 'route.invoiceDetails',
            id: 1610,
            hidden: true,
            parentId: 1584
          }
        }
      ]
    },
    {
      path: '/transaction-details',
      name: 'TransactionDetails',
      component: async () =>
        import(
          /* webpackChunkName: "transaction-details" */ '@/views/finance-management/transaction-details/transaction-details.vue'
        ),
      meta: {
        title: 'route.transactionDetails',
        id: 1586
      }
    }
  ],
  meta: {
    title: 'route.financeManagement',
    icon: '#finance',
    id: 1582
  }
};
