import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Ellipsis',
  components: {}
})
export default class Ellipsis extends Vue {
  @Prop({ type: Number, default: 1 })
  public row!: number;
  @Prop({ type: Boolean, default: true })
  public hoverTip!: Boolean;
  @Prop({ type: String, default: '' })
  public content!: number;
}
