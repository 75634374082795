import { DeliveryStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  DeliveryNoticeDetail,
  DeliveryNoticeList,
  DeliveryNoticeListQuery,
  DeliveryShopList,
  ProductProjectListQuery,
  ProjectProductDelivery,
  SaveDeliveryNotice
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload, messageError, translation } from '@/utils';
import { AxiosResponse } from 'axios';
import { NormalSelectOptions } from '@/resource/model/common';

class DeliveryNoticeService {
  public getById(id: number): Promise<DeliveryNoticeDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<DeliveryNoticeDetail>(ResourceUrlEnum.deliveryNotice, { params: { id } })
        .then((res: ApiResponse<DeliveryNoticeDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SaveDeliveryNotice): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<DeliveryNoticeDetail>(ResourceUrlEnum.deliveryNotice, resource)
        .then((res: ApiResponse<DeliveryNoticeDetail>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: DeliveryNoticeDetail): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<DeliveryNoticeDetail>(ResourceUrlEnum.deliveryNotice, resource)
        .then((res: ApiResponse<DeliveryNoticeDetail>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.deliveryNotice, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchPush(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.deliveryNotice}/batchPush`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchCancel(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.deliveryNotice}/batchCancel`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存并推送发货通知单
   * @param resource 发货通知单
   * @returns
   */
  public saveAndPush(resource: SaveDeliveryNotice): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<DeliveryNoticeDetail>(`${ResourceUrlEnum.deliveryNotice}/savePush`, resource)
        .then((res: ApiResponse<DeliveryNoticeDetail>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑并推送发货通知单
   * @param resource 发货通知单
   * @returns
   */
  public editAndPush(resource: DeliveryNoticeDetail): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<DeliveryNoticeDetail>(`${ResourceUrlEnum.deliveryNotice}/editPush`, resource)
        .then((res: ApiResponse<DeliveryNoticeDetail>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<DeliveryNoticeListQuery>,
    paging: Paging,
    sortOptions: SortOptions<DeliveryNoticeList>
  ): Promise<ApiResponse<Array<DeliveryNoticeList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DeliveryNoticeList>>(`${ResourceUrlEnum.deliveryNotice}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<DeliveryNoticeList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项目下产品分类
   * @param projectId 项目id
   * @returns
   */
  public getProductClassifies(projectId: number): Promise<Array<{ categoryId: number; categoryName: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ categoryId: number; categoryName: string }>>(
          `${ResourceUrlEnum.deliveryNotice}/getProductCategoryList`,
          {
            params: { projectId }
          }
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项目内产品项次
   * @param query 项目id
   * @param paging 分页
   * @returns
   */
  public getProjectProduct(
    query: Partial<ProductProjectListQuery>,
    paging: Paging
  ): Promise<ApiResponse<Array<ProjectProductDelivery>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectProductDelivery>>(`${ResourceUrlEnum.deliveryNotice}/getDeliveryItems`, {
          ...query,
          ...paging
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除发货单下门店发货明细
   * @param idList 门店发货明细id
   * @returns
   */
  public batchDeleteDeliveryShop(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.deliveryNotice}/deleteShop`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除门店下发货的产品
   * @param idList 门店下发货的产品
   * @returns
   */
  public batchDeleteDeliveryProduct(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.deliveryNotice}/deleteShopItemRel`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载导入发货明细模板
   * @param projectId 项目id
   * @returns
   */
  public downloadTemplate(projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.deliveryNotice}/downloadTemplate`, {
          params: { projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入发货明细数据
   * @param filePath 文件在minio上的路径
   * @param projectId 项目id
   * @returns
   */
  public importDeliveryDetails(filePath: string, projectId: number): Promise<Array<DeliveryShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DeliveryShopList>>(`${ResourceUrlEnum.deliveryNotice}/importData`, {
          filePath,
          projectId
        })
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取编辑项目时，配置的发货明细
   * @param projectId
   * @returns
   */
  public getDeliveryDetailsByProject(projectId: number): Promise<Array<DeliveryShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DeliveryShopList>>(`${ResourceUrlEnum.deliveryNotice}/getDeliveryDataByProjectId`, {
          params: { projectId }
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取发货通知单状态
   * @returns
   */
  public getStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in DeliveryStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`deliveryNoticeStatus.${key}`),
          value: DeliveryStatusEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 导出pop类发货明细
   * @param id 发货通知单id
   * @returns
   */
  public exportPop(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.deliveryNotice}/exportPop`, {
          params: { id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出海报类发货明细
   * @param id 发货通知单ids
   * @returns
   */
  public exportPoster(ids: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.deliveryNotice}/export`, ids, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 通过交货门店id门店下对应要发货的项次
   * @param projectShopId 项目交货门店id
   * @returns
   */
  public getDeliveryShopItemsByShops(shopIds: Array<number>): Promise<Array<DeliveryShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DeliveryShopList>>(`${ResourceUrlEnum.deliveryNotice}/getProjectShopItemRel`, shopIds)
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const deliveryNoticeService = new DeliveryNoticeService();
export default deliveryNoticeService;
