import { queryPlanService } from '@/api';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { QueryPlanResource } from '@/resource/model';
import { messageError, showWarningConfirm, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class OperationQueryPlan extends mixins(DialogMixin, CustomColumnMixin) {
  @Prop({ required: true, type: Number, default: 0 }) public code!: number;

  public tableOption: OsTableOption<QueryPlanResource> = {
    loading: false,
    data: [],
    fit: true
  };
  public columnOptions: Array<OsTableColumn<any>> = [];

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<QueryPlanResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'name',
      label: 'queryPlan.planName',
      minWidth: '80px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'json',
      label: 'queryPlan.plan',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<QueryPlanResource> = {
    fixed: 'right',
    width: '100px',
    operations: [
      // {
      //   operationType: 'edit',
      //   type: 'text',
      //   label: 'button.edit',
      //   icon: 'el-icon-edit',
      //   permissionCode: 'project:edit',
      //   handleClick: (item: QueryPlanResource): void => {},
      // },
      // {
      //   operationType: 'add',
      //   type: 'text',
      //   label: 'button.distributeOrder',
      //   icon: 'el-icon-plus',
      //   permissionCode: 'production:order:save',
      //   handleClick: (item: QueryPlanResource): void => { },
      // },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'project:delete',
        handleClick: (item: QueryPlanResource): void => {
          this.delete(item.id);
        }
      }
    ]
  };

  public dialogOpen(): void {
    this.title = 'queryPlan.operationTitle';
    this.initColumns(this.defaultColumnOptions, 'queryPlanTable');
    this.getQueryPlanList();
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.queryPlanFrom as Form).resetFields();
  }

  public delete(id: number): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await queryPlanService.delete(id);
          this.getQueryPlanList();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  public getQueryPlanList(): void {
    queryPlanService
      .getList({ code: this.code })
      .then(res => {
        this.tableOption.data = res.data;
        this.$emit('edit-success');
      })
      .catch(error => {
        messageError(error);
      });
  }
}
