/**
 * 系统预置
 */
export enum SystemPresetEnum {
  /**
   * 是
   */
  yes = 1,
  /**
   * 否
   */
  No = 0
}
