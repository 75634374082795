import { MinIOFile } from '@/api/minio';
import { ResourceUrlEnum } from '@/resource/enum';
import { UploadedFile } from '@/resource/model';
import { handleDownload } from '@/utils';
import { axiosRequest } from '@/utils/axios-request';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { axiosService, ApiError } from './axios';

export class FileService {
  /**
   * 文件单个上传
   * @param file 文件对象
   * @param uploadPath 保存目录
   * @returns
   */
  public static upload(file: MinIOFile, uploadPath: string): Promise<UploadedFile> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('bucket', 'upload');
      formData.append('path', uploadPath);
      formData.append('file', file.originFile!);

      const url = process.env.VUE_APP_FILE_SERVICE_BASE_URL + ResourceUrlEnum.upload;
      axiosService
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data;charset=utf-8'
          }
        })
        .then((res: any) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 文件批量上传
   * @param files 文件对象数组
   * @param uploadPath 保存目录
   * @returns
   */
  public static batchUpload(files: Array<MinIOFile>, uploadPath: string): Promise<Array<UploadedFile>> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('bucket', 'upload');
      formData.append('path', uploadPath);
      files.forEach(file => {
        formData.append('fileList', file.originFile!);
      });

      const url = process.env.VUE_APP_FILE_SERVICE_BASE_URL + ResourceUrlEnum.batchUpload;
      axiosService
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data;charset=utf-8'
          }
        })
        .then((res: any) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分组压缩文件下载
   * @param file 文件对象
   * @param uploadPath 保存目录
   * @returns
   */
  public static downloadGroupZip(idList: Array<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const url = process.env.VUE_APP_FILE_SERVICE_BASE_URL + ResourceUrlEnum.downloadGroupZip;
      axiosRequest.instance
        .post<any>(url, idList, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 文件内下载
   * @param file 文件对象
   * @param uploadPath 保存目录
   * @returns
   */
  public static downloadZip(idList: Array<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const url = process.env.VUE_APP_FILE_SERVICE_BASE_URL + ResourceUrlEnum.downloadZip;
      axiosRequest.instance
        .post<any>(url, idList, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
