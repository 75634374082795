/**
 * 第一位区分系统 1：平台端系统、2：供应商系统
 * 第二三位区分模块 01：项目管理、02：印前任务、03：客户管理、04：产品管理、05：基础数据、06：工艺管理、
 *               07：供应商管理、08：生产管理、09：物流管理、10：安装管理、11：财务管理
 */
export enum PageQueryPlanEnum {
  /**
   * 项目管理 01
   *  --项目列表 01
   *   --功能：列表页 01
   */
  projectListPage = 1010101,
  /**
   * 项目管理 01
   *  --项目列表 01
   *   --功能：项目详情-制作明细 02
   */
  projectProduct = 1010102,
  /**
   * 项目管理 01
   *  --销售明细 02
   *    --功能：列表 01
   */
  saledetails = 1010201,
  /**
   * 印前任务 02
   *  --我的改色任务 01
   *   --功能：列表 01
   */
  mineChangeColorTask = 1020101,
  /**
   * 印前任务 02
   *  --我的排版任务 02
   *   --功能：列表 01
   */
  mineCompleteManuscriptTask = 1020201,
  /**
   *  印前任务 02
   *   --改色任务 03
   *    --功能：列表 01
   */
  changeColorTask = 1020301,

  /**
   * 印前任务 02
   *  --完稿任务 04
   *   --功能：列表 01
   */
  completeManuscriptTask = 1020401,
  /**
   * 印前任务 02
   *  --改色样管理 05
   *   --功能：列表 01
   */
  colorChangeSample = 1020501,
  /**
   * 印前任务 02
   *  --我的成品任务 06
   *   --功能：列表 01
   */
  mineFinishProductTask = 1020601,

  /**
   * 物流管理 09
   *  --物流单 01
   *    --功能：列表 01
   */
  logistics = 1090101,
  /**
   * 物流管理 09
   *  --发货通知单 02
   *    --功能：列表 01
   */
  deliveryNotice = 1090201,
  /**
   *  安装管理 10
   *  --安装通知单 01
   *    --功能：列表 01
   */
  installationNotice = 1100101,
  /**
   *  安装管理 10
   *  --安装单 02
   *    --功能：列表 01
   */
  installationOrder = 1100201,
  /**
   * 财务管理 11
   *  --结算管理 01
   *    --功能：列表 01
   */
  billing = 1110101,
  /**
   * 财务管理 11
   *  --发票管理 02
   *    --功能：列表 01
   */
  financeInvoice = 1110201,
  /**
   * 财务管理 11
   *  --发票管理 03
   *    --功能：列表 01
   */
  todo = 1110301
}
