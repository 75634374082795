import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  StoreLocationList,
  StoreLocationQuery,
  StoreLocationResource,
  ImportRes,
  StoreLocationInfo
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';

class StoreLocationService {
  public getById(id: number): Promise<StoreLocationInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<StoreLocationInfo>(ResourceUrlEnum.storeLocation, { params: { id } })
        .then((res: ApiResponse<StoreLocationInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: StoreLocationResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<StoreLocationResource>(ResourceUrlEnum.storeLocation, resource)
        .then((res: ApiResponse<StoreLocationResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: StoreLocationResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<StoreLocationResource>(ResourceUrlEnum.storeLocation, resource)
        .then((res: ApiResponse<StoreLocationResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.storeLocation}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 分页列表
   * @param query
   * @param paging
   * @returns
   */

  public getList(query?: Partial<StoreLocationQuery>, paging?: Paging): Promise<ApiResponse<Array<StoreLocationList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<StoreLocationList>>(`${ResourceUrlEnum.storeLocation}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<StoreLocationList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 弹窗组件
   * @param query
   * @param paging
   * @returns
   */
  public getSelectList(
    query?: Partial<{ name: String; code: String }>,
    paging?: Paging
  ): Promise<ApiResponse<Array<StoreLocationList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<StoreLocationList>>(`${ResourceUrlEnum.storeLocation}/listForComponents`, cloneQuery)
        .then((res: ApiResponse<Array<StoreLocationList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修状态
   * @param idList
   * @param status
   * @returns
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.storeLocation}/editEnableStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 审批
   * @param idList
   * @returns
   */
  public batchApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.storeLocation}/approve`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 反审批
   * @param idList
   * @returns
   */
  public batchCancelApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.storeLocation}/cancelApprove`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载汇率导入模板
   * @returns
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.storeLocation}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public import(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.storeLocation}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编码去重
   * @param code
   * @param id
   * @returns
   */
  public checkCode(code: string, id?: number | null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.storeLocation}/checkCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 名称去重
   * @param name
   * @param id
   * @returns
   */
  public checkName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.storeLocation}/checkName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const storeLocationService = new StoreLocationService();
export default storeLocationService;
