import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { getSidebarStatus, setLanguage, setSidebarStatus } from '@/utils/cookies';
import store from '@/store';
import { getLocale } from '@/lang';
import moment from 'moment';

export enum DeviceType {
  Mobile,
  Desktop
}

export interface IAppState {
  device: DeviceType;
  sidebar: {
    opened: boolean;
    withoutAnimation: boolean;
  };
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  public sidebar = {
    opened: getSidebarStatus() !== 'closed',
    withoutAnimation: false
  };

  public device = DeviceType.Desktop;
  public language = getLocale();

  @Action
  public ToggleSideBar(withoutAnimation: boolean): void {
    this.toggleSidebar(withoutAnimation);
  }

  @Action
  public CloseSideBar(withoutAnimation: boolean): void {
    this.closeSidebar(withoutAnimation);
  }

  @Action
  public ToggleDevice(device: DeviceType): void {
    this.toggleDevice(device);
  }

  @Action
  public SetLanguage(language: string): void {
    this.setLanguage(language);
  }

  @Mutation
  private toggleSidebar(withoutAnimation: boolean): void {
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
    if (this.sidebar.opened) {
      setSidebarStatus('opened');
    } else {
      setSidebarStatus('closed');
    }
  }

  @Mutation
  private closeSidebar(withoutAnimation: boolean): void {
    this.sidebar.opened = false;
    this.sidebar.withoutAnimation = withoutAnimation;
    setSidebarStatus('closed');
  }

  @Mutation
  private toggleDevice(device: DeviceType): void {
    this.device = device;
  }

  @Mutation
  private setLanguage(language: string): void {
    this.language = language;
    setLanguage(this.language);
    moment.locale(language);
  }
}

export const AppModule = getModule(App);
