import {
  CurrencyEnum,
  PaymentModeEnum,
  ResourceUrlEnum,
  TransactionStatusEnum,
  TransactionTypeEnum
} from '@/resource/enum';
import { ImportRes, SaveTransaction, TransactionList, TransactionListQuery } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload, translation } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class TransactionService {
  public post(resource: SaveTransaction): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(ResourceUrlEnum.transaction, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SaveTransaction): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(ResourceUrlEnum.transaction, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.transaction}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<TransactionListQuery>, paging?: Paging): Promise<ApiResponse<Array<TransactionList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<TransactionList>>(`${ResourceUrlEnum.transaction}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public import(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.transaction}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载交易明细导入模板
   * @returns
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.transaction}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in TransactionStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`transactionStatus.${key}`),
          value: TransactionStatusEnum[key]
        });
      }
    }
    return options;
  }

  public getTransactionTypes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in TransactionTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`transactionType.${key}`),
          value: TransactionTypeEnum[key]
        });
      }
    }
    return options;
  }

  public getPaymentModes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PaymentModeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`paymentMode.${key}`),
          value: PaymentModeEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取结算币种
   * @returns
   */
  public getCurrencyTypes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in CurrencyEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`currencyTypes.${key}`),
          value: CurrencyEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 查询交易明细，此接口不走权限控制，一般用于dialog数据选择
   * @param query
   * @param paging
   * @returns
   */
  public getTransactionDetails(
    query?: Partial<TransactionListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<TransactionList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<TransactionList>>(`${ResourceUrlEnum.transaction}/listCondition`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const transactionService = new TransactionService();
export default transactionService;
