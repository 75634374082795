import { ProductionOrderStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { Paging } from '../base';
import { ProductionOrderResource } from '@/resource/model/production-management/production-order';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { handleDownload, messageError, translation } from '@/utils';
import { SupplierResource } from '@/resource/model';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';

class ProductionOrderService {
  /**
   * 导出生产订单明细
   * @param queryForm 查询参数
   * @returns blob
   */
  public exportDetail(queryForm: any, idList: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.productionOrder}/exportPurchaseDetail`,
          { ...queryForm, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取已生成订单相关供应商数据对象集合
   * @returns 供应商数据对象集合
   */
  public getAllUsingSupplier(): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierResource>>(`${ResourceUrlEnum.productionOrder}/listSupplier`, { repeat: true })
        .then((res: ApiResponse<Array<SupplierResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 确认订单完成
   * @param id 订单ID
   */
  public confirmComplete(id: number): Promise<void> {
    if (!id) {
      throw new Error("argument 'id' must not be null");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/finish`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 确认订单完成
   * @param id 订单ID
   */
  public onFinish(id: number): Promise<void> {
    if (!id) {
      throw new Error("argument 'id' must not be null");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/onFinish`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量删除
   * @param idList 资源ID集合
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.productionOrder}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 取消生产订单
   * @param id 订单ID
   */
  public cancel(id: number): Promise<void> {
    if (!id) {
      throw new Error("argument 'id' must not be null");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/cancel`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 完成生产订单
   * @param id 订单ID
   */
  public finish(id: number): Promise<void> {
    if (!id) {
      throw new Error("argument 'id' must not be null");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/finish`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量取消
   * @param idList ID集合
   */
  public batchCancel(idList: Array<number>): Promise<void> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/batchCancel`, idList)
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 派发订单
   * @param id 订单ID
   */
  public distribute(id: number): Promise<void> {
    if (!id) {
      throw new Error("argument 'id' must not be null");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/placeOrder`, {}, { params: { id } })
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量派发订单
   * @param id 订单ID
   */
  public batchDistribute(idList: Array<number>): Promise<void> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrder}/batchPlaceOrder`, idList)
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据ID获取详情
   * @param id ID
   * @returns 数据对象
   */
  public getById(id: number): Promise<ProductionOrderResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductionOrderResource>(`${ResourceUrlEnum.productionOrder}`, { params: { id } })
        .then((res: ApiResponse<ProductionOrderResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getList(
    query: Partial<ProductionOrderResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductionOrderResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderResource>>(`${ResourceUrlEnum.productionOrder}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProductionOrderResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存订单
   * @param resource 订单对象
   */
  public post(resource: ProductionOrderResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.productionOrder}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存并派单
   * @param resource 订单对象
   */
  public postAndSend(resource: ProductionOrderResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.productionOrder}/saveAndPlaceOrder`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑订单
   * @param resource 订单对象
   */
  public put(resource: ProductionOrderResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    if (!resource.id) {
      throw new Error('supplier id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrder}`, resource)
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据ID删除订单
   * @param id 订单ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.productionOrder}`, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: ProductionOrderStatusEnum): string {
    if (!ProductionOrderStatusEnum[status]) {
      return 'common.unknown';
    }

    return 'order.' + ProductionOrderStatusEnum[status];
  }

  /**
   * 根据状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: ProductionOrderStatusEnum): string {
    let className = '';
    switch (Number(status)) {
      case ProductionOrderStatusEnum.new:
        className = 'default-dot';
        break;
      case ProductionOrderStatusEnum.waitingAccept:
        className = 'danger-dot';
        break;
      case ProductionOrderStatusEnum.waitingPlan:
        className = 'warning-dot';
        break;
      case ProductionOrderStatusEnum.processing:
        className = 'primary-dot';
        break;
      case ProductionOrderStatusEnum.waitingConfirm:
        className = 'warning-dot';
        break;
      case ProductionOrderStatusEnum.completed:
        className = 'success-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 获取订单可选状态集合
   * @returns
   */
  public getStatusSelectableList(): Array<any> {
    const list = [];
    for (const key in ProductionOrderStatusEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        list.push({
          label: translation(this.getStatusI18Key(Number(key))),
          value: key
        });
      }
    }

    return list;
  }
}
const productionOrderService = new ProductionOrderService();
export default productionOrderService;
