/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const prepressStatisticsRoutes: RouteConfig = {
  path: '/preperss-statistics',
  component: Layout,
  redirect: 'complete-manuscript-work-content',
  children: [
    {
      path: '/complete-manuscript-work-content',
      name: 'CompleteManuscriptWorkContentInfo',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/preperss-statistics/complete-manuscript-work-content/complete-manuscript-work-content.vue'
        ),
      meta: {
        title: 'route.completeManuscriptWorkContent',
        id: 90012
      }
    },
    {
      path: '/department-average-confirmation-round',
      name: 'DepartmentAverageConfirmationRound',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/preperss-statistics/department-average-confirmation-round/department-average-confirmation-round.vue'
        ),
      meta: {
        title: 'route.departmentAverageConfirmationRound',
        id: 90013
      }
    },
    {
      path: '/customer-average-confirmation-round',
      name: 'CustomerAverageConfirmationRound',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/preperss-statistics/customer-average-confirmation-round/customer-average-confirmation-round.vue'
        ),
      meta: {
        title: 'route.customerAverageConfirmationRound',
        id: 90014
      }
    },
    {
      path: '/project-average-confirmation-round',
      name: 'ProjectAverageConfirmationRound',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/preperss-statistics/project-average-confirmation-round/project-average-confirmation-round.vue'
        ),
      meta: {
        title: 'route.projectAverageConfirmationRound',
        id: 90015
      }
    },
    {
      path: '/personal-average-confirmation-rate',
      name: 'PersonalAverageConfirmationRate',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/preperss-statistics/personal-average-confirmation-rate/personal-average-confirmation-rate.vue'
        ),
      meta: {
        title: 'route.personalAverageConfirmationRate',
        id: 90016
      }
    },
    {
      path: '/department-average-confirmation-rate',
      name: 'DepartmentAverageConfirmationRate',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/preperss-statistics/department-average-confirmation-rate/department-average-confirmation-rate.vue'
        ),
      meta: {
        title: 'route.depaertmentAverageConfirmationRate',
        id: 90017
      }
    }
  ],
  meta: {
    title: 'route.prepressStatistics',
    icon: '#craft',
    id: 361
  }
};
