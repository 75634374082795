/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const projectManagementRoutes: RouteConfig = {
  path: '/project-management',
  name: 'ProjectManagement',
  component: Layout,
  redirect: 'project',
  children: [
    {
      path: '/project',
      name: 'Project',
      component: async () => import(/* webpackChunkName: "project" */ '@/views/project-management/project/project.vue'),
      meta: {
        title: 'route.projectList',
        id: 917
      },
      children: [
        {
          path: '/project-details',
          name: 'ProjectDetails',
          component: async () =>
            import(
              /* webpackChunkName: "project-details" */ '@/views/project-management/project/project-details/project-details.vue'
            ),
          meta: {
            title: 'route.projectDetails',
            id: 941,
            hidden: true,
            parentId: 917
          },
          children: []
        }
      ]
    },

    {
      path: '/consume-management',
      name: 'ConsumeManagement',
      component: async () =>
        import(
          /* webpackChunkName: "consume-management" */ '@/views/project-management/consume-management/consume-management.vue'
        ),
      meta: {
        title: 'route.consumeManagement',
        id: 1694
      }
    },
    {
      path: '/project-todo',
      name: 'projectToDo',
      component: async () =>
        import(/* webpackChunkName: "consume-management" */ '@/views/project-management/project-todo/project-todo.vue'),
      meta: {
        title: 'route.projectToDo',
        id: 1868
      }
    },
    {
      path: '/sale-details',
      name: 'SaleDetails',
      component: async () =>
        import(/* webpackChunkName: "consume-management" */ '@/views/project-management/sale-details/sale-details.vue'),
      meta: {
        title: 'route.saleDetails',
        id: 1896
      }
    }
  ],
  meta: {
    title: 'route.project',
    icon: '#project',
    id: 916
  }
};
