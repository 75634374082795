/**
 * 财务发票状态
 */
export enum FinanceInvoiceStatusEnum {
  /**
   * 审批中
   */
  waitAudit = 2,

  /**
   * 审核未通过
   */
  failedAudit,

  /**
   * 待开票
   */
  waitMakeInvoice,
  /**
   * 已开票
   */
  hasMakeInvoice,
  /**
   * 已废除
   */
  abolished
}
