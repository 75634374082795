import { ResourceUrlEnum } from '@/resource/enum';
import { PrepressTaskList, PrepressTaskInfo, PrepressTaskListQuery } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';

class ChangeColorTaskService {
  public getById(id: number): Promise<PrepressTaskInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<PrepressTaskInfo>(ResourceUrlEnum.changeColorTask, { params: { id } })
        .then((res: ApiResponse<PrepressTaskInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<PrepressTaskListQuery>,
    paging: Paging,
    sortOptions: SortOptions<PrepressTaskList>
  ): Promise<ApiResponse<Array<PrepressTaskList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressTaskList>>(`${ResourceUrlEnum.changeColorTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<PrepressTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public export(query?: Partial<PrepressTaskListQuery>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.changeColorTask}/export`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 接单
   * @returns
   */
  public takeOrder(prarms: {
    factor: number;
    idList: Array<number>;
    prepressCraftsId: string;
    prepressCraftsName: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.changeColorTask}/receivingOrder`, prarms)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 反完成
   * @returns
   */
  public cancelComplete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.changeColorTask}/cancelComplete`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 转派
   * @returns
   */
  public redeploy(idList: Array<number>, userId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.changeColorTask}/reassignment`, { idList, userId })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const changeColorTaskService = new ChangeColorTaskService();
export default changeColorTaskService;
