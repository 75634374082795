export enum FeedbackSourceEnum {
  /**
   * 平台系统
   */
  PLATFORM,
  /**
   * 供应商系统
   */
  SUPPLIER,
  /**
   * 移动报工小程
   */
  PRODUCTION_APP,
  /**
   * 安装小程序
   */
  INSTALL_APP
}
