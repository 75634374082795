import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  ImportRes,
  UnitOfMeasurementList,
  UnitOfMeasurementListQuery,
  UnitOfMeasurementResource
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class UnitsMeasureService {
  public getById(id: number): Promise<UnitOfMeasurementList> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<UnitOfMeasurementList>(ResourceUrlEnum.unitsMeasure, { params: { id } })
        .then((res: ApiResponse<UnitOfMeasurementList>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public getDatumName(groupId: number): Promise<{ code: string; id: number; name: string }> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<{ code: string; id: number; name: string }>(`${ResourceUrlEnum.unitsMeasure}/getDatumUnit`, {
          params: { groupId }
        })
        .then((res: ApiResponse<{ code: string; id: number; name: string }>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: UnitOfMeasurementResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<UnitOfMeasurementResource>(ResourceUrlEnum.unitsMeasure, resource)
        .then((res: ApiResponse<UnitOfMeasurementResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: UnitOfMeasurementResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<UnitOfMeasurementResource>(ResourceUrlEnum.unitsMeasure, resource)
        .then((res: ApiResponse<UnitOfMeasurementResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.unitsMeasure}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<UnitOfMeasurementListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<UnitOfMeasurementList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<UnitOfMeasurementList>>(`${ResourceUrlEnum.unitsMeasure}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<UnitOfMeasurementList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getListForComponents(
    query?: Partial<UnitOfMeasurementListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<UnitOfMeasurementList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<UnitOfMeasurementList>>(`${ResourceUrlEnum.unitsMeasure}/listForComponents`, cloneQuery)
        .then((res: ApiResponse<Array<UnitOfMeasurementList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 名称去重
   * @param name
   * @param id
   * @returns
   */
  public checkName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.unitsMeasure}/checkName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkCode(code: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.unitsMeasure}/checkCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 基准编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkDatumCode(datumCode: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.unitsMeasure}/checkDatumCode`, {
          params: { id, datumCode }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 基准编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkDatumName(datumName: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.unitsMeasure}/checkDatumName`, {
          params: { id, datumName }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修状态
   * @param idList 计量单位分组id数组
   * @param status 状态
   * @returns
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.unitsMeasure}/editEnableStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 审批
   * @param idList
   * @returns
   */
  public batchApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.unitsMeasure}/approve`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 反审批
   * @param idList
   * @returns
   */
  public batchCancelApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.unitsMeasure}/cancelApprove`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 下载汇率导入模板
   * @returns
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.unitsMeasure}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public importCustomer(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.unitsMeasure}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取计价单位 已翻译
   * @returns
   */
  public getPriceUnits(): Promise<NormalSelectOptions> {
    const options: NormalSelectOptions = [];
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<UnitOfMeasurementList>>(`${ResourceUrlEnum.unitsMeasure}/listForComponents`, {
          currentPage: 1,
          groupId: null,
          showCount: 100
        })
        .then((res: ApiResponse<Array<UnitOfMeasurementList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          res.data.forEach(item => {
            options.push({
              label: item.name,
              value: item.id
            });
          });
          resolve(options);
        });
    });
  }
}
const unitsMeasureService = new UnitsMeasureService();
export default unitsMeasureService;
