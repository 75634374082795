import { ResourceUrlEnum } from '@/resource/enum';
import { PermissionResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';

class PlatformPermissionService {
  public getById(resourcesId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<PermissionResource>(ResourceUrlEnum.platFormPermission, { params: { resourcesId } })
        .then((res: ApiResponse<PermissionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query: Partial<PermissionResource>): Promise<Array<PermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PermissionResource>>(`${ResourceUrlEnum.platFormPermission}/list`, query)
        .then((res: ApiResponse<Array<PermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getAllPermissions(): Promise<Array<PermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<PermissionResource>>(`${ResourceUrlEnum.platFormPermission}/listPermissions`)
        .then((res: ApiResponse<Array<PermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: PermissionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<PermissionResource>(ResourceUrlEnum.platFormPermission, resource)
        .then((res: ApiResponse<PermissionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: PermissionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<PermissionResource>(ResourceUrlEnum.platFormPermission, resource)
        .then((res: ApiResponse<PermissionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.platFormPermission, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查资源名称是否重复
   * @param name 资源名称
   * @param resourcesId 资源主键（修改时需要传入）
   * @returns
   */
  public checkResourcesName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.platFormPermission}/checkResourcesName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 检查资源名称是否重复
   * @param name 资源名称
   * @param resourcesId 资源主键（修改时需要传入）
   * @returns
   */
  public checkResourcesCode(code: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.platFormPermission}/checkResourcesCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const platformPermissionService = new PlatformPermissionService();
export default platformPermissionService;
