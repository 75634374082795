import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class OsDragLayout extends Vue {
  public middleWidth = 5;
  public left: any = null;
  public middle: any = null;
  public right: any = null;
  public getEle = (id: string): any => {
    return document.getElementById(id);
  };
  public drag(e: any): boolean {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const disX = (e || event).clientX;
    this.middle.left = this.middle.offsetLeft;
    document.onmousemove = function(e: any): boolean {
      let middleLeft = that.middle.left + ((e || event).clientX - disX);
      const maxWidth = that.$parent.$el.clientWidth;
      middleLeft < 155 && (middleLeft = 155);
      middleLeft > 350 && (middleLeft = 350);
      that.middle.style.left = that.left.style.width = middleLeft + 'px';
      that.right.style.width = maxWidth - middleLeft - that.middleWidth + 'px';
      that.right.style.left = middleLeft + that.middleWidth + 'px';
      return false;
    };
    document.onmouseup = function(): void {
      document.onmousemove = null;
      document.onmouseup = null;
      that.middle.releaseCapture?.();
    };
    that.middle.setCapture?.();
    return false;
  }

  public mounted(): void {
    this.left = this.getEle('left');
    this.right = this.getEle('right');
    this.middle = this.getEle('middle');
    this.middle.onmousedown = this.drag;
  }
}
