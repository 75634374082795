import { Component, Vue } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { cloneDeep } from 'lodash-es';
import { OsTableColumn } from '@/components/os-table/os-table';
import { ProductList } from '@/resource/model';
import localStorage from '@/utils/local-storage';
@Component
export class CustomColumnMixin extends Vue {
  /**
   * 自定义的列配置，用于自定义列的展示
   */
  public customColumnOptions: Array<OsTableColumn<any>> = [];

  /**
   * 实际用于展示的列配置
   */
  public columnOptions: Array<OsTableColumn<any>> = [];

  /**
   * 初始化列
   * @param defaultColumnOptions 默认的列配置
   * @param pageName 页面名称
   */
  public initColumns(defaultColumnOptions: Array<OsTableColumn<any>>, pageName: string): void {
    this.initSortProp(defaultColumnOptions);
    const cacheCustomColumnOptions = this.getCustomColumn<ProductList>(pageName);
    // 如果localStorage里不存在自定义列配置，则使用默认的列配置做初始化
    if (!cacheCustomColumnOptions) {
      this.assembleColumns(defaultColumnOptions);
      return;
    }

    // 如果存在，则使用缓存中的列配置做初始化
    const columns = cloneDeep(defaultColumnOptions).map(x => {
      // 更新完版本后，列的prop可能会出现更改，或者干脆被移除掉了，所以find结果会出现预期的undefined
      const customColumn = cacheCustomColumnOptions.find(y => y.prop === x.prop);
      if (customColumn) {
        Object.assign(x, customColumn);
      }
      return x;
    });
    this.assembleColumns(
      columns.sort((a, b) => {
        return a.customIndex! - b.customIndex!;
      })
    );
  }

  /**
   * 处理列变化
   * @param customColumns 自定义的列
   * @param pageName 页面名称
   * @param tableRefName 表格引用名称
   */
  public handleColumnChanged(
    customColumns: Array<OsTableColumn<ProductList>>,
    pageName: string,
    tableRefName: string
  ): void {
    this.initSortProp(customColumns);
    this.columnOptions = customColumns.filter(x => !x.hide);
    (this.$refs[tableRefName] as OsTable).reload();
    // const data = cloneDeep(customColumns);
    localStorage.set(pageName, customColumns);
  }

  /**
   * 重置列
   * @param defaultColumnOptions 默认的列配置
   * @param pageName 页面名称
   * @param tableName 表格引用名称
   */
  public handleColumnReset(
    defaultColumnOptions: Array<OsTableColumn<any>>,
    pageName: string,
    tableRefName: string
  ): void {
    this.columnOptions = defaultColumnOptions;
    (this.$refs[tableRefName] as OsTable).reload();
    this.customColumnOptions = cloneDeep(this.columnOptions);
    localStorage.remove(pageName);
  }

  private getCustomColumn<T>(page: string): Array<OsTableColumn<T>> | null {
    return localStorage.get(page);
  }

  /**
   * 组装列
   * @param resource
   */
  private assembleColumns(resource: Array<OsTableColumn<any>>): void {
    // 过滤出最终要显示的列
    this.columnOptions = cloneDeep(resource.filter(x => !x.hide));
    this.customColumnOptions = cloneDeep(resource);
  }

  /**
   * 初始化自定义的排序索引
   * @param resource
   */
  private initSortProp(resource: Array<OsTableColumn<any>>): void {
    let i = 0;
    resource.forEach(x => {
      x.customIndex = i;
      i++;
    });
  }
}
