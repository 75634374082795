export enum NumberAssignmentTypeEnum {
  /**
   * 等于
   */
  equal = 1,
  /**
   * 加
   */
  add,
  /**
   * 减
   */
  sub
}
export enum StringAssignmentTypeEnum {
  /**
   * 修改
   */
  update = 1,
  /**
   * 追加
   */
  increase
}
export enum SelectAssignmentTypeEnum {
  /**
   * 修改
   */
  update = 1
}
