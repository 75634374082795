import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { PermissionResource, SupplierPermissionResource, SupplierRoleResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class SupplierRoleService implements BaseService<SupplierRoleResource> {
  public getById(id: number): Promise<SupplierRoleResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierRoleResource>(ResourceUrlEnum.supplierRole, { params: { id } })
        .then((res: ApiResponse<SupplierRoleResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SupplierRoleResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SupplierRoleResource>(ResourceUrlEnum.supplierRole, resource)
        .then((res: ApiResponse<SupplierRoleResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SupplierRoleResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SupplierRoleResource>(ResourceUrlEnum.supplierRole, resource)
        .then((res: ApiResponse<SupplierRoleResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierRole, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierRole}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<SupplierRoleResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierRoleResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierRoleResource>>(`${ResourceUrlEnum.supplierRole}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierRoleResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查角色名称是否重复
   * @param roleName 角色名称
   * @param companyId 供应商id
   * @param id 角色主键（修改时需要传入）
   * @returns 为true则代表重复
   */
  public checkRoleName(roleName: string, companyId: number, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierRole}/checkNameRepeat`, {
          params: { id, companyId, roleName }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改角色状态
   * @param idList 角色id数组
   * @param status 状态
   * @returns
   */
  public batchUpdateRoleStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierRole}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 给角色配置权限
   * @param resourcesIdList 供应商权限资源id数组
   * @param roleId 角色id
   * @returns
   */
  public configurationPermission(params: { resourcesIdList: Array<number>; roleId: number }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierRole}/saveResourcesRole`, params)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getPermissionByRoleId(id: number): Promise<Array<PermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(`${ResourceUrlEnum.supplierRole}/getResourcesList`, {
          params: { id }
        })
        .then((res: ApiResponse<Array<PermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getAllSupplierRoles(companyId: number): Promise<Array<SupplierRoleResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierRoleResource>>(`${ResourceUrlEnum.supplierRole}/getRoleList`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<SupplierRoleResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取角色拥有的供应商权限资源
   * @param id 角色id
   * @returns
   */
  public getSupplierPermissionListByRoleId(id: number): Promise<Array<SupplierPermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(`${ResourceUrlEnum.supplierRole}/getResourcesList`, { params: { id } })
        .then((res: ApiResponse<Array<SupplierPermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierRoleService = new SupplierRoleService();
export default supplierRoleService;
