import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { resetSize } from '../utils/util';
import { UserModule } from '@/store/modules/user';
import { aesEncrypt } from '../utils/aes';
import { authService } from '@/api';

export interface MyTouchEvent extends TouchEvent {
  clientX: number;
}
export interface MyMouseEvent extends MouseEvent {
  touches?: TouchList;
}
@Component({
  name: 'verify-slide'
})
export default class VerifySlide extends Vue {
  @Prop(String) public captchaType = 'blockPuzzle';
  @Prop({ default: '1' }) public type!: string;
  /**
   * 弹出式pop，固定fixed
   */
  @Prop({ default: 'fixed' }) public mode!: string;
  @Prop({ default: 5 }) public vSpace!: number;
  @Prop({ default: '向右滑动完成验证' }) public explain!: string;

  @Watch('explain', { immediate: true })
  handlerExplain(explain: string) {
    this.text = explain;
  }

  @Prop({
    default: () => {
      return {
        width: 310,
        height: 155
      };
    }
  })
  public imgSize!: { width: string; height: string };

  @Prop({
    default: () => {
      return {
        width: '50px',
        height: '50px'
      };
    }
  })
  public blockSize!: { width: string; height: string };

  @Prop({
    default: () => {
      return {
        width: '310px',
        height: '40px'
      };
    }
  })
  public barSize!: { width: string; height: string };

  @Prop({ default: '' }) public defaultImg!: string;
  /**
   * 后端返回的加密秘钥 字段
   */
  public secretKey: string = '';
  /**
   * 是否通过的标识
   */
  public passFlag: boolean = false;
  /**
   * 验证码背景图片
   */
  public backImgBase: string = '';
  /**
   * 验证滑块的背景图片
   */
  public blockBackImgBase: string = '';
  /**
   * 后端返回的唯一token值
   */
  public backToken: string = '';
  /**
   * 移动开始的时间
   */
  public startMoveTime: number = 0;
  /**
   * 移动结束的时间
   */
  public endMovetime: number = 0;
  /**
   * 提示词的背景颜色
   */
  public tipsBackColor: string = '';

  public tipWords: string = '';

  public text: string = '';

  public finishText: string = '';

  public setSize = {
    imgHeight: 0,
    imgWidth: 0,
    barHeight: 0,
    barWidth: 0
  };

  public top: number = 0;
  public left: number = 0;
  public moveBlockLeft: string = '';
  public leftBarWidth: string = '';
  /**
   * 移动中样式
   */
  public moveBlockBackgroundColor: string = '';
  public leftBarBorderColor: string = '#ddd';

  public iconColor: string = '';
  public iconClass: string = 'icon-right';
  /**
   * 鼠标状态
   */
  public status: boolean = false;
  /**
   * 是否验证完成
   */
  public isEnd: boolean = false;

  public showRefresh: boolean = true;
  public transitionLeft: string = '';
  public transitionWidth: string = '';
  public startLeft: number = 0;

  @Watch('type', { immediate: true })
  handler(val: string, oldVal: string) {
    this.init();
  }
  public barArea(): HTMLElement {
    return this.$el.querySelector('.verify-bar-area')!;
  }

  public resetSize() {
    return resetSize;
  }

  public mounted() {
    // 禁止拖拽
    (this.$el as HTMLElement).onselectstart = function() {
      return false;
    };
  }
  public init(): void {
    this.text = this.explain;
    this.getPictrue();
    this.$nextTick(() => {
      const setSize = resetSize(this);
      // 重新设置宽度高度
      for (let key in setSize) {
        this.$set(this.setSize, key, setSize[key]);
      }
      this.$parent.$emit('ready', this);
    });
    let _this = this;
    window.removeEventListener('touchmove', function(e: TouchEvent) {
      _this.move(e as MyTouchEvent);
    });
    window.removeEventListener('mousemove', function(e: MouseEvent) {
      _this.move(e as MyMouseEvent);
    });
    // 鼠标松开
    window.removeEventListener('touchend', function() {
      _this.end();
    });
    window.removeEventListener('mouseup', function() {
      _this.end();
    });
    window.addEventListener('touchmove', function(e: TouchEvent) {
      _this.move(e as MyTouchEvent);
    });
    window.addEventListener('mousemove', function(e: MouseEvent) {
      _this.move(e as MyMouseEvent);
    });

    // 鼠标松开
    window.addEventListener('touchend', function() {
      _this.end();
    });
    window.addEventListener('mouseup', function() {
      _this.end();
    });
  }

  // 鼠标按下
  public start(e: MyTouchEvent | MyMouseEvent) {
    e = e || window.event;
    if (!e.touches) {
      // 兼容PC端
      var x = e.clientX;
    } else {
      // 兼容移动端
      var x = e.touches[0].pageX;
    }
    this.startLeft = Math.floor(x - this.barArea().getBoundingClientRect().left);
    this.startMoveTime = Number(new Date()); // 开始滑动的时间
    if (this.isEnd === false) {
      this.text = '';
      this.moveBlockBackgroundColor = '#337ab7';
      this.leftBarBorderColor = '#337AB7';
      this.iconColor = '#fff';
      e.stopPropagation();
      this.status = true;
    }
  }
  // 鼠标移动
  public move(e: MyTouchEvent | MyMouseEvent) {
    e = e || window.event;
    if (this.status && this.isEnd === false) {
      if (!e.touches) {
        // 兼容PC端
        var x = e.clientX;
      } else {
        // 兼容移动端
        var x = e.touches[0].pageX;
      }
      let bar_area_left = this.barArea().getBoundingClientRect().left;
      let move_block_left = x - bar_area_left; // 小方块相对于父元素的left值
      if (move_block_left >= this.barArea().offsetWidth - parseInt(this.blockSize.width) / 2 - 2) {
        move_block_left = this.barArea().offsetWidth - parseInt(this.blockSize.width) / 2 - 2;
      }
      if (move_block_left <= 0) {
        move_block_left = parseInt(this.blockSize.width) / 2;
      }
      // 拖动后小方块的left值
      this.moveBlockLeft = move_block_left - this.startLeft + 'px';
      this.leftBarWidth = move_block_left - this.startLeft + 'px';
    }
  }

  // 鼠标松开
  public end() {
    this.endMovetime = Number(new Date());
    let _this = this;
    // 判断是否重合
    if (this.status && this.isEnd === false) {
      let moveLeftDistance = parseInt((this.moveBlockLeft || '').replace('px', ''));
      moveLeftDistance = (moveLeftDistance * 310) / parseInt(this.setSize.imgWidth.toString());
      const pointJson = this.secretKey
        ? aesEncrypt(JSON.stringify({ x: moveLeftDistance, y: 5.0 }), this.secretKey)
        : JSON.stringify({ x: moveLeftDistance, y: 5.0 });
      authService
        .checkCaptcha({ captchaType: this.captchaType, pointJson, token: this.backToken, username: UserModule.account })
        .then(res => {
          this.moveBlockBackgroundColor = '#5cb85c';
          this.leftBarBorderColor = '#5cb85c';
          this.iconColor = '#fff';
          this.iconClass = 'icon-check';
          this.showRefresh = false;
          this.isEnd = true;

          this.passFlag = true;
          this.tipWords = `${((this.endMovetime - this.startMoveTime) / 1000).toFixed(2)}s ${this.$t(
            'login.verifySuccessfully'
          )}`;
          let captchaVerification = this.secretKey
            ? aesEncrypt(this.backToken + '---' + JSON.stringify({ x: moveLeftDistance, y: 5.0 }), this.secretKey)
            : this.backToken + '---' + JSON.stringify({ x: moveLeftDistance, y: 5.0 });
          setTimeout(() => {
            this.tipWords = '';
            (this.$parent as any).closeBox();
            this.$parent.$emit('success', { captchaVerification, loginCode: res.loginRandomCode });
          }, 1000);
        })
        .catch(() => {
          this.moveBlockBackgroundColor = '#d9534f';
          this.leftBarBorderColor = '#d9534f';
          this.iconColor = '#fff';
          this.iconClass = 'icon-close';
          this.passFlag = false;
          setTimeout(function() {
            _this.refresh();
          }, 1000);
          this.$parent.$emit('error', this);
          this.tipWords = this.$t('login.verifyFailed').toString();
          setTimeout(() => {
            this.tipWords = '';
          }, 1000);
        });
      this.status = false;
    }
  }

  public refresh() {
    this.showRefresh = true;
    this.finishText = '';

    this.transitionLeft = 'left .3s';
    this.moveBlockLeft = '0px';

    this.leftBarWidth = '0px';
    this.transitionWidth = 'width .3s';

    this.leftBarBorderColor = '#ddd';
    this.moveBlockBackgroundColor = '#fff';
    this.iconColor = '#000';
    this.iconClass = 'icon-right';
    this.isEnd = false;

    this.getPictrue();
    setTimeout(() => {
      this.transitionWidth = '';
      this.transitionLeft = '';
      this.text = this.explain;
    }, 300);
  }

  // 请求背景图片和验证图片
  public getPictrue(): void {
    let data = {
      captchaType: this.captchaType,
      clientUid: localStorage.getItem('slider'),
      ts: Date.now() // 现在的时间戳
    };
    authService
      .getCaptcha(this.captchaType, localStorage.getItem('slider') || '', Date.now())
      .then(res => {
        this.tipWords = '';
        this.backImgBase = res.originalImageBase64;
        this.blockBackImgBase = res.jigsawImageBase64;
        this.backToken = res.token;
        this.secretKey = res.secretKey;
        // 判断接口请求次数是否失效
        // if (res.repCode === '6201') {
        //   this.backImgBase = '';
        //   this.blockBackImgBase = '';
        // }
      })
      .catch(() => {
        this.tipWords = this.$t('login.getVerifyCodeFailed').toString();
      });
  }
}
