/**
 * 打包方式
 */
export enum PackagingEnum {
  /**
   * 平铺
   */
  tiled = 1,
  /**
   * 卷筒
   */
  reel,
  /**
   * 折装
   */
  fold
}
