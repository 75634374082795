import { ResourceUrlEnum } from '@/resource/enum';
import { ImportRes, InvoiceResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class InvoiceService implements BaseService<InvoiceResource> {
  public getById(id: number): Promise<InvoiceResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<InvoiceResource>(ResourceUrlEnum.invoice, { params: { id } })
        .then((res: ApiResponse<InvoiceResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: InvoiceResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<InvoiceResource>(ResourceUrlEnum.invoice, resource)
        .then((res: ApiResponse<InvoiceResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: InvoiceResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<InvoiceResource>(ResourceUrlEnum.invoice, resource)
        .then((res: ApiResponse<InvoiceResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.invoice, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.invoice}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<InvoiceResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<InvoiceResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InvoiceResource>>(`${ResourceUrlEnum.invoice}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<InvoiceResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查企业名称是否重复
   * @param name 企业名称
   * @param id 开票信息主键（修改时需传入）
   * @returns 为true则代表重复
   */
  public checkCompanyName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.invoice}/checkNameRepeat`, {
          params: { name, id }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public importData(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.invoice}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getAllInvoices(): Promise<Array<InvoiceResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<InvoiceResource>>(`${ResourceUrlEnum.invoice}/listAll`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const invoiceService = new InvoiceService();
export default invoiceService;
