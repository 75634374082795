/**
 * 项目状态
 */
export enum ProjectStatusEnum {
  /**
   * 新建
   */
  new = 1,
  /**
   * 进行中
   */
  underway,
  /**
   * 等待验收
   */
  waitingForAcceptance,
  /**
   * 已完成
   */
  completed
}
