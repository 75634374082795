/**
 * @关闭状态枚举
 * 0: 正常、1：关闭
 */
export enum CloseStatusEnum {
  /**
   * 正常
   */
  normal = 0,
  /**
   * 已关闭
   */
  closed = 1
}
/**
 * @是否手动关闭
 * 0: 正常关闭、1：手动关闭
 */
export enum ArchiveStatusEnum {
  /**
   * 待提交
   */
  new = 1,
  /**
   * 待接单
   */
  waitForProduction,
  /**
   * 制作中
   */
  inProduction,
  /**
   * 已制作
   */
  alreadyMade = 5,
  /**
   * 不制作
   */
  notMake = 100
}
