/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const organizationRoutes: RouteConfig = {
  path: '/organization',
  name: 'Layout',
  component: Layout,
  redirect: 'department',
  children: [
    {
      path: '/department',
      name: 'Department',
      component: async () =>
        import(/* webpackChunkName: "organization-department" */ '@/views/organization/department/department.vue'),
      meta: {
        title: 'route.department',
        id: 15
      }
    },
    {
      path: '/position',
      name: 'Position',
      component: async () =>
        import(/* webpackChunkName: "organization-position" */ '@/views/organization/position/position.vue'),
      meta: {
        title: 'route.position',
        id: 204
      }
    },
    {
      path: '/personnel',
      name: 'Personnel',
      component: async () =>
        import(/* webpackChunkName: "organization-personnel" */ '@/views/organization/personnel/personnel.vue'),
      meta: {
        title: 'route.personnel',
        id: 205
      }
    }
  ],
  meta: {
    title: 'route.organization',
    icon: '#organization',
    id: 3
  }
};
