import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ImportRes, CustomerShopResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { BaseService, Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload } from '@/utils';

class CustomerShopService implements BaseService<CustomerShopResource> {
  public getById(id: number): Promise<CustomerShopResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<CustomerShopResource>(ResourceUrlEnum.customerShop, { params: { id } })
        .then((res: ApiResponse<CustomerShopResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: CustomerShopResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CustomerShopResource>(ResourceUrlEnum.customerShop, resource)
        .then((res: ApiResponse<CustomerShopResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: CustomerShopResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<CustomerShopResource>(ResourceUrlEnum.customerShop, resource)
        .then((res: ApiResponse<CustomerShopResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.customerShop, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.customerShop}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<CustomerShopResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<CustomerShopResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerShopResource>>(`${ResourceUrlEnum.customerShop}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CustomerShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有启用状态下的客户门店
   * @param companyId 客户主键
   * @returns
   */
  public getAllUsingShop(companyId: number): Promise<Array<CustomerShopResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(`${ResourceUrlEnum.customerShop}/listEnable`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<CustomerShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有客户门店（此接口不走权限控制，一般用于下拉列表选择门店）
   * @param companyId 客户主键
   * @returns
   */
  public getAllShop(companyId: number): Promise<Array<CustomerShopResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(`${ResourceUrlEnum.customerShop}/listAll`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<CustomerShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查客户门店名称是否重复
   * @param name 企业名称
   * @param companyId 所属客户的id
   * @param id 客户门店信息主键（修改时需传入）
   * @returns
   */
  public checkShopName(shopName: string, companyId: number, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.customerShop}/checkNameRepeat`, {
          params: { shopName, companyId, id }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchUpdateShopStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.customerShop}/updateStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public importData(filePath: string, companyId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.customerShop}/import`, { filePath, companyId })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public exportData(query?: Partial<CustomerShopResource>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.customerShop}/exportData`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const customerShopService = new CustomerShopService();
export default customerShopService;
