/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const systemSettingRoutes: RouteConfig = {
  path: '/system-setting',
  name: 'SystemSetting',
  component: Layout,
  redirect: 'platform-permission',
  children: [
    {
      path: '/platform-permission',
      name: 'PlatformPermission',
      component: async () =>
        import(
          /* webpackChunkName: "platform-permission" */ '@/views/system-setting/platform-permission/platform-permission.vue'
        ),
      meta: {
        title: 'route.platformPermission',
        id: 4
      }
    },
    {
      path: '/supplier-permission',
      name: 'SupplierPermission',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-permission" */ '@/views/system-setting/supplier-permission/supplier-permission.vue'
        ),
      meta: {
        title: 'route.supplierPermission',
        id: 659
      }
    },
    {
      path: '/supplier-level',
      name: 'SupplierLevel',
      component: async () =>
        import(/* webpackChunkName: "supplier-level" */ '@/views/system-setting/supplier-level/supplier-level.vue'),
      meta: {
        title: 'route.supplierLevel',
        id: 640
      }
    },
    {
      path: '/role',
      name: 'Role',
      component: async () => import(/* webpackChunkName: "role" */ '@/views/system-setting/role/role.vue'),
      meta: {
        title: 'route.role',
        id: 203
      }
    },
    {
      path: '/message-config',
      name: 'MessageConfig',
      component: async () =>
        import(/* webpackChunkName: "message-config" */ '@/views/system-setting/message-config/message-config.vue'),
      meta: {
        title: 'route.messageConfig',
        id: 1711
      }
    },
    {
      path: '/project-config',
      name: 'Project-Config',
      component: async () =>
        import(/* webpackChunkName: "message-config" */ '@/views/system-setting/project-config/project-config.vue'),
      meta: {
        title: 'route.projectConfig',
        id: 1867
      }
    },
    {
      path: '/workflow-design',
      name: 'WorkflowDesign',
      component: async () =>
        import(/* webpackChunkName: "message-config" */ '@/views/system-setting/workflow-design/workflow-design.vue'),
      meta: {
        title: 'route.workflowDesign'
      },
      children: [
        {
          path: '/approval-setting',
          name: 'ApprovalSetting',
          component: async () =>
            import(
              /* webpackChunkName: "message-config" */ '@/views/system-setting/approval-setting/approval-setting.vue'
            ),
          meta: {
            title: 'route.approvalSetting',
            hidden: true
          }
        }
      ]
    }
  ],
  meta: {
    title: 'route.systemSetting',
    icon: '#system-setting',
    id: 2
  }
};
