import { ResourceUrlEnum } from '@/resource/enum';
import {
  AETodoReport,
  PrepressReport,
  FinanceReport,
  FinanceTodoReport,
  PrepressTodoReport,
  ProjectReport,
  SaleReport,
  LogisticsListQuery,
  LogisticsList,
  MineTask
} from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

export default class DashboardService {
  /**
   * 获取销售汇总报表
   * @param params
   * @returns
   */
  public static getSaleReport(params: {
    customerId?: number;
    startTime: string;
    endTime: string;
  }): Promise<SaleReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SaleReport>(`${ResourceUrlEnum.dashboard}/sale/statistics`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取财务统计报表
   * @param params
   * @returns
   */
  public static getFinanceReport(params: {
    customerId?: number;
    startTime: string;
    endTime: string;
  }): Promise<FinanceReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<FinanceReport>(`${ResourceUrlEnum.dashboard}/finance/statistics`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取改色汇总报表
   * @param params
   * @returns
   */
  public static getChangeColorReport(params: {
    code?: string;
    startTime: string;
    endTime: string;
  }): Promise<PrepressReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<PrepressReport>(`${ResourceUrlEnum.dashboard}/colorChange/statistics`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取完稿汇总报表
   * @param params
   * @returns
   */
  public static getCompleteManuscriptReport(params: {
    code?: string;
    startTime: string;
    endTime: string;
  }): Promise<PrepressReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<PrepressReport>(`${ResourceUrlEnum.dashboard}/finish/statistics`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取财务待办汇总报表
   * @returns
   */
  public static getFinanceTodoReport(): Promise<FinanceTodoReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<FinanceTodoReport>(`${ResourceUrlEnum.dashboard}/finance/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取AE待办汇总报表
   * @returns
   */
  public static getAETodoReport(): Promise<AETodoReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<AETodoReport>(`${ResourceUrlEnum.dashboard}/ae/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取我的完稿待办
   * @returns
   */
  public static getMineComplateTodoReport(): Promise<MineTask> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<MineTask>(`${ResourceUrlEnum.dashboard}/prepress/finish/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取我的改色待办
   * @returns
   */
  public static geMineChangeColorTodoReport(): Promise<MineTask> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<MineTask>(`${ResourceUrlEnum.dashboard}/prepress/colorChange/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项目汇总报表
   * @param params
   * @returns
   */
  public static getProjectReport(): Promise<ProjectReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProjectReport>(`${ResourceUrlEnum.dashboard}/project/statistics`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取印前待办汇总报表
   * @returns
   */
  public static getPrepressTodoReport(): Promise<PrepressTodoReport> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<PrepressTodoReport>(`${ResourceUrlEnum.dashboard}/prepress/todo`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || {});
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 物流异常
   * @param query
   * @param paging
   * @returns
   */
  public static getErrorList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.dashboard}/logistics/error`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 逾期未签收
   * @param query
   * @param paging
   * @returns
   */
  public static getTimeoutList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.dashboard}/logistics/timeout`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 逾期预警
   * @param query
   * @param paging
   * @returns
   */
  public static getWarningList(
    query?: Partial<LogisticsListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.dashboard}/logistics/timeoutWarn`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
