import { ArchiveStatusEnum } from './../../resource/enum/close-status';
import {
  PrepressChangeColorCraftsEnum,
  PrepressLayoutCraftsEnum,
  PrepressStatusEnum,
  ProjectItemTypeEnum,
  ProjectProductStatusEnum,
  ResourceUrlEnum
} from '@/resource/enum';
import {
  ProductConsumedResource,
  ImportRes,
  ProjectProductDetail,
  SaveProjectProduct,
  ProjectProductList,
  ProjectProductListQuery,
  PrepressReedit
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload, messageError, translation } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class ProjectProductService {
  /**
   * 获取项次信息，用于编辑
   * @param id
   * @returns
   */
  public getById(id: number): Promise<ProjectProductDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProjectProductDetail>(ResourceUrlEnum.projectProduct, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取项次信息，用于编辑
   * @param ids
   * @returns
   */
  public getBatchById(idList: Array<number>): Promise<Array<ProjectProductDetail>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectProductDetail>>(`${ResourceUrlEnum.projectProduct}/listByIds`, idList)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项次详情
   * @param id
   * @returns
   */
  public getDetails(id: number): Promise<ProjectProductDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProjectProductDetail>(`${ResourceUrlEnum.projectProduct}/getDetail`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SaveProjectProduct): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(ResourceUrlEnum.projectProduct, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SaveProjectProduct): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SaveProjectProduct>(ResourceUrlEnum.projectProduct, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public close(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batchClose`, idList)
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public cancelClose(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batchCancelClose`, idList)
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.projectProduct}/batchDelete`, { data: idList })
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<ProjectProductListQuery>,
    paging: Paging,
    sortOptions: SortOptions<ProjectProductList>
  ): Promise<ApiResponse<Array<ProjectProductList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectProductList>>(`${ResourceUrlEnum.projectProduct}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 耗损
   * @param consumedInfo 耗损信息
   * @returns
   */
  public consumed(consumedInfo: ProductConsumedResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<null>(`${ResourceUrlEnum.projectProduct}/attrition`, consumedInfo)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载制作明细导入模板
   * @param customerId 客户id
   * @returns
   */
  public downloadTemplate(customerId: number, projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.projectProduct}/downloadTemplate`, {
          params: { customerId, projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 预览layout单
   * @returns
   */
  public previewLayout(ids: Array<number>): Promise<Array<{ id: number; url: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.projectProduct}/previewLayout`, ids)
        .then((res: ApiResponse<Array<{ id: number; url: string }>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入产品明细数据
   * @param filePath 文件在minio上的路径
   * @param projectId 项目id
   * @returns
   */
  public import(params: {
    filePath: string;
    projectId: number;
    customerId: number;
    projectCode: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.projectProduct}/importData`, {
          ...params
        })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项次类型
   * @returns
   */
  public getItemType(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in ProjectItemTypeEnum) {
      if (isNaN(Number(key)) && key !== 'consumed') {
        options.push({
          label: translation(`projectItemType.${key}`),
          value: ProjectItemTypeEnum[key]
        });
      }
    }
    return options;
  }
  /**
   * 获取印前改色工艺
   * @returns
   */
  public getPrepressChangeColorCrafts(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PrepressChangeColorCraftsEnum) {
      if (isNaN(Number(key)) && key !== 'others') {
        options.push({
          label: `prepressChangeColorCrafts.${key}`,
          value: PrepressChangeColorCraftsEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取印前排版工艺
   * @returns
   */
  public getPrepressLayoutCrafts(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PrepressLayoutCraftsEnum) {
      if (isNaN(Number(key)) && key !== 'others') {
        options.push({
          label: `prepressLayoutCrafts.${key}`,
          value: PrepressLayoutCraftsEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取项次状态
   * @returns
   */
  public getProjectProductStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in ProjectProductStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`projectProductStatus.${key}`),
          value: ProjectProductStatusEnum[key]
        });
      }
    }
    return options;
  }
  /**
   * 批量修改出（货到货时间、工序）
   * @returns
   */
  public batchEdit(params: {
    changeLogList: Array<{ id: number; changeLog: string }>;
    requiredArriveTime: Date | string | null;
    requiredDeliveryTime: Date | string | null;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batch`, params)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          return resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 修改项次类型
   * @param idList
   * @returns
   */
  public changeItemType(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/changeItemType`, idList)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量手动完成
   * @param idList
   * @returns
   */
  public batchDoneManually(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batchComplete`, idList)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getProjectItemType(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in ProjectItemTypeEnum) {
      if (isNaN(Number(key)) && key !== 'consumed') {
        options.push({
          label: translation(`projectItemType.${key}`),
          value: ProjectItemTypeEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 印前批量确稿
   * @returns
   */
  public batchConfirmManuscript(projectId: number, idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batchConfirm`, { projectId, idList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量取消印前服务
   * @returns
   */
  public batchCancelPrepressService(projectId: number, idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batchCancel`, { projectId, idList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 改稿
   * @param id 项次id
   * @returns
   */
  public reedit(resource: PrepressReedit): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/reedit`, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量改稿
   * @param id 项次id
   * @returns
   */
  public batchReedit(resource: Array<PrepressReedit>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectProduct}/batchReedit`, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量提交制作
   * @returns
   */
  public submitProduction(projectId: number, idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.projectProduct}/submit`, { projectId, idList })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出数据
   * @param query
   * @returns
   */
  public export(query?: ProjectProductListQuery): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.projectProduct}/export`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载确稿文件
   * @param query
   * @returns
   */
  public downloadManuscriptConfirmFile(isList: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.projectProduct}/exportConfirmFile`, isList, {
          responseType: 'blob',
          timeout: 300000
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取印前状态
   * @returns
   */
  public getPrepressStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PrepressStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`prepressStatus.${key}`),
          value: PrepressStatusEnum[key]
        });
      }
    }
    return options;
  }
  /**
   * 获取归档状态
   * @returns
   */
  public getArchiveStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in ArchiveStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`archiveStatus.${key}`),
          value: ArchiveStatusEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取要调打项次
   * @param query 查询条件
   * @param paging
   * @returns
   */
  public getProjectProductItems(
    query?: Partial<any>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProjectProductList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectProductList>>(`${ResourceUrlEnum.projectProduct}/listComponents`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }
  /**
   * 调打、复制
   */
  public batch(resource: Array<SaveProjectProduct>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SaveProjectProduct>(`${ResourceUrlEnum.projectProduct}/batch`, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const projectProductService = new ProjectProductService();
export default projectProductService;
