/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

/**
 * 任务中心路由配置
 */
export const taskCenterRoutes: RouteConfig = {
  path: '/task-center',
  component: Layout,
  redirect: 'todo-task',
  name: 'TaskCenter',
  children: [
    {
      path: '/todo-task',
      name: 'TodoTask',
      component: async () => import(/* webpackChunkName: "TodoTask" */ '@/views/task-center/todo-task/todo-task.vue'),
      meta: {
        title: 'route.todoTask',
        id: 2995
      }
    },
    {
      path: '/complete-task',
      name: 'CompleteTask',
      component: async () =>
        import(/* webpackChunkName: "CompleteTask" */ '@/views/task-center/complete-task/complete-task.vue'),
      meta: {
        title: 'route.completeTask',
        id: 2996
      }
    },
    {
      path: '/initiated-task',
      name: 'TnitiatedTask',
      component: async () =>
        import(/* webpackChunkName: "TnitiatedTask" */ '@/views/task-center/initiated-task/initiated-task.vue'),
      meta: {
        title: 'route.initiatedTask',
        id: 2999
      }
    },
    {
      path: '/cc-task',
      name: 'CcTask',
      component: async () => import(/* webpackChunkName: "CcTask" */ '@/views/task-center/cc-task/cc-task.vue'),
      meta: {
        title: 'route.ccTask',
        id: 2998
      }
    },
    {
      path: '/task-details',
      name: 'TaskDetails',
      component: async () =>
        import(/* webpackChunkName: "TaskDetails" */ '@/views/task-center/task-details/task-details.vue'),
      meta: {
        title: 'route.taskDetails',
        hidden: true,
        id: 2997
      }
    }
  ],
  meta: {
    title: 'route.taskCenter',
    icon: '#example',
    id: 2991
  }
};
