/**
 * 生产进度操作枚举
 */
export enum ProductionActionEnum {
  /**
   * 印前接单
   */
  prepressTakeOrder = 1,

  /**
   * 印前已完成
   */
  prepressComplete,

  /**
   * 输出接单
   */
  printTakeOrder,

  /**
   * 输出完成
   */
  printComplete,

  /**
   * 后道接单
   */
  backendTakeOrder,

  /**
   * 后道完成
   */
  backendComplete
}
