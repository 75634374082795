/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const prepressTaskRoutes: RouteConfig = {
  path: '/prepress-task',
  name: 'PrepressTask',
  component: Layout,
  redirect: 'mine-task',
  children: [
    // {
    //   path: '/mine-task',
    //   name: 'MineTask',
    //   component: async () =>
    //     import(/* webpackChunkName: "mine-task" */ '@/views/prepress-task/mine-task/mine-task.vue'),
    //   meta: {
    //     title: 'route.mineTask',
    //     id: 998
    //   }
    // },
    {
      path: '/mine-change-color-task',
      name: 'MineChangeColorTask',
      component: async () =>
        import(
          /* webpackChunkName: "mine-change-color-task" */ '@/views/prepress-task/mine-change-color-task/mine-change-color-task.vue'
        ),
      meta: {
        title: 'route.mineChangeColorTask',
        id: 1869
      }
    },
    {
      path: '/mine-finished-product-task',
      name: 'MineFinishedProductTask',
      component: async () =>
        import(
          /* webpackChunkName: "mine-finished-product-task" */ '@/views/prepress-task/mine-finished-product-task/mine-finished-product-task.vue'
        ),
      meta: {
        title: 'route.mineFinishedProductTask',
        id: 1870
      }
    },
    {
      path: '/mine-typesetting-task',
      name: 'MineTypesettingTask',
      component: async () =>
        import(
          /* webpackChunkName: "mine-typesetting-task" */ '@/views/prepress-task/mine-typesetting-task/mine-typesetting-task.vue'
        ),
      meta: {
        title: 'route.mineTypesettingTask'
      }
    },
    {
      path: '/change-color-task',
      name: 'ChangeColorTask',
      component: async () =>
        import(
          /* webpackChunkName: "change-color-task" */ '@/views/prepress-task/change-color-task/change-color-task.vue'
        ),
      meta: {
        title: 'route.changeColorTask',
        id: 999
      }
    },
    {
      path: '/complete-manuscript-task',
      name: 'CompleteManuscriptTask',
      component: async () =>
        import(
          /* webpackChunkName: "complete-manuscript-task" */ '@/views/prepress-task/complete-manuscript-task/complete-manuscript-task.vue'
        ),
      meta: {
        title: 'route.completeManuscriptTask',
        id: 1000
      }
    },
    {
      path: '/color-change-sample',
      name: 'ColorChangeSample',
      component: async () =>
        import(
          /* webpackChunkName: "color-change-sample" */ '@/views/prepress-task/color-change-sample/color-change-sample.vue'
        ),
      meta: {
        title: 'route.colorChangeSample'
      },
      children: [
        {
          path: '/add-change-color-sample',
          name: 'AddChangeColorSample',
          component: async () =>
            import(
              /* webpackChunkName: "add-change-color-sample" */ '@/views/prepress-task/color-change-sample/add-change-color-sample/add-change-color-sample.vue'
            ),
          meta: {
            title: 'route.addChangeColorSample',
            hidden: true
          }
        },
        {
          path: '/color-change-sample-details',
          name: 'ColorChangeSampleDetails',
          component: async () =>
            import(
              /* webpackChunkName: "color-change-sample-details" */ '@/views/prepress-task/color-change-sample/color-change-sample-details/color-change-sample-details.vue'
            ),
          meta: {
            title: 'route.colorChangeSampleDetails',
            hidden: true
          }
        }
      ]
    }
  ],
  meta: {
    title: 'route.prepressTask',
    icon: '#prepress-task',
    id: 997
  }
};
