import { ResourceUrlEnum } from '@/resource/enum';
import { ImportRes, CustomerInvoiceResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class CustomerInvoiceService implements BaseService<CustomerInvoiceResource> {
  public getById(id: number): Promise<CustomerInvoiceResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<CustomerInvoiceResource>(ResourceUrlEnum.customerInvoice, { params: { id } })
        .then((res: ApiResponse<CustomerInvoiceResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: CustomerInvoiceResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CustomerInvoiceResource>(ResourceUrlEnum.customerInvoice, resource)
        .then((res: ApiResponse<CustomerInvoiceResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: CustomerInvoiceResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<CustomerInvoiceResource>(ResourceUrlEnum.customerInvoice, resource)
        .then((res: ApiResponse<CustomerInvoiceResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.customerInvoice, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.customerInvoice}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<CustomerInvoiceResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<CustomerInvoiceResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerInvoiceResource>>(`${ResourceUrlEnum.customerInvoice}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CustomerInvoiceResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查企业名称是否重复
   * @param name 企业名称
   * @param companyId 所属客户的id
   * @param id 客户开票信息主键（修改时需传入）
   * @returns
   */
  public checkCompanyName(name: string, companyId: number, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.customerInvoice}/checkNameRepeat`, {
          params: { name, companyId, id }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public importData(filePath: string, companyId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.customerInvoice}/importData`, { filePath, companyId })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getAllInvoices(companyId: number): Promise<Array<CustomerInvoiceResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<CustomerInvoiceResource>>(`${ResourceUrlEnum.customerInvoice}/listAll`, {
          params: { companyId }
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const customerInvoiceService = new CustomerInvoiceService();
export default customerInvoiceService;
