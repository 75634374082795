/**
 * 耗损原因
 */
export enum BrokenReasonEnum {
    /**
     * 需求变更
     */
    requirementChange = 1,
    /**
     * 画面错误
     */
    pictureError = 2,
    /**
     * 材料错误
     */
    materialError = 3,
    /**
     * 工艺错误
     */
    processError = 4,
    /**
     * 物流耗损
     */
    shipmentBroken = 5,
    /**
     * 其他
     */
    other = 99
}