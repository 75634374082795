/**
 * 安装订单状态
 */
export enum InstallationOrderStatusEnum {
  // /**
  //  * 待推送
  //  */
  // new = 1,
  // /**
  //  * 待安装
  //  */
  // waitingInstall = 2,
  // /**
  //  * 安装中
  //  */
  // installing = 3,
  // /**
  //  * 待验收
  //  */
  // approving = 4,
  // /**
  //  * 已完成
  //  */
  // completed = 5

  /**
   * 待推送
   */
  toBePushed = 1,
  /**
   * 待接单
   */
  PendingOrders = 2,

  /**
   * 待预约
   */
  pendingAppointment = 3,

  /**
   * 服务中
   */
  inService = 4,
  /**
   * 待验收
   */
  acceptancePending = 5,
  /**
   * 已完成
   */
  completed = 6
}
