import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { SupplierPositionResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class SupplierPositionService implements BaseService<SupplierPositionResource> {
  public getById(id: number): Promise<SupplierPositionResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierPositionResource>(ResourceUrlEnum.supplierPosition, { params: { id } })
        .then((res: ApiResponse<SupplierPositionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SupplierPositionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SupplierPositionResource>(ResourceUrlEnum.supplierPosition, resource)
        .then((res: ApiResponse<SupplierPositionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SupplierPositionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SupplierPositionResource>(ResourceUrlEnum.supplierPosition, resource)
        .then((res: ApiResponse<SupplierPositionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierPosition, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierPosition}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<SupplierPositionResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierPositionResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierPositionResource>>(`${ResourceUrlEnum.supplierPosition}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierPositionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查岗位名称是否重复
   * @param name 岗位名称
   * @param id 岗位主键（修改时需传入）
   * @returns
   */
  public checkPositionName(name: string, companyId: number, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierPosition}/checkNameRepeat`, {
          params: { id, companyId, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改岗位状态
   * @param idList 岗位id数组
   * @param status 状态
   * @returns
   */
  public batchUpdatePositionStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierPosition}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据部门获取上级岗位列表
   * @param id 部门id
   * @returns
   */
  public getPositionsByDepartment(id: number): Promise<Array<SupplierPositionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierPositionResource>>(`${ResourceUrlEnum.supplierPosition}/getParentPosition`, {
          params: { id }
        })
        .then((res: ApiResponse<Array<SupplierPositionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取供应商下所有启用状态的岗位列表
   * @param companyId 供应商id
   * @returns 所有启用状态的岗位列表
   */
  public getAllSupplierPositions(companyId: number): Promise<Array<SupplierPositionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierPositionResource>>(`${ResourceUrlEnum.supplierPosition}/listPosition`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<SupplierPositionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierPositionService = new SupplierPositionService();
export default supplierPositionService;
