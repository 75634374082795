import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { AxiosResponse } from 'axios';
import { BaseService, Paging } from '../base';
import { SupplierProductResource, ResourceBase, ProductList } from '@/resource/model';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload } from '@/utils';

class SupplierProductService implements BaseService<ResourceBase> {
  public listEnable(query: Partial<SupplierProductResource>): Promise<Array<SupplierProductResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierProductResource>>(`${ResourceUrlEnum.supplierProduct}/listEnable`, {
          params: query
        })
        .then((res: ApiResponse<Array<SupplierProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   *
   * @param id 产品ID
   * @returns
   */
  public getById(id: number): Promise<SupplierProductResource> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierProductResource>(`${ResourceUrlEnum.supplierProduct}`, { params: { id } })
        .then((res: ApiResponse<SupplierProductResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询条件
   * @param paging 分页参数
   * @returns
   */
  public getList(
    query: Partial<SupplierProductResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierProductResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierProductResource>>(`${ResourceUrlEnum.supplierProduct}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 新增
   * @param resource 新增数据对象
   */
  public post(resource: SupplierProductResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(ResourceUrlEnum.supplierProduct, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量新增
   * @param resource 新增数据对象
   */
  public bindProduct(resource: any): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(ResourceUrlEnum.supplierProduct, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑
   * @param resource 编辑数据对象
   */
  public put(resource: SupplierProductResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(ResourceUrlEnum.supplierProduct, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑价格
   * @param resource 编辑价格
   */
  public editPrice(resource: SupplierProductResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(ResourceUrlEnum.supplierProduct, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除
   * @param id ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierProduct, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList ID集合
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierProduct}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载导入模板
   */
  public downloadTemplate(companyId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.supplierProduct}/downloadTemplate`, {
          params: {
            companyId
          },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导入
   * @param companyId 企业ID
   * @param filePath 导入文件地址
   */
  public import(companyId: number, filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierProduct}/importData`, { companyId, filePath })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导出
   */
  public export(query: Partial<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.supplierProduct}/export`, query, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改状态
   * @param idList ID集合
   * @param status 状态
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierProduct}/editStatus`, { idList, status })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取用于可以添加的平台产品（不会返回已添加过的）
   * @returns
   */
  // TODO  换接口
  public getCanAddPlatformProduct(
    query?: Partial<ProductList>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductList>>(`${ResourceUrlEnum.supplierProduct}/listProduct`, cloneQuery)
        .then((res: ApiResponse<Array<ProductList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierProductService = new SupplierProductService();
export default supplierProductService;
