/**
 * 导入模板在minio上的地址
 */
export enum ImportTemplateEnum {
  /**
   * 人员
   */
  personnel = 'user_template.xlsx',
  /**
   * 产品分类
   */
  classify = 'material_category_template.xlsx',
  /**
   * 产品
   */
  product = 'product_template.xlsx',
  /**
   * 企业
   */
  company = 'company_template.xlsx',
  /**
   * 输出
   */
  output = 'crafts_template.xlsx',
  /**
   * 后道
   */
  backend = 'crafts_template.xlsx',
  /**
   * 印前工艺
   */
  prepressCrafts = 'prepress_crafts_template.xlsx',
  /**
   * 客户
   */
  customer = 'customer_template.xlsx',
  /**
   * 客户开票信息
   */
  invoiceCustomer = 'invoice_template.xlsx',
  /**
   * 税率管理
   */
  taxRate = 'tax_category_template.xlsx',

  /**
   * 仓库管理
   */
  warehouse = 'wms_warehouse_template.xlsx',
  /**
   * 仓位管理
   */
  storeLocation = 'wms_store_location_template.xlsx',
  /**
   * 存货类别管理
   */
  inventoryType = 'wms_inventory_type_template.xlsx',
  /**
   * 物料清单
   */
  materialBom = 'material_bom_template.xlsx',
  /**
   * 物料雷暴
   */
  materialList = 'material_template.xlsx',
  /**
   * 客户产品
   */
  customerProduct = 'customer_product_template.xlsx',
  /**
   * 客户门店
   */
  customerShop = 'shop_template.xlsx',
  /**
   * 物流发货模板
   */
  logisticsDelivery = 'logistics_order_template.xlsx',
  /**
   * 安装工人导入模板
   */
  installEmployee = 'install_employee_template.xlsx',
  /**
   * 点位数据校验
   */
  pointDatacheck = 'point_data_check_import_template.xlsx'
}
