/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const databaseRoutes: RouteConfig = {
  path: '/database',
  component: Layout,
  redirect: 'device-classify',
  name: 'Database',
  children: [
    {
      path: '/device-classify',
      name: 'DeviceClassify',
      component: async () =>
        import(/* webpackChunkName: "device-classify" */ '@/views/database/device-classify/device-classify.vue'),
      meta: {
        title: 'route.deviceClassify',
        id: 318
      }
    },
    {
      path: '/invoice',
      name: 'Invoice',
      component: async () => import(/* webpackChunkName: "invoice" */ '@/views/database/invoice/invoice.vue'),
      meta: {
        title: 'route.invoice',
        id: 492
      }
    },
    {
      path: '/tax-rate-management',
      name: 'TaxRateManagement',
      component: async () =>
        import(
          /* webpackChunkName: "tax-rate-management" */ '@/views/database/invoice/tax-rate-management/tax-rate-management.vue'
        ),
      meta: {
        title: 'invoice.taxRateManagement',
        id: 499,
        hidden: true
      }
    },
    {
      path: '/store-location',
      name: 'StoreLocation',
      component: async () =>
        import(/* webpackChunkName: "storeLocation" */ '@/views/database/store-location/store-location.vue'),
      meta: {
        title: 'route.storeLocation',
        id: 29001
      }
    },
    {
      path: '/inventory-type',
      name: 'InventoryType',
      component: async () =>
        import(/* webpackChunkName: "inventoryType" */ '@/views/database/inventory-type/inventory-type.vue'),
      meta: {
        title: 'route.inventoryType',
        id: 29002
      }
    },
    {
      path: '/warehouse',
      name: 'Warehouse',
      component: async () => import(/* webpackChunkName: "warehouse" */ '@/views/database/warehouse/warehouse.vue'),
      meta: {
        title: 'route.warehouse',
        id: 29003
      }
    },
    {
      path: '/warehouse-details',
      name: 'WarehouseDetails',
      component: async () =>
        import(
          /* webpackChunkName: "warehouse" */ '@/views/database/warehouse/details-warehouse/details-warehouse.vue'
        ),
      meta: {
        title: 'route.warehouseDetails',
        hidden: true,
        id: 29009
      }
    },
    {
      path: '/warehouse-store',
      name: 'WarehouseStore',
      component: async () =>
        import(/* webpackChunkName: "invoice" */ '@/views/database/warehouse-store/warehouse-store.vue'),
      meta: {
        title: 'route.warehouseStore',
        id: 29004
      }
    },
    {
      path: '/material-list',
      component: async () =>
        import(/* webpackChunkName: "invoice" */ '@/views/database/material-list/material-list.vue'),
      name: 'MaterialList',
      meta: {
        title: 'route.materialList',
        id: 29005
      },
      children: [
        {
          path: '/add-material',
          name: 'AddMaterial',
          component: async () =>
            import(/* webpackChunkName: "invoice" */ '@/views/database/material-list/add-material/add-material.vue'),
          meta: {
            title: 'route.addMaterial',
            id: 29099,
            hidden: true
          }
        },
        {
          path: '/details-material',
          name: 'DetailsMaterial',
          component: async () =>
            import(
              /* webpackChunkName: "details-material" */ '@/views/database/material-list/details-material/details-material.vue'
            ),
          meta: {
            title: 'route.detailsMaterial',
            id: 29089,
            hidden: true
          }
        }
      ]
    },
    {
      path: '/bill-of-material',
      name: 'BillOfMaterial',
      component: async () =>
        import(/* webpackChunkName: "bill-of-material" */ '@/views/database/bill-of-material/bill-of-material.vue'),
      meta: {
        title: 'route.billOfMaterial',
        id: 29006
      },
      children: [
        {
          path: '/add-bom',
          name: 'AddBom',
          component: async () =>
            import(/* webpackChunkName: "bill-of-material" */ '@/views/database/bill-of-material/add-bom/add-bom.vue'),
          meta: {
            title: 'route.addBom',
            id: 29011,
            hidden: true
          }
        },
        {
          path: '/details-bom',
          name: 'DetailsBom',
          component: async () =>
            import(
              /* webpackChunkName: "details-bom" */ '@/views/database/bill-of-material/details-bom/details-bom.vue'
            ),
          meta: {
            title: 'route.detailsBom',
            id: 29012,
            hidden: true
          }
        }
      ]
    },

    {
      path: '/material-classification',
      name: 'MaterialClassification',
      component: async () =>
        import(
          /* webpackChunkName: "material-classification" */ '@/views/database/material-classification/material-classification.vue'
        ),
      meta: {
        title: 'route.materialClassification',
        id: 29007
      }
    }
  ],
  meta: {
    title: 'route.database',
    icon: '#database',
    id: 317
  }
};
