import { RegionLevelEnum, ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { RegionResource } from '@/resource/model';
import { RegionTreeResource } from '@/resource/model/public-data/region';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

class RegionService {
  public getById(id: number): Promise<RegionResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<RegionResource>(ResourceUrlEnum.region, { params: { id } })
        .then((res: ApiResponse<RegionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public post(resource: RegionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<RegionResource>(ResourceUrlEnum.region, resource)
        .then((res: ApiResponse<RegionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: RegionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<RegionResource>(ResourceUrlEnum.region, resource)
        .then((res: ApiResponse<RegionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchUpdateRegionStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.region}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public refreshCache(): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.region}/refresh`, {})
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getTreeList(query: Partial<RegionTreeResource>): Promise<Array<RegionTreeResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<RegionTreeResource>>(`${ResourceUrlEnum.region}/listTree`, query)
        .then((res: ApiResponse<Array<RegionTreeResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据父级ID获取子级列表
   * @param query
   * @returns
   */
  public getList(query: Partial<RegionResource>, paging: Paging): Promise<ApiResponse<Array<RegionResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<RegionResource>>(`${ResourceUrlEnum.region}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<RegionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据状态获取国际化key
   * @param level 等级
   * @returns 等级i18n key
   */
  public getLevelI18Key(status: RegionLevelEnum): string {
    if (!RegionLevelEnum[status]) {
      return 'common.unknown';
    }

    return 'region.' + RegionLevelEnum[status];
  }

  /**
   * 检查编码是否重复
   * @param code
   * @param id
   * @returns 为true则代表重复
   */
  public checkRegionCode(code: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.region}/checkCodeRepeat`, { params: { code, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 检查名称是否重复
   * @param name 名称
   * @param id 主键（修改时需传入）
   * @returns 为true则代表重复
   */
  public checkRegionName(name: string, parentId: number | null, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.region}/checkNameRepeat`, { params: { name, id, parentId } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const regionService = new RegionService();
export default regionService;
