import { ResourceUrlEnum } from '@/resource/enum';
import { UpdateInstallEmployee } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';

export default class CommonTagService {
  public static put(item: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<UpdateInstallEmployee>(ResourceUrlEnum.installTag, { item })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取常用标签
   * @returns string
   */
  public static getTagList(): Promise<string> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<string>(`${ResourceUrlEnum.installTag}`)
        .then((res: ApiResponse<string>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取常用标签
   * @returns string
   */
  public static getOptionTagList(): Promise<string> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<string>(`${ResourceUrlEnum.installTag}`)
        .then((res: ApiResponse<string>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
