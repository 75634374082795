/* eslint-disable @typescript-eslint/no-unused-vars */
import { ResourceUrlEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';
import { CustomerContactsResource } from '@/resource/model';

class CustomerContactsService implements BaseService<CustomerContactsResource> {
  /**
   * 分页查询联系人
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 联系人集合
   */
  public getList(
    query: Partial<CustomerContactsResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<CustomerContactsResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerContactsResource>>(`${ResourceUrlEnum.customerContacts}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CustomerContactsResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }

          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 创建联系人
   * @param resource 联系人对象
   * @returns
   */
  public create(resource: CustomerContactsResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CustomerContactsResource>(`${ResourceUrlEnum.customerContacts}`, resource)
        .then((res: ApiResponse<CustomerContactsResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑联系人
   * @param resource 联系人对象
   * @returns
   */
  public edit(resource: CustomerContactsResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<CustomerContactsResource>(`${ResourceUrlEnum.customerContacts}`, resource)
        .then((res: ApiResponse<CustomerContactsResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除联系人
   * @param id 联系人ID
   * @returns
   */
  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.customerContacts}`, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }

          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除联系人
   * @param idList 联系人ID集合
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (idList.length <= 0) {
      throw new Error('idList must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.customerContacts}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查联系人姓名是否重复
   * @param contactsName 联系人姓名
   * @param companyId 企业ID
   * @param id 联系人ID
   */
  public checkContactName(contactsName: string, companyId: number, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.customerContacts}/checkNameRepeat`, {
          params: { contactsName, companyId, id }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const customerContactsService = new CustomerContactsService();
export default customerContactsService;
