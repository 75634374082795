import { cloneDeep } from 'lodash-es';
import { Component, Vue, Prop } from 'vue-property-decorator';

/**
 * 查询配置，用来初始化条件查询项
 */
export interface OsQueryItemOption<T = { [prop: string]: any }> {
  type: 'Input' | 'Select' | 'Cascader' | 'DateRangePicker' | 'Slot';
  slotName?: string;
  field: keyof T;
  label: string;
  /**
   * 对于不同type的option配置，可参考element ui对应的配置项
   */
  option: { style?: { [prop: string]: any }; [prop: string]: any };
  /**
   * form-item的自定义class名称
   */
  className?: string;
  optionData?: Array<any> | Function;
  /**
   * 查询条件整体所在行所占百分比，可参考栅格布局，默认为6
   */
  colSpan?: number;
  /**
   *查询条件label所占百分比，可参考栅格布局，默认为8
   */
  labelCol?: number;
  /**
   *查询条件form组件所占百分比，可参考栅格布局，默认16
   */
  formCol?: number;
  change?: (value: any) => void;
}

export interface OsQueryOptions {
  /**
   * 默认展示的数量
   */
  defaultShowQuantity?: number;
  /**
   * 尺寸
   */
  size?: 'medium' | 'small' | 'mini';
}

@Component({
  name: 'os-table-query'
})
export default class OsTableQuery extends Vue {
  /**
   * 每行展示的查询条件数量
   */
  @Prop({
    type: Object,
    required: false,
    default: () => {
      return {};
    }
  })
  public queryOptions!: OsQueryOptions;

  /**
   * 查询配置，用来初始化条件查询项
   */
  @Prop({ type: Array, required: true })
  public queryItemsOption!: Array<OsQueryItemOption>;

  /**
   * 查询的Form表单
   */
  @Prop({ type: Object, required: true })
  public queryForm!: { [prop: string]: any };

  public showMore = false;

  /**
   * 默认的查询条件，用于重置功能
   */
  private defaultQueryForm!: Object;

  public get options(): Required<OsQueryOptions> {
    // 合并用户自定义配置和默认配置
    return {
      size: 'mini',
      defaultShowQuantity: 3,
      ...this.queryOptions
    };
  }

  public created(): void {
    // 初始化查询form
    this.queryItemsOption.forEach(x => {
      // 若没有单独设置查询项size，以全局的size为主
      if (!x.option.size) {
        x.option.size = this.options.size;
      }

      this.initFormData(x);
    });
    this.defaultQueryForm = cloneDeep(this.queryForm);
  }

  public resetQuery(): void {
    Object.assign(this.queryForm, this.defaultQueryForm);
    this.$nextTick(() => {
      this.queryClick('reset');
    });
  }

  public queryClick(type?: 'reset'): void {
    this.$emit('query-click', type);
  }

  public triggerInputChange(option: OsQueryItemOption, event: any): void {
    if (option.change && typeof option.change === 'function') {
      option.change(event);
      return;
    }
    this.queryClick();
  }

  public getOptionData(item: OsQueryItemOption): Array<{ label: string; value: string }> {
    return (typeof item.optionData === 'function' ? item.optionData() : item.optionData) || [];
  }

  /**
   * 初始化查询参数
   * @param queryItem 查询配置
   * @returns
   */
  private initFormData(queryItem: OsQueryItemOption): void {
    if (!this.queryForm[queryItem.field]) {
      if (queryItem.type === 'DateRangePicker') {
        // 处理特殊情况
        Vue.set(this.queryForm, queryItem.field, []);
        return;
      }
      if (queryItem.type === 'Cascader' && queryItem.option?.props?.multiple) {
        Vue.set(this.queryForm, queryItem.field, []);
        return;
      }
      Vue.set(this.queryForm, queryItem.field, undefined);
    }
  }
}
