/**
 * 作用范围
 */
export enum TodoScopeEnum {
  /**
   * 结算单
   */
  billing = 1,
  /**
   * 发票
   */
  invoice
}

/**
 * 待办事项类型 主题
 */
export enum TodoTypeEnum {
  /**
   * 结算申请
   */
  billing = 1,
  /**
   * 废结申请
   */
  abolish,
  /**
   * 发票换开申请
   */
  changeInvice
}
