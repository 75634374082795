/**
 * 审核状态
 */
export enum WorkflowStatusEnum {
  /**
   * 未发布
   */
  unpublished = 0,
  /**
   * 已发布
   */
  published = 1,
  /**
   * 已废弃
   */
  abandoned = 2
}
