/**
 * 用量计算参数
 */
export enum UsageCalculationParametersEnum {
  /**
   * 制作面积
   */
  MADE_AREA,
  /**
   * 制作周长
   */
  MADE_PERIMETER,
  /**
   * 制作数量
   */
  MADE_QUANTITY
}
