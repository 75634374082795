import supplierDeviceService from '@/api/supplier-management/supplier-deivce';
import authService from '@/api/auth';
import departmentService from './organization/department';
import personnelService from './organization/personnel';
import positionService from './organization/position';
import platformPermissionService from './system-setting/platform-permission';
import roleService from './system-setting/role';
import minioService from './minio';
import classifyService from './database/classify';
import deviceClassifyService from './database/device-classify';
import productService from './product-management/product';
import outputService from './craft-management/output';
import backendService from './craft-management/backend';
import PrepressCraftsService from './craft-management/prepress';
import customerClassifyService from './customer-management/customer-classify';
import customerService from './customer-management/customer';
import customerInvoiceService from './customer-management/customer-invoice';
import invoiceService from './database/invoice';
import taxRateService from './database/tax-rate';
import customerProductService from './customer-management/customer-product';
import customerContactsService from './customer-management/customer-contacts';
import shopTagService from './customer-management/shop-tag';
import pointTagService from './customer-management/point-tag';
import customerShopService from './customer-management/customer-shop';
import supplierClassifyService from './supplier-management/supplier-classify';
import customerPointService from './customer-management/customer-point';
import supplierService from './supplier-management/supplier';
import supplierLevelService from './system-setting/supplier-level';
import supplierRoleService from './supplier-management/supplier-role';
import supplierDepartmentService from './supplier-management/supplier-department';
import supplierPositionService from './supplier-management/supplier-position';
import supplierPersonnelService from './supplier-management/supplier-personnel';
import supplierPermissionService from './system-setting/supplier-permission';
import printingMaterialService from './supplier-management/printing-material';
import backendMaterialService from './supplier-management/backend-material';
import supplierProductService from './supplier-management/supplier-product';
import projectService from './project-management/project';
import productionOrderService from './production-management/production-order';
import productionOrderLogisticsService from './production-management/production-order-logistics';
import productionProductSearchService from './project-management/production-product-search';
import projectShopService from './project-management/project-shop';
import projectProductService from './project-management/project-product';
import productionOrderChangeService from './production-management/production-order-change';
import minePrepressTaskService from './prepress-task/mine-task';
import minePrepressChangeColorTaskService from './prepress-task/mine-change-color-task';
import mineFinishedProductTaskService from './prepress-task/mine-finished-product-task';
import mineTypesettingTaskService from './prepress-task/mine-typesetting-task';
import changeColorTaskService from './prepress-task/change-color-task';
import colorChangeSampleService from './prepress-task/color-change-sample';
import completeManuscriptTaskService from './prepress-task/complete-manuscript-task';
import deliveryNoticeService from './logistics-management/delivery-notice';
import installationNoticeService from './installation-management/installation-notice';
import installationOrderService from './installation-management/installation-order';
import logisticsService from './logistics-management/logistics';
import billingService from './finance-management/billing';
import financeInvoiceService from './finance-management/finance-invoice';
import transactionService from './finance-management/transaction-details';
import todoService from './finance-management/todo';
import messageCenterService from './message-center';
import platformPrintingMaterialService from './product-management/printing-material';
import platformBackendMaterialService from './product-management/backend-material';
import mobileInstallationOrderService from './installation-management/mobile-installation-order';
import consumeService from './project-management/consume';
import systemConfigService from './system-setting/system-config';
import hmPackagingService from './hm-module/hm-packaging';
import hmOrderService from './hm-module/hm-order';
import productionOrderProductService from './production-management/production-order-product';
import DashboardService from './dashboard/dashboard';
import InstallEmployService from './installation-management/install-employee';
import ServiceItemService from './installation-management/service-item';
import CommonTagService from './installation-management/common-tags';
import { FileService } from './file';
import { InstallExceptionRecordService } from './installation-management/install-exception-record';
import queryPlanService from './system-setting/query-plan';
import regionService from './public-data/region';
import unitsMeasureGroupsService from './public-data/units-measure-groups';
import unitsMeasureService from './public-data/units-measure';
import currencyService from './public-data/currency';
import exchangeRateService from './public-data/exchange-rate';
import workflowDesignService from './system-setting/workflow-design';
import warehouseStoreService from './database/warehouse-store';
import warehouseService from './database/warehouse';
import storeLocationService from './database/store-location';
import inventoryTypeService from './database/inventory-type';
import materialListService from './database/material-list';
import feedbackService from './feedback-suggestion/suggestion';
import billOfMaterialService from './database/bill-of-material';
import todoTaskService from './task-center/todo-task';
import preperssStatisticsService from './preperss-statistics/preperss-statistics';

export {
  authService,
  platformPermissionService,
  supplierPermissionService,
  departmentService,
  roleService,
  positionService,
  personnelService,
  minioService,
  classifyService,
  productService,
  deviceClassifyService,
  outputService,
  backendService,
  PrepressCraftsService,
  customerClassifyService,
  customerService,
  customerInvoiceService,
  invoiceService,
  taxRateService,
  customerProductService,
  customerContactsService,
  shopTagService,
  pointTagService,
  customerShopService,
  supplierClassifyService,
  supplierService,
  customerPointService,
  supplierLevelService,
  supplierRoleService,
  supplierDepartmentService,
  supplierPositionService,
  supplierPersonnelService,
  printingMaterialService,
  backendMaterialService,
  supplierDeviceService,
  supplierProductService,
  projectService,
  productionOrderService,
  productionOrderLogisticsService,
  productionProductSearchService,
  productionOrderChangeService,
  projectShopService,
  projectProductService,
  minePrepressTaskService,
  minePrepressChangeColorTaskService,
  mineFinishedProductTaskService,
  mineTypesettingTaskService,
  changeColorTaskService,
  colorChangeSampleService,
  completeManuscriptTaskService,
  deliveryNoticeService,
  installationOrderService,
  installationNoticeService,
  logisticsService,
  billingService,
  financeInvoiceService,
  transactionService,
  todoService,
  messageCenterService,
  platformPrintingMaterialService,
  platformBackendMaterialService,
  mobileInstallationOrderService,
  consumeService,
  systemConfigService,
  hmPackagingService,
  hmOrderService,
  productionOrderProductService,
  DashboardService,
  InstallEmployService,
  CommonTagService,
  ServiceItemService,
  FileService,
  InstallExceptionRecordService,
  queryPlanService,
  feedbackService,
  regionService,
  unitsMeasureGroupsService,
  currencyService,
  unitsMeasureService,
  exchangeRateService,
  workflowDesignService,
  warehouseStoreService,
  warehouseService,
  storeLocationService,
  inventoryTypeService,
  materialListService,
  billOfMaterialService,
  todoTaskService,
  preperssStatisticsService
};
