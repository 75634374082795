/**
 * 印前工艺类型
 */
export enum PrepressCraftsTypeEnum {
  /**
   * 排版
   */
  finalized = 1,
  /**
   * 改色
   */
  changeColor = 2
}
