import { ResourceUrlEnum } from '@/resource/enum';
import { PermissionResource, SupplierLevelResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class SupplierLevelService implements BaseService<SupplierLevelResource> {
  public getById(id: number): Promise<SupplierLevelResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierLevelResource>(ResourceUrlEnum.supplierLevel, { params: { id } })
        .then((res: ApiResponse<SupplierLevelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SupplierLevelResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SupplierLevelResource>(ResourceUrlEnum.supplierLevel, resource)
        .then((res: ApiResponse<SupplierLevelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SupplierLevelResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SupplierLevelResource>(ResourceUrlEnum.supplierLevel, resource)
        .then((res: ApiResponse<SupplierLevelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierLevel, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierLevel}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<SupplierLevelResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierLevelResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierLevelResource>>(`${ResourceUrlEnum.supplierLevel}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierLevelResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查级别名称是否重复
   * @param supplierLevelName 级别名称
   * @param id 角色主键（修改时需要传入）
   * @returns 为true则代表重复
   */
  public checkNameRepeat(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierLevel}/checkNameRepeat`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   *  检查供应商级别序列是否重复
   * @param serial 序列
   * @returns 为true则代表重复
   */
  public checkSerialRepeat(serial: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierLevel}/checkSerialRepeat`, {
          params: { serial }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 给供应商级别赋权
   * @param resourcesIdList 资源id
   * @param gradeId 级别id
   * @returns
   */
  public empowerForLevel(params: { resourcesIdList: Array<number>; gradeId: number }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierLevel}/empowerment`, params)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取供应商级别已存在的权限
   * @param gradeId 级别id
   * @returns
   */
  public getExistedPermissions(gradeId: number): Promise<Array<PermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<PermissionResource>>(`${ResourceUrlEnum.supplierLevel}/listResourcesByGradeId`, {
          params: { gradeId }
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有供应商等级
   * @returns 供应商等级信息列表
   */
  public getAllSupplierLevels(): Promise<Array<SupplierLevelResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(`${ResourceUrlEnum.supplierLevel}/list`)
        .then((res: ApiResponse<Array<SupplierLevelResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierLevelService = new SupplierLevelService();
export default supplierLevelService;
