import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const logisticsManagementRoutes: RouteConfig = {
  path: '/logistics-management',
  component: Layout,
  redirect: 'logistics',
  name: 'LogisticsManagement',
  children: [
    {
      path: '/delivery-notice',
      name: 'DeliveryNotice',
      component: async () =>
        import(
          /* webpackChunkName: "delivery-notice" */ '@/views/logistics-management/delivery-notice/delivery-notice.vue'
        ),
      meta: {
        title: 'route.deliveryNotice',
        id: 1254
      },
      children: [
        {
          path: '/delivery-notice-details',
          name: 'DeliveryNoticeDetails',
          component: async () =>
            import(
              /* webpackChunkName: "delivery-notice-details" */ '@/views/logistics-management/delivery-notice/delivery-notice-details/delivery-notice-details.vue'
            ),
          meta: {
            title: 'route.deliveryNoticeDetails',
            id: 1438,
            hidden: true,
            parentId: 1254
          }
        },
        {
          path: '/add-delivery-notice',
          name: 'AddDeliveryNotice',
          component: async () =>
            import(
              /* webpackChunkName: "add-delivery-notice" */ '@/views/logistics-management/delivery-notice/add-delivery-notice/add-delivery-notice.vue'
            ),
          meta: {
            title: 'route.addDeliveryNotice',
            id: 1255,
            hidden: true,
            parentId: 1254
          }
        }
      ]
    },

    {
      path: '/logistics',
      name: 'Logistics',
      component: async () =>
        import(/* webpackChunkName: "logistics" */ '@/views/logistics-management/logistics/logistics.vue'),
      meta: {
        title: 'route.logistics',
        id: 1253
      },
      children: [
        {
          path: '/logistics-details',
          name: 'LogisticsDetails',
          component: async () =>
            import(
              /* webpackChunkName: "logistics-details" */ '@/views/logistics-management/logistics/logistics-details/logistics-details.vue'
            ),
          meta: {
            title: 'route.logisticsDetails',
            id: 1258,
            hidden: true,
            parentId: 1253
          }
        }
      ]
    }
  ],
  meta: {
    title: 'route.logisticsManagement',
    id: 1252,
    icon: '#logistics'
  }
};
