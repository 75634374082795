export default {
  route: {
    login: '登录',
    dashboard: '首页',
    avatarUpload: '头像上传',
    backToTop: '返回顶部',
    i18n: '国际化',
    personalSetting: '个人设置',
    systemSetting: '系统设置',
    platformPermission: '平台权限资源',
    supplierPermission: '供应商权限资源',
    workflowDesign: '工作流设计',
    organization: '组织架构',
    department: '部门管理',
    personnel: '人员管理',
    position: '岗位管理',
    role: '角色管理',
    product: '产品管理',
    productList: '产品列表',
    productClassify: '产品分类',
    // 基础数据
    database: '基础数据',
    deviceClassify: '设备分类',
    invoice: '开票信息',
    storeLocation: '仓位列表',
    inventoryType: '存货类别',
    warehouse: '仓库列表',
    warehouseDetails: '仓库详情',
    warehouseStore: '仓库仓位关系',
    materialList: '物料列表',
    addMaterial: '物料',
    detailsMaterial: '物料详情',
    billOfMaterial: '物料清单',
    addBom: '物料清单',
    detailsBom: '物料清单详情',
    materialClassification: '物料分类',
    craft: '工艺管理',
    output: '输出工艺',
    backend: '后道工艺',
    prepressCrafts: '印前工艺',
    customer: '客户管理',
    customerList: '客户列表',
    customerClassify: '客户分类',
    customerTransfer: '客户转接',
    customerAllocationList: '客户分配查询',
    approvalSetting: '工作流',
    // 客户
    sellPrice: '售价管理',
    shop: '门店管理',
    point: '点位管理',
    shopTag: '门店标签',
    pointTag: '点位标签',
    contacts: '联系人管理',
    // 供应商
    supplier: '供应商管理',
    supplierList: '供应商列表',
    supplierClassify: '供应商分类',
    printingMaterial: '输出材料',
    backendMaterial: '后道材料',
    // 客户结束
    supplierLevel: '供应商等级',
    supplierProduct: '采购管理',
    supplierDevice: '设备管理',
    // 项目管理
    project: '项目管理',
    projectDetails: '项目详情',
    projectList: '项目列表',
    projectShopDetails: '门店明细',
    consumeManagement: '耗损管理',
    projectToDo: '项目待办',
    saleDetails: '销售明细',
    // 生产管理
    productionManagement: '生产管理',
    productionOrder: '生产订单列表',
    productionOrderChange: '生产订单变更单列表',
    productionOrderDetail: '生产订单详情',
    addProductionOrderChange: '新建生产订单变更单',
    editProductionOrderChange: '编辑生产订单变更单',
    shippingNotice: '发货通知单列表',
    addProductionOrder: '生产订单',
    distributeOrder: '下发生产',
    productionOrderChangeDetail: '生产订单变更单详情',
    // 印前任务
    prepressTask: '印前任务',
    mineTask: '我的任务',
    mineFinishedProductTask: '我的成品任务',
    mineChangeColorTask: '我的改色任务',
    mineTypesettingTask: '我的排版任务',
    changeColorTask: '改色任务',
    completeManuscriptTask: '完稿任务',
    colorChangeSample: '改色样管理',
    messageCenter: '消息中心',
    logisticsManagement: '物流管理',
    logisticsDetails: '物流单详情',
    logistics: '物流单列表',
    addLogistics: '添加物流单',
    deliveryNotice: '发货通知单列表',
    deliveryNoticeDetails: '发货通知单详情',
    addDeliveryNotice: '发货通知单',
    addChangeColorSample: '改色样',
    colorChangeSampleDetails: '改色样详情',
    printPreview: 'layout单据预览',
    // 安装管理
    installationManagement: '安装管理',
    installationNotice: '安装通知单列表',
    installationNoticeDetail: '安装通知单详情',
    addInstallationNotice: '安装通知单',
    installationOrder: '安装订单列表',
    addInstallationOrder: '新建安装单',
    installationOrderDetail: '安装订单详情',
    mobileInstallationOrder: '安装报工单',
    batchEditPrice: '批量改价',
    editInstallationOrder: '编辑安装订单',
    serviceItem: '服务项目列表',
    commonTags: '常用标签列表',
    installationAcceptance: '安装单验收',
    // 财务管理
    todo: '待办事项',
    financeManagement: '财务管理',
    financeInvoice: '发票管理',
    billing: '结算管理',
    transactionDetails: '交易明细',
    addBilling: '结算单新建',
    billingDetails: '结算单详情',
    invoiceDetails: '发票详情',
    messageConfig: '消息配置',
    projectConfig: '项目设置',
    // HM专项模块
    hmModule: 'HM专项',
    hmPackaging: '包装管理',
    hmOrder: '订单管理',
    installEmployee: '工人管理',
    // 公共数据
    publicData: '公共数据',
    region: '行政区划',
    unitMeasure: '计量单位',
    currencyManagement: '币别管理',
    unitsMeasureGroups: '计量单位分组',
    exchangeRate: '汇率管理',
    // 反馈建议
    feedbackSuggestion: '反馈建议',
    feedbackSetting: '反馈设置',
    feedbackSuggestionList: '反馈建议列表',
    addFeedbackSuggestion: '提交反馈建议',
    myFeedbackSuggestiont: '我的反馈记录',
    // 任务中心
    taskCenter: '任务中心',
    todoTask: '待办任务',
    completeTask: '已办任务',
    initiatedTask: '我发起的',
    ccTask: '抄送我的',
    taskDetails: '任务详情',
    // 印前统计
    prepressStatistics: '印前统计',
    completeManuscriptWorkContent: '完稿工作内容明细',
    departmentAverageConfirmationRound: '部门平均确稿轮数统计',
    customerAverageConfirmationRound: '客户平均确稿轮数统计',
    projectAverageConfirmationRound: '项目平均确稿轮数统计',
    personalAverageConfirmationRate: '个人平均确稿率统计',
    depaertmentAverageConfirmationRate: '部门平均确稿率统计'
  },
  common: {
    status: '状态',
    selectStatus: '请选择状态',
    createTime: '创建时间',
    startTime: '开始时间',
    endTime: '结束时间',
    operation: '操作',
    menu: '菜单',
    button: '按钮',
    startUsing: '启用',
    disabled: '禁用',
    keyword: '关键词',
    inputKeyWord: '请输入关键词',
    createUser: '创建人',
    usingSuccess: '启用成功',
    disabledSuccess: '禁用成功',
    man: '男',
    woman: '女',
    remark: '备注信息',
    inputRemark: '请填写备注信息',
    unKnownStatus: '未知状态',
    yuan: '元',
    repair: '维修中',
    statusUpdateStatus: '状态更新成功',
    createUserName: '下单人',
    inputCreateUserName: '请填写下单人姓名',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    moreOpetations: '更多操作',
    selectMaxDataTip: '最多只能选择一条数据',
    selectminDataTip: '至少选择一条数据',
    keepDataTip: '至少保留一条数据',
    unknown: '未定义',
    baseInfo: '基本信息',
    details: '明细信息',
    index: '序号',
    waitingUpload: '等待上传',
    clickToPreview: '点击预览文件',
    fileUpload: '文件上传',
    notImages: '暂无图片',
    inputLegalTel: '请填写合法的电话号码',
    yes: '是',
    no: '否',
    select: '请选择',
    input: '请填写',
    repeat: '已使用',
    selectUsingData: '请选择启用状态为【启用】且不是系统预置的数据',
    selectDisabledData: '请选择启用状态为【禁用】且不是系统预置的数据',
    codeRepeat: '编码已存在',
    nameRepeat: '名称已存在',
    submitTip: '你有必填项未填写， 请完整填写表单信息！',
    read: '已读',
    unread: '未读',
    revoke: '撤销',
    notGenerate: '未生成',
    generated: '已生成',
    generating: '生成中',
    generationFailed: '生成失败',
    approveStatus: '审核状态',
    useTip: '使用说明',
    clickToSee: '点击查看'
  },
  time: {
    hours: '时',
    minutes: '分',
    seconds: '秒'
  },
  datePicker: {
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期'
  },
  navbar: {
    logout: '退出登录',
    dashboard: '首页',
    profile: '个人中心'
  },
  tagsView: {
    refresh: '刷新',
    close: '关闭',
    closeOthers: '关闭其它',
    closeAll: '关闭所有'
  },
  table: {
    loadingText: '拼命加载中...',
    summaryText: '合计'
  },
  button: {
    cancel: '取消',
    ok: '确定',
    submit: '提交',
    add: '新增',
    search: '搜索',
    edit: '修改',
    delete: '删除',
    reset: '重置',
    using: '启用',
    disabled: '禁用',
    import: '导入',
    select: '选择',
    confirmSelect: '确认选择',
    confirmImport: '确认导入',
    packUp: '收起',
    more: '更多',
    unfold: '展开',
    fold: '折叠',
    checkAll: '全选',
    clearAll: '清空',
    back: '返回',
    export: '导出',
    exportDetail: '导出单据明细',
    repairing: '维修中',
    download: '下载',
    ClickDownload: '点击下载',
    batchCancel: '批量取消',
    batchDelete: '批量删除',
    distribute: '派单',
    confirmComplete: '确认完成',
    distributeOrder: '派发生产',
    nextStep: '下一步',
    prevStep: '上一步',
    submitAndSend: '提交并派单',
    submitApproval: '提交审批',
    approval: '审批',
    approvalSuccess: '通过审批',
    approvalFailed: '未通过审批',
    close: '关闭',
    copy: '复制',
    confirmUpload: '确认上传',
    save: '保存',
    saveAndPush: '保存并推送',
    push: '推送',
    batchPush: '批量推送',
    approve: '审核',
    reverseApprove: '反审核',
    reject: '驳回',
    agree: '同意',
    upload: '上传',
    batchUpdate: '批量修改',
    change: '变更',
    prevIndividual: '上一个',
    nextIndividual: '下一个',
    selectAnnex: '选择附件'
  },
  operationRes: {
    cancelDelete: '已取消删除',
    addSuccess: '新增成功',
    editSuccess: '修改成功',
    deleteSuccess: '删除成功',
    addFailed: '添加失败',
    editFailed: '修改失败',
    deleteFailed: '删除失败',
    cancelReset: '取消重置',
    resetSuccess: '重置成功',
    operationCanceled: '操作已取消',
    cancelOperation: '已取消操作',
    operationSuccess: '操作成功',
    saveSuccess: '保存成功',
    submitSuccess: '提交成功',
    operationFailed: '操作失败',
    setSuccess: '设置成功',
    setFailed: '设置失败',
    uploadSucess: '上传成功',
    uploadFailed: '上传失败'
  },
  tip: {
    tipInfo: '提示信息',
    closeTip: '确定要关闭吗？',
    confirmDelete: '确定要删除选中的数据吗？',
    resetPassword: '确定重置用户密码为',
    confirmTitle: '确认信息',
    confirmReload: '请确认是否需要重新加载页面信息？',
    stayCurrentPage: '停留在当前页面',
    confirmCancel: '确认取消选中数据？',
    beforeLeave: '当前页面有填写的信息尚未保存，确定要离开吗？',
    confirmApprovalSuccess: '确认审批通过?',
    confirmPush: '确定要推送选中数据吗?',
    noWaitApproveData: "请选择待审核单据",
    noApprovedData: "请选择已审核单据",
    noEnableData: "请选择【状态 = 已启用】单据"
  },
  components: {
    changeLanguageTips: '切换语言成功',
    selectRegionTip: '请选择地区',
    selectCustomer: '请选择客户'
  },
  minio: {
    initError: '服务暂时不可用，请检查网络状态，并重试!',
    bucketNotExist: '未找到模板文件，请联系管理员!'
  },
  lodop: {
    printUnInstallTip: '您还未安装打印控件，点击下载打印控件，安装成功后刷新页面即可进行打印。',
    loading: '打印控件还没准备好，请稍后再试！'
  },
  dialog: {
    selectPosition: '选择岗位',
    selectPersonnel: '选择人员',
    importPersonnel: '导入人员',
    downloadTemplate: '下载导入模板',
    importClassify: '导入分类',
    importLoadingText: '拼命导入中...',
    importSuccess: '导入成功',
    importErrorTip: '失败信息如下：',
    importErrorRow: '失败所在行数:',
    importErrorReason: '失败原因为：',
    importPlatformProduct: '平台产品导入',
    importCraft: '工艺导入',
    importCustomer: '客户信息导入',
    importInvoice: '开票信息导入',
    importTaxClassify: '税收分类导入',
    importCustomerShop: '客户门店导入',
    importCustomerPoint: '门店数据导入',
    importCustomerPointCheckData: '门店需校验数据导入',
    importSupplier: '供应商信息导入',
    importExchangeRate: '汇率导入',
    importUnitMeasure: '计量单位导入',
    uploadSuccess: '上传成功',
    saveFailed: '保存失败'
  },
  validate: {
    mobileIOrPhonellegal: '手机号或电话号不合法，例如：021-12345678',
    mobileIllegal: '手机号不合法'
  },
  requestError: {
    // 公共异常
    unknownError: '系统错误',
    _10001: '参数无效',
    _10002: '参数不能为空',
    _10003: '文件读取失败',
    _10004: '文件下载失败',
    _10005: '数据导入失败',
    _10006: '该名称已存在',
    _10007: '文件中数据为空',
    _10008: '暂无数据可导出',
    _10009: '存在关键字',
    _10010: 'sql排序关键字错误',
    _10011: '签名错误',
    _10012: '工作流程图不存在',
    _10013: '数字格式错误，请检查',
    _10014: '数据已禁用，不能执行该操作',
    _10015: '数据已审核，不能执行该操作',
    _10016: '必填字段为空',
    _10017: '已有下游数据',
    _10018: '编码已存在',
    _10019: '该数据不允许删除',
    _10020: '数据不允许提交',
    _10021: '数据不允许取消',
    _10022: '数据不允许修改',
    _10023: '数据重复',
    _10024: '手机号码填写错误',
    _10025: '数据未审核，不能执行该操作',
    _10026: '列数据格式错误',
    _10027: '请确认是否最新模板或者重新下载最新模板',
    _4: '请求超时',
    _42301: '访问过快，请稍后再试！',
    // 登录模块
    _20101: '用户未登录',
    _20102: '用户名或密码错误',
    _20103: '账户已被禁用',
    _20104: '没有操作权限',
    _20105: '登录信息已失效，请重新登录!',
    _20106: '登录信息已失效，请重新登录!',
    _20107: '登录信息已失效，请重新登录!',
    _20108: '验证码为空',
    _20109: '操作失败',
    _20110: '验证码过期',
    _20111: '发送验证码失败',
    _20112: '该邮箱未与任何账户建立联系',
    _20113: '公司域名不存在',
    _20114: '密码解密失败',
    _20115: '登录超时请重新登录',
    _20116: '手机号不存在请联系管理员',
    // 用户模块
    _20201: '账户无效',
    _20202: '密码错误',
    _20203: '用户名已存在',
    _20204: '邮箱重复',
    _20205: '电话号码已被使用',
    _20206: '重置密码失败',
    _20207: '管理员不允许删除',

    // 物料分类管理
    _20301: '父级分类不存在',
    _20302: '已有下游分类数据',

    // 资源模块
    _20401: '该资源名称已存在',
    _20402: '请先删除所有子级资源',
    _20403: '父级资源不存在',

    // 岗位管理
    _20502: '该岗位名称已存在',
    _20503: '删除失败，存在用户绑定该岗位',

    // 部门管理
    _20601: '该部门名称已存在',
    _20602: '上级部门不存在',
    _20603: '已绑定岗位数据',
    _20604: '该部门存在下级部门，请先删除下级部门',

    // 设备分类管理
    _20701: '设备分类名称重复',
    _20702: '设备分类为空',
    _20703: '已绑定数据，请勿删除',

    // 客户分类
    _20801: '已有绑定数据',

    // 客户管理模块
    _20901: '域名已被占用',
    _20902: '企业分类不存在',
    _20903: '没有查询权限',
    _20904: '企业信息不存在',

    // 客户产品
    _21001: '已有相同产品，请勿重复添加',
    _21002: '制作品的产品分类必须相同!',
    _21003: '计价单位不不存在或审核未通过',
    _21004: '客户产品不存在',
    _21005: '客户产品不可用',
    _21006: '客户产品不存在或不可用',

    // type 产品管理
    _21101: '基本单位不存在或已被禁用！',
    _21102: '物料分类不存在或已被禁用!',
    _21103: '存货类别不存在或已被禁用!',
    _21104: '库存单位不存在或已被禁用!',
    _21105: '辅助单位不存在或已被禁用!',
    _21106: '仓库不存在或已被禁用!',
    _21107: '仓位不存在或已被禁用!',
    _21108: '销售单位不存在或已被禁用!',
    _21109: '销售基本单位不存在或已被禁用!',
    _21110: '采购单位不存在或已被禁用!',
    _21111: '采购基本单位不存在或已被禁用!',
    _21112: '生产单位不存在或已被禁用!',
    _21113: '生产仓库不存在或已被禁用!',
    _21114: '生产仓位不存在或已被禁用!',
    _21115: '库存仓库仓位未关联!',
    _21116: '生产仓库仓位未关联!',
    _21117: '物料不存在!',
    _21118: '已开生产，生产流程不能全部关闭!',

    // 发票相关异常
    _21201: '发票类型不存在',
    _21301: '税率异常',

    // 门店管理
    _21401: '门店不存在',
    _21402: '门店名称为空',
    _21403: '门店名称重复',
    _21404: '门店标签重复',
    _21405: '门店编号不存在',
    _21406: '门店地址解析错误',
    _21407: '门店详细地址为空',
    _21408: '门店解析详细地址为空',
    _21409: '门店收货电话错误',
    _21410: '门店电话错误',
    _21411: '门店标签格式不正确或标签值重复',
    _21412: '门店名称超过30个字符',
    _21413: '地址匹配失败',

    // 点位标签
    _21501: '点位标签名称重复',
    _21502: '点位标签格式不正确或标签值重复',
    _21503: '点位编号不存在',
    _21504: '点位状态为空',
    _21505: '可见高度为空或为0',
    _21506: '可见宽度为空或为0',
    _21507: '完成宽度为空或为0',
    _21508: '完成高度为空或为0',
    _21509: '客户产品为空',
    _21510: '点位不存在',
    _21511: '点位已存在且不属于当前门店',
    _21512: '客户产品不存在',
    _21513: '点位标签值重复',
    _21514: '数据不一致',

    // Minlo错误
    _21601: 'minIO配置信息不存在!',
    _21602: '处理器不存在',

    // 工艺管理
    _21701: '该工艺名称已存在',

    // 角色管理
    _21801: '该角色名称已存在',
    _21802: '该角色已在岗位中使用',

    // 供应商级别
    _21901: '序列重复',
    _21902: '供应商等级不存在',

    // 供应商管理
    _22001: '供应商等级不存在或未赋予权限',
    _22002: '供应商不存在',

    // 供应商设备
    _22201: '设备分类不存在',
    _22202: '未知状态',

    // 供应商产品
    _22301: '平台产品不存在！',
    _22302: '供应商产品不存在',
    _22303: '供应商产品已存在',
    _22304: '供应商产品未开生产工序',

    // 快递100
    _22401: '数据查询失败',
    _22402: '数据订阅失败',
    _22403: '电子面单请求错误',

    // 项目管理
    _22501: '项目不存在',
    _22502: '该数据未到完成阶段',

    // 项次管理
    _22601: '当前数据未到改稿阶段！',
    _22602: '当前数据未到确稿阶段！',
    _22603: '该数据未到提交制作阶段！',
    _22604: '该状态下无法修改！',
    _22605: '项次类型不能修改',
    _22606: '客户产品不存在',
    _22607: '出货时间不能早于今天',
    _22608: '到货时间不能早于今天',
    _22609: '到货时间不能早于出货时间',
    _22610: '可见宽大于完成宽',
    _22611: '项次编号重复',
    _22612: '可见高大于完成高',
    _22613: '制作品名与客户产品无绑定关系！',
    _22614: '项次已排产或提交印前不能修改',
    _22615: '项次已排产或已下发不能修改',
    _22616: '项次不存在或未开启出货开关',
    _22617: '计价单位不存在!',
    _22618: '该数据未到接单阶段',
    _22619: '该数据未到提交确稿阶段',
    _22620: '该数据未到完成阶段！',
    _22621: '该状态下数据不能取消',
    _22622: '没有数据可以修改！',
    _22623: '已有关联单据',
    _22624: '项次当前印前状态不需要反完成',
    _22625: '项次当前已制作完成',
    _22626: '单据无需确稿',
    _22627: '改色单据无需上传图片',
    _22628: '生产数量少于已出货数量',
    _22629: '生产数量少于已安装数量',
    _22630: '生产数量少于已结算数量',
    _22631: '已安装不能关闭安装开关',
    _22632: '已发货不能关闭出货开关',
    _22633: '项次不存在',
    _22634: '数据未到归档状态',
    _22635: '数据未到上传确稿图阶段',
    _22636: '出货时间或到货时间为空',
    _22637: '项次已关闭',
    _22638: '当前类型不能开启出货',
    _22639: '当前类型不能开启安装',
    _22640: '设计排版与生产文件不能全部关闭',
    _22641: '项次已关联生产，请联系客服取消生产后再操作',
    _22642: '项次未在成品制作中',
    _22643: '当前用户非接单人，不允许操作',

    // 生产订单
    _22701: '生产订单不存在',
    _22702: '生产订单状态不是「新建」',
    _22703: '生产订单状态不是「待确认」',
    _22704: '生产订单状态不是「待接单」',
    _22705: '生产订单状态不是「待排产」',
    _22706: '生产订单状态不是「进行中」',
    _22707: '生产订单明细不存在',
    _22708: '已下发生产，不能取消所有生产制作工序',

    // 生产单据管理
    _22801: '生产单据不存在',
    _22802: '单据正在制作中',
    _22803: '单据接单失败',
    _22804: '单据完成失败',
    _22805: '单据打样公司不存在',
    _22806: '不允许回退到未开启工序',
    _22807: '平台印前未完成',
    _22808: 'LAYOUT图片创建失败',
    _22809: 'LAYOUT单不存在',
    _22810: '单据工序不能为空',
    _22811: '工序错误，请切换角色',
    _22812: '平台印前确稿未开启',
    _22813: '项次存在变更单',

    // 发货通知单
    _22901: '发货单该状态不能推送',
    _22902: '发货单该状态不能完成',
    _22903: '发货单该状态不能删除',
    _22904: '发货单该状态不能取消',
    _22905: '发货单该状态不能确认',
    _22906: '门店不存在',
    _22907: '产品明细不存在',
    _22908: '供应商不存在',
    _22909: '发货数量已超出剩余生产数量',
    _22910: '发货单该状态不能创建物流单',
    _22911: '发货通知单不存在',
    _22912: '门店为空',
    _22913: '门店重复',
    _22914: '发货通知单存在物流单，无法取消',
    _22915: '发货单该状态不能反完成',
    _22916: '该项次未开出货环节',
    _22917: '包裹数量超过最大值',

    // 生产订单变更单
    _23001: '变更单不是「新建」状态，无法推送',
    _23002: '变更单不是「待确认」状态，无法执行变更',
    _23003: '已存在未执行的变更单，请先处理已有变更单',
    _23004: '变更单不存在',
    _23005: '变更供应商产品不存在',
    _23006: '生产订单已在「待确认」或「已完成」状态，无法执行变更',
    _23007: '变更单明细不存在',
    _23008: '产品未开启生产工序',

    // 物流单管理
    _23101: '单据未到收货阶段',
    _23102: '单据未处于发货阶段',
    _23103: '物流单该状态不能进行物流轨迹订阅',
    _23104: '当前状态不允许关闭',
    _23105: '物流单号不存在',
    _23106: '物流单编号为空',
    _23107: '物流单不存在或已发货',
    _23108: '发货人为空',
    _23109: '发货人联系方式为空',
    _23110: '发货省份为空',
    _23111: '发货城市为空',
    _23112: '发货区县为空',
    _23113: '发货模版为空',
    _23114: '发货详细地址不存在',
    _23115: '物流单号为空',
    _23116: '物流单公司为空',
    _23117: '物流单号为空',
    _23118: '该状态下物流单不能删除',
    _23119: '快递公司未填写',
    _23120: '物流单发货方式不属于第三方物流',
    _23121: '物流单查询失败',
    _23122: '物流单该状态下不能编辑',
    _23123: '物流单该状态下不能取消',
    _23124: '重量为空',
    _23125: '运费为空',

    // 安装通知单
    _23201: '安装通知单不存在',
    _23202: '通知单不能提交',
    _23203: '安装通知单不能删除',
    _23204: '安装通知单不能审批',
    _23205: '安装通知单不能反审核',
    _23206: '安装通知单不能完成',
    _23207: '安装通知单不能取消',
    _23208: '安装项次超出项次数量',
    _23209: '安装单不能执行此操作',
    _23214: '完成日期为空或者早与当前时间',

    // 电子面单
    _23301: '该快递公司不支持电子面单',
    _23302: '月结账号为空',

    // 安装单
    _23401: '安装单不存在',
    _23402: '安装单当前状态不能删除！',
    _23403: '安装单预约时间不能早于当前时间！',
    _23404: '当前安装单状态不为安装中！',
    _23405: '当前安装单状态不为待审核！',
    _23406: '安装单不为空！',
    _23407: '安装单产品数据暂无可安装数量或产品数据为空',
    _23408: '安装单当前状态不能提交！',
    _23409: '安装单当前状态不能编辑！',
    _23410: '安装单当前状态不能完成！',
    _23411: '安装单当前状态不能驳回！',
    _23412: '安装单当前没有完工照！',
    _23413: '安装单当前状态不能分配！',
    _23414: '该订单不属于当前操作人！',
    _23415: '安装单当前不能执行此操作！',
    _23416: '安装单当前状态不能提交审批！',
    _23417: '安装单当前没有签名照！',

    // 结算单
    _23501: '结算明细未税总金额与发票未税总金额不一致！',
    _23502: '未填写结算明细',
    _23503: '未填写发票信息',
    _23504: '结算单不处于新建状态',
    _23505: '结算单不处于审批状态',
    _23506: '结算数量已超出！',
    _23507: '结算项次不存在！',
    _23508: '该状态下不能核销',
    _23509: '结算单不存在',
    _23510: '此次核销金额大于未核销金额，核销失败！',
    _23511: '此次反核销金额超过已核销金额，',
    _23512: '结算项次不存在或不属于该客户！',
    _23513: '结算项次重复',
    _23514: '该状态不允许废结',
    _23515: '发票状态不处于新建状态',
    _23516: '存在审批中数据',
    _23517: '请勿重复保存结算单',

    _23602: '数据未到归档状态',

    // 交易管理
    _23701: '当前状态下不允许删除',
    _23702: '客户不存在',

    // HM专项
    _23901: '订单不存在',
    _23902: '暂无可导出产品标签',
    _23903: '缺少匹配的区域信息',
    _23904: '请先导出PSL',
    _23905: '包装编号重复',
    _23906: '缺少匹配包装',
    _23907: '缺少匹配托盘',
    _23908: '未匹配到相关产品',

    // 耗损
    _24001: '该条耗损数据关联的项次处于生产中状态，不允许删除',

    // 安装工人管理
    _24101: '手机号码重复',
    _24105: '备注超过30字符',
    _24107: '无修改权限',
    _24108: '必填字段为空',
    _24109: '服务项目非法填写',

    // 项目门店管理
    _24301: '出货数量不能大于销售数量！',
    _24302: '相关门店不存在',
    _24303: '门店重复填写',
    _24304: '表格必填数据未完整填写',
    _24305: '计划出货时间需晚于当前时间',
    _24306: '交货方式未填写',
    _24307: '当前项目无可发货项次数据',
    _24308: '没有可出货项次',

    // 反馈建议
    _24601: '提交权限已关闭，请联系管理员！',
    _24602: '提交未能超过时间间隔',
    _24603: '今日提交已超出上限',

    // 货币管理
    _24701: '编号重复',
    _24702: '名称重复',
    _24703: '货币编号重复',
    _24704: '不允许删除审批通过数据',
    _24705: '数据已冻结',
    _24706: '已有下游数据',
    _24707: '系统预置数据不允许操作',

    // 税率管理
    _24801: '目标币与原币重复',
    _24802: '生效时间重叠',
    _24803: '不允许删除审批通过数据',
    _24804: '币别不存在',
    _24805: '生效区间不合法',

    // 计量单位管理
    _24901: '基准单位不允许修改',
    _24902: '基准单位不允许删除',
    _24903: '基准单位不允许反审批冻结',
    _24904: '基准单位不允许启用禁用',
    _24905: '基准单位不存在',
    _24906: '计量单位组不存在',

    // 计量单位分组管理
    _25007: '数据不存在',

    // 改色样
    _25201: '数据不允许删除',
    _25202: '数据不允许提交',
    _25203: '数据不允许取消',
    _25204: '数据不允许修改',
    _25205: '该项次无生产工序',
    _25206: '该项次已生产',

    // 仓库仓位关系
    _25301: '仓库不存在或未开启仓位管理',
    _25302: '仓位不存在或数据被禁用',

    // 物料清单
    _25401: 'BOM版本重复',
    _25402: '默认领料仓库填写错误',
    _25403: '物料填写错误',
    _25404: '清单数据不能为空',
    _25405: '清单父物料不能与清单物料相同',

    _255502: '该类型已有已发布数据',

    // 审批任务
    _42401: '工作流配置不存在',
    _25601: '任务该状态不允许审批',
    _25602: '只能提交人撤回任务',
    _25603: '任务实例不存在',
    _25604: '任务不存在',

    // 其他
    nameRepeat: '该名称已存在',
    rsourceNameRepeat: '该资源名称已存在',
    rsourceCodeRepeat: '该权限标识已存在',
    departmentNameRepeat: '该部门名称已存在',
    roleNameRepeat: '该角色名称已存在',
    positionNameRepeat: '该岗位名称已存在',
    userNameRepeat: '登录账户已存在',
    emailRepeat: '邮箱已存在',
    mobileRepeat: '手机号已存在',
    classifyNameRepeat: '该分类名称已存在',
    productNameRepeat: '该产品名称已存在',
    craftNameRepeat: '该工艺名称已存在',
    customerNameRepeat: '企业名称已存在',
    domainNameRepeat: '企业域名已存在',
    contactNameRepeat: '联系人姓名已存在',
    productClassifyIsUsing: '已绑定数据，请勿删除！',
    shopTagNameRepeat: '该门店标签已存在',
    supplierNameRepeat: '企业名称已被使用',
    supplierDomainNameRepeat: '企业域名已被使用',
    supplierSequenceRepeat: '序列重复',
    materialNameRepeat: '材料名称已被使用',
    materialCodeRepeat: '材料编号已被使用',
    deviceNameRepeat: '设备名称已被使用',
    shopTagValueRepeat: '门店标签值重复',
    pointTagValueRepeat: '点位标签值重复',
    backendDataRepeat: '后道工艺材料组合已存在',
    productRepeatUse: '产品重复下发',
    billItemRepeat: '结算项次重复',
    shopRepeatUse: '门店重复填写',
    boxNumberRepeat: '包装编号重复',
    queryPlanNameRepeat: '查询方案名称重复',
    codeRepeat: '该编码已存在',
    regionCodeRepeat: '编码重复',
    regionNameRepeat: '名称重复',
    baseExchangeRateCurrencyRepeat: '目标币与原币重复',
    baseExchangeRateEffectiveTimeRepeat: '生效时间重叠',
    baseUnitOfMeasureMentCodeRepeat: '编号重复',
    baseUnitOfMeasureMentNameRepeat: '名称重复'
  },
  httpRequestError: {
    oftenRequest: '请求过于频繁',
    otherError: '请求异常',
    unAuthorized: '未授权，请进行身份认证',
    serverDeniedAccess: '服务器拒绝访问',
    resourceNotFound: '未找到资源',
    methodDisabled: '该请求方法被禁用',
    timeout: '请求超时',
    serverError: '服务器内部错误',
    serverNotSupport: '服务器不具备完成请求的功能',
    networkError: '请求超时',
    serveDied: '服务不可用',
    neworkTimeout: '网络超时',
    httpVersionNotSupport: 'http版本不受支持',
    httpCanceled: '请求已被取消'
  },

  accountSetting: {
    basicSetting: '基本设置',
    securitySetting: '安全设置',
    email: '邮箱',
    inputEmail: '请填写邮箱地址',
    phone: '联系电话',
    inputPhone: '请填写联系电话',
    avatar: '头像',
    replaceAvatar: '更换头像',
    updateInfo: '更新信息',
    password: '账户密码',
    avatarUploadError: '头像上传失败'
  },
  messageCenter: {
    notice: '通知',
    todo: '待办',
    noNotice: '暂无通知',
    noTodo: '暂无待办'
  },
  login: {
    title: '运营登录',
    reLoginTitle: '重新登录',
    logIn: '登录',
    username: '用户名',
    password: '密码',
    forgetPassword: '忘记密码',
    rememberPassword: '记住密码',
    any: '随便填',
    emptyUsername: '用户名不能为空',
    emptyPassword: '密码不能为空',
    passwordLength: '密码不能少于8位',
    verifyExplain: '向右滑动进行验证',
    verifyTitle: '请完成安全验证',
    getVerifyCodeFailed: '获取验证码失败',
    verifySuccessfully: '验证成功',
    verifyFailed: '验证失败',
    resetPasswordTitle: '重置密码'
  },
  resetPassword: {
    email: '邮箱',
    verifyCode: '验证码',
    password: '新密码',
    checkPassword: '确认密码',
    sendVerifyCode: '发送',
    enterEmail: '请输入账号绑定的邮箱地址',
    enterVerifyCode: '请输入验证码',
    enterPassword: '请填写新密码',
    enterCheckPassword: '请确认新密码',
    emailEmpty: '请填写邮箱',
    emailError: '邮箱地址不合法',
    verifyCodeEmpty: '请填写验证码',
    passwordEmpty: '请填写密码',
    passwordError: '密码格式不符合要求',
    checkPasswordEmpty: '请再次输入密码',
    passwordNotEqual: '两次输入密码不一致',
    resetSuccess: '重置成功',
    passwordRules: '密码规则',
    passwordIntegrant: '密码由英文、数字、特殊字符组成',
    passwordLength: '密码由长度为8-20位',
    passwordMustBe: '必须包含大写字母，特殊字符'
  },
  platformPermission: {
    addResource: '新增资源',
    editResource: '修改资源',
    resourceName: '资源名称',
    resourceIcon: '资源图标',
    resourceSort: '排序',
    resourceType: '资源类型',
    resourceCode: '权限标识',
    resourceUrl: '路由地址',
    parentResource: '上级资源',
    selectParentResource: '请选择上级资源',
    selectResourceType: '请选择资源类型',
    selectResourceIcon: '请选择资源图标',
    inputResourceCode: '请输入权限标识',
    inputResourceName: '请输入资源名称',
    inputResourceUrl: '请输入路由地址',
    inputResourceSort: '请输入显示排序，例如1，2，3',
    resourceNameEmpty: '资源名称不允许为空',
    resourceNameSpace: '资源名称不允许出现空格',
    resourceCodeEmpty: '权限标识不允许为空',
    resourceUrlEmpty: '路由地址不允许为空',
    resourceSortEmpty: '显示排序不允许为空',
    permissionConfig: '权限配置'
  },
  supplierPermission: {
    addResource: '新增资源',
    editResource: '修改资源',
    resourceName: '资源名称',
    resourceIcon: '资源图标',
    resourceSort: '排序',
    resourceType: '资源类型',
    resourceCode: '权限标识',
    resourceUrl: '路由地址',
    parentResource: '上级资源',
    selectParentResource: '请选择上级资源',
    selectResourceType: '请选择资源类型',
    selectResourceIcon: '请选择资源图标',
    inputResourceCode: '请输入权限标识',
    inputResourceName: '请输入资源名称',
    inputResourceUrl: '请输入路由地址',
    inputResourceSort: '请输入显示排序，例如1，2，3',
    resourceNameEmpty: '资源名称不允许为空',
    resourceNameSpace: '资源名称不允许出现空格',
    resourceCodeEmpty: '权限标识不允许为空',
    resourceUrlEmpty: '路由地址不允许为空',
    resourceSortEmpty: '显示排序不允许为空',
    permissionConfig: '权限配置'
  },
  department: {
    departmentName: '部门名称',
    sort: '排序',
    inputSort: '请输入显示排序，例如1，2，3',
    sortEmpty: '显示排序不允许为空',
    parentDepartment: '上级部门',
    selectParentDepartment: '请选择上级部门',
    inputDepartmentName: '请输入部门名称',
    nameEmpty: '部门名称不能为空',
    nameSpace: '部门名称不能包含空格',
    addDepartment: '新增部门',
    editDepartment: '修改部门',
    department: '部门',
    selectDepartment: '请选择部门',
    assign: '要分配的部门'
  },
  role: {
    addRole: '添加角色',
    editRole: '修改角色',
    name: '角色名称',
    code: '角色编号',
    inputName: '请输入角色名称',
    inputRemark: '请输入备注信息',
    nameEmpty: '角色名称不能为空',
    permissionAllot: '权限分配',
    selectPermission: '请选择角色可以拥有的权限',
    role: '角色',
    selectRole: '请选择角色'
  },
  position: {
    addPosition: '添加岗位',
    editPosition: '修改岗位',
    parentName: '上级岗位',
    name: '岗位名称',
    code: '岗位编号',
    affiliationDepartment: '所属部门',
    selectAffiliationDepartment: '请选择所属部门',
    inputName: '请填写岗位名称',
    selectParentPosition: '请选择上级岗位',
    position: '岗位',
    selectPosition: '请选择岗位',
    topLevel: '无上级岗位'
  },
  personnel: {
    addPersonnel: '添加人员',
    editPersonnel: '修改人员',
    userName: '用户名',
    realName: '真实姓名',
    code: '员工编号',
    positonName: '岗位',
    email: '邮箱地址',
    mobile: '手机号码',
    inputUserName: '请填写登录账号',
    inputRealName: '请填写真实姓名',
    inputEmail: '请填写邮箱',
    inputMobile: '请填写手机号',
    selectPosition: '请选择岗位',
    pisition: '岗位(可多选)',
    gender: '性别',
    initPassword: '用户初始密码为：123456'
  },
  classify: {
    code: '分类编号',
    name: '分类名称',
    packageType: '打包方式',
    addClassify: '添加分类',
    editClassify: '修改分类',
    tiled: '平装',
    reel: '卷装',
    fold: '折装',
    inputName: '请填写分类名称',
    parentName: '上级分类',
    selectParent: '选择上级'
  },
  deviceClassify: {
    addDeviceClassify: '新增设备分类',
    editDeviceClassify: '修改设备分类'
  },
  product: {
    addProduct: '新增产品',
    editProduct: '修改产品',
    productClassify: '产品分类',
    deviceClassify: '设备分类',
    code: '产品编号',
    name: '产品名称',
    selectClassify: '请选择产品分类',
    selectDeviceClassify: '请选择设备分类',
    inputName: '请填写产品名称',
    calcUnit: '计价单位',
    selectCalcUnit: '选择计价单位',
    printingCraft: '输出工艺',
    printingMaterial: '输出材料',
    backendCraft: '后道工艺',
    backendMaterial: '后道材料',
    remark: '备注信息',
    operation: '操作',
    selectBackendCraft: '选择后道工艺',
    selectBackendMaterial: '选择后道材料',
    selectPrintingCraft: '选择输出工艺',
    selectPrintingMaterial: '选择输出材料',
    productTemplateName: '产品数据导入模板.xlsx',
    inputLegalName: '请输入合法的产品名称',
    nameRules: '当前名称只允许中文、英文、数字、下划线、反斜杠，并且不能以数字开头'
  },
  craft: {
    name: '工艺名称',
    code: '工艺编号',
    inputName: '请输入工艺名称',
    editCraft: '修改工艺',
    addCraft: '新增工艺'
  },
  user: {
    editPassword: '修改密码'
  },
  customer: {
    name: '企业名称',
    companyName: '企业简称',
    fullName: '企业名称',
    domainName: '企业域名',
    classify: '客户分类',
    address: '详细地址',
    servant: '客户经理',
    area: '所在地区',
    paymentDay: '回款账期（天）',
    addCustomer: '新增客户',
    editCustomer: '编辑客户',
    selectArea: '请选择所在地区',
    selectServant: '请选择客户经理',
    selectClassify: '请选择客户分类',
    inputDomainName: '请填写企业域名',
    inputName: '请填写企业简称',
    inputFullName: '请填写企业名称',
    selectedCustomers: '已选择的客户',
    assign: '分配客户',
    customerName: '客户名称',
    allocatePersonnel: '分配人员',
    allocateTime: '分配时间',
    cancelAssignment: '取消分配'
  },
  customerTransfer: {
    describe: '当成员或客户有变更时，企业可以将指定成员的指定客户数据分配给其他成员继续提供服务',
    source: '需要转接业务的客户',
    target: '将客户业务转接给',
    transferConfirm: '确认转接',
    selectCustomer: '请选择客户',
    selectUser: '请选择接替的成员',
    confirmTip: '确定要进行客户转接吗？'
  },
  invoice: {
    companyName: '企业名称',
    taxCode: '税号',
    taxRate: '税率(%)',
    invoiceType: '发票类型',
    bankName: '开户行',
    bankAccount: '对公账户',
    address: '开票地址',
    contactNumber: '联系电话',
    addInvoice: '新增开票信息',
    editInvoice: '修改开票信息',
    inputCompanyName: '请填写企业名称',
    inputTaxCode: '请填写税号',
    inputTaxRate: '请填写税率',
    selectInvoiceType: '请选择发票类型',
    inputBankName: '请填写开户银行',
    inputBankAccount: '请填写对公账号',
    inputAddress: '请填写开票地址',
    inputContactNumber: '请填写联系电话',
    taxRateValid: '税率不合法',
    taxRateManagement: '税率管理'
  },
  taxRate: {
    taxClassifyRepeat: '该税收分类名称已存在',
    inputClassify: '请填写税收分类名称',
    taxClassifyName: '税收分类',
    addTaxRate: '新增税收分类',
    editTaxRate: '修改税收分类',
    taxClassify: '税收分类'
  },
  customerProduct: {
    platformProduct: '制作品名',
    selectPlatformProduct: '请选择制作产品',
    customerProduct: '客户产品',
    productUnitPrice: '产品单价',
    addCustomerProduct: '新增客户产品',
    editCustomerProduct: '编辑客户产品',
    inputSellPrice: '请填写销售单价',
    inputWeight: '请填写克重',
    weight: '克重',
    sellPrice: '销售单价',
    chargeUnit: '计价单位',
    taxRateFlag: '是否含税',
    productTemplateName: '客户产品导入模板.xlsx'
  },
  contacts: {
    realName: '联系人姓名',
    telephone: '联系电话',
    inputRealName: '请填写联系人姓名',
    inputTelephone: '请填写联系电话',
    addContact: '新增联系人',
    editContact: '编辑联系人',
    telephoneFormatError: '联系电话由8-20个字符组成，仅支持数字'
  },
  tag: {
    name: '标签名称',
    tags: '标签内容',
    inputName: '请填写标签名称',
    tag: '标签',
    inputTag: '请添加标签',
    repeat: '标签重复'
  },
  shopTag: {
    addShopTag: '新增门店标签',
    editShopTag: '编辑门店标签'
  },
  pointTag: {
    addPointTag: '新增点位标签',
    editPointTag: '编辑点位标签'
  },
  customerShop: {
    info: '门店信息',
    code: '门店编号',
    customerCode: '自定义编号',
    inputCode: '请填写门店编号',
    name: '门店名称',
    inputName: '请填写门店名称',
    address: '门店地址',
    receiver: '收货人',
    receiverTel: '收货电话',
    inputReceiverTel: '请填写收货电话',
    shopContact: '联系方式',
    receiverInfo: '收货人信息',
    inputReceiverInfo: '请填写收货人姓名',
    shopOwnerName: '店长姓名',
    inputOwnerName: '请填写店长姓名',
    shopTel: '门店电话',
    inputShopTel: '请填写门店电话',
    installCharge: '安装费用',
    inputInstallCharge: '请填写安装费用',
    installChargeMode: '安装计费方式',
    addShop: '新增门店',
    editShop: '编辑门店',
    accordingToShop: '按门店',
    accordingToArea: '按面积（平方米）',
    shopUnit: '每店',
    areaUnit: '每平方米',
    exportFileName: '客户门店信息.xlsx',
    contact: '门店联系人',
    inputContact: '请填写门店联系人'
  },
  customerPoint: {
    shopName: '所属门店',
    name: '点位名称',
    code: '点位编号',
    visibleSize: '可见尺寸(mm)',
    finishSize: '完成尺寸(mm)',
    product: '绑定产品',
    floor: '所在楼层',
    selectShop: '请选择所属门店',
    visibleWidth: '可见宽度（mm）',
    visibleHeight: '可见高度（mm）',
    finishWidth: '完成宽度（mm）',
    finishHeight: '完成高度（mm）',
    inputName: '请填写点位名称',
    inputFloor: '请填写所在楼层',
    selectCustomerProduct: '请选择客户产品',
    inputVisibleWidth: '请填写可见宽度',
    inputVisibleHeight: '请填写可见高度',
    inputFinishWidth: '请填写完成宽度',
    inputFinishHeight: '请填写完成高度',
    addPoint: '新增点位',
    editPoint: '编辑点位',
    exportFileName: '客户点位信息.xlsx',
    areaUnit: '每平方米',
    noAbnormalData: '无异常数据',
    dataCheck: '数据校验'
  },
  supplier: {
    level: '供应商等级',
    selectLevel: '请选择等级',
    selectCategory: '请选择分类',
    companyName: '企业简称',
    fullName: '企业名称',
    domainName: '域名',
    category: '企业分类',
    address: '地址',
    servant: '供应商经理',
    paymentDay: '结算账期(天)',
    remark: '备注',
    inputName: '请填写企业简称',
    inputFullName: '请填写企业名称',
    addSupplier: '新增供应商',
    editSupplier: '编辑供应商',
    inputDomainName: '请填写域名',
    area: '所在地区',
    selectArea: '请选择所在地区',
    inputPaymentDay: '请填写结算账期',
    inputRemark: '请填写备注信息',
    inputAddress: '请填写详细地址',
    detailAddress: '详细地址',
    accountName: '管理员账号',
    defaultPassword: '默认密码',
    inputServant: '请填写供应商经理',
    currentLevel: '当前等级',
    modifyLevel: '变更等级',
    templateName: '供应商数据导入模板.xlsx'
  },
  supplierLevel: {
    serial: '等级序列',
    name: '等级名称',
    explain: '等级说明',
    companyCount: '企业数量',
    inputName: '请填写等级名称',
    selectSerial: '请选择等级序列',
    inputExplain: '请填写等级说明',
    permissionAllot: '权限分配',
    addSupplierLevel: '新增供应商级别',
    editSupplierLevel: '编辑供应商级别'
  },
  supplierMaterial: {
    code: '材料编号',
    name: '材料名称',
    category: '材料分类',
    brand: '品牌',
    size: '尺寸(宽x长)',
    unit: '计量单位',
    displayOrder: '显示顺序',
    import: '导入材料',
    selectCategory: '请选择材料分类',
    inputName: '请填写材料名称',
    inputCode: '请填写材料编号',
    inputBrand: '请填写品牌',
    selectUnit: '请选择计量单位',
    inputWidth: '请填写宽度',
    inputLength: '请填写长度',
    inputDisplayOrder: '请填写显示顺序',
    inputRemark: '请填写备注',
    addMaterial: '新增材料',
    editMaterial: '编辑材料',
    importMaterial: '导入材料',
    exportMaterial: '导出材料',
    exportPrintingMaterial: '导出输出材料',
    exportBackendMaterial: '导出后道材料',
    printingTemplateName: '输出材料导入模板.xlsx',
    backendTemplateName: '后道材料导入模板.xlsx'
  },
  materialUnit: {
    meter: '米',
    centimetre: '厘米',
    millimetre: '毫米'
  },
  materialType: {
    printing: '输出',
    backend: '后道'
  },
  supplierDevice: {
    deviceName: '设备名称',
    categoryName: '设备类型',
    printHead: '喷头信息',
    colorInfo: '颜色信息',
    category: '类型',
    selectCategory: '请选择设备类型',
    importDevice: '导入设备',
    exportDevice: '导出设备',
    deviceTemplate: '设备导入模板.xlsx',
    inputDeviceName: '请填写设备名称',
    inputPrintHead: '请填写喷头信息',
    inputColorInfo: '请填写颜色信息',
    inputRemark: '请填写备注',
    addDevice: '新增设备',
    editDevice: '修改设备'
  },
  supplierProduct: {
    templateName: '供应商产品导入模板.xlsx',
    import: '导入产品',
    productCode: '产品编号',
    categoryName: '产品分类',
    platformProductName: '平台产品',
    productName: '产品名称',
    price: '采购价格',
    priceUnit: '计价单位',
    weight: '克重',
    addProduct: '新增产品',
    editProduct: '修改产品',
    selectCategoryName: '请选择产品分类',
    selectPlatformProduct: '请选择平台产品',
    inputPrice: '请填写产品单价',
    selectPriceUnit: '请选择计价单位',
    inputWeight: '请填写产品克重',
    selectPrintingCraft: '请选择输出工艺',
    selectPrintingMaterial: '请选择输出材料',
    selectBackendCraft: '请选择后道工艺',
    selectBackendMaterial: '请选择后道材料',
    printingCraft: '输出工艺',
    printingMaterial: '输出材料',
    backendCraft: '后道工艺',
    backendMaterial: '后道材料',
    inputRemark: '请填写备注'
  },
  chargeUnit: {
    squareMeter: '平方米',
    piece: '张',
    cover: '套',
    individual: '个'
  },
  order: {
    orderStatus: '订单状态',
    customer: '所属客户',
    createDate: '下单日期',
    selectStatus: '请选择状态(可多选)',
    selectCustomer: '请选择客户',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    code: '订单编号',
    area: '生产面积(m2)',
    projectName: '项目名称',
    customerName: '所属客户',
    supplierName: '供应商',
    progress: '生产进度',
    createUserName: '下单人',
    createTime: '下单时间',
    batchDistribute: '批量派单',
    new: '新建',
    waitingAccept: '待接单',
    waitingPlan: '待排产',
    processing: '生产中',
    waitingConfirm: '待确认',
    completed: '已完成',
    confirmDistribute: '确认派发选中订单?',
    distributeSuccess: '派单成功',
    distributeCancel: '派单取消',
    selectNewOrderTip: '请勾选【新建】状态的单据',
    selectWaitingAccpetOrderTip: '请勾选【待接单】状态的单据',
    confirmCancel: '确认取消选中订单?',
    cancelSuccess: '订单取消成功',
    confirmFinish: '确认完成该订单？',
    orderCompleted: '订单已完成',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    packRequire: '打包要求',
    remark: '备注信息',
    orderAmount: '订单金额',
    productList: '产品清单',
    shipmentList: '物流跟踪',
    goOrderList: '去订单列表',
    createAgain: '再次创建',
    createSuccessText: '订单创建成功',
    editSuccessText: '订单编辑成功',
    selectSupplier: '选择供应商',
    pleaseUploadFile: '请上传文件',
    inputRemark: '请填写备注信息',
    type: '订单类型',
    online: '线上订单',
    offline: '线下订单',
    orderDetail: '订单明细',
    productionFile: '制作文件',
    allProduction: '全部生产',
    selectProduct: '选择产品',
    relatedProduct: '关联产品',
    inputBase: '填写基本信息',
    distributionProduct: '分配生产内容',
    createSuccess: '创建完成',
    pleaseDistributeProduct: '请分配订单内容',
    pleaseSelectSupplierProduct: "项次'{0}'未匹配供应商产品",
    selectSupplierProduct: '请选择供应商产品',
    selectCustomProduct: '请选择客户产品',
    createOrderChange: '新建变更单',
    createShippingNotice: '新建发货单',
    refused: '被拒绝',
    confirmComplete: '请确认是否要完成选中订单',
    exportDetail: '导出订单明细',
    urgentOrder: '是否急单',
    production: '是否生产',
    logisticsFlag: '是否出货',
    installFlag: '是否安装',
    deliveryStatus: '出货状态'
  },
  orderProduct: {
    itemCode: '项次编号',
    itemType: '项次类型',
    status: '项次状态',
    pointName: '点位名称',
    platformProductName: '制作品名',
    customProductName: '客户产品',
    supplierProductName: '供应商产品',
    count: '产品数量',
    totalArea: '生产面积(m2)',
    visibleWidth: '可见宽度(mm)',
    visibleHeight: '可见高度(mm)',
    completeWidth: '完成宽度(mm)',
    completeHeight: '完成高度(mm)',
    prepressPicture: '印前画面',
    prepressDescription: '印前排版说明',
    remark: '备注信息',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    currentProcess: '当前工序',
    finishTime: '完成时间',
    brokenLog: '报损记录',
    prepress: '印前',
    printing: '输出',
    backend: '后道',
    noProductionNeed: '无需制作',
    salePrice: '销售单价',
    purchasePrice: '采购单价',
    chargeUnit: '计价单位',
    visibleSize: '可见尺寸(mm)',
    completeSize: '完成尺寸(mm)',
    detailVisibleSize: '可见尺寸',
    detailCompleteSize: '完成尺寸',
    productArea: '生产面积',
    detailTitle: '产品详情',
    baseInfo: '基本信息',
    progress: '制作进度',
    backendCrafts: '后道工艺',
    shopName: '门店名称',
    prepressFlag: '是否需要印前',
    printingFlag: '是否需要输出',
    backendFlag: '是否需要后道',
    inputKeyword: '编号/客户产品/制作品名',
    prepressCrafts: '印前工艺',
    prepressConfirmFile: '画面预览',
    prepressCompleteFile: '生产文件',
    clickToPreviewPDF: '查看PDF',
    availableCount: '可用数量',
    maxDistributeCountTip: '最大可分配数量为',
    areaPriceFlag: '面积计价'
  },
  /**
   * 工序状态
   */
  processStatus: {
    new: '未制作',
    waiting: '待制作',
    processing: '制作中',
    completed: '已完成',
    closed: '不制作'
  },
  /**
   * 订单项次产品状态
   */
  orderProductStatus: {
    allStatus: '全部',
    waitPlan: '待排产',
    new: '待制作',
    processing: '制作中',
    completed: '已完成',
    canceled: '已取消'
  },
  orderProductType: {
    product: '产成品',
    time: '工时服务'
  },
  processAction: {
    take: '接单',
    finish: '完成'
  },
  brokenLog: {
    brokenType: '耗损类型',
    brokenProcess: '耗损工序',
    count: '耗损数量',
    priceUnit: '计量单位',
    area: '面积(m²)',
    reason: '耗损原因',
    dutyUser: '责任人',
    remark: '备注说明',
    completeSize: '完成尺寸(mm)',
    createUserName: '上报人',
    createTime: '上报时间',
    orderChangeLogCode: '关联变更单'
  },
  brokenType: {
    platform: '平台耗损',
    inner: '内部耗损'
  },
  brokenReason: {
    requirementChange: '需求变更',
    pictureError: '画面错误',
    materialError: '材料错误',
    processError: '工艺错误',
    shipmentBroken: '物流耗损',
    other: '其他'
  },
  orderLogisticsOrder: {
    code: '发货单号',
    shippingType: '出货方式',
    shippingCompany: '物流公司',
    shippingCode: '物流单号',
    status: '物流状态',
    receiveAddress: '收货地址',
    receiver: '收货人',
    contact: '联系电话',
    receiveTime: '收货时间',
    sendAddress: '发货地址',
    sender: '发货人',
    sendTime: '发货时间',
    receiveInfo: '收货信息',
    sendInfo: '发货信息',
    logisticsInfo: '物流信息',
    count: '产品数量',
    remark: '备注信息',
    parentOrder: '母单号',
    linkToDetails: '查看详情'
  },
  shippingType: {
    express: '快递',
    selfPick: '自提',
    carDelivery: '专车'
  },
  logisticsOrderStatus: {
    new: '待发货',
    receivedGoods: '待揽收',
    inTransit: '在途中',
    error: '异常',
    received: '已签收',
    closed: '已关闭'
  },
  logisticsStatus: {
    new: '待发货',
    toBeCollect: '揽收',
    inTransit: '在途',
    dispatch: '派件',
    received: '签收',
    back: '退回',
    signBack: '退签',
    conversion: '转投',
    diffcult: '疑难',
    customsClearance: '清关',
    refusalToSign: '拒签'
  },
  orderChange: {
    create: '新建',
    edit: '编辑',
    changeDate: '变更日期',
    changeTime: '变更时间',
    changeMan: '变更人',
    code: '单据编号',
    orderCode: '关联订单',
    projectName: '关联项目',
    customerName: '关联客户',
    supplierName: '关联供应商',
    status: '单据状态',
    changeReason: '变更原因',
    selectNewOrFailed: "请选择状态为'新建'的单据",
    selectPendingApproval: '请勾选【待审核】状态的单据',
    submitApprovalSucces: '已提交审批',
    submitApproval: '提交审批',
    selectStatus: '选择状态',
    confirmCancel: '确认取消选中单据?',
    approval: '审批',
    approvalDescription: '审批说明',
    inputApprovalDescription: '请填写审批说明',
    approverName: '审批人',
    approvalTime: '审批时间',
    changeDetail: '变更明细',
    inputChangeReason: '请填写变更原因',
    save: '保存',
    saveAndSubmit: '保存并提交',
    add: '新增一行',
    addProduct: '新增产品',
    editProduct: '修改产品',
    selectProduct: '选择产品',
    noDataSubmitWarning: '请填写变更明细',
    supplierProductInputTip: '请选择供应商产品',
    productCountInputTip: '请填写数量',
    requiredDeliveryTimeInputTip: '请选择要求出货时间',
    requiredArriveTimeInputTip: '请选择要求到货时间',
    timeErrorTip: '要求到货时间不能早于要求出货时间',
    submitTip: '确认【推送】选中记录？',
    hasSameProduct: '该产品已添加到列表中',
    baseContent: '基本信息',
    rejectReason: '驳回原因'
  },
  orderChangeStatus: {
    new: '新建',
    pendingApproval: '审核中',
    approvalFailed: '审核未通过',
    pendingConfirm: '待审核',
    reject: '已驳回',
    finished: '已通过'
  },
  changeType: {
    edit: '修改信息',
    minusCount: '减少数量',
    stop: '停止生产',
    add: '增加产品',
    addCount: '增加数量'
  },
  orderChangeDetail: {
    changeType: '变更类型',
    syncFlag: '是否同步数据'
  },
  project: {
    addProject: '创建项目',
    editProject: '编辑项目',
    code: '项目编号',
    name: '项目名称',
    customer: '所属客户',
    status: '项目状态',
    progress: '项目进度',
    cycle: '项目周期',
    settleCurrency: '结算币种',
    budget: '预算金额',
    quotation: '项目金额',
    creator: '创建人员',
    createTime: '下单时间',
    orderFile: '下单文件',
    inputRemark: '请填写备注信息',
    inputBudget: '请填写预算金额',
    inputName: '请填写项目名称',
    selectCustomer: '请填写项目所属客户',
    selectCycle: '请选择项目计划周期',
    selectSettleCurrency: '请选择结算币种',
    selectStatus: '请选择项目状态（支持多选）',
    orderCreator: '下单人员',
    selectCreator: '请填写下单人姓名',
    orderCreateTime: '下单日期',
    delete: '删除项目',
    export: '导出项目',
    exportDetails: '导出项目明细',
    exportFinanceDetails: '导出财务明细',
    exportFileName: '项目数据',
    exportDetailFileName: '项目明细数据',
    exportFinanceDetailFileName: '财务明细数据',
    shopDetails: '门店明细',
    productDetails: '制作明细',
    deliveryDetails: '交货明细',
    addItem: '新增项次',
    selectProjectProduct: '选择项次',
    editItem: '编辑项次',
    revision: '改稿',
    deliveryDetailsTitle: '门店 & 项次数量明细',
    addDeliveryNotice: '新建发货通知单',
    operationNoStandard: '当前选中的项目状态均不符合要求，请选择状态为新建的项目！',
    addInstallationNotice: '新建安装通知单',
    overdue: '项目已逾期！',
    overdueWarning: '项目即将逾期！',
    selectedProjects: '已选择的项目',
    assign: '分配项目',
    accepting: '验收',
    confirmAccepting: '确定要验收该项目吗？',
    acceptanceSuccessful: '验收成功',
    cancelComplete: '反完成',
    complete: '完成',
    confirmCancelCompleteTip: '确定要反完成该项目吗？',
    confirmCompleteTip: '确定要完成该项目吗？',
    unDistributeTotal: '未派发生产',
    unPrePressTotal: '未派发印前',
    itemTotal: '项次总数',
    inPrepressTotal: '印前制作中',
    toBeConfirmPrepress: '印前待确稿',
    inProduction: '生产中项次',
    deliveryNotice: '关联发货通知单',
    installNotice: '关联安装通知单',
    cannotAddProjectTip:
      '该客户下存在满足完成条件但未完成的项目，请先完成这些项目后再选择新建！点击确定将自动为您检索出这部分项目。',
    overdueWaitComplete: '逾期待完成',
    invoiceStatus: '结算状态',
    installStatus: '安装状态',
    unfinished: '未完成',
    finished: '完成',
    progressFlag: '项目进度',
    isOverdue: '逾期状态',
    pleaseSelectCustomer: '请先选择所属客户',
    // TODO
    batchRevision: '批量改稿',
    batchRevisionItem: '批量改稿',
    finishedProductTransferLayout: '成品转排版',
    finishedProductTransferLayoutTip: '确定要将成品改为排版？',
    copyAnotherItem: '调打'
  },
  isOverdue: {
    theOverdue: '即将逾期',
    HasBeenOverdue: '已逾期',
    BeyondOverdue: '逾期超14天'
  },
  currencyTypes: {
    CNY: '人民币',
    USD: '美元',
    HKD: '港币'
  },
  projectStatus: {
    new: '新建',
    underway: '进行中',
    waitingForAcceptance: '待验收',
    completed: '已完成'
  },
  prepress: {
    code: '单据编号',
    itemCode: '项次编号',
    itemType: '项次类型',
    visibleSize: '可见尺寸(mm)',
    finishSize: '完成尺寸(mm)',
    platformProduct: '制作品名',
    customerProduct: '客户产品',
    salePrice: '销售单价',
    priceUnit: '计价单位',
    backendCrafts: '后道工艺',
    requiredDeliveryTime: '要求出货时间',
    prepressPicture: '印前画面',
    prepressRemark: '印前说明',
    remark: '备注',
    status: '印前状态',
    makeUserName: '接单人',
    makeProgress: '制作进度',
    downloadProductionFile: '下载生产文件',
    confirmManuscript: '确稿',
    revision: '改稿',
    all: '全部',
    changeColor: '改色',
    finalized: '完稿',
    confirmProduction: '提交制作',
    keywords: '支持项次编号、产品名称',
    confirmProductionTip: '确定要提交印前制作吗？(只允许印前状态为新建的项次)',
    confirmManuscriptTip: '确认完成该印前单据(确认后无法修改单据)？',
    cancelTip: '确定要取消该印前单据制作吗？',
    selectCustomerProduct: '请选择客户产品',
    selectDeliveryTime: '请选择要求出货时间',
    selectBackendCraft: '请选择后道工艺',
    width: '宽度',
    height: '高度',
    inputPrepressPicture: '请填写印前画面',
    inputPrepressRemark: '请填写印前排版说明',
    inputVisibleSize: '请填写正确的可见尺寸',
    inputFinishize: '请填写正确的完成尺寸',
    deleteTip: '确定要删除选中的项次吗？(只会删除新建状态的项次)',
    operationNoStandard: '当前选中的项次均不符合要求！',
    operationNoSubmit: '单据不处于待提交状态',
    aotoOperationTips: '当前未勾选到符合要求的项次！点击确认将自动为您提交该项目下所有符合要求的数据，请知悉！',
    details: '单据详情',
    basicInfo: '基本信息',
    prepressProgress: '印前进度',
    changeRecord: '修改记录',
    finishHeight: '完成高度',
    finishWidth: '完成宽度',
    visibleHeight: '可见高度',
    visibleWidth: '可见宽度',
    deliverDetails: '出货明细',
    process: '项次工序',
    modifier: '修改人',
    makeStateTime: '接单时间',
    finishTime: '完成时间',
    productionTime: '制作时间',
    customerProductName: '客户产品',
    manuscriptConfirmFile: '确稿文件',
    productionFile: '生产文件',
    verifyVisibleSize: '可视尺寸不能大于完成尺寸',
    verifyFinfishSize: '完成尺寸必须大于可见尺寸',
    thumbnails: '确稿画面',
    preview: '预览',
    noPreview: '未上传',
    noProductionFiles: '当前选中的印前服务没有上传生产文件',
    modifyCount: '改稿次数',
    submitPrepressTip: '所选项次请先派发生产后再提交印前制作',
    projectName: '项目名称',
    finishedProduct: '成品',
    unopened: '未开启',
    createDate: '创建日期',
    createUserName: '创建人员',
    round: '轮次',
    count: '数量',
    makeCount: '制作数量',
    prepressSubmitTotal: '交稿总数',
    submitConfirmTime: '提交审稿时间',
    archiveTime: '归档时间',
    archiveUser: '归档人',
    topPocketSize: '上口袋尺寸(cm)',
    bottomPocketSize: '下口袋尺寸(cm)'
  },
  prepressStatus: {
    new: '待提交',
    waitForProduction: '待接单',
    inProduction: '制作中',
    waitForConfirm: '待确稿',
    waitComplete: '已确稿',
    completed: '已完成',
    notMake: '不制作'
  },
  prepressSampleStatus: {
    waitSubmit: '待提交',
    waitSampled: '待打样',
    inProduction: '制作中',
    completed: '已完成'
  },
  prepressAction: {
    new: '新建',
    submitProduction: '提交制作',
    cancel: '取消制作',
    takeOrder: '印前接单',
    submitConfirm: '印前提交审稿',
    reddit: '印前改稿',
    AEConfirm: 'AE确稿',
    complete: '已完成',
    submitProofing: '提交打样',
    cancelSubmitProofing: '取消打样',
    proofingComplete: '打样完成',
    AEClose: 'AE关闭',
    cancelConfirm: '印前撤销审稿',
    cancelClose: 'AE反关闭',
    prepressArchive: '印前归档',
    uploadFile: '印前上传图片',
    receivingFinishOrder: '印前成品接单',
    aeCancelMake: 'AE取消制作'
  },
  projectShop: {
    logisticsProgress: '物流进度',
    installProgress: '安装进度',
    createInstallBill: '生成安装单',
    selectShop: '选择门店',
    inputShopName: '请输入门店名称',
    inputShopCode: '请输入门店编号',
    deliveryInfo: '出货明细',
    saleCount: '剩余可分配数量',
    assignCount: '分配数量',
    import: '出货信息导入',
    importTemplate: '出货信息导入模板',
    project: '关联项目',
    customer: '关联客户',
    batchEditCount: '批量修改数量',
    inputCount: '请填写出货数量',
    formatFailed: '填写内容必须为数字',
    exportPop: '导出Pop模式明细',
    exportPoster: '导出海报模式明细',
    importPop: '导入Pop类明细',
    importPoster: '导入海报类明细',
    popFileName: 'Pop类明细导出',
    posterFileName: '海报类明细导出',
    popTemplateName: 'Pop类明细导入模板',
    posterTemplateName: '海报类明细导入模板',
    inputDeliveryRemark: '请填写发货备注',
    selectDeliveryMethod: '请选择发货方式',
    selectPlannedShippingTime: '请选择计划出货时间',
    plannedShippingTime: '计划出货时间'
  },
  // 这里的key尽量不要改动，因为涉及改稿修改记录的国际化
  projectProduct: {
    itemCode: '项次编号',
    customerName: '客户名称',
    productionProgress: '生产进度',
    pointName: '点位名称',
    consumed: '耗损',
    status: '制作状态',
    prepressStatus: '印前状态',
    finishTime: '完成时间',
    consumedCode: '耗损项次',
    copy: '复制',
    templateName: '制作明细导入模板',
    keywords: '支持项次编号、产品名称',
    itemType: '项次类型',
    finishedProduct: '产成品',
    visibleSize: '可见尺寸(mm)',
    finishSize: '完成尺寸(mm)',
    totalArea: '合计面积(㎡)',
    platformProduct: '制作品名',
    customerProduct: '客户产品',
    salePrice: '单价',
    saleUnitName: '销售单位',
    priceUnitName: '计价单位',
    unitArea: '单位面积(㎡)',
    priceCount: '计价数量',
    areaPriceFlag: '面积计价',
    priceIncludingTax: '含税单价',
    taxRate: '税率(%)',
    totalPrice: '未税金额',
    taxAmount: '税额',
    amountIncludingTax: '含税金额',

    backendCrafts: '后道工艺',
    requiredDeliveryTime: '要求出货时间',
    requiredArriveTime: '要求到货时间',
    selectCustomerProduct: '请选择客户产品',
    selectDeliveryTime: '请选择要求出货时间',
    selectBackendCraft: '请选择后道工艺',
    width: '宽度',
    height: '高度',
    inputVisibleSize: '请填写正确的可见尺寸',
    inputFinishize: '请填写正确的完成尺寸',
    inputPointName: '请填写点位名称',
    selectPriceUnit: '请选择计价单位',
    count: '销售数量',
    deliveryNumber: '发货数量',
    inputCount: '请填写销售数量',
    saleCountLessThanUserNumber: '销售数量不能小于已使用数量',
    selectPlatformProduct: '请选择制作品',
    productionArea: '生产面积(㎡)',
    remark: '备注',
    prepressPicture: '印前画面',
    prepressRemark: '印前说明',
    shopName: '门店名称',
    deliverNum: '产品数量',
    printingFlag: '输出工序',
    backendFlag: '后道工序',
    prepressFlag: '生产印前',
    platformPrepressFlag: '印前',
    confirmFlag: '需要确稿',
    inputSalePrice: '请填写销售单价',
    selectArriveTime: '请选择要求到货时间',
    details: '项次详情',
    deliveryNumOver: '现有门店出货数量不能超出总项次数量',
    consumedItem: '项次耗损',
    basicInfo: '基本信息',
    peocessControl: '工序控制',
    import: '项次导入',
    prepressInfo: '印前信息',
    prepressCrafts: '印前工艺',
    selectPrepressCrafts: '请选择印前工艺',
    prepressSubmitCount: '交稿数量',
    logisticsInfo: '出货信息',
    logisticsFlag: '出货',
    installInfo: '安装信息',
    installFlag: '安装',
    flagOpen: '开启',
    flagClose: '关闭',
    notEdit: '不修改',
    invoiceProgress: '结算进度',
    deliveryProgress: '发货进度',
    finishHeight: '完成高度',
    finishWidth: '完成宽度',
    visibleHeight: '可见高度',
    visibleWidth: '可见宽度',
    reeditExplain: '改稿说明',
    deliveryEmpty: '出货数量不允许为0',
    platformProductName: '制作品名',
    batchEdit: '批量修改',
    doneManually: '手动完成',
    doneManuallyTips: '确定要手动完成该项次吗？只支持印前和制作状态都为新建的单据！',
    isConsumeRemake: '是否为耗损重制',
    productionDetails: '制作信息',
    downloadConfirmFile: '下载确稿文件',
    close: '关闭',
    cancelClose: '反关闭',
    closeConfirmTip: '确认要关闭选中项次吗？（只能关闭【关闭状态为正常】没有下游数据的项次）',
    closeTip: '当前选中项次均不符合要求',
    cancelCloseConfirmTip: '确认要反关闭选中项次吗？（只会反关闭【关闭状态为已关闭】且是【手动关闭】的项次）',
    cancelCloseTip: '当前选中项次均不符合要求',
    submitPrepress: '提交印前',
    cancelPrepress: '撤销印前',
    confirmPrepress: '印前确稿',
    shippingDate: '出货日期',
    selectItemType: '请选择项次类型',
    isRemake: '是否重制',
    make: '制作',
    doNotMake: '不制作',
    billStatistics: '单据统计',
    exportFileName: '项次导出数据',
    cannotLessThanCurrentTime: '要求出货/到货时间不能早于当前时间',
    inputCustomerProduct: '请填写客户产品',
    deliveryCount: '发货通知数量(完成/关联)',
    installCount: '安装通知数量(完成/关联)',
    invoiceCount: '结算数量(完成/关联)',
    isOverdue: '是否逾期',
    toBeTimeout: '即将逾期',
    overdue: '已逾期',
    deliveryAndreceiptTime: '出货到货时间',
    notInputTip: '请填写信息后再提交',
    relationCount: '生产&外发数量(完成/关联)',
    relationFinishCount: '关联完成数量',
    deliveryFinishCount: '已发货数量',
    installFinishCount: '已安装数量',
    invoiceFinishCount: '已结算数量',
    prepressSubimitCount: '交稿数量',
    prepressSubmitTotal: '交稿总数',
    closeStatus: '关闭状态',
    closeUser: '关闭人',
    closeTime: '关闭时间',
    manualShutdownFlag: '是否手动关闭',
    archiveStatus: '归档状态',
    confirmationScreen: '确稿画面',
    totalRounds: '总轮次',
    designTypography: '设计排版',
    designTypographyTip: '需要排版制作时开启，该流程需确稿',
    ProductionFile: '生产文件',
    changeItemTypeWarning: '请选择【项次类型=成品】且未关联生产、发货、安装、结算的单据',
    layoutPreview: 'Layout',
    topPocketSize: '上口袋尺寸',
    bottomPocketSize: '下口袋尺寸',
    productionInfo: '生产信息',
    pocketSizeCannotZero: '上下口袋尺寸不能同时为0',
    pocketSizeOverMaxSize: '口袋尺寸最大不能超过999.9cm',
    closedCannotEdit: '已关闭不能改稿',
    relatedProductionCannotEdit: '已关联生产，不允许改稿',
    prepressStatusMismatchEditCondition: '不是待确稿或已制作状态，不允许改稿'
  },
  closeStatus: {
    normal: '正常',
    closed: '已关闭'
  },
  archiveStatus: {
    new: '待提交',
    waitForProduction: '待接单',
    inProduction: '制作中',
    alreadyMade: '已制作',
    notMake: '不制作'
  },
  consumed: {
    consumedInfo: '耗损信息',
    consumedSelectRowTip: '耗损操作最多只能选择一条数据',
    consumedType: '耗损类型',
    selectConsumedType: '请选择耗损类型',
    consumedNum: '耗损数量',
    inputConsumedCount: '请填写耗损数量',
    consumedNumIllegal: '耗损数量不能大于总的销售数量',
    consumedMeasureType: '计量类型',
    selectConsumedMeasureType: '请选择计量类型',
    consumedReason: '责任原因',
    inputReason: '请填写责任原因',
    dutyUser: '责任人',
    inputDutyUser: '请填写责任人',
    dutyOrganize: '责任部门',
    inputDutyOrganize: '请填写责任部门',
    findUser: '发现人',
    inputFindUser: '请填写发现人',
    findOrganize: '发现部门',
    inputFindOrganize: '请填写发现部门',
    brokenArea: '耗损面积(㎡)',
    inputBrokenArea: '请填写耗损面积',
    resetInfo: '重置信息',
    consumeStage: '耗损环节',
    selectConsumeStage: '请选择耗损环节',
    dutyAgencyType: '责任机构类型',
    selectDutyAgencyType: '请选择责任机构类型',
    inputPlatformProduct: '请填写制作品名',
    inputItemCode: '请填写项次编号',
    checkMakeStatusTips: '请确认选中项次制作状态是否符合要求(状态不能是新建或待制作)'
  },
  projectProductStatus: {
    new: '待提交',
    waitForProduction: '待生产',
    inProduction: '生产中',
    waitConfirm: '待确认',
    completed: '已完成',
    consumed: '已耗损'
  },
  prepressTask: {
    artworkComplete: '制作完成',
    exportMineTask: '我的任务',
    exportChangeColorTask: '改色任务',
    exportCompleteManuscriptask: '完稿任务',
    exportChangeColorSample: '改色样管理',
    productionStartTime: '制作开始时间',
    billStatus: '印前状态',
    deliveryTime: '出货时间',
    takeOrder: '接单',
    typesettingReceivingOrders: '排版接单',
    finishProductReceivingOrders: '成品接单',
    redeploy: '转派',
    cancelComplete: '反完成',
    redeployBill: '转派单据',
    assignee: '受让人',
    selectAssignee: '请选择受让人',
    selectBillStatus: '请选择印前状态',
    completeUpload: '上传生产文件',
    confirmUpload: '上传确稿图',
    noFileToUpload: '没有任何文件符合上传条件，请检查文件名是否与项次编号对应',
    fileName: '文件',
    unMatchedItemCode: '与所有选中的项次都不匹配，是否跳过这些文件继续上传？',
    unMatchedWaitCompleteStatus: '当前选中的项次均不符合制作完成要求！请重新选择项次！',
    unMatchedCompleteStatus: '勾选单据中存在不符合条件单据',
    unMatchedInProduction: '只能选择状态为“制作中”的项次',
    unMatchedWaitProduction: '只能选择印前状态为【待制作、制作中】【未关闭】的项次',
    unMatchedRedeployStatus: '未接单和已完成状态的项次不允许转派',
    isNeedUploadFile: '是否需要上传制作完成文件？',
    need: '需要',
    notNeed: '不需要',
    fileRepeat: '已存在对应项次的文件',
    fileAdded: '该文件文件重复添加',
    submitPrintSample: '提交打样',
    addChangeColorSample: '新建改色样',
    batchAddChangeColorSample: '批量新建改色样',
    submitConfirmTip: '确定要提交打样吗?(只支持状态为制作中的改色项次)',
    unMatchedRequire: '请选择符合要求的项次！',
    archiveCompleteTip: '请选择【已确稿】未归档的项次！',
    selectMatchedRequire: '请选择【制作中】【待确稿】【已确稿】的数据',
    submitReview: '提交审稿',
    cancelReview: '撤销审稿',
    cancelOrder: '取消接单',
    submitReviewConfirmTip: '确定要提交审稿吗？(只支持状态为制作中且制作状态为新建或待确认的项次)',
    screenArea: '画面面积(㎡)',
    screenRate: '画面比例',
    screenAreaTotal: '合计面积',
    count: '制作数量',
    proofingStatus: '打样状态',
    all: '全部',
    archiveComplete: '归档',
    uploadAndArchive: '上传并归档',
    // TODO 添加英译
    typesettingCancelTip: '单据不处于【制作中】',
    typesettingCancelTip2: '仅首轮单据允许取消接单',
    finishProductCancelTip: '单据不处于【制作中】',
    finishProductReceivingTip: '单据不处于待接单状态',
    previewLayout: 'layout预览'
  },
  addDeliveryNotice: {
    code: '单据编号: ',
    selectSupplier: '请选择供应商',
    relatedProjects: '关联项目: ',
    relatedCustomer: '关联客户: ',
    shopDetails: '门店明细',
    productDetails: '产品明细',
    customSite: '自定义地点',
    addSite: '添加地点',
    batchEdit: '批量修改',
    importDeliveryDetails: '导入发货明细',
    selectProduct: '选择产品',
    deliverySite: '交货地点',
    deliveryAddress: '交货地址',
    receiver: '收货人',
    receiverTel: '收货电话',
    supplier: '供应商: ',
    deliveryRemark: '发货说明: ',
    remark: '备注信息: ',
    inputReceiver: '请填写收货人',
    inputReceiverTel: '请填写收货人电话',
    area: '所在地区',
    selectArea: '请选择所在地区',
    address: '详细地址',
    inputAddress: '请填写详细地址',
    addDeliverySite: '新增交货地点',
    editDeliverySite: '编辑交货地点',
    shopName: '交货地点',
    inputShopName: '请填写交货地点',
    sendCount: '本次发货数量',
    selectProductItem: '选择项次',
    productClassify: '产品分类',
    platformProduct: '平台产品',
    customerProduct: '客户产品',
    projectProductKey: '支持项次编号、点位名称',
    addDeliveryNotice: '新建发货通知单',
    editDeliveryNotice: '编辑发货通知单',
    remainDeliveryCount: '发货数量已超出项次内产品总数，该项次剩余可发货数量为',
    remainCount: '剩余可发货数量',
    templateName: '交货明细导入模板',
    importTitle: '导入交货明细',
    verifyCountFailed: '产品明细的发货数量不能小于1，请检查门店',
    verifyEmptyFailed: '未发现产品明细，若不需要针对该门店发货，请请删除该门店',
    isInitDeliveryDataForProject: '是否从项目中自动导入交货明细数据？',
    pleaseAddstore: '请添加门店明细',
    batchSetAnnex: '批量设置附件',
    batchSetShopItem: '批量设置'
  },
  deliveryNoticeStatus: {
    new: '待提交',
    waitTakeOrder: '待接单',
    waitDelivery: '待发货',
    partDelivery: '部分发货',
    delivered: '已发货',
    complete: '已完成'
  },
  deliveryNoticeList: {
    code: '单据编号',
    status: '单据状态',
    customer: '所属客户',
    orderCreateTime: '下单日期',
    supplier: '供应商',
    project: '关联项目',
    deliveryRemark: '发货说明',
    selectCustomer: '请选择客户',
    selectStatus: '请选择单据状态',
    selectOrderCreateTime: '请选择下单时间',
    batchPush: '批量推送',
    batchCancel: '批量取消',
    inputCode: '请填写单据编号',
    confirmPush: '确定要推送选中的发货通知单吗？（只支持待提交状态的单据）',
    confirmCancel: '确定要取消选中的发货通知单吗？（只支持待接单状态的单据）',
    operationNoStandard: '当前选中的通知单状态均不符合要求，请选择状态为【待提交】 的发货通知单！',
    onlyWaitTakeOrder: '当前选中的通知单状态均不符合要求，请选择状态为 ”待接单“ 的发货通知单！',
    shopTotal: '门店数量',
    itemTotal: '产品数量',
    exportMaxTip: '导出POP明细时最多只能选择一条发货通知单',
    popFileName: 'POP模式明细导出',
    posterFileName: '海报模式明细导出'
  },
  installationNotice: {
    code: '单据编号',
    projectName: '关联项目',
    customerName: '关联客户',
    status: '单据状态',
    description: '安装说明',
    createUserName: '下单人员',
    createTime: '下单时间',
    customer: '客户',
    inputCreateUserName: '请填写下单人员姓名',
    createDate: '下单日期',
    batchSubmit: '提交',
    batchAudit: '审核',
    batchFinish: '完成',
    cancelComplete: '反完成',
    selectNewReceiptsTip: '请选择【新建】状态单据',
    confirmPush: '确认推送选中单据？',
    selectWaitingAcceptOrderTip: '请选择【待接单】单据',
    confirmCancel: '确认取消选中单据？',
    confirmTake: '确认审核选择中单据？',
    selectWaitingInstallTip: '请选择【待分配】单据',
    confirmFinishInstall: '确认完成选中单据？',
    confirmCancelFinishInstall: '确认反完成选中单据？',
    selectCompleted: '请选择【已完成】单据',
    fileName: '文件名称',
    fileExtension: '文件格式',
    fileSize: '文件大小(KB)',
    filePath: '文件链接',
    batchReject: '反审核',
    confirmReject: '确认反审核选中单据？',
    selectWaitingAuditTip: '请选择【待审核】单据',
    selectPartialInstallTip: '请选择【待分配】【部分分配】单据',
    editSummary: '修改单据摘要',
    orderSummary: '单据摘要',
    inputAbstractContent: '请填写单据摘要信息',
    selectSingleData: '请选择1条数据',
    orderTotal: '关联安装单',
    showRemark: '单据备注',
    setReconciled: '设为已对账',
    setUnreconciled: '设为未对账',
    reconciled: '已对账',
    unreconciled: '未对账',
    billStatus: '对账状态',

    popImport: 'POP导入',
    posterImport: '海报导入',
    installationAttachment: '安装附件',
    installationNoticeFile: '安装通知明细文件'
  },
  installationNoticeStatus: {
    new: '待提交',
    waitingTake: '待审核',
    waitingInstall: '待分配',
    partialInstall: '部分分配',
    completed: '已完成'
  },
  installationOrder: {
    add: '新建安装订单',
    edit: '编辑安装订单',
    status: '单据状态',
    customer: '客户',
    createUserName: '下单人',
    createDate: '下单日期',
    batchFinish: '完成',
    downloadZip: '下载完工照',
    batchEditPrice: '修改费用',
    batchCancel: '取消订单',
    batchDelete: '删除',
    code: '单据编号',
    installNoticeCode: '关联通知单',
    shopName: '关联门店',
    projectName: '关联项目',
    customerName: '关联客户',
    requireOnSiteTime: '要求见刊时间',
    address: '详细地址',
    contactsPerson: '联系人',
    contactsPhone: '联系电话',
    qrCode: '二维码',
    installPrice: '安装报价',
    approveStatus: '审核状态',
    installTime: '安装时间',
    completeTime: '完成时间',
    selectNewReceiptsTip: '请选择【新建】单据',
    selectWaitingAcceptOrderTip: '请选择【待安装】单据',
    confirmCancel: '确认取消选中单据？',
    selectWaitingInstallTip: '请选择【待接单】或【待预约】或【服务中】单据',
    confirmFinishInstall: '确认完成选中单据？',

    // new: '新建',
    // waitingInstall: '待安装',
    // installing: '安装中',
    // approving: '待审核',

    toBePushed: '待推送',
    PendingOrders: '待接单',
    pendingAppointment: '待预约',
    inService: '服务中',
    acceptancePending: '待验收',
    completed: '已完成',

    batchCreate: '新建安装单',
    createSuccess: '安装单创建成功，请至安装模块查看单据',
    attachment: '安装附件',
    installedPhoto: '完工照片',
    limitCount: '最大上传数量:',
    selectOrder: '请选择单据',
    auditDescription: '审批说明',
    inputAuditDescription: '请填写审批说明',
    requireInstallTime: '要求见刊时间',
    contactPerson: '联系人员',
    contactPhone: '联系电话',
    quotedPrice: '安装报价',
    area: '门店地区',
    selectRequireInstallTime: '请选择时间',
    inputContactPerson: '请输入联系人',
    inputContactPhone: '请输入联系电话',
    inputQuotedPrice: '请输入报价',
    selectArea: '请选择地区',
    inputAddress: '请填写详细地址',
    btnBatchSetPrice: '批量改价',
    batchPush: '推送订单',
    selectNewOrderTip: '请选择待推送单据',
    confirmPushOrder: '确认推送选中单据?',
    clickToPreview: '点击查看',
    remark: '备注信息',
    inputRemark: '请填写备注',
    installEmployee: '负责人员',
    serviceStaff: '服务人员',
    selectInstallEmployee: '请选择安装负责人',
    realInstallTime: '实际安装时间',
    selectRealInstallation: '请选择实际安装时间',
    exceptionRecord: '异常记录',
    acceptanceRecord: '验收记录',
    exceptionExplain: '异常说明',
    exceptionHandling: '异常处理',
    installer: '安装执行人',
    uploadFinishedPhotos: '上传完工照',
    leastOneInstallProduct: '至少添加一个安装产品',
    detailCount: '产品数量',
    serveTime: '服务时间',
    selectServeTime: '请选择服务时间',
    batchSetServiceTime: '修改服务时间',
    canNotLessThanCurrentTime: '不能小于当前时间',
    serviceInfo: '服务信息',
    saveSelect: '保存已选',
    pushSelect: '推送已选',
    auditOpinion: '审核意见',
    acceptancePassed: '验收通过',
    acceptanceFailed: '验收不通过',
    finishTime: '完工时间',
    serveItem: '服务项目'
  },
  addInstallationNotice: {
    code: '单据编号',
    installIsNull: '安装通知单不存在',
    addInstallationNotice: '安装通知单 - 新建',
    editInstallationNotice: '安装通知单 - 编辑',
    remark: '备注信息',
    tag: '提示标签',
    shopDetails: '门店明细',
    productDetails: '产品明细',
    site: '安装地点',
    shopName: '门店名称',
    inputSite: '请填写安装地点',
    address: '门店地址',
    contactsPerson: '联系人员',
    inputContactsPerson: '请填写安装联系人',
    inputContactsPhone: '请填写安装联系电话',
    contactsPhone: '联系电话',
    addInstallationSite: '新增安装地点',
    editInstallationSite: '编辑安装地点',
    remainCount: '剩余可安装数量',
    installCount: '安装数量',
    remainInstallCount: '安装数量已超出项次内产品总数，该项次剩余可安装数量为',
    minRemainInstallCount: '安装数量不能小于1，该项次剩余可安装数量为',
    installNumberLessOne: '安装数量最小为1',
    verifyCountFailed: '产品明细的安装数量不能小于1，请检查门店',
    verifyEmptyFailed: '未发现产品明细，若不需要针对该门店进行安装，请请删除该门店',
    addInstallationOrder: '新建安装单',
    isInitInstalldataForProject: '是否从项目中自动导入安装明细数据？',
    totalBudget: '预算总费用',
    budgetAmount: '预算费用',
    inputBudgetAmount: '请填写预算费用',
    planInstallTime: '计划安装时间',
    finishDay: '完工日期',
    selectFinishDay: '请选择完工日期，不填写则不更新',
    serviceItem: '服务项目',
    selectServiceItem: '请选择服务项目，不填写则不更新',
    selectPlanInstallTime: '请选择服务时间',
    selectFinishInstallTime: '请选择完成日期',
    planInstallStartTime: '计划安装开始时间',
    planInstallEndTime: '计划安装结束时间',
    verifyShopPropFailed: '请点击编辑并检查该门店的必填属性是否填写',
    verifyRemainCount: '项次可安装数量为0，已为您自动过滤',
    updateUser: '更新人员',
    updateTime: '更新时间',
    receivingTime: '接单时间',
    reservationTime: '预约时间',
    signTime: '签到时间',
    finishTime: '完工日期',
    finishRemark: '完工说明',
    acceptanceTime: '验收时间'
  },
  processActionEnum: {
    take: '接单',
    finish: '完成'
  },
  operationTypeEnum: {
    prepress: '印前',
    print: '输出',
    backend: '后道'
  },
  logistics: {
    deliveryMode: '出货方式',
    shopCount: '每店单数',
    deliveryRemark: '发货备注',
    logisticsInfo: '物流信息',
    expressCompany: '物流公司',
    logisticsNo: '物流单号',
    inputLogisticsNo: '请输入物流单号',
    receiverInfo: '收货信息',
    senderInfo: '发货信息',
    shippingInfo: '配送信息',
    subscribeStatus: '轨迹订阅状态',
    code: '单据编号',
    inputCode: '请输入单据编号',
    relevanceDeliveryBill: '关联发货单',
    relevanceCustomer: '关联客户',
    relevanceProject: '关联项目',
    relevanceShop: '关联门店',
    childCount: '包裹数量',
    inputChildCount: '请填写包裹数量',
    status: '单据状态',
    logisticsStatus: '物流状态',
    newLogisticsTrack: '最新轨迹',
    selectCustomer: '请选择关联客户',
    seelctOrderStatus: '请选择单据状态',
    seelctStatus: '请选择物流状态',
    subscribeTrack: '轨迹订阅',
    subscribeSuccess: '订阅成功',
    unSubscribe: '未订阅',
    deliveryTime: '发货日期',
    receivingTime: '收货日期',
    downloadFileName: '物流单',
    logisticsCost: '物流费用',
    weight: '重量(千克)',
    price: '价格(元)',
    deliveryArea: '发货地区',
    receiveArea: '收货地区',
    arriveTime: '到达时间',
    inTransit: '运输中',
    logisticsTrack: '物流轨迹',
    mapMode: '地图模式',
    noLogisticsTrack: '暂未查询到相关物流轨迹信息',
    export: '导出',
    exportFileName: '物流单导出信息',
    receivingCity: '收货城市',
    inputReceivingCity: '请填写收货城市',
    selectExpressCompany: '请选择物流公司',
    selectDeliveryMode: '请选择运输方式',
    productList: '产品列表',
    logisticsTracking: '物流跟踪',
    associatedSettlement: '关联结算单',
    requiredArriveTime: '要求到达',
    arrivalTime: '预计到达',
    closeReason: '关闭原因'
  },
  deliveryMode: {
    TPL: '第三方物流',
    specialTrain: '专车',
    selfPickUp: '自提'
  },
  billing: {
    addBilling: '新增结算单',
    editBilling: '编辑结算单',
    code: '单据编号',
    customer: '客户',
    customerName: '客户名称',
    selectCustomer: '请选择客户',
    settleCurrency: '结算币种',
    selectSettleCurrency: '请选择结算币种',
    settleExchangeRate: '结算汇率',
    inputSettleExchangeRate: '请填写结算汇率',
    paymentMode: '结算方式',
    selectPaymentMode: '请选择结算方式',
    noTaxAmount: '未税金额',
    areaPricing: '面积计价',
    tax: '税额',
    taxIncludeAmount: '含税金额',
    amountWrittenOff: '已核销金额',
    amountToBeWrittenOff: '待核销金额',
    invoiceRecord: '发票（已开 / 总数）',
    amountExcludingTaxError: '结算金额不能小于0',
    status: '单据状态',
    finishTime: '完成时间',
    applyAbolish: '申请废结',
    exportBill: '导出单据',
    exportLedger: '导出台账',
    makeDetails: '制作明细',
    packageTransportDetails: '包装运输明细',
    installDetails: '安装明细',
    otherDetails: '其他明细',
    invoiceInfo: '开票信息',
    saleCount: '销售数量',
    batchEditPrice: '批量修改金额',
    ownerProject: '所属项目',
    selectOwnerProject: '请选择所属项目',
    amountExcludingTax: '未税金额',
    amountIncludingTax: '含税金额',
    priceBeforeTax: '未税单价',
    priceIncludingTax: '含税单价',
    selectProp: '请选择要批量修改的属性',
    prop: '属性',
    amount: '金额',
    taxRate: '税率(%)',
    discountRate: '优惠比例(%)',
    taxAmount: '税额',
    discountAmount: '优惠金额',
    itemType: '产品属性',
    saleUnit: '销售单位',
    priceCount: '计价数量',
    paymentCount: '结算数量',
    totalNumber: '总数量',
    priceUnit: '计价单位',
    price: '单价',
    selectProjectFirst: '请先选择项目，然后点击搜索按钮！',
    relevanceProject: '关联项目',
    color: '红蓝字',
    taxNumber: '纳税人识别号',
    invoiceNumber: '发票号',
    invoiceType: '发票类型',
    invoiceMode: '开票方式',
    selectColor: '请选择发票颜色',
    selectInvoiceMode: '请选择开票方式',
    createInvoice: '创建发票',
    attachment: '附件',
    customerInvoice: '客户开票信息',
    invoiceTitle: '发票抬头',
    selectInvoiceTitle: '请选择发票抬头',
    paymentDay: '回款账期(天)',
    inputPaymentDay: '请填写回款账期(天)',
    inputTaxNumber: '请填写纳税人识别号',
    bankName: '开户行',
    bankNumber: '银行账号',
    inputBankNumber: '请填写银行账号',
    tel: '联系电话',
    inputTel: '请填写联系电话',
    address: '通讯地址',
    inputAddress: '请填写通讯地址',
    selectInvoiceType: '请选择发票类型',
    invoiceCompany: '开票公司',
    selectInvoiceCompany: '请选择开票公司',
    invoices: '开票记录',
    detailedSummary: '明细汇总',
    taxRevenueName: '税收分类',
    serviceName: '服务名称',
    platformInvoice: '平台开票信息',
    sameAmountTip: '结算总金额（未税）必须与开票总金额（未税）相等！',
    inputAmount: '请填写金额',
    countExceedTip: '结算数量不能大于销售数量',
    inputCount: '请先填写计价数量',
    inputPriceBeforeTax: '请先填写未税单价',
    inputAmountExcludingTax: '请先填写未税金额',
    inputDiscountOrExcludingTax: '优惠金额和未税金额不能同时为0',
    conmfirmAbolish: '确定要申请废结吗？',
    abolishNoStandard: '申请废结操作只支持 ‘待开票、待核销、结算完成’ 状态的单据',
    inputApplyReason: '请填写申请原因',
    confirmSubmit: '确定要提交审核吗？',
    confirmCancelApply: '确定要撤销审核吗？',
    submitNoStandard: '提交审核操作只支持 ‘新建’ 状态的单据',
    cancelApplyNoStandard: '撤销审核操作只支持 ‘审批中’ 状态的单据',
    exportFileName: '结算单',
    exportLedgeFileName: '台账',
    financeInfo: '财务信息',
    applicant: '申请人',
    invoicingTime: '开票时间',
    applyTime: '申请时间',
    drawer: '开票人',
    invoiceMaterial: '形式发票材料',
    writeOffRecords: '核销记录',
    writeOff: '核销',
    antiWriteOff: '反核销',
    confirmAntiWriteOff: '确定要进行反核销吗？',
    selectTransaction: '选择交易明细',
    confirmWriteOff: '确定要进行核销吗？',
    cancelAudit: '取消审核',
    operationNoStandard: '当前选中的结算单均不符合要求，请选择状态为新建的结算单！',
    amountExcludingTaxExceed: '发票未税金额不能超过99999.99!',
    area: '单位面积(㎡)',
    totalArea: '总面积(㎡)',
    billAmount: '结算金额',
    writeOffTotalAmount: '核销总金额',
    invoiceStatus: '发票状态',
    approval: '审批记录',
    roundDown: '抹零',
    amountExcludeTax: '结算总金额',
    invoiceAmountExcludeTax: '发票未税金额',
    invoiceAmountIncludeTax: '发票含税金额',
    invoiceTax: '发票税额',
    selectItemType: '请选择产品属性',
    defaultStrategy: '批量修改',
    byArea: '按计价数量分配',
    byNum: '按结算数量分配',
    selectStrategy: '请选择策略',
    strategy: '策略',
    selectProject: '选择项目',
    inputDiscountAmount: '请填写优惠金额',
    inputDiscountRate: '请填写优惠比例',
    discountRemark: '优惠备注',
    batchSetDiscount: '设置优惠',
    setDiscountTip1: '1. 优惠金额与优惠比例必填一个，若都填写则默认设置优惠金额。',
    setDiscountTip2: '2. 优惠比例可设置0.00-100.00之间百分比。',
    setDiscountTip3: '3. 优惠金额超出结算金额的，自动设置为结算金额。',
    setDiscountTip4: '4. 未选择单据则默认对所有单据生效。',
    invoiceFile: '发票文件',
    downloadInvoice: '下载发票',
    uploaded: '已上传',
    notUploaded: '未上传'
  },
  paymentMode: {
    cash: '现金',
    cheque: '支票',
    bankTransfer: '银行转账',
    wireTransfer: '电汇',
    weChat: '微信',
    alipay: '支付宝',
    other: '其他'
  },
  billingItemType: {
    finishedProduct: '成品',
    changeColor: '改色',
    designArtis: '排版',
    server: '服务',
    cost: '费用',
    consumed: '耗损项次'
  },
  billingStatus: {
    new: '新建',
    waitAudit: '审批中',
    waitMakeInvoice: '待开票',
    waitCancelAfterVerification: '待核销',
    complete: '结算完成',
    failedAudit: '审核未通过',
    abolishAudit: '废结审核中',
    failedAbolishAudit: '废结审核未通过',
    abolished: '已废除'
  },
  invoiceColor: {
    red: '红字',
    blue: '蓝字'
  },
  invoiceMode: {
    paper: '纸质发票',
    electronic: '电子发票'
  },
  invoiceType: {
    normalInvoice: '普通发票',
    valueAddedTaxInvoice: '增值税专用发票',
    proformaInvoice: '形式发票'
  },
  financeInvoice: {
    billCode: '所属结算单',
    applyChange: '申请换开',
    makeInvoice: '开票',
    makeInvoiceNoStandard: '开票操作只支持“待开票”状态的数据',
    changeNoStandard: '发票换开只支持"已开票"状态的单据',
    inputInvoiceNo: '请填写发票号',
    inputTip: '按列表已选中顺序，每个发票号以逗号隔开',
    applyChangeInvoice: '发票申请换开',
    invoiceNumError: '填写的发票数量与勾选的数量不匹配！',
    exportFileName: '财务发票',
    invoiceNo: '发票号',
    selectInvoicingTime: '请选择开票时间',
    expiryDate: '到期日',
    inputBillCode: '请填写结算单编号',
    edit: '开票修改',
    exportInvoice: '导出单据',
    exportInvoiceWithDetail: '导出单据(含明细)'
  },
  financeInvoiceStatus: {
    new: '新建',
    waitAudit: '审批中',
    failedAudit: '审核未通过',
    waitMakeInvoice: '待开票',
    hasMakeInvoice: '已开票',
    abolished: '已废除'
  },
  transaction: {
    add: '添加交易明细',
    edit: '编辑交易明细',
    import: '导入交易明细',
    importTemplate: '交易明细导入模板',
    transactionType: '交易类型',
    selectTransactionType: '请选择交易类型',
    paymentMode: '交易渠道',
    selectPaymentMode: '请选择交易渠道',
    abstract: '摘要',
    inputAbstract: '请填写摘要',
    serialNumber: '流水号',
    inputSerialNumber: '请填写流水号',
    currency: '币种',
    selectCurrency: '请选择币种',
    amount: '金额',
    inputAmount: '请填写金额',
    counterpartyAccount: '对方账户',
    inputCounterpartyAccount: '请填写对方账户',
    counterpartyName: '对方名称',
    inputCounterpartyName: '请填写对方名称',
    counterpartyBank: '对方开户行',
    inputCounterpartyBank: '请填写对方开户行',
    tradingHours: '交易时间',
    selectTradingHours: '请选择交易时间',
    writeOffBillingCode: '核销结算单'
  },
  transactionStatus: {
    toBeWrittenOff: '待核销',
    writtenOff: '已核销'
  },
  transactionType: {
    income: '收入',
    expenditure: '支出'
  },
  todo: {
    code: '单据编号',
    topic: '主题',
    applyReason: '申请原因',
    customer: '关联客户',
    approveTheInitiator: '审批发起人',
    inputApproveTheInitiator: '请填写申请发起人',
    approveTheTime: '审批发起时间',
    confirmApproved: '确定要通过审批吗？',
    inputRejectReason: '请填写驳回原因'
  },
  todoScope: {
    billing: '结算单',
    invoice: '发票'
  },
  todoType: {
    billing: '结算申请',
    abolish: '废结申请',
    changeInvice: '发票换开申请'
  },
  approveStatus: {
    notCommit: '未提交',
    waitApprove: '待审核',
    passed: '审核通过',
    failed: '审核未通过'
  },
  projectItemType: {
    finishedProduct: '成品',
    changeColor: '改色',
    designArtis: '排版',
    server: '服务',
    cost: '费用',
    consumed: '耗损项次'
  },
  prepressLayoutCrafts: {
    noneAdjustmentToPrint: '原档输出',
    dimensionAdjustment: '调整尺寸&客户指定内容',
    accordingToGuideline: '按guideline排版',
    ratioLayout: '比例档',
    printReadyFile: '外发文件',
    pixelLayout: '像素排版',
    others: '其他',
    accordingPretty: '依美观排版',
    accordingPrtion: '依比例档排版',
    fence: '围挡排版',
    showbill: '吊旗排版',
    lineDraft: '线稿排版',
    copyPrint: '调打'
  },
  prepressChangeColorCrafts: {
    noneAdjustmentToPrint: '原档输出',
    accordingToTheProof: '依客户样对色',
    graphicRetouch: '修图补图',
    commonSense: '颜色漂亮美观',
    softPrinting: '依屏幕色改色',
    others: '其他',
    accordingColorCard: '依指定色票对色'
  },
  consume: {
    export: '耗损单据'
  },
  hmPackaging: {
    addPackaging: '新增包装',
    editPackaging: '编辑包装',
    boxName: '包装名称',
    boxNumber: '包装编号',
    outerSize: '外尺寸(长x宽x高mm)',
    innerSize: '内尺寸(长x宽x高mm)',
    netWeight: '净重(kg)',
    maxSize: '最大装箱数',
    packType: '包装方式',
    sortUnitTime: '产品分拣时长(s)',
    wrapUnitTime: '封箱时长(s)',
    price: '包装价格(元)',
    inputBoxName: '请填写包装名称',
    inputBoxNumber: '请填写包装编号',
    length: '长度',
    width: '宽度',
    height: '高度',
    inputLength: '请填写外尺寸长度',
    inputWidth: '请填写外尺寸宽度',
    inputHeight: '请填写外尺寸高度',
    widthCompareError: '外宽不能小于内宽',
    lengthCompareError: '外长不能小于内长',
    heightCompareError: '外高不能小于内高',
    inputNetWeight: '请填写净重',
    inputMaxSize: '请填写最大装箱数',
    inputBoxPackType: '请选择包装方式',
    inputSortUnitTime: '请填写产品分拣时长',
    inputWrapUnitTime: '请填写封箱时长',
    inputPrice: '请填写包装价格'
  },
  hmOrder: {
    addOrder: '新建订单',
    orderName: '订单名称',
    campaignNo: '活动编号',
    campaignName: '活动名称',
    projectCode: '项目编号',
    area: '地区',
    version: '版本号',
    sendDate: '订单发送时间',
    onSiteDate: '要求到仓时间',
    transportDate: '发货时间',
    logisticsServiceProvider: '物流提供商',
    invoiceIssuingAddress: '发货信息',
    invoiceReference: '客户财务',
    specCode: '编码',
    comments: '备注',
    createTime: '创建时间',
    createUserName: '创建人',
    downloadPSL: 'PSL',
    downloadBoxSticker: '箱贴',
    downloadPalletSticker: '托盘贴',
    downloadProductTag: '产品标签',
    downloadBooking: 'Booking',
    inputOrderName: '请填写订单名称',
    file: '订单文件',
    exportShippingDetails: '导出发货明细',
    shippingDetailsFileName: 'HM发货明细'
  },
  approveResultEnum: {
    Approving: '审批中',
    approved: '审批通过',
    reject: '驳回',
    revoked: '已撤销'
  },
  approveFlowType: {
    newBill: '提交结算单申请',
    abolishBill: '废除结算单申请',
    replaceInvoice: '发票换开申请'
  },
  dutyAgencyType: {
    platform: '平台自营',
    productionSupplier: '生产供应商',
    materialSupplier: '材料供应商',
    logisticsSupplier: '物流供应商',
    installSupplier: '安装供应商'
  },
  dashboard: {
    thisMonth: '本月',
    thisSeason: '本季',
    thisYear: '本年',
    next7Days: '未来7天',
    next14Days: '未来14天',
    next30Days: '未来30天',
    todo: '待办事项',
    projectTodo: '项目待办',
    projectSummary: '项目统计',
    today: '今日',
    thisWeek: '本周',
    mineChangeColorToDo: '我的改色待办',
    mineComplateManuscriptToDo: '我的完稿待办'
  },
  saleReport: {
    title: '销售统计',
    orderArea: '下单总面积',
    producedArea: '已生产面积',
    productionArea: '生产中面积',
    notProduceYetArea: '未生产面积',
    consumeArea: '耗损面积',
    orderAreaTip: '有效项目项次总面积之和',
    productionAreaTip: '有效项目制作中的项次总面积之和',
    producedAreaTip: '有效项目已完成的项次总面积之和',
    notProduceYetAreaTip: '有效项目未完成生产的项次总面积之和',
    consumeAreaTip: '已记录的平台加自营工厂耗损面积之和'
  },
  financeReport: {
    title: '财务统计',
    invoiceTotalAmount: '开票总金额',
    invoicedAmount: '已开票金额',
    unInvoiceAmount: '未开票金额',
    invoiceTotalAmountTip: '已创建发票税后金额总和',
    invoicedAmountTip: '已开票税后金额总和',
    unInvoiceAmountTip: '未开票税后金额总和'
  },
  prepressReport: {
    changeColor: '改色任务统计',
    completeManuscript: '完稿任务统计',
    waitingForOrder: '待接单',
    finishedProductWaitingForOrder: '成品待接单',
    typesettingWaitingForOrder: '排版待接单',
    production: '制作中',
    finishedProduct: '成品制作中',
    typesetting: '排版制作中',
    waitingForConfirm: '待确稿',
    waitingForComplete: '待完成',
    timeoutItem: '逾期未交稿',
    inputProjectCode: '请填写项目编号',
    productionOrder: '制作中单据',
    waitingForCompleteOrder: '待完成单据',
    productionOrderTip: '当前用户【制作中】状态的印前单据总和',
    waitingForCompleteOrderTip: '当前用户【待完成】状态的印前单据总和',
    checkProjectCode: '请检查项目编号是否正确'
  },
  AETodo: {
    overdueProject: '已逾期项目',
    toBeCompletedProject: '待完成项目',
    notDistributeItem: '待派发生产项次',
    uncommittedPrepressItem: '待提交印前项次',
    toBeDeliveryItem: '待发货项次',
    toBeInstallItem: '待安装项次',
    unsettledItem: '待结算项次',
    toBeConfirmItem: '印前待确稿项次',
    uncommittedBill: '未提交结算单',
    toBeConfirm: '待确稿项次'
  },
  financeTodo: {
    toBeApprovalBillCount: '待审批结算单单据',
    toBeApprovalInvoiceCount: '待审批发票单据',
    waitingInvoice: '待开发票',
    waitingMyApproval: '待我审批',
    waitingApprovalBillTip: '财务待审批结算单据数量总和',
    waitingApprovalInvoiceTip: '财务待审批发票单据数量总和',
    waitingInvoiceTip: '待开票状态发票数量总和',
    waitingMyApprovalTip: '当前用户需处理审批单据数量总和'
  },
  productionAction: {
    prepressTakeOrder: '印前接单',
    prepressComplete: '印前已完成',
    printTakeOrder: '输出接单',
    printComplete: '输出完成',
    backendTakeOrder: '后道接单',
    backendComplete: '后道完成'
  },
  installEmployee: {
    whetherBindWechat: '微信',
    bound: '已绑定',
    unbound: '未绑定',
    name: '姓名',
    mobile: '手机号',
    nickname: '昵称',
    birthday: '出生日期',
    inputNickname: '请填写昵称',
    area: '服务地区',
    import: '导入工人',
    add: '新增工人',
    edit: '编辑工人',
    selectBirthday: '请选择出生日期',
    inputArea: '请填写所在地区'
  },
  exceptionRecord: {
    explain: '异常说明',
    handle: '异常处理',
    inputExplain: '请填写异常说明',
    inputHandle: '请填写异常处理',
    add: '添加异常记录',
    edit: '编辑异常记录'
  },
  projectConfig: {
    approvalFlagExplain: '开启该功能后对所有符合下方条件的项次进行处理，默认开启',
    timeThresholdExplain: '单位：小时，默认为10。要求出货时间距离操作(下单/修改)时间小于交货提前期则标记为【急单】'
  },
  projectToDo: {
    title: '项目待办',
    myToDo: '我的待办',
    myInitiated: '我发起的',
    approved: '已审批'
  },
  exceptionReport: {
    title: '异常播报',
    logisticsExcept: '物流异常',
    slippageUnsigned: '逾期未签收',
    slippageWarning: '逾期预警'
  },
  queryPlan: {
    newTitle: '新建查询方案',
    editTitle: '更新查询方案',
    planName: '方案名称',
    plan: '方案',
    inputName: '请输入方案名称',
    operationTitle: '编辑查询方案'
  },
  region: {
    code: '编码',
    name: '名称',
    administrativeLevel: '行政级别',
    administrativeDivisions: '行政区划',
    parentLevel: '上级行政区划',
    status: '状态',
    description: '描述',
    refreshCache: '刷新缓存',
    inputName: '请填写名称',
    inputCode: '请填写编码',
    selectLevel: '请选择行政等级',
    province: '省',
    city: '市',
    district: '区/县',
    invalidFormat: '格式错误，请输入0-9'
  },
  suggestionConfig: {
    submitFlagExplain: '关闭后用户不可提交反馈信息，默认开启',
    submitCountLimitFlagExplain: '默认关闭，开启后需要设置下方提交次数',
    limitCountExplain: '单位：次。当【限制单日提交】开启后生效，用户单日提交反馈不能超过指定次数',
    submitIntervalExplain:
      '单位：秒。两次提交反馈之间的时间间隔，第二次提交距离第一次提交时间未超过指定间隔则不允许提交'
  },
  feedback: {
    title: '反馈建议',
    code: '反馈编码',
    content: '反馈内容',
    type: '反馈类型',
    create: '提交日期',
    source: '反馈渠道',
    createUserName: '提交人',
    createTime: '提交时间',
    inputContent: '请填写反馈内容',
    selectType: '请选择反馈类型',
    enclosure: '附件',
    details: '反馈详情',
    exportFileName: '反馈建议',
    status: '状态',
    handleOpinion: '处理意见'
  },
  feedbackSuggestionStatus: {
    pendingProcessing: '待处理',
    processing: '处理中',
    closed: '已关闭'
  },
  feedbackSource: {
    PLATFORM: '平台系统',
    SUPPLIER: '供应商系统',
    PRODUCTION_APP: '移动报工小程',
    INSTALL_APP: '安装小程序'
  },
  settlement: {
    billCode: '结算单号',
    count: '结算数量',
    createUserName: '申请人'
  },

  unitsMeasureGroups: {
    approveStatus: '状态',
    enableStatus: '启用状态',
    systemFlag: '系统预置',
    batchAudit: '审核',
    batchReject: '反审核',
    title: '计量单位分组',
    code: '编码',
    name: '名称',
    datumCode: '基准单位编码',
    datumName: '基准单位名称',
    datumPrecision: '基准单位精度',
    describe: '描述',
    inputCode: '填写编码',
    inputName: '填写名称',
    inputDatumCode: '填写基准单位编码',
    inputDatumName: '填写基准单位名称',
    inputDatumPrecision: '填写基准单位精度',
    inputDescribe: '填写描述',
    basicInfo: '基本信息',
    otherInfo: '其他信息',
    createTime: '创建时间',
    createUserName: '创建人',
    updateTime: '修改时间',
    updateUserName: '修改人',
    forbiddenTime: '禁用时间',
    forbiddenUserName: '禁用人',
    approveTime: '审批时间',
    approveUserName: '审批人',
    dispatchTime: '派单时间'
  },
  currency: {
    approveStatus: '状态',
    enableStatus: '启用状态',
    systemFlag: '系统预置',
    batchAudit: '审核',
    batchReject: '反审核',
    title: '币别',
    selectCurrency: '选择币别',
    code: '编码',
    name: '名称',
    currencyCode: '货币代码',
    pricePrecision: '单价精度',
    amountPrecision: '金额精度',
    inputCode: '填写编码',
    inputName: '填写名称',
    inputCurrencyCode: '填写货币代码',
    inputPricePrecision: '填写单价精度',
    inputAmountPrecision: '填写金额精度',
    basicInfo: '基本信息'
  },
  exchangeRate: {
    approveStatus: '状态',
    enableStatus: '启用状态',
    systemFlag: '系统预置',
    batchAudit: '审核',
    batchReject: '反审核',
    title: '汇率',
    sourceCurrencyName: '原币',
    targetCurrencyName: '目标币',
    effective: '生效日期',
    sourceExchangeRate: '直接汇率',
    targetExchangeRate: '间接汇率',
    effectiveStartTime: '生效日期',
    effectiveEndTime: '失效日期',
    selectEffectiveStartTime: '请选择生效日期',
    selectEffectiveEndTime: '请选择失效日期',
    effectivetimeErrorTip: '失效日期不能早于生效日期',
    selectSourceCurrency: '请选择原币',
    selectTargetCurrency: '请选择目标币',
    inputSourceExchangeRate: '请填写直接汇率',
    inputTargetExchangeRate: '请填写间接汇率',

    selectWaitApproveData: '请勾选启用状态为【启用】且审核状态为【待审核】且不是系统预置的数据',
    selectPassedData: '请选择启用状态为【启用】且审核状态为【已审核】且不是系统预置的数据',
    selectWaitApproveUsingData: '请选择启用状态为【启用】且审核状态为【已审核】且不是系统预置的数据',
    selectDeleteTip: '请选择启用状态为【启用】且审核状态为【待审核】且不是系统预置的数据',
    selectDeleteTip1: '请选择启用状态为【启用】且审核状态为【待审核】且不是系统预置的数据',
    basicInfo: '基本信息'
  },
  publicApproveStatus: {
    passed: '已审核',
    waitApprove: '待审核'
  },
  unitsMeasure: {
    approveStatus: '状态',
    enableStatus: '启用状态',
    systemFlag: '系统预置',
    batchAudit: '审核',
    batchReject: '反审核',
    title: '计量单位',
    code: '编码',
    name: '名称',
    groupId: '所属分组',
    precision: '精度',
    denominator: '换算分母',
    numerator: '换算分子',
    currentUnit: '当前单位',
    datumName: '基准单位',
    datumFlag: '基准计量单位',
    describe: '描述',
    inputCode: '填写编码',
    inputName: '填写名称',
    selectGroupId: '请选择所属分组',
    inputPrecision: '填写精度',
    inputDescribe: '填写描述',
    inputDenominator: '填写换算分母',
    inputNumerator: '填写换算分子',
    basicInfo: '基本信息'
  },
  serviceItem: {
    approveStatus: '状态',
    enableStatus: '启用状态',
    systemFlag: '系统预置',
    batchAudit: '审核',
    batchReject: '反审核',
    title: '服务项目',
    name: '名称',
    code: '编码',
    type: '业务类型',
    inputName: '请填写名称',
    inputCode: '请填写编码',
    selectType: '请选择业务类型',

    createTime: '创建时间',
    createUserName: '创建人',

    selectWaitApproveData: '请勾选启用状态为【启用】且审核状态为【待审核】的数据',
    selectPassedData: '请选择启用状态为【启用】且审核状态为【已审核】的数据',
    selectWaitApproveUsingData: '请选择启用状态为【启用】且审核状态为【已审核】的数据',
    selectDeleteTip: '请选择启用状态为【启用】且审核状态为【待审核】的数据',
    selectDeleteTip1: '请选择启用状态为【启用】且审核状态为【待审核】的数据',
    basicInfo: '基本信息'
  },
  businessType: {
    install: '安装',
    measure: '测量',
    repair: '维修'
  },
  database: {
    name: '名称',
    inputName: '请填写名称',
    code: '编码',
    inputCode: '请填写编码',
    approveStatus: '状态',
    enableStatus: '启用状态',
    description: '描述',
    inputDescribe: '请填写描述',
    basicInfo: '基本信息',
    otherInfo: '其他信息',
    createTime: '创建时间',
    createUserName: '创建人',
    updateTime: '修改时间',
    updateUserName: '修改人',
    forbiddenTime: '禁用时间',
    forbiddenUserName: '禁用人',
    approveTime: '审批时间',
    approveUserName: '审批人',
    import: '导入',
    selectWaitApproveData: '请选择启用状态为【启用】且审核状态为【待审核】的数据',
    selectApprovedData: '请选择启用状态为【启用】且审核状态为【已审核】的数据',
    selectDeleteTip: '请选择启用状态为【启用】且审核状态为【待审核】的数据',
    selectUsingData: '请选择启用状态为【启用】的数据',
    selectDisabledData: '请选择启用状态为【禁用】的数据',

    confirmTime: '审稿时间',
    confirmUserName: '审稿人',
    confirmResult: '审稿结果'
  },
  warehouseStore: {
    warehouseCode: '仓库编码',
    warehouseName: '仓库名称',
    storeCode: '仓位编码',
    storeCodeRepeat: '仓位编码已存在',
    storeName: '仓位名称',
    title: '仓库仓位关系',
    selectWarehouseCode: '请选择仓库编码',
    selectStoreCode: '请选择仓位编码'
  },
  warehouse: {
    title: '仓库',
    positionFlag: '启用仓位管理'
  },
  storeLocation: {
    title: '仓位',
    list: '仓位列表',
    name: '仓位名称',
    code: '仓位编码'
  },
  inventoryType: {
    title: '存货类别',
    categoryParent: '上级分类',
    selectCategoryParent: '请选择上级分类'
  },

  materialList: {
    title: '物料',
    serviceControl: '业务控制',
    inventoryInfo: '库存信息',
    salesInfo: '销售信息',
    purchasingInfo: '采购信息',
    productionInfo: '生产信息',
    specification: '规格型号',
    category: '物料分类',
    baseUnit: '基本单位',
    brand: '品牌',
    attribute: '物料属性',
    sizeUnit: '尺寸单位',
    length: '长度',
    width: '宽度',
    height: '高度',
    weightUnitName: '重量单位',
    grossWeight: '毛重',
    netWeight: '净重',
    volume: '体积',
    inventoryType: '存货类别',
    taxRate: '默认税率(%)',
    saleFlag: '可销售',
    purchaseFlag: '可采购',
    inventoryFlag: '可库存',
    productionFlag: '可生产',
    outsourceFlag: '可委外',
    inventoryUnit: '库存单位',
    adjunctUnit: '辅助单位',
    inventoryThresholdFlag: '启用安全库存预警',
    inventoryThreshold: '安全库存',
    effectiveFlag: '启用保质期',
    effectiveUnit: '保质期单位',
    effectiveNum: '保质期',
    leadTimeUnit: '提前期单位',
    effectiveThresholdFlag: '启用保质期预警',
    effectiveThresholdNum: '预警提前期',
    warehouse: '仓库',
    store: '仓位',
    saleUnit: '销售单位',
    priceUnit: '计价单位',
    purchaseUnit: '采购单位',
    supplier: '默认供应商',
    productionUnit: '生产单位',
    productionStore: '入库仓位',
    productionWarehouse: ' 入库仓库',
    backend: '后道工艺',
    importMaterial: '物料导入',
    productionFlagTip: '未开启生产工序',
    generateName: '生成名称',
    namePreview: '名称预览'
  },
  materialPropertyEnum: {
    SELF_CONTROL: '自制',
    OUTSOURCE: '外购',
    SERVICE: '服务',
    PROPERTY: '资产',
    COST: '费用',
    VIRTUALLY: '虚拟'
  },
  materialClassification: {
    title: '物料分类',
    categoryParent: '上级分类',
    selectCategoryParent: '请选择上级分类'
  },
  materialBom: {
    title: '物料清单',
    bomName: 'BOM名称',
    bomVersion: 'BOM版本',
    mainProductInfo: '主产品信息',
    mainMaterialCode: '父物料编码',
    mainMaterialName: '父物料名称',
    mainMaterialUnitName: '父物料单位',
    specification: '规格型号',
    attribute: '物料属性',
    materialCode: '物料编码',
    materialName: '物料名称',
    type: '用量类型',
    typeParam: '用量计算参数',
    numerator: '用量(分子)',
    denominator: '用量(分母)',
    fixedAttrition: '固定耗损',
    setting: '物料设置',
    receiveWarehouse: '默认发料仓库',
    payment: '领料方式'
  },
  usageTypeEnum: {
    CHANGE: '变动',
    FIXED: '固定'
  },
  usageCalculationParametersEnum: {
    MADE_AREA: '制作面积',
    MADE_PERIMETER: '制作周长',
    MADE_QUANTITY: '制作数量'
  },
  receiveModeEnum: {
    ORDINARY: '普通领料',
    BACKFLUSH: '倒冲领料',
    NO: '不领料'
  },
  workflowDesign: {
    document: '单据',
    workflowIdentity: '工作流标识',
    abandonedWorkflow: '废弃流程',
    resumeWorkflow: '恢复流程',
    tag: '流程标识',
    status: '状态',
    selectDeleteTip: '请选择【未发布】的单据',
    selectRestoreTip: '请选择【已废弃】的单据',
    selectRepealTip: '请选择【已发布】的单据'
  },
  workflowStatus: {
    unpublished: '未发布',
    published: '已发布',
    abandoned: '已废弃'
  },
  taskCenter: {
    code: '单据编号',
    title: '主题',
    createUserName: '发起人',
    createTime: '发起时间',
    completeTime: '处理时间',
    ccTime: '送达时间',
    status: '状态',
    approveUserName: '当前审批人',
    content: '审批意见',
    setRead: '设为已读',
    initiateApproval: '发起审批',
    leadership: '直属领导',
    approvalProcess: '审批流程',
    agree: '已同意',
    reject: '已驳回',
    selectRevokeTip: '请选择【审批中】的单据',
    selectUnreadTip: '请选择未读的单据'
  },
  approveResult: {
    approving: '审批中',
    approved: '已同意',
    reject: '已驳回',
    revoked: '已撤销'
  },

  prepressCrafts: {
    title: '印前工艺',
    type: '工艺类型',
    factor: '难度系数'
  },
  prepressCraftsTypeEnum: {
    finalized: '排版',
    changeColor: '改色'
  },
  prepressStatistics: {
    processedBy: '压单人',
    orderType: '单据类型',
    PressingTime: '压单时间',
    batchEditWorkload: '批改工作量',
    updateReason: '修改原因',
    dimension: '统计维度',
    statPeriod: '统计周期',
    confirmItemTotal: '确稿项次总数',
    reeditTotal: '总排版轮数',
    avgConfirmReedit: '平均确稿轮数',
    reviewsTotal: '已审稿总稿数',
    confirmedTotal: '确稿总稿数',
    averageConfirmationRate: '平均确稿率(%)',
    workload: '工作量',
    dateRange: '日期范围',
    statisticalCustomer: '统计客户',
    personnelName: '人员姓名',
    timeTip: '时间跨度超过一年,请选择跨度一年以内的时间范围'
  },
  batchSetupData: {
    title: '批量修改',
    editItem: '修改项',
    editMethod: '修改方式',
    editValue: '修改值',
    remove: '移除',
    selectDataTip: '请勾选单据'
  },
  dimensionEnum: {
    year: '按年',
    quarter: '按季',
    month: '按月',
    week: '按周'
  },
  confirmResultEnum: {
    new: '新建',
    confirm: '确稿',
    reedit: '改稿'
  },
  layoutStatusEnum: {
    init: '未生成',
    generated: '已生成',
    generating: '生成中',
    generationFailed: '生成失败'
  }
};
