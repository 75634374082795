/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const customerManagementRoutes: RouteConfig = {
  path: '/customer-management',
  component: Layout,
  name: 'CustomerManagement',
  redirect: 'customer',
  children: [
    {
      path: '/customer',
      name: 'Customer',
      component: async () =>
        import(/* webpackChunkName: "customer" */ '@/views/customer-management/customer/customer.vue'),
      meta: {
        title: 'route.customerList',
        id: 220
      }
    },
    {
      path: '/customer-allocation',
      name: 'CustomerAllocation',
      component: async () =>
        import(
          /* webpackChunkName: "CustomerAllocation" */ '@/views/customer-management/customer-allocation/customer-allocation.vue'
        ),
      meta: {
        title: 'route.customerAllocationList',
        id: 1913
      }
    },
    {
      path: '/customer-invoice',
      name: 'CustomerInvoice',
      component: async () =>
        import(
          /* webpackChunkName: "customer-invoice" */ '@/views/customer-management/customer/customer-invoice/customer-invoice.vue'
        ),
      meta: {
        title: 'route.invoice',
        id: 485,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/customer-product',
      name: 'CustomerProduct',
      component: async () =>
        import(
          /* webpackChunkName: "customer-product" */ '@/views/customer-management/customer/customer-product/customer-product.vue'
        ),
      meta: {
        title: 'route.sellPrice',
        id: 506,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/shop-tag',
      name: 'ShopTag',
      component: async () =>
        import(/* webpackChunkName: "shop-tag" */ '@/views/customer-management/customer/shop-tag/shop-tag.vue'),
      meta: {
        title: 'route.shopTag',
        id: 530,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/point-tag',
      name: 'PointTag',
      component: async () =>
        import(/* webpackChunkName: "point-tag" */ '@/views/customer-management/customer/point-tag/point-tag.vue'),
      meta: {
        title: 'route.pointTag',
        id: 531,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/customer-shop',
      name: 'CustomerShop',
      component: async () =>
        import(
          /* webpackChunkName: "customer-shop" */ '@/views/customer-management/customer/customer-shop/customer-shop.vue'
        ),
      meta: {
        title: 'route.shop',
        id: 542,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/customer-point',
      name: 'CustomerPoint',
      component: async () =>
        import(
          /* webpackChunkName: "customer-point" */ '@/views/customer-management/customer/customer-point/customer-point.vue'
        ),
      meta: {
        title: 'route.point',
        id: 543,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/customer-contacts',
      name: 'CustomerContacts',
      component: async () => import('@/views/customer-management/customer/customer-contacts/customer-contacts.vue'),
      meta: {
        title: 'route.contacts',
        id: 524,
        parentId: 220,
        hidden: true
      }
    },
    {
      path: '/customer-classify',
      name: 'CustomerClassify',
      component: async () =>
        import(
          /* webpackChunkName: "customer-classify" */ '@/views//customer-management/customer-classify/customer-classify.vue'
        ),
      meta: {
        title: 'route.customerClassify',
        id: 221
      }
    },
    {
      path: '/customer-transfer',
      name: 'CustomerTransfer',
      component: async () =>
        import(
          /* webpackChunkName: "customer-transfer" */ '@/views//customer-management/customer-transfer/customer-transfer.vue'
        ),
      meta: {
        title: 'route.customerTransfer',
        id: 1809
      }
    }
  ],
  meta: {
    title: 'route.customer',
    icon: '#customer',
    id: 219
  }
};
