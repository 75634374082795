import { DeliveryModeEnum, ResourceUrlEnum } from '@/resource/enum';
import { LogisticsList, LogisticsListQuery, LogisticsDetail, LogisticsProductList } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload, translation } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class LogisticsService {
  public getById(id: number): Promise<LogisticsDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<LogisticsDetail>(ResourceUrlEnum.logistics, { params: { id } })
        .then((res: ApiResponse<LogisticsDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<LogisticsListQuery>, paging?: Paging): Promise<ApiResponse<Array<LogisticsList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsList>>(`${ResourceUrlEnum.logistics}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有关联了项目的客户列表
   */
  public getProjectCustomer(): Promise<Array<{ customerId: number; customerName: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ customerId: number; customerName: string }>>(`${ResourceUrlEnum.logistics}/getCustomer`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取出货方式
   * @returns
   */
  public getDeliveryMode(): NormalSelectOptions {
    const deliveryModes: NormalSelectOptions = [];
    for (const key in DeliveryModeEnum) {
      if (isNaN(Number(key))) {
        deliveryModes.push({
          label: translation(`deliveryMode.${key}`),
          value: DeliveryModeEnum[key]
        });
      }
    }
    return deliveryModes;
  }

  /**
   * 导出结算单
   * @param query
   * @returns
   */
  public export(query?: Partial<LogisticsListQuery>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.logistics}/export`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有快递公司
   * @returns
   */
  public getExpressCompanys(): Promise<Array<{ id: number; name: string; code: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ id: number; name: string; code: string }>>(`/express/company/list`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取物流单下的项次信息
   * @returns
   */
  public getLogisticsProducts(id: number, paging: Paging): Promise<ApiResponse<Array<LogisticsProductList>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsProductList>>(`${ResourceUrlEnum.logistics}/listLogisticsItemPage`, { id, ...paging })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 刷新物流信息
   * @returns
   */
  public refreshTrack(id: number): Promise<LogisticsDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<LogisticsDetail>(`${ResourceUrlEnum.logistics}/refreshTrack`, { params: { id } })
        .then((res: ApiResponse<LogisticsDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const logisticsService = new LogisticsService();
export default logisticsService;
