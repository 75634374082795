/**
 * 结算方式
 */
export enum PaymentModeEnum {
  /**
   *现金
   */
  cash = 1,
  /**
   * 支票
   */
  cheque,
  /**
   * 银行转账
   */
  bankTransfer,
  /**
   * 电汇
   */
  wireTransfer,
  /**
   * 微信
   */
  weChat,
  /**
   * 支付宝
   */
  alipay,
  /**
   * 其他
   */
  other
}
