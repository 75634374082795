import { ResourceUrlEnum } from '@/resource/enum';
import { HmPackagingList, HmPackagingListQuery, HmPackagingEdit, HmPackagingSave } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { axiosService, ApiResponse, ApiError } from '../axios';
import { Paging } from '../base';

/**
 * HM专项-包装管理service
 * @author chris chen 2021-10-31 11:36
 * @version 3.0.0
 */
class HmPackagingService {
  /**
   * 编辑
   * @param resource 编辑资源对象
   * @returns
   */
  public put(resource: HmPackagingEdit): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<HmPackagingSave>(ResourceUrlEnum.hmPackaging, resource)
        .then((res: ApiResponse<HmPackagingSave>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存
   * @param resource 保存资源对象
   * @returns
   */
  public post(resource: HmPackagingSave): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<HmPackagingSave>(ResourceUrlEnum.hmPackaging, resource)
        .then((res: ApiResponse<HmPackagingSave>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询条件
   * @param paging 分页条件
   * @returns 分页数据
   */
  public getList(
    query?: Partial<HmPackagingListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<HmPackagingList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<HmPackagingList>>(`${ResourceUrlEnum.hmPackaging}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<HmPackagingList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除
   * @param id 资源ID
   * @returns
   */
  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.hmPackaging, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const hmPackagingService = new HmPackagingService();

export default hmPackagingService;
