import { InstallationNoticeStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  ImportRes,
  InstallationNoticeInfo,
  InstallationNoticeListQuery,
  ProductProjectListQuery,
  ProjectProductInstallation,
  CreateInstallationNotice,
  ShopList,
  LogisticsList,
  InstallationNoticeList
} from '@/resource/model';
import { handleDownload, messageError, translation } from '@/utils';
import { axiosRequest } from '@/utils/axios-request';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { axiosService, ApiResponse, ApiError } from '../axios';
import { Paging, SortOptions } from '../base';

class InstallationNoticeService {
  public getLogisticsOrders(id: number): Promise<Array<LogisticsList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<any>>(`${ResourceUrlEnum.installationNotice}/${id}/getLogisticsOrders`)
        .then((res: ApiResponse<Array<LogisticsList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }

          resolve(res.data);
        }).catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        })
    })
  }

  public getById(id: number): Promise<InstallationNoticeInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<InstallationNoticeInfo>(ResourceUrlEnum.installationNotice, {
          params: { id }
        })
        .then((res: ApiResponse<InstallationNoticeInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 新建安装单调用
   * @param id
   * @returns
   */
  public getInfo(id: number): Promise<InstallationNoticeInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<InstallationNoticeInfo>(`${ResourceUrlEnum.installationNotice}/undistributed`, {
          params: { id }
        })
        .then((res: ApiResponse<InstallationNoticeInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: CreateInstallationNotice): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<InstallationNoticeInfo>(ResourceUrlEnum.installationNotice, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: InstallationNoticeInfo): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<InstallationNoticeInfo>(ResourceUrlEnum.installationNotice, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.installationNotice}/batch`, { data: idList })
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量提交审核
   * @param idList
   * @returns
   */
  public batchSubmit(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.installationNotice}/batchSubmit`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量审核
   * @param idList
   * @returns
   */
  public batchAudit(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/batchAudit`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量反审核
   * @param idList
   * @returns
   */
  public batchReject(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/batchRevertAudit`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量完成
   * @param idList
   * @returns
   */
  public batchFinish(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/batchFinish`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量完成
   * @param idList
   * @returns
   */
  public cancelFinish(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/cancelFinish`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量取消
   * @param idList
   * @returns
   */
  public batchCancel(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/batchCancel`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 设置已对账
   * @param idList
   * @returns
   */
  public submitBillStatus(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/submitBillStatus`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 设置已对账
   * @param idList
   * @returns
   */
  public cancelBillStatus(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationNotice}/cancelBillStatus`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存并推送安装通知单
   * @param resource 安装通知单
   * @returns
   */
  public saveAndPush(resource: CreateInstallationNotice): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<InstallationNoticeInfo>(`${ResourceUrlEnum.installationNotice}/savePush`, resource)
        .then((res: ApiResponse<InstallationNoticeInfo>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑并推送安装通知单
   * @param resource 安装通知单
   * @returns
   */
  public editAndPush(resource: InstallationNoticeInfo): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<InstallationNoticeInfo>(`${ResourceUrlEnum.installationNotice}/editPush`, resource)
        .then((res: ApiResponse<InstallationNoticeInfo>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<InstallationNoticeListQuery>,
    paging: Paging,
    sortOptions: SortOptions<InstallationNoticeList>
  ): Promise<ApiResponse<Array<InstallationNoticeInfo>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InstallationNoticeInfo>>(`${ResourceUrlEnum.installationNotice}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<InstallationNoticeInfo>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项目下产品分类
   * @param projectId 项目id
   * @returns
   */
  public getProductClassifies(projectId: number): Promise<Array<{ categoryId: number; categoryName: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ categoryId: number; categoryName: string }>>(
          `${ResourceUrlEnum.installationNotice}/getProductCategoryList`,
          {
            params: { projectId }
          }
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项目内产品项次
   * @param projectId 项目id
   * @param query 查询
   * @returns
   */
  public getProjectProduct(
    query: Partial<ProductProjectListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProjectProductInstallation>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectProductInstallation>>(`${ResourceUrlEnum.installationNotice}/getInstallItems`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除安装单下门店安装明细
   * @param idList 门店安装明细id
   * @returns
   */
  public batchDeleteInstallationShop(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.installationNotice}/deleteShop`, { data: idList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载导入安装明细模板
   * @param projectId 项目id
   * @returns
   */
  public downloadTemplate(projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.installationNotice}/downloadTemplate`, {
          params: { projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入安装明细数据
   * @param filePath 文件在minio上的路径
   * @param projectId 项目id
   * @returns
   */
  public importInstallationDetails(filePath: string, projectId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<ImportRes>(`${ResourceUrlEnum.installationNotice}/importData`, {
          filePath,
          projectId
        })
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: InstallationNoticeStatusEnum): string {
    if (!InstallationNoticeStatusEnum[status]) {
      return 'common.unknown';
    }

    return 'installationNoticeStatus.' + InstallationNoticeStatusEnum[status];
  }

  /**
   * 根据状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: InstallationNoticeStatusEnum): string {
    let className = '';
    switch (status) {
      case InstallationNoticeStatusEnum.new:
        className = 'default-dot';
        break;
      case InstallationNoticeStatusEnum.waitingTake:
        className = 'warning-dot';
        break;
      case InstallationNoticeStatusEnum.waitingInstall:
        className = 'primary-dot';
        break;
      case InstallationNoticeStatusEnum.completed:
        className = 'success-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 获取资源可选状态集合
   * @returns
   */
  public getStatusSelectableList(): Array<any> {
    const list = [];
    for (const key in InstallationNoticeStatusEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        list.push({
          label: translation(this.getStatusI18Key(Number(key))),
          value: key
        });
      }
    }

    return list;
  }

  /**
   * 获取编辑项目时，配置的发货明细
   * @param projectId
   * @returns
   */
  public getInstallDetailsByProject(projectId: number): Promise<Array<ShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ShopList>>(`${ResourceUrlEnum.installationNotice}/getInstallDataByProjectId`, {
          params: { projectId }
        })
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据项目门店ids，获取项目交货明细下，门店配置的要发货项次
   * @param projectShopId 项目交货门店id
   * @returns
   */
  public getShopItemsByShops(projectShopIds: Array<number>): Promise<Array<ShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ShopList>>(`${ResourceUrlEnum.installationNotice}/getProjectShopInstallItemList`, projectShopIds)
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出安装通知单明细
   * @param query
   * @param idList
   * @returns
   */
  public export(query?: Partial<InstallationNoticeListQuery>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.installationNotice}/exportDetails`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 编辑摘要
   * @param form
   * @returns
   */
  public editSummary(form: { id: number; abstractContent: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<InstallationNoticeInfo>(`${ResourceUrlEnum.installationNotice}/abstract`, form)
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载海报导入模版
   * @returns
   */
  public downloadPosterTemplate(requestList: Array<Partial<ShopList>>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.installationNotice}/downloadPoserTemplate`, requestList, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public importPoster(filePath: string, projectId: number): Promise<ApiResponse<ImportRes> | Array<ShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.installationNotice}/importShopInfo`, { filePath, projectId })
        .then((res: ApiResponse<ImportRes> | any) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 下载POP导入模版
   * @returns
   */
  public downloadPopTemplate(projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.installationNotice}/downloadPopTemplate`, {
          params: { projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public importPop(filePath: string, projectId: number): Promise<Array<ShopList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.installationNotice}/importPop`, { filePath, projectId })
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const installationNoticeService = new InstallationNoticeService();
export default installationNoticeService;
