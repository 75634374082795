import { PrepressSampleStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ColorChangeSampleList, ColorChangeSampleInfo, ColorChangeSampleQuery } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload, messageError, translation } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';
import { ColorChangeSample } from '@/resource/model/prepress-task/prepress-task';

class ColorChangeSampleService {
  public getById(id: number): Promise<ColorChangeSampleInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ColorChangeSampleInfo>(ResourceUrlEnum.colorChangeSample, { params: { id } })
        .then((res: ApiResponse<ColorChangeSampleInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<ColorChangeSampleQuery>,
    paging: Paging,
    sortOptions: SortOptions<ColorChangeSampleList>
  ): Promise<ApiResponse<Array<ColorChangeSampleList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ColorChangeSampleList>>(`${ResourceUrlEnum.colorChangeSample}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ColorChangeSampleList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 新增
   * @returns
   */
  public post(resource: Array<ColorChangeSample>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<ColorChangeSample>(`${ResourceUrlEnum.colorChangeSample}`, resource)
        .then((res: ApiResponse<ColorChangeSample>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 编辑
   * @returns
   */
  public put(resource: ColorChangeSample): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<ColorChangeSample>(`${ResourceUrlEnum.colorChangeSample}`, resource)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 提交打样
   * @returns
   */
  public submit(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.colorChangeSample}/submit`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 取消打样
   * @returns
   */
  public cancel(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.colorChangeSample}/cancel`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量删除
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete<any>(`${ResourceUrlEnum.colorChangeSample}`, { data: idList })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public export(query?: Partial<ColorChangeSampleQuery>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.colorChangeSample}/export`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取印前状态
   * @returns
   */
  public getPrepressSampleStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PrepressSampleStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`prepressSampleStatus.${key}`),
          value: PrepressSampleStatusEnum[key]
        });
      }
    }
    return options;
  }
}
const colorChangeSampleService = new ColorChangeSampleService();
export default colorChangeSampleService;
