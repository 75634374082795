export const isExternal = (path: string): boolean => /^(https?:|mailto:|tel:)/.test(path);
export const emailReg = (email: string): boolean =>
  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/.test(email);
export const passwordReg = (password: string): boolean =>
  /^(?=^.{8,20}$)(?=(?:.*?\d){1})(?=.*[a-z])(?=(?:.*?[A-Z]){1})(?=(?:.*?[`·~!@#$%^&*()_+}{|:;'",<.>/?\=\[\]\-\\]){1})(?!.*\s)[0-9a-zA-Z`·~!@#$%^&*()_+}{|:;'",<.>/?\=\[\]\-\\]*$/.test(
    password
  );
export const mobileReg = (mobile: string): boolean => /^1[3-9]\d{9}$/.test(mobile);
export const phoneReg = (mobile: string): boolean => /^([0, 8]\d{2,3}-?)?\d{7,8}(-\d{1,6})?$/.test(mobile.toString());
/**
 * 限制税率为1-100的正整数
 * @param value 税率
 * @returns 验证结果
 */
export const taxRate = (value: string): boolean => /^(([1-9]\d?)|(100)|(0))$/.test(value);

/**
 * 验证客户产品名称
 * @param value
 * @returns
 */
export const customerProductNameReg = (value: string): boolean =>
  /^[A-Za-z_\u4e00-\u9fa5\\]{1}[\dA-Za-z_\u4e00-\u9fa5\\]{0,49}$/.test(value);

/**
 * 验证输入的电话或手机号 匹配中国手机号、座机号以及国际电话
 * @param str
 * @returns
 */
export const telOrMobile = (str: string): boolean => /^(1[3-9]\d{9})$|^(\d{2,4}-\d{7,8}(-\d{1,5})?)$|^(\+?\d+)$/.test(str);
