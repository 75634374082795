import { ResourceUrlEnum } from '@/resource/enum';
import { ApproveParamter, TodoList, TodoListQuery } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

class TodoService {
  public approved(param: ApproveParamter): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.todo}/submitTask`, param)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public reject(param: ApproveParamter): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.todo}/rejectTask`, param)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<TodoListQuery>, paging?: Paging): Promise<ApiResponse<Array<TodoList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<TodoList>>(`${ResourceUrlEnum.todo}/getTaskList`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const todoService = new TodoService();
export default todoService;
