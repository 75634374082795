import { ResourceUrlEnum } from '@/resource/enum';
import { QueryPlanResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class QueryPlanService implements BaseService<QueryPlanResource> {
  public getById(id: number): Promise<QueryPlanResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<QueryPlanResource>(ResourceUrlEnum.queryPlan, { params: { id } })
        .then((res: ApiResponse<QueryPlanResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: QueryPlanResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<QueryPlanResource>(ResourceUrlEnum.queryPlan, resource)
        .then((res: ApiResponse<QueryPlanResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: QueryPlanResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<QueryPlanResource>(ResourceUrlEnum.queryPlan, resource)
        .then((res: ApiResponse<QueryPlanResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.queryPlan, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<QueryPlanResource>, paging?: Paging): Promise<ApiResponse<Array<QueryPlanResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<QueryPlanResource>>(`${ResourceUrlEnum.queryPlan}`, { params: { code: query?.code } })
        .then((res: ApiResponse<Array<QueryPlanResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查页面方案名称是否重复
   * @param supplierLevelName 级别名称
   * @param id 角色主键（修改时需要传入）
   * @returns 为true则代表重复
   */
  public checkPlanName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierLevel}/checkNameRepeat`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const queryPlanService = new QueryPlanService();
export default queryPlanService;
