/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const publicDataRoutes: RouteConfig = {
  path: '/public-data',
  name: 'PublicData',
  component: Layout,
  redirect: 'region',
  children: [
    {
      path: '/region',
      name: 'Region',
      component: async () =>
        import(/* webpackChunkName: "organization-department" */ '@/views/public-data/region/region.vue'),
      meta: {
        title: 'route.region',
        id: 1916
      }
    },
    {
      path: '/unit-measure',
      name: 'UnitMeasure',
      component: async () =>
        import(/* webpackChunkName: "organization-department" */ '@/views/public-data/unit-measure/unit-measure.vue'),
      meta: {
        title: 'route.unitMeasure'
      }
    },
    {
      path: '/currency-management',
      name: 'CurrencyManagement',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/public-data/currency-management/currency-management.vue'
        ),
      meta: {
        title: 'route.currencyManagement'
      }
    },
    {
      path: '/units-measure-groups',
      name: 'UnitsMeasureGroups',
      component: async () =>
        import(
          /* webpackChunkName: "organization-department" */ '@/views/public-data/units-measure-groups/units-measure-groups.vue'
        ),
      meta: {
        title: 'route.unitsMeasureGroups'
      }
    },
    {
      path: '/exchange-rate',
      name: 'ExchangeRate',
      component: async () =>
        import(/* webpackChunkName: "organization-department" */ '@/views/public-data/exchange-rate/exchange-rate.vue'),
      meta: {
        title: 'route.exchangeRate'
      }
    }
  ],
  meta: {
    title: 'route.publicData',
    icon: '#database',
    id: 1915
  }
};
