import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ImportRes, CustomerResource, SaveCustomerTransfer } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { BaseService, Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload } from '@/utils';

class CustomerService implements BaseService<CustomerResource> {
  public getById(id: number): Promise<CustomerResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<CustomerResource>(ResourceUrlEnum.customer, { params: { id } })
        .then((res: ApiResponse<CustomerResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: CustomerResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CustomerResource>(ResourceUrlEnum.customer, resource)
        .then((res: ApiResponse<CustomerResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: CustomerResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<CustomerResource>(ResourceUrlEnum.customer, resource)
        .then((res: ApiResponse<CustomerResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.customer, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.customer}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query: Partial<CustomerResource>, paging: Paging): Promise<ApiResponse<Array<CustomerResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerResource>>(`${ResourceUrlEnum.customer}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CustomerResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查客户名称是否重复
   * @param name 客户名称
   * @param id 客户主键（修改时需传入）
   * @returns 为true则代表重复
   */
  public checkCustomerName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.customer}/checkNameRepeat`, { params: { name, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查客户域名是否重复
   * @param domainName 域名
   * @param id 客户主键
   * @returns 为true则代表重复
   */
  public checkDomainName(domainName: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.customer}/checkDomainNameRepeat`, { params: { domainName, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchUpdateCustomerStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.customer}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public importCustomer(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.customer}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有启用的客户
   */
  public getAllUsingCustomer(): Promise<Array<CustomerResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<CustomerResource>>(`${ResourceUrlEnum.customer}/listEnable`, { repeat: true })
        .then((res: ApiResponse<Array<CustomerResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载客户导入模板
   * @returns
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.customer}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有启用的客户
   */
  public getCustomerByUser(
    query: Partial<{ companyName: string; userId: number }>,
    paging: Paging
  ): Promise<ApiResponse<Array<CustomerResource>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerResource>>(`${ResourceUrlEnum.customer}/listPageForComponents`, { ...query, ...paging })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 转接客户
   */
  public customerTransfer(data: SaveCustomerTransfer): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerResource>>(`${ResourceUrlEnum.customer}/reassignment`, data)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 取消分配
   * @param idList
   * @returns
   */
  public cancelAssignment(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.department}/customer`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据部门获取客户
   * @param query
   * @returns
   */
  public getCustomerList(
    query: Partial<CustomerResource>,
    paging: Paging
  ): Promise<ApiResponse<Array<CustomerResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerResource>>(`${ResourceUrlEnum.department}/customer/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CustomerResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const customerService = new CustomerService();
export default customerService;
