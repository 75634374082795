/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const productionManagementRoutes: RouteConfig = {
  path: '/production-management',
  name: 'ProductionManagement',
  component: Layout,
  redirect: 'production-order',
  children: [
    {
      path: '/production-order',
      name: 'ProductionOrder',
      component: async () =>
        import(
          /* webpackChunkName: "production-order" */ '@/views/production-management/production-order/production-order.vue'
        ),
      meta: {
        title: 'route.productionOrder',
        id: 931
      },
      children: [
        {
          path: '/add-production-order',
          name: 'AddProductionOrder',
          component: async () =>
            import(
              /* webpackChunkName: "add-production-order" */ '@/views/production-management/production-order/add-production-order/add-production-order.vue'
            ),
          meta: {
            title: 'route.addProductionOrder',
            id: 961,
            hidden: true,
            parentId: 931,
            noCache: true
          }
        },
        {
          path: '/production-order-detail',
          name: 'ProductionOrderDetail',
          component: async () =>
            import(
              /* webpackChunkName: "production-order-detail" */ '@/views/production-management/production-order/production-order-detail/production-order-detail.vue'
            ),
          meta: {
            title: 'route.productionOrderDetail',
            id: 939,
            hidden: true,
            parentId: 931,
            noCache: true
          }
        }
      ]
    },

    {
      path: '/production-order-change',
      name: 'ProductionOrderChange',
      component: async () =>
        import(
          /* webpackChunkName: "production-order-change" */ '@/views/production-management/production-order-change/production-order-change.vue'
        ),
      meta: {
        title: 'route.productionOrderChange',
        id: 932
      },
      children: [
        {
          path: '/production-order-change-detail',
          name: 'ProductionOrderChangeDetail',
          component: async () =>
            import(
              /* webpackChunkName: "production-order-change-detail" */ '@/views/production-management/production-order-change/production-order-change-detail/production-order-change-detail.vue'
            ),
          meta: {
            title: 'route.productionOrderChangeDetail',
            id: 990,
            hidden: true,
            parentId: 932,
            noCache: true
          }
        },
        {
          path: '/add-production-order-change',
          name: 'AddProductionOrderChange',
          component: async () =>
            import(
              /* webpackChunkName: "add-production-order-change" */ '@/views/production-management/production-order-change/add-production-order-change/add-production-order-change.vue'
            ),
          meta: {
            title: 'route.addProductionOrderChange',
            id: 953,
            hidden: true,
            parentId: 932,
            noCache: true
          }
        }
      ]
    }
  ],
  meta: {
    title: 'route.productionManagement',
    icon: '#production',
    id: 930
  }
};
