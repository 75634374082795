import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { WarehouseList, WarehouseQuery, ExchangeRateResource, ImportRes } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';

class WarehouseService {
  public getById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(ResourceUrlEnum.warehouse, { params: { id } })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: ExchangeRateResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<ExchangeRateResource>(ResourceUrlEnum.warehouse, resource)
        .then((res: ApiResponse<ExchangeRateResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: ExchangeRateResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<ExchangeRateResource>(ResourceUrlEnum.warehouse, resource)
        .then((res: ApiResponse<ExchangeRateResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList
   * @returns
   */
  public batchWarehouseDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.warehouse}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<WarehouseQuery>, paging?: Paging): Promise<ApiResponse<Array<WarehouseList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<WarehouseList>>(`${ResourceUrlEnum.warehouse}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<WarehouseList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public getSelectList(
    query?: Partial<{
      name: string;
      code: string;
    }>,
    paging?: Paging
  ): Promise<ApiResponse<Array<WarehouseList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<WarehouseList>>(`${ResourceUrlEnum.warehouse}/listForComponents`, cloneQuery)
        .then((res: ApiResponse<Array<WarehouseList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   *
   * @param time
   * @param sourceCurrencyId
   * @param targetCurrencyId
   * @param id
   * @returns
   */
  // eslint-disable-next-line max-params
  public checkEffectiveTime(
    time: string,
    sourceCurrencyId: number | null,
    targetCurrencyId: number | null,
    id?: number
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.warehouse}/checkEffectiveTime`, {
          params: { id, time, sourceCurrencyId, targetCurrencyId }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修状态
   * @param idList
   * @param status
   * @returns
   */
  public batchUpdateWarehouseStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.warehouse}/editEnableStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 审批
   * @param idList
   * @returns
   */
  public batchWarehouseApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.warehouse}/approve`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 反审批
   * @param idList
   * @returns
   */
  public batchWarehouseCancelApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.warehouse}/cancelApprove`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载汇率导入模板
   * @returns
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.warehouse}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public import(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.warehouse}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编码去重
   * @param code
   * @param id
   * @returns
   */
  public checkCode(code: string, id?: number | null): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.warehouse}/checkCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 名称去重
   * @param name
   * @param id
   * @returns
   */
  public checkName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.warehouse}/checkName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const warehouseService = new WarehouseService();
export default warehouseService;
