import router, { resetRouter } from './router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Route } from 'vue-router';
import { axiosRequest } from './utils/axios-request';
import { UserModule } from './store/modules/user';
import { PermissionModule } from './store/modules/permission';
import i18n from './lang';
import { authService } from './api';
import { isJsonStr, messageError } from './utils';
import { MessageCenterModule } from './store/modules/message';
import { MessageResource } from './resource/model';
import { MessageTypeEnum } from './resource/enum';
import { webSocket } from './utils/websocket';
NProgress.configure({ showSpinner: false });

const whiteList = ['/login', '/mobile-installation-order-detail'];

router.beforeEach(async (to: Route, from: Route, next: any) => {
  // 路由三级不显示处理
  if (to.matched && to.matched.length > 2) {
    to.matched.splice(1, to.matched.length - 2);
  }
  // 开启加载进度条
  NProgress.start();
  axiosRequest.clearPendingRequest();
  // 判断用户是否已经登录
  if (UserModule.token) {
    if (to.path === '/login') {
      // 如果已经登录，就重定向回主页
      next({ path: '/' });
      NProgress.done();
      return;
    }
    // 检查是否已经获取过用户及权限菜单的相关信息
    if (!UserModule.reLoading) {
      next();
      NProgress.done();
      return;
    }
    try {
      const [permissionMenus, permissionButtons, userInfo] = await Promise.all([
        authService.getPermissionMenu(),
        authService.getPermissionButton(),
        authService.getUserInfo()
      ]);
      UserModule.setReLoading(false);
      // 保存用户信息
      UserModule.setUserInfo(userInfo);
      // 基于角色生成可访问路由表
      PermissionModule.GenerateRoutes(permissionMenus);
      resetRouter();
      // 动态添加可访问的路由
      PermissionModule.permissionRoutes.forEach(x => {
        router.addRoute(x);
      });
      PermissionModule.SetPermissionOperations(permissionButtons);
      // 加载消息中心数据
      // 暂时关闭，等消息通知功能就绪后重开
      /* MessageCenterModule.InitUnReadTotal();
      MessageCenterModule.InitNotices();
      MessageCenterModule.InitTodos(); */

      // 建立消息中心websocket连接
      const socketUrl = process.env.VUE_APP_MESSAGE_CENTER_WEBSOCKET;
      // const socketUrl = '';
      if (socketUrl) {
        webSocket.connect({
          key: 'messageCenter',
          url: socketUrl,
          onMessage: (evt: any): void => {
            if (!isJsonStr(evt.data)) {
              return;
            }
            const message: MessageResource = JSON.parse(JSON.parse(evt.data).body.content);
            message.type === MessageTypeEnum.notice
              ? MessageCenterModule.AddNotice(message)
              : MessageCenterModule.AddTodo(message);
          }
        });
      }

      // Hack: 确保添加的路由表是完整的,这么做的原因是 router.addRoutes之后的next()可能会失效，因为可能next()的时候路由并没有完全add完成
      // 设置replace: true，这样导航就不会留下历史记录
      next({ ...to, replace: true });
    } catch (error) {
      messageError(error);
      // 若查询用户信息失败，则清除token，并重定向到登录页面
      UserModule.clearToken();
      next('/login');
      // next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
    next();
    NProgress.done();
    return;
  }
  // Has no token
  if (whiteList.indexOf(to.path) !== -1) {
    // 如果在免登录的白名单中，则直接进入
    next();
    NProgress.done();
    return;
  }
  // 其他没有访问权限的页面被重定向到登录页面。
  // next(`/login?redirect=${to.path}`);
  next('/login');
  NProgress.done();
});

router.afterEach((to: Route) => {
  // Finish progress bar
  NProgress.done();
  // set page title
  if (to.meta?.title) {
    document.title = `智印联盟-${i18n.t(to.meta.title)}`;
  }
});
