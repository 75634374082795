import { FinanceInvoiceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  SaveApplyChangeInvoice,
  FinanceInvoiceInfo,
  FinanceInvoiceListQuery,
  FinaneInvoiceList,
  InvoiceEditForm
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';

class FinanceInvoiceService {
  public getById(id: number): Promise<FinanceInvoiceInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<FinanceInvoiceInfo>(ResourceUrlEnum.financeInvoice, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<FinanceInvoiceListQuery>,
    paging: Paging,
    sortOptions: SortOptions<FinaneInvoiceList>
  ): Promise<ApiResponse<Array<FinaneInvoiceList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<FinaneInvoiceList>>(`${ResourceUrlEnum.financeInvoice}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 开票
   * @param idList
   * @param reason
   * @returns
   */
  public makeInvoice(params: Array<{ billId: number; id: number; invoiceNo: string }>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.financeInvoice}/invoicing`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public export(query?: Partial<FinanceInvoiceListQuery> & { idList: Array<number> }): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.financeInvoice}/exportData`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 申请换开
   * @param invoice
   * @returns
   */
  public applyChangeInvoice(newInvoice: SaveApplyChangeInvoice): Promise<number> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.financeInvoice}/exchangeInvoice`, newInvoice)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取发票类型
   * @returns
   */
  public getStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in FinanceInvoiceStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `financeInvoiceStatus.${key}`,
          value: FinanceInvoiceStatusEnum[key]
        });
      }
    }
    return options;
  }

  public editInvoiceInfo(form: InvoiceEditForm): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.financeInvoice}/invoicing`, form)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量删除文件
   * @param idList 门店发货明细id
   * @returns
   */
  public batchFileDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.financeInvoice, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }
}

const financeInvoiceService = new FinanceInvoiceService();
export default financeInvoiceService;
