/**
 * 用量类型
 */
export enum ReceiveModeEnum {
  /**
   * 普通领料
   */
  ORDINARY,
  /**
   * 倒冲领料
   */
  BACKFLUSH,
  /**
   * 不领料
   */
  NO
}
