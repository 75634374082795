export default {
  route: {
    login: 'Login',
    dashboard: 'Dashboard',
    avatarUpload: 'Avatar Upload',
    backToTop: 'Back To Top',
    i18n: 'I18n',
    personalSetting: 'Personnel setting',
    systemSetting: 'System setting',
    platformPermission: 'Platform permission',
    supplierPermission: 'Supplier permission',
    workflowDesign: 'Workflow design',
    organization: 'Organization',
    department: 'Department management',
    personnel: 'Personnel management',
    position: 'Position management',
    role: 'Role management',
    product: 'Product management',
    productList: 'Product list',
    productClassify: 'Product classify',
    // 基础数据
    database: 'Database',
    deviceClassify: 'Device classify',
    invoice: 'Invoice info',
    storeLocation: 'Store location',
    inventoryType: 'Inventory type',
    warehouse: 'Warehouse',
    warehouseDetails: 'Warehouse details',
    warehouseStore: 'Warehouse store',
    materialList: 'Material list',
    addMaterial: 'Add Material',
    detailsMaterial: 'Material Details',
    billOfMaterial: 'BoM',
    materialClassification: 'Material classification',
    craft: 'Craft management',
    output: 'Output craft',
    backend: 'Backend craft',
    prepressCrafts: 'prepress craft',
    customer: 'Customer management',
    customerList: 'Customer list',
    customerClassify: 'Customer classify',
    customerTransfer: 'Customer transfer',
    customerAllocationList: 'Customer allocation list',
    // 客户
    sellPrice: 'Sell management',
    shop: 'Shop management',
    point: 'Point management',
    shopTag: 'Shop tag',
    pointTag: 'Point tag',
    contacts: 'Contact management',
    // 供应商
    supplier: 'Supplier management',
    supplierList: 'Supplier List',
    supplierClassify: 'Supplier classify',
    printingMaterial: 'Printing material',
    backendMaterial: 'Backend material',
    // 客户结束
    supplierLevel: 'Supplier level',
    supplierProduct: 'Purchase',
    supplierDevice: 'Device',
    // 项目管理
    project: 'Project management',
    projectDetails: 'Project details',
    projectList: 'Project list',
    projectShopDetails: 'Project shop details',
    consumeManagement: 'Consume management',
    noDataCanOperate: 'No data to modify!',
    selectedItemsSettled: 'Items settled or in settlement',
    projectToDo: 'Project to do',
    saleDetails: 'Sale Details',
    // 项目项次
    shopCannotFind: 'Shop cannot find',
    shopRepeatUse: 'Shop repeat use',
    importDataError: 'Please input required columns',
    deliveryDateError: 'Plan delivery date cannot earlier than current time',
    deliveryMethodError: 'Please input delivery method',
    // 生产管理
    productionManagement: 'Production Management',
    productionOrder: 'Order List',
    productionOrderChange: 'Order Change Log',
    productionOrderDetail: 'Order Detail',
    addProductionOrderChange: 'New Order Change Log',
    editProductionOrderChange: 'Edit Order Change Log',
    shippingNotice: 'Shipping Notice',
    addProductionOrder: 'Production Order',
    distributeOrder: 'Add Order',
    productionOrderChangeDetail: 'Production Order Change Detail',
    productRepeatUse: 'Product repeat use',
    // 印前任务
    prepressTask: 'Prepress task',
    mineTask: 'Mine task',
    mineFinishedProductTask: 'Mine Finished product Task',
    mineChangeColorTask: 'Mine Change Color Task',
    mineTypesettingTask: 'Mine Typesetting Task',
    changeColorTask: 'Change color task',
    completeManuscriptTask: 'Complete manuscript task',
    colorChangeSample: 'Color Change Sample management',
    messageCenter: 'Message center',
    addChangeColorSample: 'Change color sample',
    batchAddChangeColorSample: 'Batch new color change samples',
    colorChangeSampleDetails: 'Change color sample details',
    // 物流管理
    logisticsManagement: 'Logistics management',
    logisticsDetails: 'Logistics details',
    addLogistics: 'Add logistics',
    logistics: 'Logistics list',
    deliveryNotice: 'Delivery notice list',
    deliveryNoticeDetails: 'Delivery notice details',
    addDeliveryNotice: 'Delivery notice',
    // 安装管理
    installationManagement: 'Installation management',
    installationNotice: 'Installation notice list',
    installationNoticeDetail: 'Installation notice detail',
    addInstallationNotice: 'Installation notice',
    installationOrder: 'Installation order list',
    addInstallationOrder: 'Add installation Order',
    installationOrderDetail: 'Installation order detail',
    mobileInstallationOrder: 'Installation order',
    batchEditPrice: 'Batch edit price',
    editInstallationOrder: 'Edit installation order',
    serviceItem: 'Service items',
    commonTags: 'Common tags',
    installationAcceptance: 'Installation acceptance',
    // 财务管理
    todo: 'Todo',
    financeManagement: 'Finance management',
    financeInvoice: 'Finance invoice',
    billing: 'Billing management',
    transactionDetails: 'Transaction details',
    addBilling: 'Billing',
    billingDetails: 'Billing details',
    invoiceDetails: 'Invoice details',
    messageConfig: 'Message config',
    projectConfig: 'Project config',
    // HM专项模块
    hmModule: 'HM专项',
    hmPackaging: '包装管理',
    hmOrder: '订单管理',
    // 公共数据
    publicData: 'Public data',
    region: 'region',
    unitMeasure: 'Unit Measure',
    currencyManagement: 'Currency Management',
    unitsMeasureGroups: 'Units Measure Groups',
    exchangeRate: 'Exchange Rate',
    // 反馈建议
    feedbackSuggestion: 'Feedback Suggestion',
    feedbackSetting: 'Feedback Setting',
    feedbackSuggestionList: 'Feedback Suggestion List',
    addFeedbackSuggestion: 'Submit Feedback Suggestion',
    myFeedbackSuggestiont: 'My Feedback Record',
    // 任务中心
    taskCenter: 'Task Center',
    todoTask: 'Todo Task',
    completeTask: 'Complete Task',
    initiatedTask: 'Initiated Task',
    ccTask: 'Cc Task',
    taskDetails: 'Task Details'
  },
  common: {
    status: 'Status',
    selectStatus: 'Please select status',
    createTime: 'Create time',
    startTime: 'startTime',
    endTime: 'endTime',
    operation: 'Operation',
    menu: 'Menu',
    button: 'Button',
    startUsing: 'Using',
    disabled: 'Disabled',
    receiptNumber: 'Receipt number',
    inputReceiptNumber: 'Please enter receipt number',
    createUser: 'Creator',
    usingSuccess: 'Using successful',
    disabledSuccess: 'Disabled successful',
    man: 'Man',
    woman: 'Woman',
    remark: 'Remark',
    inputRemark: 'Please fill in the remarks',
    unKnownStatus: 'UnKnown Status',
    yuan: 'yuan',
    repair: 'Repairing',
    statusUpdateStatus: 'Status updated',
    createUserName: 'Creator',
    inputCreateUserName: 'Please enter create user name',
    startPlaceholder: 'Begin Date',
    endPlaceholder: 'End Date',
    moreOpetations: 'More operations',
    selectMaxDataTip: 'You can select a maximum of one piece of data',
    selectminDataTip: 'You must choose at least one piece of data',
    keepDataTip: 'You keep at least one piece of data',
    unknown: 'undefined',
    baseInfo: 'Base info',
    details: 'Details',
    index: 'Index',
    waitingUpload: 'Waiting upload',
    clickToPreview: 'Click to preview',
    fileUpload: 'File upload',
    notImages: 'No images',
    inputLegalTel: 'Please fill in a legal phone number',
    yes: 'Yes',
    no: 'No',
    select: 'Please select',
    input: 'Please input',
    repeat: 'repeat',
    codeRepeat: 'Code repeat',
    nameRepeat: 'Name repeat',
    submitTip: 'You have a required item that has not been filled in. Please fill in the form information completely!',
    read: 'Read',
    unread: 'Unread',
    revoke: 'Revoke',
    notGenerate: 'Not generate',
    generated: 'Generated',
    generating: 'Generating',
    generationFailed: 'Generation Failed',
    approveStatus: 'Approve status',
    useTip: 'Use tip',
    clickToSee: 'Click to see'
  },
  time: {
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds'
  },
  datePicker: {
    startPlaceholder: 'Begin date',
    endPlaceholder: 'End date'
  },
  navbar: {
    logout: 'Logout',
    dashboard: 'Dashboard',
    profile: 'Profile'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  table: {
    loadingText: 'loading...',
    summaryText: 'Sum'
  },
  button: {
    cancel: 'Cancel',
    ok: 'Ok',
    submit: 'Submit',
    add: 'Add',
    search: 'Search',
    edit: 'Edit',
    delete: 'Delete',
    reset: 'Reset',
    using: 'Using',
    disabled: 'Disabled',
    import: 'Import',
    select: 'Select',
    confirmSelect: 'Confirm select',
    confirmImport: 'Import',
    packUp: 'Pack up',
    more: 'More',
    unfold: 'Unfold',
    fold: 'Fold',
    checkAll: 'Check all',
    clearAll: 'Clear all',
    back: 'Back',
    export: 'Export',
    exportDetail: 'Export documents detail',
    repairing: 'Repairing',
    download: 'Download',
    ClickDownload: 'Click Download',
    batchCancel: 'Batch Cancel',
    batchDelete: 'Batch Delete',
    confirmComplete: 'Confirm Complete',
    distributeOrder: 'Distribute',
    nextStep: 'Next',
    prevStep: 'Prev',
    submitAndSend: 'Submit And Send',
    submitApproval: 'Submit Approval',
    approval: 'Approval',
    close: 'Close',
    copy: 'Copy',
    confirmUpload: 'Confirm upload',
    save: 'Save',
    saveAndPush: 'Save and Push',
    push: 'Push',
    approve: 'Approve',
    reverseApprove: 'Reverse approve',
    agree: 'agree',
    reject: 'Reject',
    upload: 'Upload',
    batchUpdate: 'Batch update',
    change: 'change',
    prevIndividual: 'previous',
    nextIndividual: 'next',
    selectAnnex: 'Select annex'
  },
  operationRes: {
    cancelDelete: 'Canceled delete',
    addSuccess: 'Added successfully',
    editSuccess: 'Edited successfully',
    deleteSuccess: 'Deleted successfully',
    addFailed: 'Failed to add',
    editFailed: 'Fail to edit',
    deleteFailed: 'Failed to delete',
    cancelReset: 'Cancel reset',
    resetSuccess: 'Reset success',
    operationCanceled: 'Operation Canceled',
    cancelOperation: 'Operation cancelled',
    operationSuccess: 'Operation success',
    saveSuccess: 'Save success',
    submitSuccess: 'Submit success',
    operationFailed: 'Operation failed',
    setSuccess: 'Set success ',
    setFailed: 'Set failed',
    uploadSucess: 'Upload Sucess',
    uploadFailed: 'Upload Failed'
  },
  tip: {
    tipInfo: 'prompt message',
    closeTip: 'Are you sure you want to close it?',
    confirmDelete: 'Are you sure you want to delete the selected data?',
    resetPassword: 'Make sure to reset user password to',
    confirmTitle: 'Confirmation message',
    confirmReload: 'Please confirm whether you need to reload the page information?',
    stayCurrentPage: 'Stay on the current page',
    confirmCancel: 'Are you sure to cancel?',
    beforeLeave: 'The information in the current page has not been saved, are you sure you want to leave?',
    confirmApprovalSuccess: 'Confirm to approve success?',
    confirmPush: 'Are you sure you want to push the selected data?',
    noWaitApproveData: "Please select the data waiting for approveal",
    noApprovedData: "Please select approved data",
    noEnableData: "Please select enable data"
  },
  components: {
    changeLanguageTips: 'Switch Language Success',
    selectRegionTip: 'Please select region',
    selectCustomer: 'Please select a customer'
  },
  minio: {
    initError: 'The service is temporarily unavailable. Please check the network status and try again!',
    bucketNotExist: 'Template file not found, please contact the administrator!'
  },
  lodop: {
    printUnInstallTip:
      'You have not installed the print control, click download the print control, and after successful installation, refresh the page to print. ',
    loading: 'The print control is not ready yet, please try again later!'
  },
  dialog: {
    selectPosition: 'Select position',
    selectPersonnel: 'Select personnel',
    downloadTemplate: 'Download template',
    importPersonnel: 'Import personnel',
    importClassify: 'Import classify',
    importLoadingText: 'loading...',
    importSuccess: 'Import success',
    importErrorTip: 'The failure message is as follows:',
    importErrorRow: 'Number of failed lines：',
    importErrorReason: 'Reasons for failure：',
    importPlatformProduct: 'Import platform product',
    importCraft: 'Import craft',
    importCustomer: 'Import customer ',
    importInvoice: 'Import invoice info',
    importTaxClassify: 'Import tax classification',
    importCustomerShop: 'Import customer shop',
    importCustomerPoint: 'Import shop data',
    importSupplier: 'Import supplier data',
    importExchangeRate: 'Import exchange rate data',
    importUnitMeasure: 'Import unit of measure data',
    uploadSuccess: 'Upload success',
    saveFailed: 'Save failed'
  },
  validate: {
    mobileIllegal: 'The phone number is illegal'
  },
  requestError: {
    // 公共异常
    unknownError: 'System error',
    _10001: 'Parameter is invalid',
    _10002: 'Parameter cannot be empty',
    _10004: 'File download failed',
    _10003: 'File read failed',
    _10005: 'Data import failed',
    _10006: 'The name already exists',
    _10007: 'No data in file',
    _10008: 'There is no data to export',
    _10009: 'Keyword present',
    _10010: 'sql sort keywords error',
    _10011: 'signature error',
    _10012: 'The workflow diagram does not exist',
    _10013: 'Number format error, please check',
    _10014: 'Data frozen, cannot process this operation',
    _10015: 'Data approved, cannot process this operation',
    _10016: 'The required field is empty',
    _10017: 'Existing downstream data',
    _10018: 'Number already exists',
    _10019: 'This data is not allowed to be deleted',
    _10020: 'Data not allowed to be submitted',
    _10021: 'Data does not allow cancellation',
    _10022: 'Data not allowed to be modified',
    _10023: 'Data duplication',
    _10024: 'Mobile phone number error',
    _10025: 'Data not approve, cannot process this operation',
    _10026: 'Column data format error',
    _10027: 'Confirm if the template is the latest template or download it again',
    _4: 'Timeout',
    _42301: 'Access too fast, please try again later!',

    // 登录模块
    _20101: 'User not logged in',
    _20102: 'Incorrect user name or password',
    _20103: 'The account has been disabled',
    _20104: 'No permission to operate',
    _20105: 'Login information is invalid. Please log in again!',
    _20106: 'Login information is invalid. Please log in again!',
    _20107: 'Login information is invalid. Please log in again!',
    _20108: 'The verification code is empty',
    _20109: 'Operation failed',
    _20110: 'Verification code expired',
    _20111: 'Failed to send a verification code',
    _20112: 'The email address has not been linked to any account',
    _20113: 'Company domain name does not exist',
    _20122: 'Password reset failed',
    _20123: 'The password format does not meet the requirements',
    _20114: 'Password decryption failure',
    _20115: 'Login timeout, please log in again',
    _20116: 'The phone number does not exist. Please contact the administrator',

    // 用户模块
    _20201: 'Invalid account',
    _20202: 'Password error',
    _20203: 'User name already exists',
    _20204: 'Email duplicate',
    _20205: 'The phone number is already in use',
    _20206: 'Password reset failed',
    _20207: 'Administrator does not allow deletion',

    // 物料分类管理
    _20301: 'Bound data, do not delete!',
    _20302: 'Existing downstream classification data',

    // 资源模块
    _20401: 'The resource name already exists',
    _20402: 'Delete all child resources first',
    _20403: 'The parent resource does not exist',

    // 岗位管理
    _20503: 'Delete failed, there is a user bound to the post',
    _20502: 'The position name already exists',

    // 部门管理
    _20601: 'Duplicate Department Name',
    _20602: 'The superior department does not exist',
    _20603: 'Job data has been bound',
    _20604: 'There are subordinate departments in this department. Please delete subordinate departments first',

    // 设备分类管理
    _20701: 'Duplicate device classification name',
    _20702: 'Device classification is empty',
    _20703: 'Data bound, please do not delete',

    // 客户分类
    _20801: 'Existing bound data',

    // 客户管理模块
    _20901: 'The domain name is already occupied',
    _20902: 'Enterprise classification does not exist',
    _20903: 'No query permission',
    _20904: 'Enterprise information does not exist',

    // 客户产品
    _21001: 'There has same product',
    _21002: 'The production categories  must be the same!',
    _21003: 'Pricing unit does not exist or approval failed',
    _21004: 'Customer product not exist',
    _21005: 'Customer product not available',
    _21006: 'Customer product not exist or available',

    // type 产品管理
    _21101: 'Basic unit does not exist or has been disabled！',
    _21102: 'Material classification does not exist or has been disabled!',
    _21103: 'Inventory category does not exist or has been disabled!',
    _21104: 'Inventory unit does not exist or has been disabled!',
    _21105: 'Auxiliary unit does not exist or has been disabled!',
    _21106: 'Warehouse does not exist or has been disabled!',
    _21107: 'store does not exist or has been disabled!',
    _21108: 'Sales unit does not exist or has been disabled!',
    _21109: 'Sales base unit does not exist or has been disabled!',
    _21110: 'The purchasing unit does not exist or has been disabled!',
    _21111: 'The purchasing basic unit does not exist or has been disabled!',
    _21112: 'Production unit does not exist or has been disabled!',
    _21113: 'The production warehouse does not exist or has been disabled!',
    _21114: 'Production bin does not exist or has been disabled!',
    _21115: 'Inventory warehouse positions not associated!',
    _21116: 'Production warehouse positions not associated!',
    _21117: 'Material does not exist!',
    _21118: 'Production has started, the production process cannot be completely closed!',

    // 发票相关异常
    _21201: 'Invoice type does not exist',
    _21301: 'Abnormal tax rate',

    // 门店管理
    _21401: 'Shop does not exist',
    _21402: 'Shop name is empty',
    _21403: 'Duplicate shop name',
    _21404: 'Duplicate shop label',
    _21405: 'Shop number does not exist',
    _21406: 'Shop address parsing error',
    _21407: 'Shop detailed address is empty',
    _21408: 'Shop resolution detailed address is empty',
    _21409: 'Shop receiving phone error',
    _21410: 'Shop phone error',
    _21411: 'Incorrect shop label format or duplicate label values',
    _21412: 'Shop name exceeds 30 characters',
    _21413: 'Address matching failed',

    // 点位标签
    _21501: 'Point name values are duplicated',
    _21502: 'Incorrect format of point labels or duplicate label values',
    _21503: 'The point number does not exist',
    _21504: 'The point status is empty',
    _21505: 'Visible height is empty or 0',
    _21506: 'Visible width is empty or 0',
    _21507: 'The completion width is empty or 0',
    _21508: 'The completion height is empty or 0',
    _21509: 'Customer product is empty',
    _21510: 'The point does not exist',
    _21511: 'The point already exists and does not belong to the current store',
    _21512: 'Customer product does not exist',
    _21513: 'Point lable values are duplicated',
    _21514: 'Inconsistent data',

    // Minlo错误
    _21601: 'The miniO configuration information does not exist!',
    _21602: 'Processor does not exist',

    // 工艺管理
    _21701: 'The craft name already exists',

    // 角色管理
    _21801: 'The role name already exists',
    _21802: 'This role has been used in the position',

    // 供应商级别
    _21901: 'Sequence repeat',
    _21902: 'Supplier level does not exist',

    // 供应商管理
    _22001: 'Supplier level does not exist or is not granted permission',
    _22002: 'Supplier does not exist',

    // 供应商设备
    _22201: 'Device classification does not exist',
    _22202: 'Unknown state',

    // 供应商产品
    _22301: 'The platform product does not exist!',
    _22302: 'Supplier product does not exist',
    _22303: 'Supplier product already exists',
    _22304: `Supplier product has not started production process`,

    // 快递100
    _22401: 'Data query failed',
    _22402: 'Data subscription failed',
    _22403: 'Electronic ticket request error',

    // 项目管理
    _22501: 'Project does not exist',
    _22502: 'The data has not reached the completion stage',

    // 项次管理
    _22601: 'The current data has not yet reached the revision stage!',
    _22602: 'The current data has not yet reached the finalization stage!',
    _22603: 'The current data has not yet reached the submit stage!',
    _22604: 'Cannot be modified in this state!',
    _22605: 'The item type cannot be modified',
    _22606: 'Customer product does not exist',
    _22607: 'The shipment time cannot be earlier than today',
    _22608: 'The delivery time cannot be earlier than today',
    _22609: 'The arrival time cannot be earlier than the shipment time',
    _22610: 'Visible width greater than finished width',
    _22611: 'Duplicate item number',
    _22612: 'Visible height greater than completed height',
    _22613: `There is no binding relationship between the product name and the customer's product!`,
    _22614: 'The item has been scheduled for production or cannot be modified before submission for printing!',
    _22615: 'The item has been scheduled and cannot be modified!',
    _22616: 'The item does not exist or the shipment switch has not been activated',
    _22617: 'Pricing unit does not exist!',
    _22618: 'This data has not reached the order receiving stage',
    _22619: 'The data has not reached the submission and finalization stage',
    _22620: 'The data has not yet reached the completion stage!',
    _22621: 'Data cannot be cancelled in this state',
    _22622: 'There is no data to modify!',
    _22623: 'The amount of consumption has been exceeded!',
    _22624: 'The current prepress status of the item does not require reverse completion',
    _22625: 'The item is currently completed',
    _22626: 'No matching document',
    _22627: 'Document status is change color，no need to upload',
    _22628: 'The production quantity is less than the shipped quantity',
    _22629: 'Production quantity is less than installed quantity',
    _22630: 'The production quantity is less than the settled quantity',
    _22631: 'Installed, unable to turn off the installation switch',
    _22632: 'The shipment switch cannot be closed after it has been shipped',
    _22633: 'ItemCode not found',
    _22634: 'Data not in archived state',
    _22635: 'The data has not reached the upload confirmation stage',
    _22636: 'The delivery time or arrival time is empty',
    _22637: 'Item closed',
    _22638: 'The item type can not open delivery switch',
    _22639: 'The item type can not open installtion switch',
    _22640: 'Design typesetting and production documents cannot be closed at all',
    _22641: 'The item associated production, please contact customer service and re-operate after order cancel',
    _22642: 'The item is not in production',
    _22643: 'Current user not processor, can not do this',

    // 生产订单
    _22701: 'Production order not exist',
    _22702: 'Production order status not 「New」',
    _22703: 'Production order status not 「Waiting confirm」',
    _22704: 'Production order status not 「Waiting accept」',
    _22705: 'Production order status not 「Waiting schedule」',
    _22706: 'Production order status not 「In progress」',
    _22707: 'Production order detail not exist',
    _22708: 'Production has been issued and all production processes cannot be cancelled',

    // 生产单据管理
    _22801: 'Production document does not exist',
    _22802: 'The document is currently being prepared',
    _22803: 'Document acceptance failed',
    _22804: 'Document completion failed',
    _22805: 'The document sampling company does not exist',
    _22806: 'Do not allow rollback to an unopened process',
    _22807: 'Platform prepress not completed',
    _22808: 'LAYOUT image creation failed',
    _22809: 'LAYOUT order does not exist',
    _22810: 'The document process cannot be empty',
    _22811: 'Process error, please switch roles',
    _22812: 'Platform pre press confirmation not opened',
    _22813: 'There is a change order for the item number',

    // 发货通知单
    _22901: 'The shipment order cannot be pushed in this status',
    _22902: 'The status of the shipment order cannot be completed',
    _22903: 'The status of the shipment order cannot be deleted',
    _22904: 'The status of the shipment order cannot be cancelled',
    _22905: 'The status of the shipment order cannot be confirmed',
    _22906: 'Store does not exist',
    _22907: 'Product details do not exist',
    _22908: 'Supplier does not exist',
    _22909: 'The shipment quantity has exceeded the remaining production quantity',
    _22910: 'The status of the shipment order cannot create a logistics order',
    _22911: 'The delivery notice does not exist',
    _22912: 'Store is empty',
    _22913: 'Store duplication',
    _22914: 'The delivery notice contains a logistics note and cannot be cancelled',
    _22915: 'The status of the shipment order cannot be undone',
    _22916: 'The shipment phase has not been opened for this item',
    _22917: 'The number of packages exceeds the maximum value',

    // 生产订单变更单
    _23001: 'Change log can not to push, because the status not 「new」',
    _23002: 'Change log can not to confirm, because the status not 「waiting confirm」',
    _23003: 'The order already have change log which not execution, do not create repeat',
    _23004: 'Change log not exist',
    _23005: 'Change product not exist',
    _23006: 'The production order is in a "pending confirmation" or "completed" state and cannot be changed',
    _23007: 'Change log detail not exist',
    _23008: 'This product does not require production',

    // 物流单管理
    _23101: 'The document has not arrived at the receiving stage',
    _23102: 'The document is not in the shipping stage',
    _23103: 'Logistics tracking subscription cannot be performed for logistics orders in this state',
    _23104: 'The current state does not allow closing',
    _23105: 'The logistics tracking number does not exist',
    _23106: 'The logistics order number is empty',
    _23107: 'The logistics order does not exist or has been shipped',
    _23108: 'Shipper is empty',
    _23109: 'The contact information of the shipper is empty',
    _23110: 'The shipping province is empty',
    _23111: 'The shipping city is empty',
    _23112: 'The shipping area/county is empty',
    _23113: 'The shipping template does not exist',
    _23114: 'The detailed shipping address does not exist',
    _23115: 'The logistics tracking number does not exist',
    _23116: 'The logistics company does not exist',
    _23117: 'The logistics tracking number does not exist',
    _23118: 'The logistics order cannot be deleted in this state',
    _23119: 'The courier company does not exist',
    _23120: 'The shipping method of the logistics bill does not belong to third-party logistics',
    _23121: 'Logistics order query failed',
    _23122: 'The logistics order cannot be edited in this status',
    _23123: 'The logistics order cannot be cancelled in this state',
    _23124: 'Weight not filled in',
    _23125: 'Freight not filled in',

    // 安装通知单
    _23202: 'Notice cannot be submitted',
    _23201: 'Installation notice does not exist',
    _23203: 'Installation notice cannot be deleted',
    _23204: 'Installation notice cannot be approved',
    _23205: 'Installation notice cannot be de approved',
    _23206: 'Installation notice cannot be completed',
    _23207: 'Installation notice cannot be canceled',
    _23407:
      'There is currently no installation quantity or product data is empty in the installation single product data',
    _23208: 'Install notice item count exceed',
    _23209: 'The installation list does not operation',
    _23214: 'The completion date is empty or earlier than the current time',

    // 电子面单
    _23301: 'The courier company does not support electronic waybills',
    _23302: 'Monthly settlement account is empty',

    // 安装单
    _23401: 'Installation order does not exist',
    _23402: 'The current state of the installation order cannot be deleted!',
    _23403: 'The installation order appointment time cannot be earlier than the current time!',
    _23404: 'The status of the current installation order is not being installed!',
    _23405: 'The current installation order status is not pending review!',
    _23406: 'The installation list is not empty!',
    _23408: 'The current state of the installation order cannot be submitted!',
    _23409: 'The current state of the installation list cannot be edited!',
    _23410: 'The current state of the installation order cannot be completed!',
    _23411: 'The current status of the installation order cannot be rejected!',
    _23412: 'The installation list does not currently have a completed photo!',
    _23413: 'The current status of the installation order cannot be assigned!',
    _23414: 'This order does not belong to the current operator!',
    _23415: 'The installation order cannot currently perform this operation!',
    _23416: 'The current status of the installation order cannot be submitted for approval!',
    _23417: 'The installation order currently does not have a signed photo!',

    // 结算单
    _23501:
      'The total untaxed amount of the settlement details is inconsistent with the total untaxed amount of the invoice!',
    _23502: 'Unfilled settlement details',
    _23503: 'Invoice information not filled in',
    _23504: 'Statement is not in a new state',
    _23505: 'The settlement statement is not in approval status',
    _23506: 'The number of settlements has been exceeded!',
    _23507: 'The billing item does not exist!',
    _23508: 'Cannot write off in this state',
    _23509: 'The settlement statement does not exist',
    _23510: 'The verification amount is greater than the unverified amount, verification failed!',
    _23511: 'The amount of this cancellation exceeds the amount already written off,',
    _23512: 'The settlement item does not exist or does not belong to the customer!',
    _23513: 'Settlement item repetition',
    _23514: 'This status does not allow for the cancellation of the closing',
    _23515: 'The invoice status is not in the new state',
    _23516: 'There is data under review',
    _23517: 'Do not save bill repeat',

    _23602: 'Data not in archive status',

    // 交易管理
    _23701: 'Deletion is not allowed in the current state',
    _23702: 'Customer does not exist',

    // HM专项
    _23901: 'Order not exist',
    _23902: 'No product tag can export',
    _23903: 'No match are config',
    _23904: 'Please export PSL first',
    _23905: 'Box number repeat',
    _23906: 'No match box',
    _23907: 'No match pallet',
    _23908: 'No related products were matched',

    // 耗损
    _24001: 'The item associated with this wear and tear data is in the production state and cannot be deleted',

    // 安装工人管理
    _24101: 'Mobile phone number is heavy',
    _24105: 'Remarks exceeding 30 characters',
    _24107: 'No modification permission',
    _24108: 'The required field is empty',
    _24109: 'Illegal filling of service items',

    // 项目门店管理
    _24301: 'The shipment quantity cannot be greater than the sales quantity!',
    _24302: 'The relevant store does not exist',
    _24303: 'Repeated shop filling',
    _24304: 'The required data in the form is not fully filled out',
    _24305: 'The planned shipment time needs to be later than the current time',
    _24306: 'Delivery method not filled in',
    _24307: 'There is no deliverable item data for the current project',
    _24308: 'No shipment items available',

    // 反馈建议
    _24601: 'The submission permission has been closed. Please contact the administrator!',
    _24602: 'The submission failed to exceed the time interval',
    _24603: "Today's submission has exceeded the upper limit",

    // 货币管理
    _24701: 'The code already existst',
    _24702: 'The name already existst',
    _24703: 'The currency code already existst',
    _24704: 'Deleting approved data is not allowed',
    _24705: 'Data frozen',
    _24706: 'Existing downstream data',
    _24707: 'System preset data is not allowed to operate',

    // 税率管理
    _24801: 'The target currency is duplicated with the original currency',
    _24802: 'Effective time overlap',
    _24803: 'Deleting approved data is not allowed',
    _24804: 'Currency does not exist',
    _24805: 'Invalid effective range',

    // 计量单位管理

    _24901: 'Base unit cannot be modified',
    _24902: 'Base unit cannot be deleted',
    _24903: 'Benchmark company is not allowed to de-approve and freeze',
    _24904: 'Base unit not allowed to be enabled or disabled',
    _24905: 'Base unit does not exist',
    _24906: 'UoM group does not exist',

    // 计量单位分组管理
    _25007: 'Data does not exist',

    // 改色样
    _25201: 'Data not allowed to be deleted',
    _25202: 'Data not allowed to be submitted',
    _25203: 'Data does not allow cancellation',
    _25204: 'Data not allowed to be update',
    _25205: 'There is no production process for this item',
    _25206: 'This item has been produced',

    // 仓库仓位关系
    _25301: 'Warehouse does not exist or warehouse management is not enabled',
    _25302: 'Warehouse does not exist or warehouse management is not enabled',

    // 物料清单
    _25401: 'BOM version duplicate',
    _25402: 'Default picking warehouse filled in incorrectly',
    _25403: 'Material filling error',
    _25404: 'Inventory data cannot be empty',
    _25405: 'The list parent material cannot be the same as the list material',

    // 其他
    nameRepeat: 'The name already exists',
    rsourceNameRepeat: 'The resource name already exists',
    departmentNameRepeat: 'Duplicate Department Name',
    roleNameRepeat: 'The role name already exists',
    positionNameRepeat: 'The position name already exists',
    userNameRepeat: 'The login account already exists',
    emailRepeat: 'The email already exists',
    mobileRepeat: 'The phone number already exists',
    classifyNameRepeat: 'The classify name already exists',
    productNameRepeat: 'The product name already exists',
    craftNameRepeat: 'The craft name already exists',
    customerNameRepeat: 'The enterprise name already exists',
    domainNameRepeat: 'The enterprise domain name already exists',
    contactNameRepeat: 'The contact name already exists',
    shopTagNameRepeat: 'The store tag already exists',
    supplierNameRepeat: 'The company name has been used',
    supplierDomainNameRepeat: 'The domain name has been used',
    supplierSequenceRepeat: 'Sequence repeat',
    materialNameRepeat: 'Material name has been used',
    materialCodeRepeat: 'Material code has been used',
    deviceNameRepeat: 'Device name has been used',
    shopTagValueRepeat: 'Shop label values are duplicated',
    pointTagValueRepeat: 'Point label values are duplicated',
    billItemRepeat: 'Settlement item repetition',
    proofingRepeat: 'Proofing repeat!',
    boxNumberRepeat: 'Box number repeat',
    queryPlanNameRepeat: 'Query plan name repeat！',
    codeRepeat: 'The code already existst',
    regionCodeRepeat: 'The code already existst',
    regionNameRepeat: 'The name already existst',
    baseExchangeRateCurrencyRepeat: 'Duplicate target currency and original currency',
    baseExchangeRateEffectiveTimeRepeat: 'Effective time overlap',
    baseUnitOfMeasureMentCodeRepeat: 'The code already existst',
    baseUnitOfMeasureMentNameRepeat: 'The name already existst'
  },
  httpRequestError: {
    oftenRequest: 'Requesting too often',
    otherError: 'Abnormal request',
    unAuthorized: 'Unauthorized, please authenticate your identity',
    serverDeniedAccess: 'Server denied access',
    resourceNotFound: 'Resources not found',
    methodDisabled: 'The request method is disabled',
    timeout: 'The request timeout',
    serverError: 'Server internal error',
    serverNotSupport: 'The server does not have the capability to complete the request',
    networkError: 'The request timeout',
    serveDied: 'Service unavailable',
    neworkTimeout: 'Network timeout',
    httpVersionNotSupport: 'The HTTP version is not supported',
    httpCanceled: 'The request has been cancelled',
    craftMustNotNull: 'Craft must not be null',
    backendDataRepeat: 'Backend craft & material exists',
    deliveryCountBeyond: 'The shipment quantity cannot be greater than the sales quantity!'
  },
  accountSetting: {
    basicSetting: 'Basic setting',
    securitySetting: 'Security setting',
    email: 'Email',
    inputEmail: 'Please fill in the email address',
    phone: 'Phone',
    inputPhone: 'Please fill in the contact number',
    avatar: 'Avatar',
    replaceAvatar: 'Replace avatar',
    updateInfo: 'Update information',
    password: 'Account password',
    avatarUploadError: 'Failed to upload avatar'
  },
  messageCenter: {
    notice: 'Notice',
    todo: 'Todo',
    noNotice: 'No notice',
    noTodo: 'No todo'
  },
  login: {
    title: 'Operator Login',
    reLoginTitle: 'Login again',
    logIn: 'Login',
    username: 'Username',
    password: 'Password',
    forgetPassword: 'Forget password',
    rememberPassword: 'Remember password',
    emptyUsername: 'Please enter the correct user name',
    emptyPassword: 'Please enter the correct password',
    passwordLength: 'The password can not be less than 8 digits',
    verifyExplain: 'Swipe to the right to verify',
    verifyTitle: 'Please complete the security verification',
    getVerifyCodeFailed: 'Failed to get the captcha',
    verifySuccessfully: 'verify successfully',
    verifyFailed: 'Verification failed',
    resetPasswordTitle: 'Reset password'
  },
  resetPassword: {
    email: 'Email',
    verifyCode: 'Verification code',
    password: 'New password',
    checkPassword: 'Confirm password',
    sendVerifyCode: 'Send',
    enterEmail: 'Please enter the email address',
    enterVerifyCode: 'Verification code',
    enterPassword: 'Please fill in a new password',
    enterCheckPassword: 'Please confirm the new password',
    emailEmpty: 'Please fill in the email',
    emailError: 'The email address is not valid',
    verifyCodeEmpty: 'Please fill in the verification code',
    passwordEmpty: 'Please fill in your password.',
    passwordError: 'The password format does not meet the requirements',
    checkPasswordEmpty: 'Please enter your password again',
    passwordNotEqual: 'The two passwords do not match',
    resetSuccess: 'Reset success',
    passwordRules: 'Password rules',
    passwordIntegrant: 'The password consists of English, numbers and special characters',
    passwordLength: 'Passwords should be 8-20 characters long',
    passwordMustBe: 'Must contain uppercase letters, special characters'
  },
  platformPermission: {
    addResource: 'New resources',
    editResource: 'Edit resource',
    resourceName: 'Resource name',
    resourceIcon: 'Icon',
    resourceSort: 'Sort',
    resourceType: 'Type',
    resourceCode: 'Permission identify',
    resourceUrl: 'Routing address',
    resourceKeyword: 'Resource keywords',
    inputResourceKeyWord: 'Please enter the resource keywords',
    selectResourceStatus: 'Please select the resource status',
    parentResource: 'Superior resources',
    selectParentResource: 'Please select superior resources',
    selectResourceType: 'Please select the resource type',
    selectResourceIcon: 'Please select the resource icon',
    inputResourceCode: 'Please enter permission ID',
    inputResourceName: 'Please enter a resource name',
    inputResourceUrl: 'Please enter the routing address',
    inputResourceSort: 'Please enter display sorting, such as 1,2,3',
    resourceNameEmpty: 'Resource names are not allowed to be empty',
    resourceNameSpace: 'Spaces are not allowed in resource names',
    resourceCodeEmpty: 'The permission ID is not allowed to be empty',
    resourceUrlEmpty: 'Route addresses are not allowed to be nul',
    resourceSortEmpty: 'Display sort is not allowed to be empty',
    permissionConfig: 'Access config'
  },
  supplierPermission: {
    addResource: 'New resources',
    editResource: 'Edit resource',
    resourceName: 'Resource name',
    resourceIcon: 'Icon',
    resourceSort: 'Sort',
    resourceType: 'Type',
    resourceCode: 'Permission identify',
    resourceUrl: 'Routing address',
    resourceKeyword: 'Resource keywords',
    inputResourceKeyWord: 'Please enter the resource keywords',
    selectResourceStatus: 'Please select the resource status',
    parentResource: 'Superior resources',
    selectParentResource: 'Please select superior resources',
    selectResourceType: 'Please select the resource type',
    selectResourceIcon: 'Please select the resource icon',
    inputResourceCode: 'Please enter permission ID',
    inputResourceName: 'Please enter a resource name',
    inputResourceUrl: 'Please enter the routing address',
    inputResourceSort: 'Please enter display sorting, such as 1,2,3',
    resourceNameEmpty: 'Resource names are not allowed to be empty',
    resourceNameSpace: 'Spaces are not allowed in resource names',
    resourceCodeEmpty: 'The permission ID is not allowed to be empty',
    resourceUrlEmpty: 'Route addresses are not allowed to be nul',
    resourceSortEmpty: 'Display sort is not allowed to be empty',
    permissionConfig: 'Access config'
  },
  department: {
    departmentName: 'Department name',
    sort: 'Sort',
    inputSort: 'Please enter display sorting, such as 1,2,3',
    sortEmpty: 'Display sort is not allowed to be empty',
    parentDepartment: 'Superior department',
    selectParentDepartment: 'Please select superior department',
    inputDepartmentName: 'Please enter department name',
    nameEmpty: 'The department name cannot be blank',
    nameSpace: 'Department names cannot contain Spaces',
    nameRepeat: 'The department name already exists',
    addDepartment: 'New department',
    editDepartment: 'Edit department',
    department: 'Department',
    selectDepartment: 'Please Select Department',
    assign: 'To be assigned department'
  },
  role: {
    addRole: 'New role',
    editRole: 'Edit role',
    name: 'Role name',
    code: 'Role code',
    inputName: 'Please enter a role name',
    inputRemark: 'Please enter remark information',
    nameEmpty: 'The role name cannot be empty',
    permissionAllot: 'Permission allot',
    selectPermission: 'Select the permissions that the role can have',
    role: 'Role',
    selectRole: 'Please select a role'
  },
  position: {
    addPosition: 'Add position',
    editPosition: 'Edit position',
    parentName: 'Higher position',
    name: 'Position name',
    code: 'Position code',
    affiliationDepartment: 'Department',
    selectAffiliationDepartment: 'Please select department',
    inputName: 'Please fill in the position name',
    selectParentPosition: 'Please select a superior position',
    position: 'Position',
    selectPosition: 'Please select position',
    topLevel: 'Top level'
  },
  personnel: {
    addPersonnel: 'Add personnel',
    editPersonnel: 'Edit personnel',
    userName: 'User name',
    realName: 'Real name',
    code: 'User code',
    positonName: 'Position name',
    email: 'Email',
    mobile: 'Mobile',
    inputUserName: 'Please fill in your login account',
    inputRealName: 'Please fill in your real name',
    userNameRepeat: 'The login account already exists',
    emailRepeat: 'Email already exists',
    mobileRepeat: 'The phone number already exists',
    inputEmail: 'Please fill in the email',
    inputMobile: 'Please fill in your phone number',
    selectPosition: 'Please select positon',
    pisition: 'Position(multiple)',
    gender: 'Gender',
    initPassword: 'The user initial password is 123456'
  },
  classify: {
    code: 'Classify code',
    name: 'Classify name',
    packageType: 'Packaging',
    addClassify: 'Add classify',
    editClassify: 'Edit classify',
    tiled: 'tiled',
    reel: 'reel',
    fold: 'fold',
    inputName: 'Please fill in the classify name',
    parentName: 'Parent classify',
    selectParent: 'Select parent'
  },
  deviceClassify: {
    addDeviceClassify: 'Add device classify',
    editDeviceClassify: 'Edit device classify'
  },
  product: {
    addProduct: 'Add product',
    editProduct: 'Edit product',
    productClassify: 'Product classify',
    deviceClassify: 'Device classify',
    code: 'Product code',
    name: 'Product name',
    selectClassify: 'Please select product classify',
    selectDeviceClassify: 'Please select the device classify',
    inputName: 'Please fill in the product name',
    calcUnit: 'Unit',
    selectCalcUnit: 'Select unit',
    printingCraft: 'Printing craft',
    printingMaterial: 'Printing material',
    backendCraft: 'Backend craft',
    backendMaterial: 'Backend material',
    remark: 'Remark',
    operation: 'Operation',
    selectBackendCraft: 'Select backend craft',
    selectBackendMaterial: 'Select backend material',
    selectPrintingCraft: 'Select printing craft',
    selectPrintingMaterial: 'Select printing material',
    productTemplateName: 'product_import_template.xlsx',
    inputLegalName: 'Please enter a legal product name',
    nameRules:
      'The current name only allows Chinese, English, numbers, underscores, backslashes, and cannot start with a number'
  },
  craft: {
    name: 'Craft name',
    code: 'Craft code',
    inputName: 'Please fill in the craft name',
    editCraft: 'Edit craft',
    addCraft: 'Add craft'
  },
  user: {
    editPassword: 'Change password'
  },
  customer: {
    name: 'Enterprise name',
    fullName: 'Enterprise abbreviation',
    domainName: 'Domain name',
    classify: 'Enterprise classify',
    address: 'Address',
    servant: 'Account manager',
    area: 'Area',
    paymentDay: 'Payment day',
    addCustomer: 'Add customer',
    editCustomer: 'Edit customer',
    selectArea: 'Please select your area',
    selectServant: 'Please select Account Manager',
    selectClassify: 'Please select the customer classify',
    inputDomainName: 'Please fill in your domain name',
    inputName: 'Please fill in the enterprise abbreviation ',
    inputFullName: 'Please fill in the enterprise name',
    selectedCustomers: 'Selected customers',
    assign: 'Customer assign ',
    customerName: 'Customer name',
    allocatePersonnel: 'Allocate personnel',
    allocateTime: 'Allocate time',
    cancelAssignment: 'Cancel assignment'
  },
  customerTransfer: {
    describe:
      'When there is a change in members or customers, the enterprise can assign the designated customer data of the designated member to other members to continue to provide services',
    source: 'Customers who need to transfer services',
    target: 'Transfer customer business to',
    transferConfirm: 'Transfer confirm',
    selectCustomer: 'Select customer',
    selectUser: 'Select user',
    confirmTip: 'Are you sure you want to make a customer transfer?'
  },
  invoice: {
    companyName: 'Company name',
    taxCode: 'Tax code',
    taxRate: 'Tax rate(%)',
    invoiceType: 'Invoice type',
    bankName: 'Bank of deposit',
    bankAccount: 'Corporate account',
    address: 'Billing address',
    contactNumber: 'Contact number',
    addInvoice: 'Add invoice info',
    editInvoice: 'Edit invocice info',
    inputCompanyName: 'Please fill in the company name',
    inputTaxCode: 'Please fill in the tax code',
    inputTaxRate: 'Please fill in the tax rate',
    selectInvoiceType: 'Please select invoice type',
    inputBankName: 'Please fill in your bank',
    inputBankAccount: 'Please fill in the corporate account',
    inputAddress: 'Please fill in the billing address',
    inputContactNumber: 'Please fill in your contact number',
    taxRateValid: 'The tax rate is illegal',
    taxRateManagement: 'Tax manage'
  },
  taxRate: {
    taxClassifyRepeat: 'The tax classification name already exists',
    inputClassify: 'Please fill in the tax classification name',
    addTaxRate: 'New tax classification',
    editTaxRate: 'Edit tax classification',
    taxClassify: 'Tax classification'
  },
  customerProduct: {
    platformProduct: 'Platform product',
    selectPlatformProduct: 'Please select the platform product',
    customerProduct: 'Customer product',
    productUnitPrice: 'Unit price',
    addCustomerProduct: 'Add customer product',
    editCustomerProduct: 'Edit customer product',
    inputSellPrice: 'Please fill in the unit price',
    inputWeight: 'Please fill in the gram weight',
    weight: 'Weight',
    taxRateFlag: 'Tax-inclusive',
    sellPrice: 'Sell price',
    chargeUnit: 'Charge unit',
    productTemplateName: 'customer_product_import_template.xlsx'
  },
  contacts: {
    realName: 'Real Name',
    telephone: 'Telephone',
    inputRealName: 'Please fill the real name',
    inputTelephone: 'Please fill the telephone',
    addContact: 'Add Contact',
    editContact: 'Edit Contact',
    telephoneFormatError: 'Allow 8-20 number characters'
  },
  tag: {
    name: 'Tag name',
    tags: 'Tag content',
    inputName: 'Please fill in the tag name',
    tag: 'Tag',
    inputTag: 'Please add tags',
    repeat: 'Tag repeat'
  },
  shopTag: {
    addShopTag: 'Add shop tag',
    editShopTag: 'Edit shop tag'
  },
  pointTag: {
    addPointTag: 'Add point tag',
    editPointTag: 'Edit point tag'
  },
  customerShop: {
    info: 'Shop information',
    code: 'Shop code',
    customerCode: 'Custom code',
    inputCode: 'Please fill in the store number',
    name: 'Shop name',
    inputName: 'Please fill in the shop name',
    address: 'Shop address',
    receiver: 'Receiver',
    receiverTel: 'Receiver tel',
    inputReceiverTel: 'Please fill in the receiving telephone number',
    shopContact: 'Contact',
    receiverInfo: 'Receiver info',
    inputReceiverInfo: 'Please fill in the name of consignee',
    shopOwnerName: 'Manager name',
    inputOwnerName: 'Please fill in the name of the shop manager',
    shopTel: 'Store tel',
    inputShopTel: 'Please fill in the store telephone number',
    installCharge: 'Installation charge',
    inputInstallCharge: 'Please fill in the installation fee',
    installChargeMode: 'Install charge mode',
    addShop: 'Add shop',
    editShop: 'Edit shop',
    accordingToShop: 'According to the stores',
    accordingToArea: 'By area (square metres)',
    shopUnit: 'shop',
    areaUnit: 'square metres',
    exportFileName: 'customer_shop_information.xlsx',
    contact: 'Shop contact',
    inputContact: 'Please input shop contact'
  },
  customerPoint: {
    shopName: 'Shop name',
    name: 'Point name',
    code: 'Point code',
    visibleSize: 'Visible size(mm)',
    finishSize: 'Finish size(mm)',
    product: 'Binding product',
    floor: 'Floor',
    selectShop: 'Please select your shop',
    visibleWidth: 'Visible width (mm)',
    visibleHeight: 'Visible height (mm)',
    finishWidth: 'Finished width (mm)',
    finishHeight: 'Finished height (mm)',
    inputName: 'Please fill in the name of the point',
    inputFloor: 'Please fill in your floor',
    selectCustomerProduct: 'Please select product',
    inputVisibleWidth: 'Please fill in the visible width',
    inputVisibleHeight: 'Please fill in the visible height',
    inputFinishWidth: 'Please fill in the finished width',
    inputFinishHeight: 'Please fill in the finished height',
    addPoint: 'Add point',
    editPoint: 'Edit point',
    exportFileName: 'customer_point_information.xlsx',
    areaUnit: 'square metres',
    noAbnormalData: 'No abnormal data',
    dataCheck: 'Data verification'
  },
  supplier: {
    level: 'Level',
    selectLevel: 'Please select level',
    selectCategory: 'Please select category',
    companyName: 'Company Name',
    fullName: 'Enterprise abbreviation',
    domainName: 'Domain Name',
    category: 'Category',
    address: 'Address',
    servant: 'Servant',
    paymentDay: 'Payment Day(Days)',
    remark: 'Remark',
    inputName: 'Please fill name',
    inputFullName: 'Please fill in the enterprise abbreviation',
    addSupplier: 'Add Supplier',
    editSupplier: 'Edit Supplier',
    inputDomainName: 'Please fill domain name',
    area: 'Area',
    selectArea: 'Please select area',
    inputPaymentDay: 'Please input payment day',
    inputRemark: 'Please fill remark',
    inputAddress: 'Please fill address',
    detailAddress: 'Address',
    accountName: 'Admin Account',
    defaultPassword: 'Default Password',
    inputServant: 'Please fill servant',
    currentLevel: 'Current Level',
    modifyLevel: 'Modify Level',
    templateName: 'supplier_import_template.xlsx'
  },
  supplierLevel: {
    serial: 'Serial',
    name: 'Name',
    explain: 'Explain',
    companyCount: 'Company count',
    inputName: 'Please fill in the level name',
    selectSerial: 'Please select the rank sequence',
    inputExplain: 'Please fill in the level description',
    permissionAllot: 'Access config',
    addSupplierLevel: 'Add supplier level',
    editSupplierLevel: 'Edit supplier level'
  },
  supplierMaterial: {
    code: 'Code',
    name: 'Name',
    category: 'Category',
    brand: 'Brand',
    size: 'Size',
    unit: 'Unit',
    displayOrder: 'Display order',
    import: 'Import Material',
    selectCategory: 'Please select category',
    inputName: 'Please fill name',
    inputCode: 'Please fil code',
    inputBrand: 'Please fill brand',
    selectUnit: 'Please select unit',
    inputWidth: 'Please fill width',
    inputLength: 'Please fill length',
    inputDisplayOrder: 'Please fill display order',
    inputRemark: 'Please fill remark',
    addMaterial: 'Add material',
    editMaterial: 'Edit material',
    importMaterial: 'Import material',
    exportMaterial: 'Export material',
    exportPrintingMaterial: 'Export printing material',
    exportBackendMaterial: 'Export backend material',
    printingTemplateName: 'printing_import_template.xlsx',
    backendTemplateName: 'backend_import_template.xlsx'
  },
  materialUnit: {
    meter: 'Meter',
    centimetre: 'Centimetre',
    millimetre: 'Millimetre'
  },
  materialType: {
    printing: 'Printing',
    backend: 'Backend'
  },
  supplierDevice: {
    deviceName: 'Name',
    categoryName: 'Category',
    printHead: 'Print Head',
    colorInfo: 'Color Info',
    category: 'Category',
    selectCategory: 'Please select category',
    deviceTemplate: 'device_template.xlsx',
    inputDeviceName: 'Please fill device name',
    inputPrintHead: 'Please fill print head info',
    inputColorInfo: 'Please fill color info',
    inputRemark: 'Please fill remark',
    addDevice: 'Add Device',
    editDevice: 'Edit Device'
  },
  supplierProduct: {
    templateName: 'supplier_product_template.xlsx',
    import: 'Import Product',
    productCode: 'Product Code',
    categoryName: 'Category',
    platformProductName: 'Platform Product',
    productName: 'Product Name',
    price: 'Purchase price',
    priceUnit: 'Unit',
    weight: 'weight',
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    selectCategoryName: 'Please select category',
    selectPlatformProduct: 'Please select platform product',
    inputPrice: 'Please fill price',
    selectPriceUnit: 'Pleasee select unit',
    inputWeight: 'Please fill weight',
    selectPrintingCraft: 'Please select printing craft',
    selectPrintingMaterial: 'Please select printing mateiral',
    selectBackendCraft: 'Please select backend craft',
    selectBackendMaterial: 'Please select backend material',
    printingCraft: 'Printing Craft',
    printingMaterial: 'Printing Material',
    backendCraft: 'Backend Craft',
    backendMaterial: 'Backend Material',
    inputRemark: 'Please input remark'
  },
  chargeUnit: {
    squareMeter: 'Meter',
    piece: 'Piece',
    cover: 'Suit',
    individual: 'One'
  },
  order: {
    type: 'Order type',
    orderStatus: 'Status',
    customer: 'Customer',
    createUserName: 'Creator',
    createDate: 'Create time',
    selectStatus: 'Please select status(multiple)',
    selectCustomer: 'Please select customer',
    startPlaceholder: 'Begin Date',
    endPlaceholder: 'End Date',
    code: 'Order Code',
    area: 'Area(m2)',
    projectName: 'Project Name',
    customerName: 'Customer',
    supplierName: 'Supplier',
    progress: 'Progress',
    createTime: 'Create Time',
    batchDistribute: 'Batch Distribute',
    new: 'New',
    waitingAccept: 'Waiting Accept',
    waitingPlan: 'Waiting Plan',
    processing: 'Processing',
    waitingConfirm: 'Waiting Confirm',
    completed: 'Completed',
    confirmDistribute: 'Confirm distribute selected order?',
    distributeSuccess: 'Distribute success',
    distributeCancel: 'Distribute cancel',
    selectNewOrderTip: 'Please select [New] status order',
    selectWaitingAccpetOrderTip: 'Please select [Waiting accept] status order',
    confirmCancel: 'Confirm to cancel selected orders?',
    cancelSuccess: 'Order cancel success',
    confirmFinish: 'Confirm to finish this order?',
    orderCompleted: 'Order completed',
    requiredDeliveryTime: 'Required Delivery',
    requiredArriveTime: 'Required Arrive',
    packRequire: 'Pack require',
    remark: 'Remark',
    orderAmount: 'Amount',
    productList: 'Product List',
    shipmentList: 'Shipment List',
    goOrderList: 'Go List',
    createAgain: 'Create again',
    createSuccessText: 'Order create success',
    editSuccessText: 'Order edit success',
    selectSupplier: 'Select Supplier',
    pleaseUploadFile: 'Please Upload File',
    inputRemark: 'Please enter remark',
    online: 'Online',
    offline: 'Offline',
    orderDetail: 'Detail File',
    productionFile: 'Production File',
    allProduction: 'All Production',
    selectProduct: 'Select Products',
    relatedProduct: 'Related Product',
    inputBase: 'Base',
    distributionProduct: 'Select Products',
    createSuccess: 'Created',
    pleaseDistributeProduct: 'Please select products',
    pleaseSelectSupplierProduct: "Please choose supplier product for item '{0}'",
    selectSupplierProduct: 'Please select supplier product',
    selectCustomProduct: 'Please select customer product',
    createOrderChange: 'New order change',
    createShippingNotice: 'New shipping notice',
    refused: 'Refused',
    confirmComplete: 'Please confirm if you want to complete the selected order',
    exportDetail: 'Export production order detail data',
    urgentOrder: 'Urgent Order',
    production: 'whether to produce',
    logisticsFlag: 'whether to shipment',
    installFlag: 'whether to install',
    deliveryStatus: 'Shipment status'
  },
  orderProduct: {
    itemCode: 'Code',
    itemType: 'Type',
    status: 'Status',
    pointName: 'Point',
    platformProductName: 'Platform product',
    customProductName: 'Customer product',
    supplierProductName: 'Supplier product',
    count: 'Count',
    totalArea: 'Production area(m2)',
    visibleWidth: 'Visible width(mm)',
    visibleHeight: 'Visible weight(mm)',
    completeWidth: 'Complete width(mm)',
    completeHeight: 'Complete height(mm)',
    prepressPicture: 'Prepress picture',
    prepressDescription: 'Prepress description',
    remark: 'Remark',
    requiredDeliveryTime: 'Required delivery time',
    requiredArriveTime: 'Required arrive time',
    currentProcess: 'Current process',
    finishTime: 'Finish time',
    brokenLog: 'Broken log',
    noProductionNeed: 'No production need',
    prepress: 'Prepress',
    printing: 'Printing',
    backend: 'Backend',
    salePrice: 'Sale price',
    purchasePrice: 'Purchase price',
    chargeUnit: 'Unit',
    visibleSize: 'Visible size(mm)',
    completeSize: 'Complete size(mm)',
    detailVisibleSize: 'Visible size',
    detailCompleteSize: 'Complete size',
    productArea: 'Production area',
    detailTitle: 'Product detail',
    baseInfo: 'Base',
    progress: 'Progress',
    backendCrafts: 'Backend crafts',
    shopName: 'Shop Name',
    prepressFlag: 'Need Prepress',
    printingFlag: 'Need printing',
    backendFlag: 'Need backend',
    inputKeyword: 'code / point / customer product / platform product',
    prepressCrafts: 'Prepress crafts',
    prepressConfirmFile: 'Preivew',
    prepressCompleteFile: 'Production file',
    clickToPreviewPDF: 'Preview PDF',
    availableCount: 'Available count',
    maxDistributeCountTip: 'Limit count: ',
    areaPriceFlag: 'Area pricing'
  },
  /**
   * 工序状态
   */
  processStatus: {
    new: 'New',
    waiting: 'Waiting',
    processing: 'Processing',
    completed: 'Finished',
    closed: 'No Need'
  },
  /**
   * 订单项次产品状态
   */
  orderProductStatus: {
    allStatus: 'All',
    waitPlan: 'Waiting plan',
    new: 'Pending',
    processing: 'Processing',
    completed: 'Finished',
    canceled: 'Canceled'
  },
  orderProductType: {
    product: 'Product',
    time: 'Time Service'
  },
  processAction: {
    take: 'Take',
    finish: 'Finish'
  },
  brokenLog: {
    brokenType: 'Broken Type',
    brokenProcess: 'Process',
    count: 'Count',
    priceUnit: 'Unit',
    area: 'Area(m²)',
    reason: 'Reason',
    dutyUser: 'Duty User',
    remark: 'Remark',
    completeSize: 'Complete Size(mm)',
    createUserName: 'Creator',
    createTime: 'Create Time',
    orderChangeLogCode: 'Related Order Change Log'
  },
  brokenReason: {
    requirementChange: 'Requirement Change',
    pictureError: 'Picture Error',
    materialError: 'Material Error',
    processError: 'Process Error',
    shipmentBroken: 'Shipment Broken',
    other: 'Other'
  },
  orderLogisticsOrder: {
    code: 'Shipping Code',
    shippingType: 'Shipping Type',
    shippingCompany: 'Company',
    shippingCode: 'Code',
    status: 'Status',
    receiveAddress: 'Address',
    receiver: 'Receiver',
    contact: 'Contact',
    receiveTime: 'Recive Time',
    sendAddress: 'Address',
    sender: 'Sender',
    sendTime: 'Send Time',
    receiveInfo: 'Receive ',
    sendInfo: 'Send',
    logisticsInfo: 'Logistics',
    count: 'Count',
    remark: 'Remark',
    parentOrder: 'Parent Order'
  },
  shippingType: {
    express: 'Express',
    selfPick: 'Self Pick',
    carDelivery: 'Car Delivery'
  },
  logisticsOrderStatus: {
    new: 'Waiting delivery goods',
    receivedGoods: 'Received goods',
    inTransit: 'In transit',
    error: 'Exception',
    received: 'Received',
    closed: 'closed'
  },
  logisticsStatus: {
    new: 'Waiting delivery goods',
    toBeCollect: 'To be collect',
    inTransit: 'In transit',
    dispatch: 'Dispatch',
    received: 'Received',
    back: 'Give back',
    signBack: 'Sign back',
    conversion: 'Conversion',
    diffcult: 'Difficult',
    customsClearance: 'Customs clearance',
    refusalToSign: 'Refusal to sign'
  },
  orderChange: {
    create: 'New',
    changeDate: 'Change Date',
    changeTime: 'Change Time',
    changeMan: 'Change man',
    code: 'Code',
    orderCode: 'Order Code',
    projectName: 'Project Name',
    customerName: 'Customer',
    supplierName: 'Supplier',
    status: 'Status',
    changeReason: 'Change reason',
    selectNewOrFailed: 'Please select a new state of the documents',
    selectPendingApproval: 'Please choose pending approval status record',
    submitApprovalSucces: 'Submit Success',
    submitApproval: 'Submit Approval',
    selectStatus: 'Select status',
    approval: 'Approval',
    approvalDescription: 'Description',
    inputApprovalDescription: 'Please enter the description',
    approverName: 'Approver',
    approvalTime: 'Approval time',
    changeDetail: 'Change Details',
    inputChangeReason: 'Please enter change reason',
    save: 'Save',
    saveAndSubmit: 'Save & Submit',
    add: 'Add line',
    addProduct: 'Add Product',
    editProduct: 'Edit Product',
    noDataSubmitWarning: 'Please enter change details',
    supplierProductInputTip: 'Please select supplier product',
    productCountInputTip: 'Please enter count',
    requiredDeliveryTimeInputTip: 'Please select require delivery time',
    requiredArriveTimeInputTip: 'Please select require arrive time',
    timeErrorTip: 'Require arrive time can not earlier than require delivery time',
    submitTip: 'Confirm to push selected records？',
    hasSameProduct: 'There has same product on detail list',
    baseContent: 'Base',
    rejectReason: 'Reject Reason'
  },
  orderChangeStatus: {
    new: 'New',
    pendingApproval: 'Auditing',
    approvalFailed: 'Approval Failed',
    pendingConfirm: 'Pending Confirm',
    reject: 'Rejected',
    finished: 'Finished'
  },
  changeType: {
    edit: 'Modify',
    minusCount: 'Minus',
    stop: 'Stop',
    add: 'New product',
    addCount: 'Increase'
  },
  orderChangeDetail: {
    changeType: 'Change type',
    syncFlag: 'Sync data'
  },
  project: {
    createProject: 'Create project',
    editProject: 'Edit prject',
    code: 'Project code',
    name: 'Project name',
    customer: 'Customer',
    status: 'Project status',
    progress: 'Project progress',
    cycle: 'Project cycle',
    settleCurrency: 'Settle currency',
    budget: 'Budget',
    quotation: 'Project amount',
    creator: 'Creator',
    createTime: 'Create time',
    orderFile: 'Order file',
    inputRemark: 'Please input remark',
    inputBudget: 'Please fill in the budgeted amount',
    inputName: 'Please fill in the project name',
    selectCustomer: 'Please fill in the customer of the project',
    selectCycle: 'Please select a project plan cycle',
    selectSettleCurrency: 'Please select the settlement currency',
    selectStatus: 'Please select project status (multiple support)',
    orderCreator: 'Order creator',
    selectCreator: 'Please fill in the order creator',
    orderCreateTime: 'Order create time',
    delete: 'Delete project',
    export: 'Export project',
    exportDetails: 'Export project details',
    exportFinanceDetails: 'Export finance details',
    exportFileName: 'Project_data',
    exportDetailFileName: 'Project_detils_data',
    exportFinanceDetailFileName: 'Finance_details_data',
    shopDetails: 'Shop details',
    productDetails: 'Production details',
    deliveryDetails: 'Delivery details',
    addItem: 'Add project item',
    selectProjectProduct: 'Select project product',
    editItem: 'Edit project item',
    revision: 'revision',
    deliveryDetailsTitle: 'Shop & Item quantity',
    addDeliveryNotice: 'Add delivery notice',
    operationNoStandard:
      'The current selected project status does not meet the requirements, please select the new project!',
    addInstallationNotice: 'Add installation notice',
    overdue: 'The project is overdue!',
    overdueWarning: 'The project is about to be overdue!',
    selectedProjects: 'Selected projects',
    assign: 'Project assign',
    accepting: 'Accepting',
    confirmAccepting: 'Are you sure you want to accept the project?',
    acceptanceSuccessful: 'Acceptance successful',
    cancelComplete: 'Cancel complete',
    complete: 'Complete',
    confirmCancelCompleteTip: 'Are you sure you want to cancel complete the project?',
    confirmCompleteTip: 'Are you sure you want to complete the project?',
    unDistributeTotal: 'Un distribute total',
    unPrePressTotal: 'Un prePress total',
    itemTotal: 'Item total',
    inPrepress: 'In prepress',
    toBeConfirmPrepress: 'To be confirm prepress',
    inProduction: 'In production',
    deliveryNotice: 'Associated delivery notice',
    installNotice: 'Associated install notice',
    cannotAddProjectTip:
      'There are unfinished projects that meet the completion conditions under this customer. Please complete these projects before selecting New! Clicking OK will automatically retrieve this part of the item for you.',
    overdueWaitComplete: 'Overdue completion',
    invoiceStatus: 'Invoice status',
    installStatus: 'Install status',
    unfinished: 'unfinished',
    finished: 'finished',
    pleaseSelectCustomer: 'Please Select Customer'
  },
  isOverdue: {
    theOverdue: 'The overdue',
    HasBeenOverdue: 'Has been overdue',
    BeyondOverdue: 'Beyond 14 days overdue'
  },
  currencyTypes: {
    CNY: 'CNY',
    USD: 'USD',
    HKD: 'HKD'
  },
  projectStatus: {
    new: 'New',
    underway: 'Underway',
    waitingForAcceptance: 'Waiting for acceptance',
    completed: 'Completed'
  },
  prepress: {
    code: 'Order code',
    itemCode: 'Code',
    itemType: 'Type',
    visibleSize: 'Visible size(mm)',
    finishSize: 'Finish size(mm)',
    platformProduct: 'Production name',
    customerProduct: 'Customer product',
    salePrice: 'Sale price',
    priceUnit: 'Price unit',
    backendCrafts: 'Backend crafts',
    requiredDeliveryTime: 'delivery time',
    prepressPicture: 'Prepress picture',
    prepressRemark: 'Prepress explain',
    makeProgress: 'Make progress',
    remark: 'Remark',
    status: 'Prepress status',
    producer: 'Producer',
    downloadConfirmFile: 'Download manuscript confirm file',
    downloadProductionFile: 'Download production file',
    confirmManuscript: 'Confirm',
    revision: 'Revision',
    import: 'import prepress data',
    templateName: 'prepress_data_import_template',
    all: 'All',
    changeColor: 'Change color',
    finalized: 'Finalized',
    confirmProduction: 'Confirm production',
    keywords: 'Item code or product name are supported',
    confirmProductionTip: 'Are you sure you want to submit prepress? (Only lines with prepress status New are allowed)',
    confirmManuscriptTip: 'Is the pre-press document confirmed (the document cannot be modified after confirmation)?',
    cancelTip: 'Are you sure cancel the prepress ?',
    selectCustomerProduct: 'Please select product',
    selectDeliveryTime: 'Please choose the required delivery time',
    selectBackendCraft: 'Please select backend craft',
    width: 'Width',
    height: 'Height',
    inputPrepressPicture: 'Please fill in the prepress picture',
    inputPrepressRemark: 'Please fill in the prepress instructions',
    inputVisibleSize: 'Please fill in the correct visible dimensions',
    inputFinishize: 'Please fill in the correct finished size',
    deleteTip: 'Are you sure you want to delete the selected items? (Only new state items will be deleted)',
    operationNoStandard: 'The current selected items do not meet the requirements!',
    operationNoSubmit: 'The document is not pending.',
    aotoOperationTips:
      'There are currently no items that meet the requirements! Clicking confirm will automatically submit all the data that meets the requirements under the project, please know!',
    details: 'Bill details',
    basicInfo: 'Basic info',
    prepressProgress: 'Prepress progress',
    changeRecord: 'Change record',
    finishHeight: 'Finish height',
    finishWidth: 'Finish width',
    visibleHeight: 'Visible height',
    visibleWidth: 'Visible width',
    deliverDetails: 'Delivery list',
    process: 'Process',
    modifier: 'Modifier',
    makeStateTime: 'Take order time',
    finishTime: 'Finish time',
    productionTime: 'Production time',
    prepressItemCode: 'Prepress item',
    customerProductName: 'Customer product',
    manuscriptConfirmFile: 'manuscript_confirm_file',
    productionFile: 'procution_file',
    verifyVisibleSize: 'The visible size cannot be greater than the finished size',
    verifyFinfishSize: 'The finished size must be greater than the visible size',
    thumbnails: 'Screen preview',
    preview: 'Preview',
    noPreview: 'Not uploaded',
    noProductionFiles: 'The currently selected prepress service does not upload production files',
    modifyCount: 'Revision count',
    submitPrepressTip:
      'For the selected item, please distribute it for production before submitting for pre-press production',
    projectName: 'Project name',
    finishedProduct: 'Finished product',
    unopened: 'Unopened',
    createDate: 'Create date',
    createUserName: 'Create user name',
    round: 'Round',
    count: 'Count',
    makeCount: 'Make count',
    prepressSubmitTotal: 'Submit total',
    submitConfirmTime: 'Submit time',
    archiveTime: 'Archive time',
    archiveUser: 'Archive user',
    topPocketSize: 'Top pocket size(cm)',
    bottomPocketSize: 'Bottom pocket size(cm)'
  },
  prepressStatus: {
    new: 'New',
    waitForProduction: 'Wait for production',
    inProduction: 'In production',
    waitForConfirm: 'Wait for confirm',
    waitComplete: 'Wait complete',
    completed: 'Completed',
    notMake: 'Not Make'
  },
  prepressSampleStatus: {
    waitSubmit: 'Wait submit',
    waitSampled: 'Wait sampled',
    inProduction: 'In production',
    completed: 'completed'
  },
  prepressAction: {
    new: 'New',
    submitProduction: 'Submit production',
    cancel: 'Cancel production',
    takeOrder: 'Take order',
    submitConfirm: 'Submit confirm',
    reddit: 'Reddit',
    AEConfirm: 'AE Confirm',
    complete: 'Complete',
    submitProofing: 'Submit proofing',
    cancelSubmitProofing: 'Cancel submit proofing',
    proofingComplete: 'Proofing complete',
    AEClose: 'AE closed',
    cancelConfirm: 'Pre press cancellation confirmation',
    cancelClose: 'AE de-closing',
    prepressArchive: 'Prepress archiving',
    uploadFile: 'Upload images before printing',
    receivingFinishOrder: 'Prepress finished product order',
    aeCancelMake: 'AE cancel make'
  },
  projectShop: {
    logisticsProgress: 'Logistics progress',
    installProgress: 'Install progress',
    createInstallBill: 'Create install bill',
    selectShop: 'Select shop',
    inputShopName: 'Please enter shop name',
    inputShopCode: 'Please enter shop code',
    deliveryInfo: 'Delivery info',
    saleCount: 'Sale count',
    assignCount: 'Assign count',
    import: 'Shipping information import',
    importTemplate: 'shipping_information_import_template',
    project: 'Associated project',
    customer: 'Associated customer',
    batchEditCount: 'Batch edit count',
    inputCount: 'Please fill in the shipment quantity',
    formatFailed: 'The content must be a number',
    exportPop: 'Export Pop',
    exportPoster: 'Export Poster',
    importPop: 'Import pop',
    importPoster: 'Import Poster',
    popFileName: 'export_pop',
    popsterFileName: 'export_poster',
    popTemplateName: 'pop_import_template',
    posterTemplateName: 'poster_import_template',
    inputDeliveryRemark: 'Please input delivery remark',
    selectDeliveryMethod: 'Please select a shipping method',
    selectPlannedShippingTime: 'Please select a planned shipping time',
    plannedShippingTime: 'Planned shipping time'
  },
  projectProduct: {
    itemCode: 'Item code',
    customerName: 'CustomerName',
    productionProgress: 'Production progress',
    pointName: 'Point name',
    consumed: 'Consumed',
    status: 'Make status',
    prepressStatus: 'Prepress status',
    finishTime: 'Finish time',
    consumedCode: 'Consumed item code',
    copy: 'Copy',
    templateName: 'production_import_template',
    keywords: 'Item code or product name are supported',
    itemType: 'Item type',
    finishedProduct: 'Finished product',
    totalArea: 'Total area(㎡)',
    inputPointName: 'Please fill in the name of the point',
    visibleSize: 'Visible size(mm)',
    finishSize: 'Finish size(mm)',
    platformProduct: 'Production name',
    customerProduct: 'Customer product',
    salePrice: 'Sale price',
    saleUnit: 'Sale unit',
    priceUnit: 'Price unit',
    saleUnitName: 'Sale unit',
    priceUnitName: 'Price unit',
    unitArea: 'Unit area(㎡)',
    priceCount: 'Price count',
    areaPriceFlag: 'Area pricing',
    priceIncludingTax: 'Unit price including tax',
    taxRate: 'Tax rate (%)',
    totalPrice: 'Untaxed amount',
    taxAmount: 'Tax amount',
    amountIncludingTax: 'Amount including tax',
    backendCrafts: 'Backend crafts',
    requiredDeliveryTime: 'Delivery time',
    requiredArriveTime: 'Arrive time',
    inputVisibleSize: 'Please fill in the correct visible dimensions',
    inputFinishize: 'Please fill in the correct finished size',
    selectCustomerProduct: 'Please select product',
    selectDeliveryTime: 'Please choose the required delivery time',
    selectBackendCraft: 'Please select backend craft',
    width: 'Width',
    height: 'Height',
    selectPriceUnit: 'Pleasee select unit',
    count: 'Sale count',
    deliveryNumber: 'Delivery Number',
    inputCount: 'Please fill in the sale count',
    saleCountLessThanUserNumber: 'The sales quantity cannot be less than the used quantity',
    relationalPrepress: 'Relational prepress',
    selectPlatformProduct: 'Please select the production name',
    productionArea: 'Production area(㎡)',
    remark: 'Remark',
    prepressPicture: 'Pre Press Picture',
    prepressDescription: 'Pre Press Description',
    prepressRemark: 'Prepress explain',
    shopName: 'Shop name',
    deliverNum: 'Product name',
    printingFlag: 'Printing',
    backendFlag: 'Backend',
    prepressFlag: 'Production prepress',
    platformPrepressFlag: 'Platform prepress',
    confirmFlag: 'Need confirm manuscript',
    inputSalePrice: 'Please fill in the  price',
    selectArriveTime: 'Please choose the time of arrival requested',
    details: 'Item details',
    deliveryNumOver: 'The shipment quantity of existing stores cannot exceed the total quantity',
    consumedItem: 'Consume item',
    basicInfo: 'Basic info',
    peocessControl: 'Peocess control',
    consumedInfo: 'Consume info',
    import: 'Project item import',
    prepressInfo: 'Prepress info',
    prepressCrafts: 'Prepress crafts',
    selectPrepressCrafts: 'Please choose pre-press craft',
    prepressSubmitCount: 'Number of submissions',
    logisticsInfo: 'Delivery info',
    logisticsFlag: 'Delivery switch',
    installInfo: 'Install info',
    installFlag: 'Install switch',
    flagOpen: 'Open',
    flagClose: 'Close',
    notEdit: 'Not edit',
    invoiceProgress: 'Settlement progress',
    deliveryProgress: 'Delivery progress',
    finishHeight: 'Finish height',
    finishWidth: 'Finish weight',
    visibleHeight: 'Visible height',
    visibleWidth: 'Visible weight',
    reeditExplain: 'Revision explain',
    deliveryEmpty: 'The shipment quantity is not allowed to be 0',
    platformProductName: 'Production name',
    batchEdit: 'Batch edit',
    doneManually: 'Done manually',
    doneManuallyTips:
      'Are you sure you want to complete this item manually? Only support the pre-press and production status of the newly created documents!',
    isConsumeRemake: 'Consume remake',
    productionDetails: 'Production details',
    downloadConfirmFile: 'Download manuscript confirm file',
    close: 'Close',
    cancelClose: 'Cancel close',
    closeConfirmTip:
      'Are you sure you want to close the selected item? (Only items with a closed status of normal will be closed)',
    closeTip: 'The currently selected items do not meet the requirements',
    cancelCloseConfirmTip: `Are you sure you want to de close the selected item? (Only items with a status of 'closed' and 'manually closed' will be de closed)`,
    cancelCloseTip: 'The currently selected items do not meet the requirements',
    submitPrepress: 'Submit prepress',
    cancelPrepress: 'Cancel prepress',
    confirmPrepress: 'Confirm prepres',
    shippingDate: 'Shipping date',
    selectItemType: 'Please select item type',
    isRemake: 'Is remake',
    make: 'Make',
    doNotMake: 'Do not make',
    billStatistics: 'Bill statistics',
    exportFileName: 'project_item_export',
    cannotLessThanCurrentTime: 'Request shipment/arrival time cannot be earlier than current time',
    inputCustomerProduct: 'Please fill in customer product',
    deliveryCount: 'Delivery count',
    installCount: 'Install count',
    invoiceCount: 'Invoice count',
    isOverdue: 'Is Overdue',
    toBeTimeout: 'Will be overdue',
    overdue: 'overdue',
    deliveryAndreceiptTime: 'Delivery and receipt time',
    notInputTip: 'Please fill in the information before submitting',
    relationCount: 'Associated quantity',
    relationFinishCount: 'Number of completed associations',
    deliveryFinishCount: 'Shipped quantity',
    installFinishCount: 'Installed quantity',
    invoiceFinishCount: 'Settled quantity',
    prepressSubimitCount: 'Number of submissions',
    prepressSubimitTotal: 'Total number of submissions',
    closeStatus: 'Closed state',
    manualShutdownFlag: 'Manual close',
    archiveStatus: 'Archive status',
    confirmationScreen: 'Confirmation screen',
    closeUser: 'Close user',
    closeTime: 'Close Time',
    totalRounds: 'Total rounds',
    changeItemTypeWarning: 'Please select【Type=Product and not associated with production, delivery, installation, settlement',
    layoutPreview: 'Layout',
    topPocketSize: 'Top pocket size',
    bottomPocketSize: 'Bottom pocket size',
    productionInfo: 'Production info',
    pocketSizeCannotZero: 'Pocket size cannot all be zero',
    pocketSizeOverMaxSize: 'pocket size cannot greater than 999.9cm',
    closedCannotEdit: 'Closed can not edit',
    relatedProductionCannotEdit: 'Related production cannot edit',
    prepressStatusMismatchEditCondition: 'Only wait confirm or made status can edit'
  },
  closeStatus: {
    normal: 'Normal',
    closed: 'Closed'
  },
  archiveStatus: {
    new: 'To be submitted',
    waitForProduction: 'Wait for production',
    inProduction: 'In production',
    alreadyMade: 'Already made',
    notMake: 'Not Make'
  },
  consumed: {
    consumedInfo: 'Consume info',
    consumedSelectRowTip: 'A consume operation can select at most one piece of data',
    consumedType: 'Consumed type',
    selectConsumedType: 'Please select consume type',
    consumedNum: 'Consumed quantity',
    inputConsumedCount: 'Please fill in the quantity consumed',
    consumedNumIllegal: 'The quantity consumed shall not be greater than the total quantity sold',
    consumedMeasureType: 'Measure type',
    selectConsumedMeasureType: 'Please select a measure type',
    consumedReason: 'Consumed reason',
    inputReason: 'Please fill in the consume reason',
    dutyUser: 'Duty user',
    inputDutyUser: 'Please fill in the duty user',
    dutyOrganize: 'Outy organize',
    inputDutyOrganize: 'Please fill in the outy organize',
    findUser: 'Find user',
    inputFindUser: 'Please fill in the find user',
    findOrganize: 'Find organize',
    inputFindOrganize: 'Please fill in the find organize',
    brokenArea: 'Broken area(㎡)',
    inputBrokenArea: 'Please fill in the broken area',
    resetInfo: 'Reset info',
    consumeStage: 'Consume stage',
    selectConsumeStage: 'Please select consume stage',
    dutyAgencyType: 'Duty agency type',
    selectDutyAgencyType: 'Please select the type of duty agency',
    inputPlatformProduct: 'Please fill in the production name',
    inputItemCode: 'Please fill in the item code',
    checkMakeStatusTips:
      'Please confirm whether the production status of the selected item meets the requirements (the status is not New or to be produced)'
  },
  projectProductStatus: {
    new: 'New',
    waitForProduction: 'Wait for production',
    inProduction: 'In production',
    waitConfirm: 'Wait confirm',
    completed: 'Completed',
    consumed: 'Consumed'
  },
  prepressTask: {
    artworkComplete: 'Artwork complete',
    exportMineTask: 'mine_task',
    exportChangeColorTask: 'change_color_task',
    exportCompleteManuscriptask: 'complete_manuscript_task',
    exportChangeColorSample: 'change_color_sample',
    productionStartTime: 'Production start time',
    billStatus: 'Prepress status',
    deliveryTime: 'Delivery time',
    takeOrder: 'Take order',
    typesettingReceivingOrders: 'Typesetting Receiving Orders',
    finishProductReceivingOrders: 'Finish Product Receiving Orders',
    redeploy: 'Redeploy',
    cancelComplete: 'Cancel complete',
    redeployBill: 'Bill redeploy',
    assignee: 'Assignee',
    selectAssignee: 'Please select the assignee',
    selectBillStatus: 'Please select prepress status',
    completeUpload: 'Upload production file',
    confirmUpload: 'Upload artwork confirmation file',
    noFileToUpload: 'No file can be uploaded. Check whether the file name corresponds to the item code',
    fileName: 'The file name ',
    unMatchedItemCode: 'does not match any of the selected items，Whether to skip these files and continue uploading？',
    unMatchedWaitCompleteStatus:
      'None of the currently selected items meet the requirements for completion of production! Please select another item!',
    unMatchedCompleteStatus: 'Check the documents do not conform to the conditions existing in the documents',
    unMatchedInProduction: 'You can only select items whose state is "in production"',
    unMatchedWaitProduction: 'You can only select items whose state is "wait production"',
    unMatchedRedeployStatus: 'Items in the “wait production” or "completed" state are not allowed to be redeploy',
    isNeedUploadFile: 'Do I need to upload completed files?',
    need: 'Need',
    notNeed: 'Not need',
    fileRepeat: 'A file corresponding to the item already exists',
    fileAdded: 'This file has been added',
    submitPrintSample: 'Submit print sample',
    addChangeColorSample: 'add change color sample',
    submitConfirmTip:
      'Are you sure you want to submit? (Only support color change items whose status is in production)',
    unMatchedRequire: 'Please select the item that meets the requirements!',
    archiveCompleteTip: 'Please select the item that has been confirmed and has not been archived',
    selectMatchedRequire: 'Please select the data for 【In production】【To be completed】【Completed】',
    submitReview: 'Submit review',
    cancelOrder: 'Cancel Order',
    changeColorConfirmTip:
      'Are you sure you want to submit review? (Only support items whose status is in production and the production status is new or pending confirmation)',
    screenArea: 'Screen area(㎡)',
    screenRate: 'Screen rate',
    screenAreaTotal: 'Total area',
    count: 'Make count',
    proofingStatus: 'Proofing status',
    all: 'All',
    archiveComplete: 'Archive complete',
    typesettingCancelTip: 'The document is not in [production]',
    typesettingCancelTip2: 'Only the first round of documents allows cancellation of orders',
    finishProductCancelTip: 'The document is not in [production]',
    finishProductReceivingTip: 'The document is not pending.',
    previewLayout: 'Preview layout'
  },
  addDeliveryNotice: {
    code: 'Code: ',
    selectSupplier: 'Please select supplier',
    relatedProjects: 'Related project: ',
    relatedCustomer: 'Related customer: ',
    shopDetails: 'Shop details',
    productDetails: 'Product details',
    customSite: 'Custom site',
    addSite: 'Add site',
    batchEdit: 'Batch edit',
    importDeliveryDetails: 'Import delivery details',
    selectProduct: 'Select production',
    deliverySite: 'Delivery site',
    deliveryAddress: 'Delivery address',
    receiver: 'Receiver',
    receiverTel: 'Receiver tel',
    supplier: 'supplier: ',
    deliveryRemark: 'Delivery remark: ',
    remark: 'Remark: ',
    inputReceiver: 'Please fill in the receiver',
    inputReceiverTel: 'Please fill in the receiver telephone number',
    area: 'Area',
    selectArea: 'Please select local area',
    address: 'Address',
    inputAddress: 'Please fill in the full address',
    addDeliverySite: 'Add delivery point',
    editDeliverySite: 'Edit delivery point',
    shopName: 'Delivery point',
    inputShopName: 'Please fill in the delivery point',
    sendCount: 'Quantity shipped',
    selectProductItem: 'Select product item',
    productClassify: 'Product classify',
    platformProduct: 'Platform product',
    customerProduct: 'Customer product',
    projectProductKey: 'Support by item code, point name',
    addDeliveryNotice: 'Add delivery notice',
    editDeliveryNotice: 'Edit delivery notice',
    remainDeliveryCount:
      'The delivered quantity has exceeded the total number of products in the item, the remaining deliverable quantity of the item is',
    remainCount: 'Remain count',
    templateName: 'delivery_details_template',
    verifyCountFailed: 'The delivery quantity of the product details is not allowed to be 0, please check the store',
    verifyEmptyFailed:
      'No product details were found. If you do not need to ship to this store, please delete the store',
    importTitle: 'Import delivery details',
    isInitDeliveryDataForProject: 'Is the delivery detail data automatically imported from the project?',
    pleaseAddstore: 'Please add a store',
    batchSetAnnex: 'Batch set annex',
    batchSetShopItem: 'Batch set'
  },
  addInstallationNotice: {
    code: 'Bill code',
    installIsNull: 'Installation notification does not exist',
    addInstallationNotice: 'Add installation notice',
    editInstallationNotice: 'Edit installation notice',
    remark: 'Remark Information',
    tag: 'hint label',
    shopDetails: 'Shop details',
    productDetails: 'Product details',
    site: 'Installation sidt',
    shopName: 'Shop name',
    inputSite: 'Please fill in the installation location',
    address: 'Installation addresss',
    contactsPerson: 'Contacts person',
    contactsPhone: 'Contacts phone',
    inputContactsPerson: 'Please fill in the installation contact',
    inputContactsPhone: 'Please fill in the installation contact number',
    addInstallationSite: 'Add installation location',
    editInstallationSite: 'Edit installation location',
    remainCount: 'Remaining installable quantity',
    installCount: 'Installed quantity',
    remainInstallCount:
      'The installed quantity has exceeded the total number of products in the item, and the remaining installable quantity of this item is',
    minRemainInstallCount:
      'The installed quantity cannot be less than 0, and the remaining installable quantity of this item is',
    installNumberLessOne: 'Install the minimum quantity is 1',
    verifyCountFailed:
      'The installation quantity of the product details is not allowed to be 0, please check the store',
    verifyEmptyFailed: 'No product details found, if you don’t need to install for this store, please delete the store',
    addInstallationOrder: 'Add installation order',
    isInitInstalldataForProject: 'Will the installation details be automatically imported from the project?',
    totalBudget: 'Total budget',
    budgetAmount: 'Budget amount',
    inputBudgetAmount: 'Please input budget amount',
    planInstallTime: 'Scheduled installation time',
    finishDay: 'finishDay',
    selectFinishDay: 'Please select the completion date, do not update if left blank',
    serviceItem: 'Service item',
    selectServiceItem: 'Please select a service item and do not update if left blank',
    selectPlanInstallTime: 'Please select a planned installation time',
    selectFinishInstallTime: 'Please select a finished installation time',
    planInstallStartTime: 'Plan to install the start time',
    planInstallEndTime: 'Plan to install the end time',
    verifyShopPropFailed: 'Please click Edit and check if the required attributes for this store are filled in',
    verifyRemainCount: 'The installable quantity of items is 0, which has been automatically filtered for you'
  },
  deliveryNoticeStatus: {
    new: 'New',
    waitTakeOrder: 'Wait take order',
    waitDelivery: 'Wait delivery',
    partDelivery: 'Part delivery',
    delivered: 'Delivered',
    complete: 'Complete'
  },
  deliveryNoticeList: {
    code: 'Code',
    status: 'Status',
    customer: 'Customer',
    orderCreateTime: 'Order create time',
    supplier: 'Supplier',
    project: 'Project',
    deliveryRemark: 'Delivery remark',
    selectCustomer: 'Please select select customer',
    selectStatus: 'Please select document status',
    selectOrderCreateTime: 'Please select your order time',
    batchPush: 'Batch Push',
    batchCancel: 'Batch Cancel',
    inputCode: 'Please fill in the code',
    confirmPush: 'Are you sure you want to push the selected delivery notice? (Only new documents are supported)',
    confirmCancel:
      'Are you sure you want to cancel the selected delivery notice? (Only documents to be "wait take order" are supported)',
    operationNoStandard:
      'The status of the selected delivery notice does not meet the requirements. Please select the delivery notice with "new" status!',
    onlyWaitTakeOrder:
      'The status of the selected delivery notice does not meet the requirements. Please select the delivery notice with "wait take order" status!',
    shopTotal: 'Shop total',
    itemTotal: 'Item total',
    exportMaxTip: 'When exporting POP details, only one shipping notice can be selected at most',
    popFileName: 'pop_export',
    posterFileName: 'poster_export'
  },
  installationNotice: {
    code: 'Code',
    projectName: 'Project',
    customerName: 'Customer',
    status: 'Status',
    description: 'Description',
    createUserName: 'Create user',
    createTime: 'Create time',
    customer: 'Customer',
    inputCreateUserName: 'Please enter create user name',
    createDate: 'Create time',
    batchSubmit: 'Push',
    batchAudit: 'Audit',
    batchFinish: 'Finish',
    cancelComplete: 'Cancel complete',
    selectNewReceiptsTip: 'Please select new records',
    confirmPush: 'Confirm to push selected records？',
    selectWaitingAcceptOrderTip: 'Please select waiting take records',
    confirmCancel: 'Confirm to cancel selected records',
    confirmTake: 'Confirm to take selected records',
    selectWaitingInstallTip: 'Please select waiting install records',
    confirmInstall: 'Confirm to finish selected records？',
    fileName: 'File name',
    fileExtension: 'File Extension',
    fileSize: 'File size(KB)',
    filePath: 'File Path',
    batchReject: 'Reject',
    confirmReject: 'Confirm the de-approved selected document?',
    selectWaitingAuditTip: 'Please select waiting audit records',
    selectPartialInstallTip: 'Please select partical install records',
    editSummary: 'Edit summary',
    orderSummary: 'Order summary',
    inputAbstractContent: 'Please fill in the document summary information',
    selectSingleData: 'Please select 1 piece of data',
    orderTotal: 'Associated Installation Order',
    showRemark: 'Order remark',
    installationAttachment: 'InstallationAttchment',
    popImport: 'Pop import',
    posterImport: 'Poster import',
    setReconciled: 'Set reconciled',
    setUnreconciled: 'Set unreconciled',
    reconciled: 'reconciled',
    unreconciled: 'unreconciled',
    billStatus: 'Reconciliation status',
    installationNoticeFile: 'Installation_notification_details_file'
  },
  installationNoticeStatus: {
    new: 'New',
    waitingTake: 'Waiting take',
    waitingInstall: 'Waiting install',
    partialInstall: 'Partial install',
    completed: 'Completed'
  },
  installationOrder: {
    add: 'Add installation order',
    edit: 'Edit installation order',
    status: 'Status',
    customer: 'Customer',
    createUserName: 'Create user',
    createDate: 'Create date',
    batchFinish: 'Finish',
    downloadZip: 'Download Zip',
    batchEditPrice: 'Edit price',
    batchCancel: 'Cancel order',
    batchDelete: 'Delete',
    code: 'Code',
    installNoticeCode: 'Installation notice code',
    shopName: 'Shop',
    projectName: 'Project',
    customerName: 'Customer',
    requireOnSiteTime: 'On-Site date',
    address: 'Address',
    contactsPerson: 'Contact person',
    contactsPhone: 'Contact number',
    qrCode: 'QR Code',
    installPrice: 'Price',
    approveStatus: 'Approve status',
    installTime: 'Install time',
    completeTime: 'Complete time',
    selectNewReceiptsTip: 'Please select new records',
    selectWaitingAcceptOrderTip: 'Please select waiting install records',
    confirmCancel: 'Confirm to cancel selected records',
    selectWaitingInstallTip: 'Please select records which status is waiting or installing',
    confirmFinishInstall: 'Confirm to complete selected records',
    confirmCancelFinishInstall: 'Confirm to cancel complete selected records',

    // new: 'New',
    // waitingInstall: 'Waiting Install',
    // installing: 'Installing',
    // approving: 'Approving',
    // completed: 'Completed',

    toBePushed: 'To be pushed',
    PendingOrders: 'Pending orders',
    pendingAppointment: 'Pending appointment',
    inService: 'In service',
    acceptancePending: 'Acceptance pending',
    completed: 'Completed',

    batchCreate: 'Create installation order',
    createSuccess: 'Create success, to installation order page see the order.',
    attachment: 'Attachments',
    installedPhoto: 'Installed photos',
    limitCount: 'Limit upload count:',
    selectOrder: 'Please select order',
    auditDescription: 'Audit description',
    inputAuditDescription: 'Please input audit description',
    requireInstallTime: 'Require on-site time',
    contactPerson: 'Contact person',
    contactPhone: 'Contact phone',
    quotedPrice: 'Quoted',
    area: 'Area',
    selectRequireInstallTime: 'Select time',
    inputContactPerson: 'Enter contact person',
    inputContactPhone: 'Enter contact phone number',
    inputQuotedPrice: 'Enter quoted price',
    selectArea: 'Select area',
    inputAddress: 'Enter address',
    btnBatchSetPrice: 'Batch set',
    batchPush: 'Push order',
    selectNewOrderTip: 'Please select new order',
    confirmPushOrder: 'Confirm to push selected orders?',
    clickToPreview: 'Click to preview',
    remark: 'Remark',
    inputRemark: 'Please enter remark',
    installEmployee: 'Install employee',
    serviceStaff: 'Service staff',
    selectInstallEmployee: 'Please select install employee',
    realInstallTime: 'Actual installation time',
    selectRealInstallation: 'Please select the actual installation time',
    exceptionRecord: 'Exception record',
    acceptanceRecord: 'Acceptance record',
    exceptionExplain: 'Exception explain',
    exceptionHandling: 'Exception handling',
    installer: 'Installer',
    uploadFinishedPhotos: 'Upload finished photos',
    leastOneInstallProduct: 'Add at least one installed product',
    detailCount: 'product amount ',
    serveTime: 'Serve time',
    selectServeTime: 'Select serve time',
    batchSetServiceTime: 'Edit service time',
    canNotLessThanCurrentTime: 'Can not less than current time',
    serviceInfo: 'service information',
    saveSelect: 'Save select',
    pushSelect: 'Push select',
    auditOpinion: 'Audit opinion',
    acceptancePassed: 'Acceptance passed',
    acceptanceFailed: 'Acceptance failed',
    finishTime: 'Finish time',
    serveItem: 'Service Item'
  },
  processActionEnum: {
    take: 'Take',
    finish: 'Finish'
  },
  operationTypeEnum: {
    prepress: 'Prepress',
    print: 'Print',
    backend: 'Backend'
  },
  deliveryMode: {
    TPL: 'TPL',
    specialTrain: 'Special train',
    selfPickUp: 'Self pick up'
  },
  logistics: {
    add: 'Add logistics',
    deliveryMode: 'Delivery mode',
    selectCustomer: 'Please select associate customer',
    seelctOrderStatus: 'Please select logistics order status',
    seelctStatus: 'Please select logistics status',
    shopCount: 'Shop count',
    deliveryRemark: 'Delivery remark',
    shippingInfo: 'Shipping information',
    logisticsInfo: 'Logistics info',
    expressCompany: 'Logistics company',
    logisticsNo: 'Logistics No',
    inputLogisticsNo: 'Please enter Logistics No',
    receiverInfo: 'Receiving info',
    senderInfo: 'Delivery info',
    subscribeStatus: 'Subscribe status',
    code: 'Bill code',
    inputCode: 'Please enter bill code',
    relevanceDeliveryBill: 'Relevance delivery bill',
    relevanceCustomer: 'Relevance customer',
    relevanceShop: 'Relevance shop',
    relevanceProject: 'Relevance project',
    childCount: 'Parcel count',
    inputChildCount: 'Please enter parcel count',
    status: 'Logistics order status',
    logisticsStatus: 'Logistics status',
    newLogisticsTrack: 'The latest track',
    subscribeTrack: 'Subscribe track',
    subscribeSuccess: 'Subscribe success',
    unSubscribe: 'Un subscribe',
    deliveryTime: 'Delivery time',
    receivingTime: 'Receiving time',
    logisticsCost: 'Logistics cost',
    weight: 'Weight(KG)',
    price: 'Price(Yuan)',
    batchSet: 'Batch set',
    arriveTime: 'Arrive time',
    inTransit: 'In transit',
    logisticsTrack: 'Logistics track',
    mapMode: 'Map mode',
    noLogisticsTrack: 'No relevant logistics track information has been queried yet',
    export: 'Export',
    exportFileName: 'logistic_export_file',
    receivingCity: 'Receiving city',
    inputReceivingCity: 'Please enter receiving city',
    selectExpressCompany: 'Please select a logistics company',
    selectDeliveryMode: 'Please select shipping method',
    productList: 'Product list',
    logisticsTracking: 'Logistics tracking',
    associatedSettlement: 'Associated settlement doc',
    requiredArriveTime: 'Required arrive time',
    arrivalTime: 'Arrival time',
    closeReason: 'Close Reason'
  },
  billing: {
    addBilling: 'Add billing',
    editBilling: 'Edit billing',
    code: 'Code',
    customerName: 'Customer name',
    customer: 'Csutomer',
    selectCustomer: 'Please select customer',
    settleCurrency: 'Settle currency',
    selectSettleCurrency: 'Please select the settlement currency',
    settleExchangeRate: 'Settle exchange rate',
    inputSettleExchangeRate: 'Please fill in the settle exchange rate',
    paymentMode: 'Payment mode',
    selectPaymentMode: 'Please select the payment mode',
    noTaxAmount: 'No tax amount',
    tax: 'Tax',
    areaPricing: 'Area pricing',
    taxIncludeAmount: 'Tax include amount',
    amountWrittenOff: 'Amount written off',
    amountToBeWrittenOff: 'Amount to be written off',
    amountExcludingTaxError: 'The settlement amount cannot be less than 0',
    invoiceRecord: 'Invoice(have / total)',
    status: 'Status',
    finishTime: 'Finish time',
    applyAbolish: 'Apply abolish',
    exportBill: 'Export bill',
    exportLedger: 'Export ledger',
    makeDetails: 'Make details',
    packageTransportDetails: 'Package transport details',
    installDetails: 'Install details',
    otherDetails: 'Other details',
    invoiceInfo: 'Invoice info',
    saleCount: 'Sale count',
    batchEditPrice: 'Batch edit price',
    ownerProject: 'Owner project',
    selectOwnerProject: 'Please select owner project',
    amountExcludingTax: 'No tax amount',
    amountIncludingTax: 'Tax-included amount',
    priceBeforeTax: 'No tax price',
    priceIncludingTax: 'Tax-included price',
    selectProp: 'Select the properties you want to batch modify',
    prop: 'Property',
    amount: 'Amount',
    taxRate: 'Tax rate(%)',
    discountRate: 'Discount rate(%)',
    taxAmount: 'Tax amount',
    discountAmount: 'Discount amount',
    itemType: 'Product attribute',
    saleUnit: 'Sale unit',
    priceCount: 'Price count',
    paymentCount: 'Payment count',
    totalNumber: 'Total quantity;',
    priceUnit: 'Price unit',
    price: 'Price',
    selectProjectFirst: 'Please select the item first and then click the search button!',
    relevanceProject: 'Relevance project',
    color: 'Red or Blue',
    taxNumber: 'Taxpayer number',
    invoiceNumber: 'Invoice number',
    invoiceType: 'Invoice type',
    invoiceMode: 'Invoice method',
    selectColor: 'Please select invoice color',
    selectInvoiceMode: 'Please choose the billing method',
    createInvoice: 'Create invoice',
    attachment: 'Attachment',
    customerInvoice: 'Customer invoice info',
    paymentDay: 'Payment day',
    inputPaymentDay: 'Please fill in payment collection period(days)',
    inputTaxNumber: 'Please fill in the taxpayer identification number',
    bankName: 'Bank name',
    bankNumber: 'Bank number',
    inputBankNumber: 'Please fill in your bank account number',
    tel: 'Tel',
    inputTel: 'Please fill in the contact number',
    address: 'Contact address',
    inputAddress: 'Please fill in your contact address',
    selectInvoiceType: 'Please select the invoice type',
    invoiceCompany: 'Invoice company',
    selectInvoiceCompany: 'Please select invoice company',
    invoices: 'Invoice record',
    taxRevenueName: 'Tax classification',
    serviceName: 'Service name',
    platformInvoice: 'Platform invoice',
    sameAmountTip:
      'The total settlement amount (tax excluded) must be equal to the total invoice amount (tax excluded)!',
    inputAmount: 'Please fill in the amount',
    countExceedTip: 'The priced quantity shall not be greater than the sold quantity',
    inputCount: 'Please fill in the price quantity first',
    inputPriceBeforeTax: 'Please fill in the untaxed unit  first',
    inputAmountExcludingTax: 'Please fill in the untaxed amount first',
    inputDiscountOrExcludingTax: 'The preferential amount and the untaxed amount cannot be 0 at the same time',
    conmfirmAbolish: 'Are you sure you want to apply abolish?',
    abolishNoStandard:
      'The operation  only supports the bill in the status of "to be invoited, to be cancelled after verification and settlement completed"',
    inputApplyReason: 'Please fill in the reason for apply',
    confirmSubmit: 'Are you sure you want to submit?',
    confirmCancelApply: 'Are you sure you want to cancel apply',
    submitNoStandard: 'The submit operation only supports status in the "new" state.',
    cancelApplyNoStandard: 'The cancel audit operation only supports status in the "wait audit" state.',
    exportFileName: 'Billing',
    exportLedgeFileName: 'Ledge',
    financeInfo: 'Finance ingo',
    applicant: 'Applicant',
    invoicingTime: 'Invoicing time',
    applyTime: 'Apply time',
    drawer: 'Drawer',
    invoiceMaterial: 'Invoice material',
    writeOffRecords: 'Write off records',
    writeOff: 'Write off',
    antiWriteOff: 'Anti write off',
    confirmAntiWriteOff: 'Are you sure you want to anti-write off?',
    selectTransaction: 'Select transaction details',
    confirmWriteOff: 'Are you sure you want to write off?',
    cancelAudit: 'Cancel audit',
    operationNoStandard:
      'None of the currently selected statements meet the requirements, please select the statement whose status is New!',
    amountExcludingTaxExceed: 'The untaxed amount of the invoice cannot exceed 99999.99!',
    area: 'Area(㎡)',
    totalArea: 'Total area(㎡)',
    billAmount: 'Bill amount',
    writeOffTotalAmount: 'Write off total amount',
    invoiceStatus: 'Invoice status',
    approval: 'Approval record',
    roundDown: 'Round down',
    amountExcludeTax: 'Total settlement amount',
    invoiceAmountExcludeTax: 'Invoice untaxed amount',
    invoiceAmountIncludeTax: 'Invoice intaxed amount',
    invoiceTax: 'Invoice tax',
    invoiceTitle: 'Invoice title',
    selectItemType: 'Please select a product attribute',
    defaultStrategy: 'Batch edit',
    byArea: 'Allocate by area',
    byNum: 'Allocate by quantity',
    selectStrategy: 'Please select a strategy',
    strategy: 'Strategy',
    selectProject: 'Select project',
    inputDiscountAmount: 'Please fill in the discount amount',
    inputDiscountRate: 'Please fill in the discount rate',
    discountRemark: 'Discount remark',
    batchSetDiscount: 'Batch set discount',
    setDiscountTip1:
      '1. One of the discount amount and the discount ratio is required. If both are filled in, the discount amount will be set by default.',
    setDiscountTip2: '2. The discount ratio can be set as a percentage between 0.00-100.00.',
    setDiscountTip3:
      '3. If the discount amount exceeds the settlement amount, it will be automatically set to the settlement amount',
    setDiscountTip4: '4. If no document is selected, it will take effect for all documents by default.',
    invoiceFile: 'Invoice file',
    downloadInvoice: 'Download invoice',
    uploaded: 'uploaded',
    notUploaded: 'Not uploaded'
  },
  paymentMode: {
    cash: 'Cash',
    cheque: 'Cheque',
    bankTransfer: 'Bank transfer',
    wireTransfer: 'Wire transfer',
    weChat: 'Wechat',
    alipay: 'Alipay',
    other: 'Other'
  },
  billingItemType: {
    finalized: 'Dinalized',
    changeColor: 'ChangeColor',
    product: 'Product'
  },
  billingStatus: {
    new: 'New',
    waitAudit: 'Wait audit',
    waitMakeInvoice: 'Wait make invoice',
    waitCancelAfterVerification: 'Wait cancel after verification',
    complete: 'Complete',
    failedAudit: 'Failed audit',
    abolishAudit: 'Abolish audit',
    failedAbolishAudit: 'Failed abolish audit',
    abolished: 'Abolished'
  },
  invoiceColor: {
    red: 'Red',
    blue: 'Blue'
  },
  invoiceMode: {
    paper: 'Paper',
    electronic: 'Electronic'
  },
  invoiceType: {
    normalInvoice: 'Normal invoice',
    valueAddedTaxInvoice: 'Value-added tax invoice',
    proformaInvoice: 'Proforma invoice'
  },
  financeInvoice: {
    billCode: 'Billing code',
    applyChange: 'Apply change',
    makeInvoice: 'Make invoice',
    makeInvoiceNoStandard: 'The make invoice operation only supports status is "wait make invoice" state',
    changeNoStandard: 'The change invoice operation only supports status is "has make Invoice" state',
    inputInvoiceNo: 'Please fill in the invoice number',
    inputTip: 'Each invoice number is separated by commas in the order the list has been selected',
    applyChangeInvoice: 'Apply for change of invoice',
    invoiceNumError: 'The number of invoices filled in does not match the number checked!',
    exportFileName: 'financial_invoice',
    selectInvoicingTime: 'Please select invoicing time',
    invoiceNo: 'Invoice no',
    expiryDate: 'Expiry date',
    inputBillCode: 'Please fill in the bill code',
    edit: 'Invoice edit',
    exportInvoice: 'Export',
    exportInvoiceWithDetail: 'Export with detail'
  },
  financeInvoiceStatus: {
    new: 'New',
    waitAudit: 'Wait audit',
    failedAudit: 'Failed audit',
    waitMakeInvoice: 'Wait make invoice',
    hasMakeInvoice: 'Has make invoice',
    abolished: 'Abolished'
  },
  transaction: {
    add: 'Add transation details',
    edit: 'Edit transation details',
    import: 'Import transaction details',
    importTemplate: 'Transaction_details_import_template',
    transactionType: 'Transaction Type',
    selectTransactionType: 'Please select transaction type',
    paymentMode: 'Trading channel',
    selectPaymentMode: 'Please select transaction channel',
    abstract: 'Abstract',
    inputAbstract: 'Please fill in the summary',
    serialNumber: 'Serial number',
    inputSerialNumber: 'Please fill in the serial number',
    currency: 'Currency',
    selectCurrency: 'Please select currency',
    amount: 'Amount',
    inputAmount: 'Please fill in the amount',
    counterpartyAccount: 'Counterparty account',
    inputCounterpartyAccount: 'Please fill in the counterparty account',
    counterpartyName: 'Counterparty name',
    inputCounterpartyName: 'Please fill in the name of the other party',
    counterpartyBank: 'Counterparty bank',
    inputCounterpartyBank: 'Please fill in the counterparty bank',
    tradingHours: 'Transaction hour',
    selectTradingHours: 'Please select transaction time',
    writeOffBillingCode: 'Write off billing code'
  },
  transactionStatus: {
    toBeWrittenOff: 'To be written off',
    writtenOff: 'Written Off'
  },
  transactionType: {
    income: 'Income',
    expenditure: 'Expenditure'
  },
  todo: {
    code: 'Code',
    topic: 'Topic',
    applyReason: 'Apply reason',
    customer: 'Associated customer',
    approveTheInitiator: 'Approve the initiator',
    approveTheTime: 'Approve the time',
    inputApproveTheInitiator: 'Please fill in the sponsor of the application',
    confirmApproved: 'Are you sure you want to pass the approval?',
    inputRejectReason: 'Please fill in the reason for rejection'
  },
  todoScope: {
    billing: 'Billing',
    invoice: 'Invoice'
  },
  todoType: {
    billing: 'Settlement application',
    abolish: 'Abolish application',
    changeInvice: 'Change invoice application'
  },
  approveStatus: {
    notCommit: 'Not commit',
    waitApprove: 'Wait approve',
    passed: 'Passed',
    failed: 'Failed'
  },
  projectItemType: {
    finishedProduct: 'Finished product',
    changeColor: 'Change color',
    designArtis: 'Design artis',
    server: 'Server',
    cost: 'Cost',
    consumed: 'Consumed'
  },
  prepressLayoutCrafts: {
    noneAdjustmentToPrint: 'None adjustment to print',
    dimensionAdjustment: 'Dimension adjustment',
    accordingToGuideline: 'According to guideline',
    ratioLayout: 'Ratio Llyout',
    printReadyFile: 'Print ready file',
    pixelLayout: 'Pixel layout',
    others: 'Others',
    accordingPretty: 'According pretty',
    accordingPrtion: 'According prtion',
    fence: 'fence',
    showbill: 'showbill',
    lineDraft: 'lineDraft',
    copyPrint: 'copyPrint'
  },
  prepressChangeColorCrafts: {
    pixelLayout: 'Pixel layout',
    accordingToTheProof: 'According to the proof',
    graphicRetouch: 'Graphic retouch',
    commonSense: 'Common sense',
    softPrinting: 'Soft printing',
    others: 'Others',
    accordingColorCard: 'According color card'
  },
  consume: {
    export: 'Consume bill'
  },
  hmPackaging: {
    addPackaging: 'Create package',
    editPackaging: 'Create package',
    boxName: 'Packaging name',
    boxNumber: 'Packaing number',
    outerSize: 'Outer size(Length x Width x Height mm)',
    innerSize: 'Inner size(Length x Width x Height mm)',
    netWeight: 'Net weight(kg)',
    maxSize: 'Max size',
    packType: 'Pack type',
    sortUnitTime: 'Sort unit time(s)',
    wrapUnitTime: 'Wrap unit time(s)',
    price: 'Packaging price(Yuan)',
    inputBoxName: 'Please enter name',
    inputBoxNumber: 'Please enter number',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    inputLength: 'Please enter length',
    inputWidth: 'Please enter width',
    inputHeight: 'Please enter height',
    widthCompareError: 'Outer width can not less than inner width',
    lengthCompareError: 'Outer length can not less than inner length',
    heightCompareError: 'Outer height can not less than inner height',
    inputNetWeight: 'Please enter net weight',
    inputMaxSize: 'Please enter max size',
    inputBoxPackType: 'Please select pack type',
    inputSortUnitTime: 'Please enter sort unit time',
    inputWrapUnitTime: 'Please enter wrap unit time',
    inputPrice: 'Please enter price'
  },
  hmOrder: {
    addOrder: 'Add order',
    orderName: 'Order name',
    campaignNo: 'Campaign no',
    campaignName: 'Campaign name',
    projectCode: 'Project code',
    area: 'Area',
    version: 'Version',
    sendDate: 'Send date',
    onSiteDate: 'OnSite date',
    transportDate: 'Transport date',
    logisticsServiceProvider: 'Logistics service provider',
    invoiceIssuingAddress: 'Invoice address',
    invoiceReference: 'Invoice reference',
    specCode: 'Spec code',
    comments: 'Comments',
    createTime: 'Create time',
    createUserName: 'Create user',
    downloadPSL: 'PSL',
    downloadBoxSticker: 'Box sticker',
    downloadPalletSticker: 'Pallet sticker',
    downloadProductTag: 'Product tag',
    downloadBooking: 'Booking',
    inputOrderName: 'Please enter order name',
    file: 'Order file',
    exportShippingDetails: 'Export shipping details',
    shippingDetailsFileName: 'hm_shipping_details'
  },
  approveResultEnum: {
    approving: 'Approving',
    approved: 'Approved',
    reject: 'Reject',
    revoked: 'Revoked'
  },
  approveFlowType: {
    newBill: 'Apply submit',
    abolishBill: 'Apply abolish ',
    replaceInvoice: 'Apply invoice replace '
  },
  dutyAgencyType: {
    platform: 'Platform',
    productionSupplier: 'Production supplier',
    materialSupplier: 'Material supplier',
    logisticsSupplier: 'Logistics supplier',
    installSupplier: 'Install supplier'
  },
  dashboard: {
    thisMonth: 'Month',
    thisSeason: 'Season',
    thisYear: 'Year',
    next7Days: 'Next 7 days',
    next14Days: 'Next 14 days',
    next30Days: 'Next 30 days',
    todo: 'Todo',
    projectTodo: 'Project Todo',
    projectSummary: 'Project summary',
    today: 'Today',
    thisWeek: 'Week',
    mineChangeColorToDo: 'Mine change color ToDo',
    mineComplateManuscriptToDo: 'Mine complate manuscript ToDo'
  },
  saleReport: {
    title: 'Sale summary',
    orderArea: 'Take order area',
    producedArea: 'Produced area',
    productionArea: 'Production area',
    notProduceYetArea: 'Not produce yet area',
    consumeArea: 'Consume Area',
    orderAreaTip: 'The sum of the total area of valid project lines',
    producedAreaTip: 'The sum of the total area of lines completed for active projects',
    productionAreaTip: 'Sum of line total area in active project production',
    notProduceYetAreaTip: 'The sum of the total area of the line with outstanding production of the active project',
    consumeAreaTip: 'Sum of recorded platform plus self-operated factory wastage area'
  },
  financeReport: {
    title: 'Finance summary',
    invoiceTotalAmount: 'Invoice total amount',
    invoicedAmount: 'Invoiced amount',
    unInvoiceAmount: 'Un invoice amount',
    invoiceTotalAmountTip: 'Sum of after-tax amounts of invoices created',
    invoicedAmountTip: 'Sum of invoiced after-tax amounts',
    unInvoiceAmountTip: 'Sum of unbilled after-tax amounts'
  },
  prepressReport: {
    changeColor: 'Change color summary',
    completeManuscript: 'Complete manuscript summary',
    waitingForOrder: 'Waiting for order',
    finishedProductWaitingForOrder: 'Finished product pending order',
    typesettingWaitingForOrder: 'Typesetting pending orders',
    production: 'Production',
    finishedProduct: 'Finished product in production',
    typesetting: 'Typesetting is in progress',
    waitingForConfirm: 'Waiting for confirm',
    waitingForComplete: 'Waiting for complete',
    timeoutItem: 'Overdue no delivery',
    inputProjectCode: 'Please fill in the project code',
    productionOrder: 'Production order',
    waitingForCompleteOrder: 'Waiting for complete order',
    productionOrderTip: 'The sum of prepress documents in the status of the current user [in preparation]',
    waitingForCompleteOrderTip: 'The sum of the prepress documents in the status of the current user [to be completed]',
    checkProjectCode: 'Please check that the project code is correct'
  },
  AETodo: {
    uncommittedPrepressItem: 'Un commit prepress',
    notDistributeItem: 'Not distribute',
    unsettledItem: 'Un settled',
    toBeConfirm: 'Items to be confirmed'
  },
  financeTodo: {
    toBeApprovalBillCount: 'waiting approval final statement',
    toBeApprovalInvoiceCount: 'waiting approval invoice',
    waitingInvoice: 'waiting invoice',
    waitingMyApproval: 'waiting my approval',
    waitingApprovalTip: 'Sum of the number of financial documents to be approved',
    waitingInvoiceTip: 'Total number of invoices in pending invoice status',
    waitingMyApprovalTip: 'The current user needs to process the total number of approval documents'
  },
  productionAction: {
    prepressTakeOrder: 'Prepress take order',
    prepressComplete: 'Prepress complete',
    printTakeOrder: 'Print take order',
    printComplete: 'Print complete',
    backendTakeOrder: 'Backend take order',
    backendComplete: 'Backend complete'
  },
  installEmployee: {
    whetherBindWechat: 'WeChat',
    bound: 'Bound',
    unbound: 'Un bound',
    name: 'Name',
    mobile: 'Mobile',
    nickname: 'Nickame',
    area: 'Service area',
    birthday: 'Birthday',
    import: 'Import employee',
    add: 'Add employee',
    edit: 'Edit employee',
    selectBirthday: 'Please select date of birth',
    inputArea: 'Please fill in your region'
  },
  exceptionRecord: {
    explain: 'Exception explain',
    handle: 'Exception hanlde',
    inputExplain: 'Please fill in the exception description',
    inputHandle: 'Please fill in exception handling',
    add: 'Add exception record',
    edit: 'Edit exception record'
  },
  projectConfig: {
    approvalFlagExplain:
      'After this function is opened, all items meeting the following conditions will be processed. It is open by default',
    timeThresholdExplain:
      'Unit: hour, 10 by default. If the shipment time distance operation (order placement/modification) time is less than the delivery lead time, it will be marked as 【Urgent Order】'
  },
  projectToDo: {
    title: 'Project to do',
    myToDo: 'My To Do',
    myInitiated: 'I initiated',
    approved: 'Approved'
  },
  //  异常播报
  exceptionReport: {
    title: 'Exception report',
    logisticsExcept: 'Logistics except',
    slippageUnsigned: 'Slippage unsigned',
    slippageWarning: 'Slippage warning'
  },

  region: {
    code: 'Code',
    name: 'Name',
    administrativeLevel: 'Administrative level',
    administrativeDivisions: 'Administrative divisions',
    parentLevel: 'Higher administrative divisions',
    status: 'Status',
    description: 'Description',
    refreshCache: 'Refresh cache',
    inputName: 'Please fill in the name',
    inputCode: 'Please fill in the code',
    selectLevel: 'Please select an administrative level',
    province: 'Province',
    city: 'City',
    district: 'District'
  },
  suggestionConfig: {
    submitFlagExplain: 'Users cannot submit feedback after closure, it is enabled by default.',
    submitCountLimitFlagExplain: 'It is disabled by default. Once enabled, the submission count below needs to be set.',
    limitCountExplain:
      'Unit: times. It takes effect when "Limit daily submissions" is enabled, and users are not allowed to submit feedback more than the specified number of times per day.',
    submitIntervalExplain:
      'Unit: seconds. The time interval between two feedback submissions, if the time elapsed between the first and second submissions is less than the specified interval, the second submission is not allowed.'
  },
  feedback: {
    title: 'Feedbanck Suggestion',
    code: 'Feedback code',
    content: 'Feedback Content',
    type: 'Feedback type',
    create: 'Submission date',
    source: 'Feedback channel',
    createUserName: 'Submitter',
    createTime: 'Submission time',
    inputContent: 'Please enter feedback content',
    selectType: 'Please select feedback type',
    enclosure: 'Enclosure',
    details: 'Feedback details',
    exportFileName: 'Feedback suggestion',
    status: 'Status',
    handleOpinion: 'Handle opinion'
  },
  feedbackSuggestionStatus: {
    pendingProcessing: 'Pending processing',
    processing: 'processing',
    closed: 'closed'
  },
  feedbackSource: {
    PLATFORM: 'PLATFORM',
    SUPPLIER: 'SUPPLIER',
    PRODUCTION_APP: 'PRODUCTION_APP',
    INSTALL_APP: 'INSTALL_APP'
  },
  settlement: {
    billCode: 'Settlement code',
    count: 'Settlement count',
    createUserName: 'applicant'
  },
  unitsMeasureGroups: {
    approveStatus: 'Approve status',
    enableStatus: 'Enable Status',
    systemFlag: 'System preset',
    batchAudit: 'Audit',
    batchReject: 'Reject',
    title: 'Unit of measurement group',
    code: 'Code',
    name: 'Name',
    datumCode: 'Datum code',
    datumName: 'Datum name',
    datumPrecision: 'Datum precision',
    describe: 'Describe',
    inputCode: 'Please fill in the code',
    inputName: 'Please fill in the name',
    inputDatumCode: 'Please fill in the datum code',
    inputDatumName: 'Please fill in the datum name',
    inputDatumPrecision: 'Please fill in the base unit precision',
    inputDescribe: 'Please fill in the description',
    basicInfo: 'Basic Info',
    otherInfo: 'Other Info',
    createTime: 'Create time',
    createUserName: 'Create user name',
    updateTime: 'Update time',
    updateUserName: 'Update user name',
    forbiddenTime: 'Forbidden time',
    forbiddenUserName: 'Forbidden user name',
    approveTime: 'Approve time',
    approveUserName: 'Approve User name',
    dispatchTime: 'Dispatch time'
  },
  currency: {
    approveStatus: 'Approve status',
    enableStatus: 'Enable status',
    systemFlag: 'System preset',
    batchAudit: 'Audit',
    batchReject: 'Reject',
    title: 'Currency',
    selectCurrency: 'Select currency',
    code: 'Code',
    name: 'Name',
    currencyCode: 'Currency code',
    pricePrecision: 'Price Precision',
    amountPrecision: 'Amount Precision',
    inputCode: 'Please fill in the code',
    inputName: 'Please fill in the name',
    inputCurrencyCode: 'Please fill in the currency code',
    inputPricePrecision: 'Please fill in the price precision',
    inputAmountPrecision: 'Please fill in the amount precision',
    basicInfo: 'Basic Info'
  },
  exchangeRate: {
    approveStatus: 'Approve status',
    enableStatus: 'Enable status',
    systemFlag: 'System preset',
    batchAudit: 'Audit',
    batchReject: 'Reject',
    title: 'Exchange rate',
    sourceCurrencyName: 'Source currency name',
    targetCurrencyName: 'Target currency Name',
    effective: 'Effective',
    sourceExchangeRate: 'Direct exchange rate',
    targetExchangeRate: 'Indirect exchange rate',
    effectiveStartTime: 'Effective date',
    effectiveEndTime: 'Expiration date',
    selectEffectiveStartTime: 'Please select the effective date',
    selectEffectiveEndTime: 'Please select the expiration date',
    effectivetimeErrorTip: 'The expiration date cannot be earlier than the effective date',
    selectSourceCurrency: 'Please select the original currency',
    selectTargetCurrency: 'Please select the target currency',
    inputSourceExchangeRate: 'Please fill in the direct exchange rate',
    inputTargetExchangeRate: 'Please fill in the indirect exchange rate',

    selectWaitApproveData:
      'Please select the data with the enabling status of 【Enabled】 and the approval status of 【Pending Approval】',
    selectPassedData:
      'Please select the data with the activation status of [Enabled] and the approval status of [Approved]',
    selectWaitApproveUsingData:
      'Please select the data with the activation status of [Enabled] and the approval status of [Approved]',
    selectDeleteTip:
      'Please select the data with the enabled status of Enabled and the approval status of Pending and not preset by the system',
    selectDeleteTip1:
      'Please select the data with the enabling status of [Enabled] and the approval status of [Pending Approval]',
    basicInfo: 'Basic info'
  },
  publicApproveStatus: {
    passed: 'Passed',
    waitApprove: 'Wait approve'
  },
  unitsMeasure: {
    approveStatus: 'Approve status',
    enableStatus: 'Enable status',
    systemFlag: 'System preset',
    batchAudit: 'Audit',
    batchReject: 'Reject',
    title: 'Unit of measurement',
    code: 'Code',
    name: 'Name',
    groupId: 'Group',
    precision: 'Precision',
    denominator: 'Denominator',
    numerator: 'Numerator',
    currentUnit: 'Current unit',
    datumName: 'Base unit',
    datumFlag: 'Benchmark unit of measurement',
    describe: 'Describe',
    inputCode: 'Please fill in the code',
    inputName: 'Please fill in the name',
    selectGroupId: ' Please select group',
    inputPrecision: 'Please fill in the precision',
    inputDescribe: 'Please fill in the  describe',
    inputDenominator: 'Please fill in the conversion denominator',
    inputNumerator: 'Please fill in the conversion numerator',
    basicInfo: 'Basic Info'
  },
  serviceItem: {
    approveStatus: 'ApproveStatus',
    enableStatus: 'EnableStatus',
    systemFlag: 'System Presets',
    batchAudit: 'Approve',
    batchReject: 'Cancel approve',
    title: '服务项目',
    name: 'Name',
    code: 'code',
    type: 'Business type',
    inputName: 'Please fill in the name',
    inputCode: 'Please fill in the code',
    selectType: 'Please select a business type',
    createTime: 'Creation time',
    createUserName: 'Create user name',
    selectWaitApproveData: `Please check the data with an enabled status of 'enabled' and an approved status of 'pending approval'`,
    selectPassedData: `Please select data with an enabled status of 'enabled' and an approved status of 'approved'`,
    selectWaitApproveUsingData: `Please select data with an enabled status of 'enabled' and an approved status of 'approved'`,
    selectDeleteTip: `Please select data with an enabled status of 'enabled' and an approved status of 'pending review'`,
    selectDeleteTip1: `Please select data with an enabled status of 'enabled' and an approved status of 'pending review'`,
    basicInfo: 'Basic info'
  },
  businessType: {
    install: 'install',
    measure: 'measure',
    repair: 'repair'
  },

  database: {
    name: 'name',
    inputName: 'Input name',
    code: 'code',
    inputCode: 'Input code',
    approveStatus: 'status',
    enableStatus: 'Enable Status',
    description: 'description',
    inputDescribe: 'Input describe',
    basicInfo: 'Basic info',
    otherInfo: 'Other info',
    createTime: 'Create time',
    createUserName: 'Create user name',
    updateTime: 'Update time',
    updateUserName: 'Update user name',
    forbiddenTime: 'Forbidden time',
    forbiddenUserName: 'Forbidden user name',
    approveTime: 'Approve time',
    approveUserName: 'Approve user name',
    import: 'import',
    selectWaitApproveData: `Please select data with an enabled status of 'enabled' and an approved status of 'pending review'`,
    selectApprovedData: `Please select data with an enabled status of 'enabled' and an approved status of 'approved'`,
    selectDeleteTip: `Please select data with an enabled status of 'enabled' and an approved status of 'pending review'`,
    selectUsingData: `Please select data with an enabled status of 'enabled'`,
    selectDisabledData: `Please select data with an enabled status of 'disabled'`
  },
  warehouseStore: {
    warehouseCode: 'Warehouse code',
    warehouseName: 'Warehouse name',
    storeCode: 'Store code',
    storeCodeRepeat: 'Bin code already exists',
    storeName: 'Store name',
    title: 'Warehouse location relationship',
    selectWarehouseCode: 'Please select a warehouse code',
    selectStoreCode: 'Please select a store code'
  },
  warehouse: {
    title: 'Warehouse',
    positionFlag: 'Enable Management'
  },
  storeLocation: {
    title: 'Store location',
    list: 'Store location list',
    name: 'Store location name',
    code: 'Store location code'
  },
  inventoryType: {
    title: 'Inventory category',
    categoryParent: 'Category parent',
    selectCategoryParent: 'Please select a superior classification'
  },
  materialList: {
    title: 'material',
    serviceControl: 'Service control',
    inventoryInfo: 'Inventory information',
    salesInfo: 'Sales information',
    purchasingInfo: 'Purchasing information',
    productionInfo: 'Production information',
    specification: 'Specification',
    category: 'Category',
    baseUnit: 'BaseUnit',
    brand: 'Brand',
    attribute: 'Attribute',
    sizeUnit: 'Size unit',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    weightUnitName: 'Weight unit name',
    grossWeight: 'Gross weight',
    netWeight: 'Net weight',
    volume: 'Volume',
    inventoryType: 'Inventory type',
    taxRate: 'taxRate(%)',
    saleFlag: 'Can be sold',
    purchaseFlag: 'Can be purchased',
    inventoryFlag: 'Can be inventory',
    productionFlag: 'Can be production',
    outsourceFlag: 'Can be outsource',
    inventoryUnit: 'Inventory unit',
    adjunctUnit: 'Adjunct unit',
    inventoryThresholdFlag: 'Enable Safety Stock Alert',
    inventoryThreshold: 'Safety stock',
    effectiveFlag: 'Enable shelf life',
    effectiveUnit: 'Shelf life unit',
    effectiveNum: 'Shelf life',
    leadTimeUnit: 'Lead time unit',
    effectiveThresholdFlag: 'Enable shelf life warning',
    effectiveThresholdNum: 'Early warning lead time',
    warehouse: 'warehouse',
    store: 'store',
    saleUnit: 'Sale unit',
    priceUnit: 'Price unit',
    purchaseUnit: 'Purchasing unit',
    supplier: 'Default supplier',
    productionUnit: 'Production unit',
    productionStore: 'Warehousing Bin',
    productionWarehouse: 'Warehousing warehouse',
    backend: 'backend',
    importMaterial: 'Import material',
    productionFlagTip: 'Enable at least one of the options in [Production Pre press], [Output], and [Post process]',
    generateName: 'Generate name',
    namePreview: 'Name preview'
  },
  materialPropertyEnum: {
    SELF_CONTROL: 'Self-control',
    OUTSOURCE: 'Outsourcing',
    SERVICE: 'Service',
    PROPERTY: 'Property',
    COST: 'Cost',
    VIRTUALLY: 'Virtually'
  },
  materialClassification: {
    title: 'Material classification',
    categoryParent: 'Category Parent',
    selectCategoryParent: 'Please select a superior classification'
  },
  materialBom: {
    title: 'BoM',
    bomName: 'BOM name',
    bomVersion: 'BOM version',
    mainProductInfo: 'Main product information',
    mainMaterialCode: 'Parent Material Code',
    mainMaterialName: 'Parent Material Name',
    mainMaterialUnitName: 'Parent material unit',
    specification: 'specification',
    attribute: 'attribute',
    materialCode: 'material code',
    materialName: 'Material name',
    type: 'Usage type',
    typeParam: 'Usage calculation parameters',
    numerator: 'Dosage (molecular)',
    denominator: 'Dosage (denominator)',
    fixedAttrition: 'Fixed loss',
    setting: 'setting',
    receiveWarehouse: 'Default Material Issuance Warehouse',
    payment: 'Material requisition method'
  },
  usageTypeEnum: {
    CHANGE: 'Change',
    FIXED: 'Fixed'
  },
  usageCalculationParametersEnum: {
    MADE_AREA: 'Production area',
    MADE_PERIMETER: 'Production perimeter',
    MADE_QUANTITY: 'Production quantity'
  },
  receiveModeEnum: {
    ORDINARY: 'Ordinary',
    BACKFLUSH: 'Backflush',
    NO: 'No'
  },
  workflowDesign: {
    document: 'Document',
    workflowIdentity: 'Workflow Identifier',
    abandonedWorkflow: 'Disposal Process',
    resumeWorkflow: 'Restoration Process',
    tag: 'Process Identifier',
    status: 'Status',
    selectDeleteTip: 'Please select the documents that have not been published',
    selectRestoreTip: 'Please select the documents that have been discarded',
    selectRepealTip: 'Please select the documents that have been published'
  },
  workflowStatus: {
    unpublished: 'Unpublished',
    published: 'Published',
    abandoned: 'Discarded'
  },
  taskCenter: {
    code: 'Code',
    title: 'Title',
    createUserName: 'Sponsor',
    createTime: 'Launch time',
    completeTime: 'Complete time',
    ccTime: 'Cc time',
    status: 'Status',
    approveUserName: 'Approve user name',
    content: 'content',
    setRead: 'Set read',
    initiateApproval: '发起审批',
    leadership: 'Leadership',
    approvalProcess: 'Approval process',
    agree: 'Agree',
    reject: 'Reject',
    selectRevokeTip: 'Please select the document of [Approving].',
    selectUnreadTip: 'Please select unread documents'
  },
  approveResult: {
    approving: 'Under approval',
    approved: 'Have agreed to',
    reject: 'Rejected',
    revoked: 'Revoked'
  },

  prepressCrafts: {
    title: 'Prepress crafts',
    type: 'Crafts type',
    factor: 'factor'
  },
  prepressCraftsTypeEnum: {
    finalized: 'Finalized',
    changeColor: 'Change color'
  },
  prepressStatistics: {
    processedBy: 'Single person',
    orderType: 'Document type',
    PressingTime: 'Pressing time',
    batchEditWorkload: 'Correction workload',
    updateReason: 'Reason for modification',
    dimension: 'Statistical dimension',
    statPeriod: 'Statistical cycle',
    confirmItemTotal: 'Total number of confirmed draft items',
    reeditTotal: 'Total number of layout rounds',
    avgConfirmReedit: 'Average number of confirmed draft rounds',
    reviewsTotal: 'Total number of reviewed manuscripts',
    confirmedTotal: 'Total number of confirmed manuscripts',
    averageConfirmationRate: 'Average accuracy rate (%)',
    workload: 'workload',
    dateRange: 'Date range',
    statisticalCustomer: 'Customer statistics',
    personnelName: 'Personnel Name',
    timeTip: 'Time span exceeds one year, please select a time range that spans within one year'
  },
  batchSetupData: {
    title: 'Batch modification',
    editItem: 'Modification items',
    editMethod: 'Modification method',
    editValue: 'Modify value',
    remove: 'remove',
    selectDataTip: 'Please check the document'
  },
  dimensionEnum: {
    year: 'Yearly',
    quarter: 'Quarterly',
    month: 'monthly',
    week: 'Weekly'
  },
  confirmResultEnum: {
    new: 'New',
    confirm: 'Confirmed draft',
    reedit: 'Revised manuscript'
  },
  layoutStatusEnum: {
    init: 'Not generate',
    generated: 'Generated',
    generating: 'Generating',
    generationFailed: 'Generation failed'
  }
};
