/**
 * 用量类型
 */
export enum UsageTypeEnum {
  /**
   * 变动
   */
  CHANGE,
  /**
   * 固定
   */
  FIXED
}
