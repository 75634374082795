/**
 * 生产订单项次类型
 */
export enum OrderProductTypeEnum {
    /**
     * 成品
     */
    product = 1,
    /**
     * 改色
     */
    sample = 2,
    /**
     * 工时服务
     */
    time = 4
}