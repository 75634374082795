// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const moneyFormat = (value: number | string, precision: number = 2): string => {
  if (!value) {
    return '0';
  }

  const strArr = Number(value).toFixed(precision).split(".");
  const firstPart = Number(strArr[0]).toLocaleString();
  const secondPart = strArr[1];
  
  return firstPart + "." + secondPart;
};