import { DimensionEnum, OrderTypeEnum, ResourceUrlEnum } from '@/resource/enum';
import { DepartmentResource, PrepressStatistics, CompleteManuscriptWorkContentInfo } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload, messageError, translation } from '@/utils';
import { AxiosResponse } from 'axios';
import { NormalSelectOptions } from '@/resource/model/common';

class PreperssStatisticsService {
  public getById(params: { id: number; itemType: number; modifyCount: number }): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<any>(ResourceUrlEnum.prepressTaskStatistics, { params: { ...params } })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 客户平均确稿轮数统计
   * @param query
   * @returns
   */
  public getCustomerStatistics(query: Partial<any>, paging?: Paging): Promise<ApiResponse<Array<PrepressStatistics>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressStatistics>>(`${ResourceUrlEnum.prepressTaskStatistics}/customerStatistics`, cloneQuery)
        .then((res: ApiResponse<Array<PrepressStatistics>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 部门平均确稿率统计
   * @param query
   * @returns
   */
  public getDepRatioStatistics(query: Partial<any>): Promise<Array<PrepressStatistics>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressStatistics>>(`${ResourceUrlEnum.prepressTaskStatistics}/depRatioStatistics`, query)
        .then((res: ApiResponse<Array<PrepressStatistics>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 部门平均确稿轮数统计
   * @param query
   * @returns
   */
  public getDepStatistics(query: Partial<any>): Promise<Array<PrepressStatistics>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressStatistics>>(`${ResourceUrlEnum.prepressTaskStatistics}/depStatistics`, query)
        .then((res: ApiResponse<Array<PrepressStatistics>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 完稿工作明细
   * @param query
   * @returns
   */
  public getListPage(
    query?: Partial<any>,
    paging?: Paging,
    sortOptions?: SortOptions<any>
  ): Promise<ApiResponse<Array<CompleteManuscriptWorkContentInfo>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CompleteManuscriptWorkContentInfo>>(
          `${ResourceUrlEnum.prepressTaskStatistics}/listPage`,
          cloneQuery
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 完稿工作明细--导出
   * @param query
   * @returns
   */
  public detailExport(query?: Partial<any>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.prepressTaskStatistics}/detailExport`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 个人平均率统计
   * @param query
   * @returns
   */
  public getPersonalStatistics(query: Partial<any>): Promise<Array<PrepressStatistics>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressStatistics>>(`${ResourceUrlEnum.prepressTaskStatistics}/personalStatistics`, query)
        .then((res: ApiResponse<Array<PrepressStatistics>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 个人平均确稿率统计-导出
   * @param query
   * @returns
   */
  public personalStatisticsExport(query?: Partial<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.prepressTaskStatistics}/personalStatisticsExport`,
          { ...query },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 部门平均确稿率统计
   * @param query
   * @returns
   */
  public getProjectStatisticss(query: Partial<any>): Promise<Array<PrepressStatistics>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressStatistics>>(`${ResourceUrlEnum.prepressTaskStatistics}/projectStatistics`, query)
        .then((res: ApiResponse<Array<PrepressStatistics>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 部门平均确稿率统计--导出
   * @param query
   * @returns
   */
  public depRatioStatisticsExport(query?: Partial<any>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.prepressTaskStatistics}/depRatioStatisticsExport`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: Array<CompleteManuscriptWorkContentInfo>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<DepartmentResource>(ResourceUrlEnum.prepressTaskStatistics, resource)
        .then((res: ApiResponse<DepartmentResource>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.prepressTaskStatistics, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取维度
   * @returns
   */
  public getDimensionList(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in DimensionEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`dimensionEnum.${key}`),
          value: key
        });
      }
    }
    return options;
  }
  /**
   * 获取单据类型
   * @returns
   */
  public getOrderTypeList(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in OrderTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`projectItemType.${key}`),
          value: OrderTypeEnum[key]
        });
      }
    }
    return options;
  }
}
const preperssStatisticsService = new PreperssStatisticsService();
export default preperssStatisticsService;
