/**
 * 发票类型
 */
export enum InvoiceTypeEnum {
  /**
   * 普通发票
   */
  normalInvoice = 1,
  /**
   * 增值税专用发票
   */
  valueAddedTaxInvoice,
  /**
   * 形式发票
   */
  proformaInvoice
}

/**
 * 发票颜色
 */
export enum InvoiceColorEnum {
  /**
   * 红票
   */
  red = 1,
  /**
   * 蓝票
   */
  blue
}

/**
 * 开票方式
 */
export enum InvoiceModeEnum {
  /**
   * 纸质发票
   */
  paper = 1,
  /**
   * 电子发票
   */
  electronic
}
