/**
 * 生产订单状态
 */
export enum ProductionOrderStatusEnum {
  /**
   * 新建
   */
  new = 1,
  /**
   * 待接单
   */
  waitingAccept,
  /**
   * 待排产
   */
  waitingPlan,
  /**
   * 生产中
   */
  processing,
  /**
   * 待确认
   */
  waitingConfirm,
  /**
   * 已完成
   */
  completed
}
