import { Message, Upload } from 'element-ui';
import { ElButton } from 'element-ui/types/button';
import { ElUpload, ElUploadInternalFileDetail, ElUploadInternalRawFile, FileListItem } from 'element-ui/types/upload';
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface OsFileUploadOptions extends Partial<ElUpload> {
  /**
   * 上传文件最大尺寸 单位为MB
   */
  maxSize?: number;
  /**
   * 文件上传时的loading
   */
  uploadLoading?: boolean;
  /**
   * loading的显示的文本
   */
  loadingText?: (() => string) | string;

  /**
   * 是否隐藏上传提示
   */
  hiddenUploadTip?: boolean;

  /**
   * isSuccessShow
   */
  uploadedShowFile?: boolean;
}

@Component({
  i18n: {
    messages: {
      zh: {
        upload: {
          importExceed: '上传文件数量超出',
          dragUpload: '将文件拖到此处，或',
          clickUpload: '点击上传',
          acceptExtensionTip: '支持的扩展名：',
          maxUploadSizeTip: '文件最大为',
          loadingText: '文件上传中，请等待...'
        }
      },
      en: {
        upload: {
          importExceed: 'The number of uploaded files exceeds the threshold',
          dragUpload: 'Drag the file here, or',
          clickUpload: ' click on the upload',
          maxUploadSizeTip: 'The maximum file size is ',
          acceptExtensionTip: 'Supported extension:',
          loadingText: 'Uploading...'
        }
      }
    }
  }
})
export default class OsFileUpload extends Vue {
  /**
   * 上传配置
   */
  @Prop({
    type: Object,
    required: true
  })
  public options!: OsFileUploadOptions;

  /**
   * 上传按钮的配置
   */
  @Prop({
    type: Object,
    required: false,
    default: (): Partial<ElButton> => {
      return {};
    }
  })
  public uploadBtnOptions!: { name?: string } & Partial<ElButton>;

  public get maxUploadSize(): string {
    if (this.options.maxSize && this.options.maxSize < 1) {
      return `${parseFloat((this.options.maxSize * 1024).toFixed(2))}KB`;
    }
    if (this.options.maxSize && this.options.maxSize > 1024) {
      return `${parseFloat((this.options.maxSize / 1024).toFixed(2))}GB `;
    }
    return `${this.options.maxSize}MB`;
  }

  public clearFiles(): void {
    (this.$refs.upload as Upload).clearFiles();
    this.options.fileList = [];
  }

  public submit(): void {
    (this.$refs.upload as Upload).submit();
  }

  public abort(file: ElUploadInternalFileDetail): void {
    (this.$refs.upload as Upload).abort(file);
  }

  private onChange(file: ElUploadInternalFileDetail, fileList: ElUploadInternalFileDetail[]): void {
    if (file.status === 'ready' && this.options.autoUpload !== undefined && !this.options.autoUpload) {
      if (this.beforeUpload(file.raw)) {
        this.options.fileList?.push(file as FileListItem);
      } else {
        // 不符合上传文件前的检查时，移除该文件
        fileList.pop();
      }
    }
  }

  private beforeUpload(file: ElUploadInternalRawFile): boolean | Promise<File | Blob | boolean> {
    if (this.options.maxSize && file.size / 1024 / 1024 > this.options.maxSize) {
      Message.error(`${this.$t('upload.maxUploadSizeTip')}${this.maxUploadSize}`);
      return false;
    }
    if (this.options.beforeUpload) {
      return this.options.beforeUpload(file);
    }
    return true;
  }

  private onExceed(file: ElUploadInternalFileDetail, fileList: ElUploadInternalFileDetail[]): void {
    if (this.options.limit && fileList.length + 1 > this.options.limit) {
      Message.warning(this.$t('upload.importExceed') as string);
    }
    if (this.options.onExceed) {
      this.options.onExceed(file, fileList);
    }
  }
}
