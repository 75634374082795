import { ResourceUrlEnum } from '@/resource/enum';
import { SupplierDepartmentResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService } from '../base';

class SupplierDepartmentService implements BaseService<SupplierDepartmentResource> {
  public getById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierDepartmentResource>(ResourceUrlEnum.supplierDepartment, { params: { id } })
        .then((res: ApiResponse<SupplierDepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query: Partial<SupplierDepartmentResource>): Promise<Array<SupplierDepartmentResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierDepartmentResource>>(`${ResourceUrlEnum.supplierDepartment}/list`, query)
        .then((res: ApiResponse<Array<SupplierDepartmentResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SupplierDepartmentResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SupplierDepartmentResource>(ResourceUrlEnum.supplierDepartment, resource)
        .then((res: ApiResponse<SupplierDepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SupplierDepartmentResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SupplierDepartmentResource>(ResourceUrlEnum.supplierDepartment, resource)
        .then((res: ApiResponse<SupplierDepartmentResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierDepartment, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查部门名称是否重复
   * @param depName
   * @param companyId 供应商id
   * @param id 部门主键
   * @returns 为true则代表重复
   */
  public checkDepartmentName(depName: string, companyId: number, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierDepartment}/checkDepName`, {
          params: { id, companyId, depName }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取供应商下所有启用状态的部门
   * @param companyId 供应商id
   * @returns 供应商下所有启用状态的部门
   */
  public getAllSupplierDepartments(companyId: number): Promise<Array<SupplierDepartmentResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierDepartmentResource>>(`${ResourceUrlEnum.supplierDepartment}/listDepartment`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<SupplierDepartmentResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierDepartmentService = new SupplierDepartmentService();
export default supplierDepartmentService;
