import { render, staticRenderFns } from "./os-table-query.vue?vue&type=template&id=883ecce8&scoped=true&"
import script from "./os-table-query.ts?vue&type=script&lang=ts&"
export * from "./os-table-query.ts?vue&type=script&lang=ts&"
import style0 from "./os-table-query.scoped.scss?vue&type=style&index=0&id=883ecce8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "883ecce8",
  null
  
)

export default component.exports