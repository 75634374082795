/**
 * 安装计费方式
 */
export enum InstallChargeModeEnum {
  /**
   * 按门店
   */
  shop = 1,
  /**
   * 按面积（平方米）
   */
  area
}
