/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const craftManagementRoutes: RouteConfig = {
  path: '/craft-management',
  component: Layout,
  redirect: 'output',
  children: [
    {
      path: '/output',
      name: 'Output',
      component: async () => import(/* webpackChunkName: "output" */ '@/views/craft-management/output/output.vue'),
      meta: {
        title: 'route.output',
        id: 362
      }
    },
    {
      path: '/backend',
      name: 'Backend',
      component: async () => import(/* webpackChunkName: "backend" */ '@/views//craft-management/backend/backend.vue'),
      meta: {
        title: 'route.backend',
        id: 363
      }
    },
    {
      path: '/prepress-crafts',
      name: 'PrepressList',
      component: async () => import(/* webpackChunkName: "backend" */ '@/views/craft-management/prepress/prepress.vue'),
      meta: {
        title: 'route.prepressCrafts'
      }
    }
  ],
  meta: {
    title: 'route.craft',
    icon: '#craft',
    id: 361
  }
};
