import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ElTableColumn, TableColumn, TableColumnFixedType } from 'element-ui/types/table-column';
import { DefaultSortOptions, ElTable, SortOrder } from 'element-ui/types/table';
import { ElButton } from 'element-ui/types/button';
import { LoadingServiceOptions } from 'element-ui/types/loading';
import { Table } from 'element-ui';
import { debounce } from 'lodash-es';

export interface SummaryPropsOptions<T> {
  /**
   * 要进行合计的属性
   */
  prop: keyof T;
  /**
   * 结果保留几位小数
   */
  fixPlace?: number;
  /**
   * 单位
   */
  unit?: string;
}

export type OsTableColumn<T> = Partial<ElTableColumn> & {
  /**
   * 要绑定的data内字段名
   */
  prop: keyof T;
  label: string;
  /**
   * 数据为空时，默认展示的内容
   */
  emptyValue?: string;
  /**
   * 该列是否隐藏
   */
  hide?: boolean;
  /**
   * 自定义索引
   */
  customIndex?: number;
};

/**
 * 操作配置
 */
export interface Operation<T> extends Partial<ElButton> {
  /**
   * 操作类型，用于标识按钮的唯一性
   */
  operationType: 'add' | 'edit' | 'delete' | 'detail' | string;
  /**
   * 按钮显示的文本
   * 请保证按钮文本和图标至少有一项
   */
  label?: string;
  /**
   * 按钮的图标
   */
  icon?: string;
  /**
   * 是否动态隐藏该操作按钮,不传则默认显示按钮
   * 返回true则隐藏
   */
  dynamicHidden?: (rowData: T) => boolean;
  handleClick: (roleData: T, operation: Operation<T>) => void;
  /**
   * 权限标识
   */
  permissionCode?: string;
}
/**
 * 行操作
 */
export interface RowOperation<T extends object> extends Partial<ElTableColumn> {
  /**
   * 同column的fixed，不传默认为 'right'
   */
  fixed?: boolean | TableColumnFixedType;
  /**
   * 同column的label，不传默认为 '操作'
   */
  label?: string;
  /**
   * 操作配置
   */
  operations: Array<Operation<T>>;
}

/**
 * 表格配置
 */
export interface OsTableOption<T extends object> extends Partial<Omit<ElTable, 'defaultSort'>> {
  data: Array<T>;
  /**
   * 加载数据时滚动
   */
  loading?: boolean;
  loadingOptions?: LoadingServiceOptions;
  /**
   * 开启表格单选
   */
  radioSelection?: boolean;
  /**
   * 表格距底部距离，为其他元素预留位置
   */
  bottomOffset?: number;
  size?: 'medium' | 'small' | 'mini';
  className?: string;
  /**
   * 是否关闭自适应高度
   */
  closeAdaptiveHeight?: boolean;
  sumPropsOptions?: Array<SummaryPropsOptions<T>>;
  /**
   * 默认排序
   * 覆盖el-table默认的defaultSort类型定义
   */
  defaultSort?: { prop: keyof T; order: SortOrder };
}
@Component({
  name: 'os-table'
})
export default class OsTable extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  public tableOption!: OsTableOption<object>;

  @Prop({
    required: true,
    type: Array
  })
  public tableColumnOption!: Array<OsTableColumn<any>>;

  @Prop({
    type: Object,
    required: false
  })
  public rowOperationOption!: RowOperation<any>;

  public radioSelected: object | null = null;

  private tableKey = true;

  private maxHeight = 200;

  private clientHeight = 0;

  private selectedRows: Array<object> = [];

  /**
   * 实现shift多选相关的参数
   */
  private shiftMultipleOpts = {
    /**
     * 当前shift按键是否被按下
     */
    keyDown: false,
    /**
     * 记录选中的起始行索引
     */
    startIndex: -1,
    allowEmit: true
  };

  public get border(): boolean {
    return this.tableOption.border ?? true;
  }

  public get emptyText(): string | undefined {
    return this.tableOption.emptyText ? this.$t(this.tableOption.emptyText as string).toString() : undefined;
  }

  public mounted(): void {
    this.initKeyDownListener();
    if (this.tableOption.closeAdaptiveHeight) {
      return;
    }
    this.getAutoMaxHeight();
    window.onresize = debounce((): void => {
      this.clientHeight = document.documentElement.clientHeight;
    }, 100);
  }

  public destroyed(): void {
    window.onresize = null;
  }

  public handleSelectionChange(values: Array<object>): void {
    if (this.shiftMultipleOpts.allowEmit) {
      this.$emit('selection-change', values);
    }
  }

  public handleSortChange(sortOptions: DefaultSortOptions): void {
    this.$emit('sort-change', sortOptions);
  }

  public rowDblclick(row: Object, column: ElTableColumn, event: PointerEvent): void {
    this.$emit('row-dblclick', row, column, event);
  }
  // 双击表格内容实现单选
  public handleRowClick(row: Object, column: ElTableColumn, event: PointerEvent): void {
    if (this.tableOption.radioSelection === true) {
      this.radioSelected = row;
    }
    this.$emit('row-click', row, column, event);
  }

  public clearSelection(): void {
    (this.$refs.osTable as Table).clearSelection();
  }

  public doLayout(): void {
    (this.$refs.osTable as Table).doLayout();
  }

  public reload(): void {
    this.tableKey = !this.tableKey;
  }

  public get tableRef(): Table {
    return this.$refs.osTable as Table;
  }

  /**
   * 设置单选情况下的的行选中状态
   */
  public toggleRadioSelection(rowData: object): void {
    this.radioSelected = rowData;
  }

  public clearRadioSelection(): void {
    this.radioSelected = null;
  }

  public openLoading(): void {
    this.setLoading(true);
  }

  public closeLoading(): void {
    this.setLoading(false);
  }

  public handleCellClick(row: Object, column: ElTableColumn, cell: Object): void {
    this.$emit('cell-click', row, column, cell);
  }

  /**
   * 设置loading属性
   * @param value 状态
   */
  private setLoading(value: boolean): void {
    this.tableOption.loading = value;
  }

  @Watch('radioSelected')
  private handleRadioSelection(value: object): void {
    this.$emit('radio-selection-change', value);
  }

  private get getLoadingText(): string {
    return this.tableOption.loadingOptions
      ? this.tableOption.loadingOptions.text || 'table.loadingText'
      : 'table.loadingText';
  }

  private get getLoadingSpinner(): string {
    return this.tableOption.loadingOptions
      ? this.tableOption.loadingOptions.spinner || 'el-icon-loading'
      : 'el-icon-loading';
  }

  private get getLoadingBackground(): string {
    return this.tableOption.loadingOptions ? this.tableOption.loadingOptions.background || '' : '';
  }

  private renderSlot(item: OsTableColumn<any>): boolean {
    return item.type !== 'selection' && item.type !== 'index' && !item.formatter;
  }

  private getAutoMaxHeight(): void {
    const el = (this.$refs.osTable as ElTable).$el;
    let bottomOffset = 20;
    if (this.tableOption.bottomOffset !== 0) {
      bottomOffset = this.tableOption.bottomOffset || 78;
    }
    this.$nextTick(() => {
      // 实测，第一次渲染页面时，获取到的视口位置的高度会有31px的误差（大了31px），不知道为什么，下一次执行周期，会恢复正常
      const topHeight = el.getBoundingClientRect().top;
      this.maxHeight = window.innerHeight - topHeight - bottomOffset;
    });
  }

  @Watch('clientHeight')
  private computedMaxHeight(): void {
    this.getAutoMaxHeight();
  }

  /**
   * 计算指定列的合计
   * @param param
   * @returns
   */
  private getSummaries(param: any): Array<string> {
    const { columns, data }: { columns: Array<TableColumn>; data: Array<any> } = param;
    if (data.length === 0) {
      return [];
    }
    const sums: Array<string> = [];
    const needSumProps: Array<string> = this.tableOption.sumPropsOptions?.map(x => x.prop) || [];

    columns.forEach((column, index) => {
      if (index === 0) {
        sums[index] = this.$t('table.summaryText') as string;
        return;
      }
      if (!needSumProps.includes(column.property as any)) {
        sums[index] = '';
        return;
      }

      const values = data.map(item => Number(item[column.property]));

      if (!values.every((value: any) => isNaN(value))) {
        const sumValue = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!isNaN(value)) {
            return prev + curr;
          } else {
            return prev;
          }
        }, 0);

        let sumRes = '';
        const { fixPlace, unit } = this.tableOption.sumPropsOptions!.find(x => x.prop === column.property)!;
        sumRes = fixPlace ? sumValue?.toFixed(fixPlace) : sumValue.toString();
        sumRes = unit ? `${sumRes} ${unit}` : sumRes;

        sums[index] = sumRes;
      } else {
        sums[index] = 'N/A';
      }
    });
    return sums;
  }

  /**
   * 监听键盘按下、弹起事件
   */
  private initKeyDownListener(): void {
    window.addEventListener('keydown', e => {
      if (e.shiftKey) {
        this.shiftMultipleOpts.keyDown = true;
      }
    });
    window.addEventListener('keyup', () => {
      this.shiftMultipleOpts.keyDown = false;
    });
  }

  private shiftMultiple(selection: Array<object>, row: object): void {
    // table当前数据
    const data = (this.$refs.osTable as ElTable).data;
    // 最后选中行的索引
    const endIdx = (this.$refs.osTable as ElTable).data.findIndex(x => x === row);
    if (this.shiftMultipleOpts.keyDown && selection.includes(data[this.shiftMultipleOpts.startIndex])) {
      this.shiftMultipleOpts.allowEmit = false;
      // 用户可能反向选取，所以要取绝对值
      const sum = Math.abs(this.shiftMultipleOpts.startIndex - endIdx) + 1;
      // 获取起点和终点较小的值
      const min = Math.min(this.shiftMultipleOpts.startIndex, endIdx);
      let i = 0;
      this.$nextTick(() => {
        while (i < sum) {
          const index = min + i;
          // 设置最后一个选中时，允许emit selection-change 事件，防止每次设置选中项都emit一次
          if (i === sum - 2) {
            this.shiftMultipleOpts.allowEmit = true;
          }
          if (!this.selectedRows.includes(data[index])) {
            (this.$refs.osTable as ElTable).toggleRowSelection(data[index], true);
          }
          i++;
        }
      });
    } else {
      // 记录最后一次仅点击的行索引
      this.shiftMultipleOpts.startIndex = endIdx;
    }
  }
}
