import { axiosRequest } from '@/utils/axios-request';
import { ResourceUrlEnum, ResourceStatusEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Cancel, AxiosResponse } from 'axios';
import { BaseService, Paging } from '../base';
import { SupplierResource } from '@/resource/model';
import { handleDownload } from '@/utils';

class SupplierService implements BaseService<SupplierResource> {
  /**
   * 获取可用供应商数据
   */
  public listEnable(): Promise<Array<SupplierResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierResource>>(`${ResourceUrlEnum.supplier}/listEnable`)
        .then((res: ApiResponse<Array<SupplierResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 供应商等级变更
   * @param supplierId 供应商ID
   * @param gradeId 等级ID
   * @returns
   */
  public modifyLevel(supplierId: number, gradeId: number): Promise<void> {
    if (!supplierId || !gradeId) {
      throw new Error('companyId or gradeId must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplier}/editGrade`, { supplierId, gradeId })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public import(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplier}/importData`, { filePath })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: ApiError | Cancel) => {
          reject(errorRes);
        });
    });
  }

  /**
   * 下载导入模板
   * @returns Promise 文件流
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.supplier}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getById(id: number): Promise<SupplierResource> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierResource>(`${ResourceUrlEnum.supplier}`, { params: id })
        .then((res: ApiResponse<SupplierResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询供应商数据
   * @param query 查询参数对象
   * @param paging 分页参数对象
   * @returns response body
   */
  public getList(
    query: Partial<SupplierResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierResource>>> {
    const cloneQuery = {};

    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierResource>>(`${ResourceUrlEnum.supplier}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 新增供应商
   * @param resource 供应商数据对象
   */
  public post(resource: SupplierResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplier}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 修改供应商信息
   * @param resource 供应商数据对象
   */
  public put(resource: SupplierResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    if (!resource.id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplier}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除供应商
   * @param id 供应商ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplier}`, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除供应商
   * @param idList 企业ID集合
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplier}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查企业名称是否重复
   * @param name 企业名称
   * @param id 企业ID
   */
  public checkCompanyNameRepeat(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplier}/checkNameRepeat`, { params: { name, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查企业域名是否重复
   * @param domainName 企业域名
   * @param id 企业ID
   */
  public checkDomainNameRepeat(domainName: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplier}/checkDomainNameRepeat`, { params: { domainName, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量更新状态
   * @param idList 供应商ID集合
   * @param status 状态 1.启用 2.禁用
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    if (!idList || idList.length === 0) {
      throw new Error('idList must not be null');
    }

    if (!status) {
      throw new Error('status must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplier}/editStatus`, { idList, status })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 修改供应商等级
   * @param gradeId 等级ID
   * @param supplierId 供应商ID
   * @returns
   */
  public editGrade(gradeId: number, supplierId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplier}/editGrade`, { gradeId, supplierId })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const supplierService = new SupplierService();
export default supplierService;
