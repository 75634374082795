/**
 * 审批状态
 */
enum ApproveResultEnum {
  /**
   * 审批中
   */
  approving = 0,
  /**
   * 通过
   */
  approved = 1,
  /**
   * 驳回
   */
  reject = 2,
  /**
   * 已撤销
   */
  revoked = 3
}

/**
 * 审批流程类型
 */
enum ApproveFlowTypeEnum {
  /**
   * 结算单提交申请
   */
  newBill = 1,
  /**
   * 结算单废除申请
   */
  abolishBill,
  /**
   * 发票换开申请
   */
  replaceInvoice
}

export { ApproveResultEnum, ApproveFlowTypeEnum };
