import {
  ResourceStatusEnum,
  ResourceUrlEnum,
  UsageCalculationParametersEnum,
  UsageTypeEnum,
  ReceiveModeEnum
} from '@/resource/enum';
import {
  BillOfMaterialDetails,
  ImportRes,
  BillOfMaterialSourceList,
  BillOfMaterialQuery,
  UnitOfMeasurementGroupResource
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload, messageError, translation } from '@/utils';
import { AxiosResponse } from 'axios';

class BillOfMaterialService {
  public getById(id: number): Promise<BillOfMaterialDetails> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<BillOfMaterialDetails>(`${ResourceUrlEnum.billOfMaterial}`, {
          params: { id }
        })
        .then((res: ApiResponse<BillOfMaterialDetails>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: UnitOfMeasurementGroupResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<UnitOfMeasurementGroupResource>(ResourceUrlEnum.billOfMaterial, resource)
        .then((res: ApiResponse<UnitOfMeasurementGroupResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: UnitOfMeasurementGroupResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<UnitOfMeasurementGroupResource>(ResourceUrlEnum.billOfMaterial, resource)
        .then((res: ApiResponse<UnitOfMeasurementGroupResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.billOfMaterial}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<BillOfMaterialQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<BillOfMaterialSourceList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<BillOfMaterialSourceList>>(`${ResourceUrlEnum.billOfMaterial}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<BillOfMaterialSourceList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 弹窗组件
   * @param query
   * @param paging
   * @returns
   */
  public getSelectList(
    query?: Partial<BillOfMaterialQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<BillOfMaterialSourceList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<BillOfMaterialSourceList>>(`${ResourceUrlEnum.billOfMaterial}/listForPageComponents`, cloneQuery)
        .then((res: ApiResponse<Array<BillOfMaterialSourceList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 名称去重
   * @param name
   * @param id
   * @returns
   */
  public checkName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.billOfMaterial}/checkName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkCode(code: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.billOfMaterial}/checkCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 基准编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkDatumCode(datumCode: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.billOfMaterial}/checkDatumCode`, {
          params: { id, datumCode }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 基准编号去重
   * @param code
   * @param id
   * @returns
   */
  public checkDatumName(datumName: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.billOfMaterial}/checkDatumName`, {
          params: { id, datumName }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修状态
   * @param idList 计量单位分组id数组
   * @param status 状态
   * @returns
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.billOfMaterial}/editEnableStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 审批
   * @param idList
   * @returns
   */
  public batchApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.billOfMaterial}/approve`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 反审批
   * @param idList
   * @returns
   */
  public batchCancelApprove(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.billOfMaterial}/cancelApprove`, idList)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public import(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.billOfMaterial}/importData`, { filePath })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 下载物料分类入模板
   * @returns
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.billOfMaterial}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取用量类型
   * @returns
   */
  public getUsageTypeList(): Array<{ label: string; value: string }> {
    const options: Array<{ label: string; value: string }> = [];
    for (const key in UsageTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`usageTypeEnum.${key}`),
          value: key
        });
      }
    }
    return options;
  }
  /**
   * 获取用量计算参数selectList
   * @returns
   */
  public getUsageCalculationParametersList(): Array<{ label: string; value: string }> {
    const options: Array<{ label: string; value: string }> = [];
    for (const key in UsageCalculationParametersEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`usageCalculationParametersEnum.${key}`),
          value: key
        });
      }
    }
    return options;
  }
  /**
   * 获取用量计算参数selectList
   * @returns
   */
  public getReceiveModeList(): Array<{ label: string; value: string }> {
    const options: Array<{ label: string; value: string }> = [];
    for (const key in ReceiveModeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`receiveModeEnum.${key}`),
          value: key
        });
      }
    }
    return options;
  }
}
const billOfMaterialService = new BillOfMaterialService();
export default billOfMaterialService;
