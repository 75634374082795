/**
 * 生产订单变更单状态枚举
 */
export enum OrderChangeStatusEnum {
  /**
   * 新建
   */
  new = 1,
  /**
   * 待审核
   */
  // pendingApproval,
  /**
   * 审核未通过
   */
  // approvalFailed,
  /**
   * 待确认
   */
  pendingConfirm = 2,
  /**
   * 驳回
   */
  reject = 3,
  /**
   * 已生效
   */
  finished = 5
}
