/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const supplierManagementRoutes: RouteConfig = {
  path: '/supplier-management',
  name: 'SupplierManagement',
  component: Layout,
  redirect: 'supplier',
  children: [
    {
      path: '/supplier',
      name: 'Supplier',
      component: async () => import('@/views/supplier-management/supplier/supplier.vue'),
      meta: {
        title: 'route.supplierList',
        id: 593
      }
    },
    {
      path: '/supplier-role',
      name: 'SupplierRole',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-role" */ '@/views/supplier-management/supplier/supplier-role/supplier-role.vue'
        ),
      meta: {
        title: 'route.role',
        id: 651,
        hidden: true,
        parentId: 593
      }
    },
    {
      path: '/supplier-department',
      name: 'SupplierDepartment',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-department" */ '@/views/supplier-management/supplier/supplier-department/supplier-department.vue'
        ),
      meta: {
        title: 'route.department',
        id: 653,
        hidden: true,
        parentId: 593
      }
    },
    {
      path: '/supplier-position',
      name: 'SupplierPosition',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-position" */ '@/views/supplier-management/supplier/supplier-position/supplier-position.vue'
        ),
      meta: {
        title: 'route.position',
        id: 652,
        hidden: true,
        parentId: 593
      }
    },
    {
      path: '/supplier-personnel',
      name: 'SupplierPersonnel',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-personnel" */ '@/views/supplier-management/supplier/supplier-personnel/supplier-personnel.vue'
        ),
      meta: {
        title: 'route.personnel',
        id: 654,
        hidden: true,
        parentId: 593
      }
    },
    {
      path: '/supplier-device',
      name: 'SupplierDevice',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-device" */ '@/views/supplier-management/supplier/supplier-device/supplier-device.vue'
        ),
      meta: {
        title: 'route.supplierDevice',
        id: 739,
        hidden: true,
        parentId: 593
      }
    },
    {
      path: '/supplier-product',
      name: 'SupplierProduct',
      component: async () =>
        import(
          /* webpackChunkName: "supplier-product" */ '@/views/supplier-management/supplier/supplier-product/supplier-product.vue'
        ),
      meta: {
        title: 'route.supplierProduct',
        id: 762,
        hidden: true,
        parentId: 593
      }
    },
    {
      path: '/supplier-classify',
      name: 'SupplierClassify',
      component: async () => import('@/views/supplier-management/supplier-classify/supplier-classify.vue'),
      meta: {
        title: 'route.supplierClassify',
        id: 592
      }
    }
  ],
  meta: {
    title: 'route.supplier',
    icon: '#supplier',
    id: 591
  }
};
