import { ResourceUrlEnum, ErrorLevelEnum } from '@/resource/enum';
import { AxiosRequestConfig } from 'axios';
import { axiosRequest } from '../utils/axios-request';

export interface ApiResponse<T> {
  code: number;
  data: T;
  message: string;
  total: number;
}
export interface ApiError {
  level: ErrorLevelEnum;
  code?: number;
  message?: string;
}
class AxiosService {
  public get<T = any>(url: ResourceUrlEnum | string, config?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<ApiResponse<T>>(url, config)
        .then(axiosRes => resolve(axiosRes.data))
        .catch((error: ApiError) => {
          reject(error);
        });
    });
  }

  public post<T = any>(
    url: ResourceUrlEnum | string,
    data?: T | any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<T>> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<ApiResponse<T>>(url, data, config)
        .then(axiosRes => resolve(axiosRes.data))
        .catch((error: ApiError) => {
          reject(error);
        });
    });
  }

  public put<T = any>(
    url: ResourceUrlEnum | string,
    data: T | any,
    config?: AxiosRequestConfig
  ): Promise<ApiResponse<T>> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .put<ApiResponse<T>>(url, data, config)
        .then(axiosRes => resolve(axiosRes.data))
        .catch((error: ApiError) => {
          reject(error);
        });
    });
  }

  public delete<T = any>(url: ResourceUrlEnum | string, config: AxiosRequestConfig): Promise<ApiResponse<T>> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .delete<ApiResponse<T>>(url, config)
        .then(axiosRes => resolve(axiosRes.data))
        .catch((error: ApiError) => {
          reject(error);
        });
    });
  }
}
export const axiosService = new AxiosService();
