import { ProductionProductStatusEnum } from './../../resource/enum/production-product-status';
import { ProcessStatusEnum } from './../../resource/enum/process-status';
import { ProcessEnum, ResourceUrlEnum } from '@/resource/enum';
import { Paging } from '../base';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ProductionOrderProductResource, SupplierProductResource } from '@/resource/model';
import { BrokenLogResource } from '@/resource/model/production-management/broken-log';

class ProductionOrderProductService {
  /**
   * 根据订单ID获取所有项次数据
   * @param orderId 订单ID
   */
  public listAll(orderId: number): Promise<Array<ProductionOrderProductResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ProductionOrderProductResource>>(`${ResourceUrlEnum.productionOrderProduct}/listByOrderId`, {
          params: { orderId }
        })
        .then((res: ApiResponse<Array<ProductionOrderProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 根据平台产品查询可用供应商产品数据
   * @param query 检索条件
   * @returns
   */
  public listEnableByPlatformProduct(query: Partial<any>): Promise<Array<SupplierProductResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierProductResource>>(`${ResourceUrlEnum.productionOrder}/getSupplierProductList`, {
          params: query
        })
        .then((res: ApiResponse<Array<SupplierProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getById(id: number): Promise<ProductionOrderProductResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductionOrderProductResource>(`${ResourceUrlEnum.productionOrderProduct}/detailById`, {
          params: { id }
        })
        .then((res: ApiResponse<ProductionOrderProductResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getList(
    query: Partial<ProductionOrderProductResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductionOrderProductResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderProductResource>>(`${ResourceUrlEnum.productionOrderProduct}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProductionOrderProductResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据工序状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getProcessStatusI18Key(status: ProcessStatusEnum): string {
    return 'processStatus.' + ProcessStatusEnum[status];
  }

  /**
   * 根据工序状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: ProductionProductStatusEnum): string {
    return 'orderProductStatus.' + ProductionProductStatusEnum[status];
  }

  /**
   * 根据工序获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getProcessI18Key(status: ProcessEnum): string {
    return 'orderProduct.' + ProcessEnum[status];
  }

  /**
   * 根据工序状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: ProductionProductStatusEnum): string {
    let className = '';
    switch (status) {
      case ProductionProductStatusEnum.waitPlan:
        className = 'info-dot';
        break;
      case ProductionProductStatusEnum.new:
        className = 'default-dot';
        break;
      case ProductionProductStatusEnum.processing:
        className = 'primary-dot';
        break;
      case ProductionProductStatusEnum.completed:
        className = 'success-dot';
        break;
      case ProductionProductStatusEnum.canceled:
        className = 'default-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 分页查询订单项次报损记录
   * @param query 查询参数
   * @param paging 分页参数
   */
  public getProductConsumeList(
    query: Partial<BrokenLogResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<BrokenLogResource>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<BrokenLogResource>>(`${ResourceUrlEnum.productionOrderProduct}/attritionListPage`, cloneQuery)
        .then((res: ApiResponse<Array<BrokenLogResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const productionOrderProductService = new ProductionOrderProductService();
export default productionOrderProductService;
