/**
 * 印前服务类型
 */
export enum PrepressTypeEnum {
  /**
   * 排版
   */
  finalized = 1,
  /**
   * 改色
   */
  changeColor = 2,
  /**
   * 成品
   */
  typesetting = 3
}
