/**
 * 印前进度操作枚举
 */
export enum PrepressActionEnum {
  /**
   * 新建
   */
  new = 1,
  /**
   * 提交制作
   */
  submitProduction,
  /**
   *取消
   */
  cancel,
  /**
   * 接单
   */
  takeOrder,
  /**
   * 提交确稿
   */
  submitConfirm,
  /**
   * 发起改稿
   */
  reddit,
  /**
   * AE确稿
   */
  AEConfirm,
  /**
   * 完成
   */
  complete,
  /**
   * 提交打样
   */
  submitProofing,
  /**
   * 打样完成
   */
  proofingComplete,
  /**
   * AE手动关闭
   */
  AEClose = 11,
  /**
   * 印前取消确稿
   */
  cancelConfirm = 12,
  /**
   * AE反关闭
   */
  cancelClose = 13,
  /**
   * 印前归档
   */
  prepressArchive = 14,
  /**
   * 印前上传确稿图
   */
  uploadFile = 15,
  /**
   * 印前成平接单
   */
  receivingFinishOrder = 16,
  /**
   * 印前成平接单
   */
  aeCancelMake = 17
}
