/**
 * 安装通知单状态
 */
export enum InstallationNoticeStatusEnum {
  /**
   * 新建
   */
  new = 1,
  /**
   * 待接单
   */
  waitingTake = 2,
  /**
   * 待分配
   */
  waitingInstall = 3,

  /**
   * 部分分配
   */
  partialInstall = 4,
  /**
   * 已安装
   */
  completed = 5
}
