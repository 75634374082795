/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const hmModuleRoutes: RouteConfig = {
  path: '/hm-module',
  component: Layout,
  redirect: 'hm-packaging',
  name: 'HMModule',
  children: [
    {
      path: '/hm-packaging',
      name: 'HMPackaging',
      component: async () =>
        import(/* webpackChunkName: "hm-packaging" */ '@/views/hm-module/hm-packaging/hm-packaging.vue'),
      meta: {
        title: 'route.hmPackaging',
        id: 1717
      }
    },
    {
      path: '/hm-order',
      name: 'HMOrder',
      component: async () => import(/* webpackChunkName: "hm-order" */ '@/views/hm-module/hm-order/hm-order.vue'),
      meta: {
        title: 'route.hmOrder',
        id: 1718
      }
    }
  ],
  meta: {
    title: 'route.hmModule',
    icon: '#project',
    id: 1716
  }
};
