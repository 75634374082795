import { ResourceUrlEnum } from '@/resource/enum';
import { CreateExceptionRecord, ExceptionRecordList, UpdateExceptionRecord } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

export class InstallExceptionRecordService {
  /**
   * 分页查询
   * @param orderId 安装订单id
   * @param paging 分页参数
   * @returns 分页数据
   */
  public static getList(installOrderId: number, paging: Paging): Promise<ApiResponse<Array<ExceptionRecordList>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ExceptionRecordList>>(`${ResourceUrlEnum.installException}/listPage`, { installOrderId, ...paging })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public static post(resource: CreateExceptionRecord): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CreateExceptionRecord>(ResourceUrlEnum.installException, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public static put(resource: UpdateExceptionRecord): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<UpdateExceptionRecord>(ResourceUrlEnum.installException, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList 资源ID集合
   */
  public static batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.installException}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
