import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { Route } from 'vue-router';
import store from '@/store';
import { eventCenter } from '@/utils/event-center';

export interface ITagView extends Partial<Route> {
  title?: string;
}

export interface ITagsViewState {
  /**
   * 访问过的页面
   */
  visitedViews: ITagView[];
  /**
   * 缓存的页面
   */
  cachedViews: (string | undefined)[];
}

@Module({ dynamic: true, store, name: 'tagsView' })
class TagsView extends VuexModule implements ITagsViewState {
  public visitedViews: Array<ITagView> = [];

  public cachedViews: Array<string | undefined> = [];

  @Action
  public AddView(view: ITagView): void {
    this.addVisitedView(view);
    this.addCachedVIew(view);
  }

  @Action
  public AddVisitedView(view: ITagView): void {
    this.addVisitedView(view);
  }

  @Action
  public DelView(view: ITagView): void {
    this.delVisitedView(view);
    this.delCachedView(view);
  }

  @Action
  public DelCachedView(view: ITagView): void {
    this.delCachedView(view);
  }

  @Action
  public DelOthersViews(view: ITagView): void {
    this.delOthersVisitedViews(view);
    this.delOthersCachedViews(view);
  }

  @Action
  public DelAllViews(): void {
    this.delAllVisitedViews();
    this.delAllCachedViews();
  }

  @Action
  public RefreshCurrentTag(): void {
    eventCenter.$emit('refresh-current-tag');
  }

  @Action
  public DelAllCachedViews(): void {
    this.delAllCachedViews();
  }

  @Action
  public UpdateVisitedView(view: ITagView): void {
    this.updateVisitedView(view);
  }

  @Mutation
  private updateVisitedView(view: ITagView): void {
    for (let v of this.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view);
        break;
      }
    }
  }

  @Mutation
  private addVisitedView(view: ITagView): void {
    if (this.visitedViews.some(v => v.path === view.path)) return;
    this.visitedViews.push({ ...view, title: view.meta!.title || 'no-name' });
  }

  @Mutation
  private addCachedVIew(view: ITagView): void {
    if (view.name === null) return;
    if (this.cachedViews.includes(view.name)) return;
    if (!view.meta!.noCache) {
      this.cachedViews.push(view.name);
    }
  }

  @Mutation
  private delVisitedView(view: ITagView): void {
    for (const [i, v] of this.visitedViews.entries()) {
      if (v.path === view.path) {
        this.visitedViews.splice(i, 1);
        break;
      }
    }
  }

  @Mutation
  private delCachedView(view: ITagView): void {
    if (view.name === null) return;
    const index = this.cachedViews.indexOf(view.name);
    index > -1 && this.cachedViews.splice(index, 1);
  }

  @Mutation
  private delOthersVisitedViews(view: ITagView): void {
    this.visitedViews = this.visitedViews.filter(v => {
      return v.meta!.affix || v.path === view.path;
    });
  }

  @Mutation
  private delOthersCachedViews(view: ITagView): void {
    if (view.name === null) return;
    const index = this.cachedViews.indexOf(view.name);
    if (index > -1) {
      this.cachedViews = this.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1，清空缓存的标签
      this.cachedViews = [];
    }
  }

  @Mutation
  private delAllVisitedViews(): void {
    const affixTags = this.visitedViews.filter(tag => tag.meta!.affix);
    this.visitedViews = affixTags;
  }

  @Mutation
  private delAllCachedViews(): void {
    this.cachedViews = [];
  }
}

export const TagsViewModule = getModule(TagsView);
