import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ImportRes, SupplierPersonnelResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class SupplierPersonnelService implements BaseService<SupplierPersonnelResource> {
  public getById(id: number): Promise<SupplierPersonnelResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierPersonnelResource>(ResourceUrlEnum.supplierPersonnel, { params: { id } })
        .then((res: ApiResponse<SupplierPersonnelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SupplierPersonnelResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SupplierPersonnelResource>(ResourceUrlEnum.supplierPersonnel, resource)
        .then((res: ApiResponse<SupplierPersonnelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SupplierPersonnelResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SupplierPersonnelResource>(ResourceUrlEnum.supplierPersonnel, resource)
        .then((res: ApiResponse<SupplierPersonnelResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierPersonnel, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(list: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierPersonnel}/batchDelete`, { data: list })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<SupplierPersonnelResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierPersonnelResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierPersonnelResource>>(`${ResourceUrlEnum.supplierPersonnel}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierPersonnelResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查人员属性是否重复
   * @param props 属性对象 { userName?: string; email?: string; mobile?: string },
   * @param id 人员主键 （修改时需要传入）
   * @returns 为true则代表重复
   */
  public checkPersonnelProp(
    props: { userName?: string; email?: string; mobile?: string },
    companyId: number,
    id?: number
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<boolean>(`${ResourceUrlEnum.supplierPersonnel}/checkData`, { id, companyId, ...props })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改人员状态
   * @param idList 人员id数组
   * @param status 状态
   * @returns
   */
  public batchUpdatePersonnelStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierPersonnel}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 重置用户密码
   * @param id 用户id
   * @returns
   */
  public resetPassword(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(
          `${ResourceUrlEnum.supplierPersonnel}/resetPassword`,
          {},
          {
            params: { id }
          }
        )
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public importPersonnel(filePath: string, companyId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierPersonnel}/importData`, { filePath, companyId })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierPersonnelService = new SupplierPersonnelService();
export default supplierPersonnelService;
