import { DeviceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { AxiosResponse } from 'axios';
import { BaseService, Paging } from '../base';
import { SupplierDeviceResource } from '@/resource/model';
import { axiosRequest } from '@/utils/axios-request';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { handleDownload } from '@/utils';

class SupplierDeviceService implements BaseService<SupplierDeviceResource> {
  public getById(id: number): Promise<SupplierDeviceResource> {
    if (!id) {
      throw new Error('id must not be null');
    }
    throw new Error('Method not implemented.');
  }
  /**
   * 分页查询
   * @param query 查询参数对象
   * @param paging 分页参数对象
   */
  public getList(
    query: Partial<SupplierDeviceResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierDeviceResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierDeviceResource>>(`${ResourceUrlEnum.supplierDevice}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierDeviceResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 创建
   * @param resource 创建对象
   */
  public post(resource: SupplierDeviceResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierDevice}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 修改
   * @param resource 修改对象
   */
  public put(resource: SupplierDeviceResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierDevice}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除
   * @param id 设备ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierDevice}`, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList ID集合
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierDevice}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量更新设备状态
   * @param idList ID集合
   * @param status 状态
   */
  public batchUpdateStatus(idList: Array<number>, status: DeviceStatusEnum): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null');
    }

    if (!status) {
      throw new Error('status must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierDevice}/editStatus`, { idList, status })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查设备名称是否重复
   * @param companyId 企业ID
   * @param id 设备ID
   * @param name 设备名称
   */
  public checkNameRepeat(companyId: number, name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierDevice}/checkNameRepeat`, {
          params: {
            companyId,
            id,
            name
          }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载导入模板
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.supplierDevice}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导入
   * @param companyId 企业ID
   * @param filePath 导入文件地址
   */
  public import(companyId: number, filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierDevice}/importData`, { companyId, filePath })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导出
   */
  public export(query: Partial<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.supplierDevice}/export`, query, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询所有设备
   * @param companyId 企业ID
   */
  public listAll(companyId: number): Promise<Array<SupplierDeviceResource>> {
    if (!companyId) {
      throw new Error('companyId must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierDeviceResource>>(`${ResourceUrlEnum.supplierDevice}/listAll`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<SupplierDeviceResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询企业启用的设备
   * @param companyId 企业ID
   */
  public listEnable(companyId: number): Promise<Array<SupplierDeviceResource>> {
    if (!companyId) {
      throw new Error('companyId must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierDeviceResource>>(`${ResourceUrlEnum.supplierDevice}/listEnable`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<SupplierDeviceResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取设备状态class
   * @param status 状态
   * @returns 状态class name
   */
  public getDeviceStatusClass = (status: DeviceStatusEnum): string => {
    if (status === DeviceStatusEnum.enable) {
      return 'start-dot';
    }

    if (status === DeviceStatusEnum.disable) {
      return 'disabled-dot';
    }

    return 'warning-dot';
  };

  /**
   * 返回对应设备状态的i18n key
   * @param status 状态
   * @returns
   */
  public getDeviceStatusI18Key = (status: DeviceStatusEnum): string => {
    let res;
    switch (status) {
      case DeviceStatusEnum.enable:
        res = 'common.startUsing';
        break;
      case DeviceStatusEnum.disable:
        res = 'common.disabled';
        break;
      case DeviceStatusEnum.repair:
        res = 'common.repair';
        break;
      default:
        res = 'common.unKnownStatus';
        break;
    }
    return res;
  };
}
const supplierDeviceService = new SupplierDeviceService();
export default supplierDeviceService;
