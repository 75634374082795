import { ResourceUrlEnum } from '@/resource/enum';
import { MessageConfigList, Region, RegionQuery, SaveMessageConfig } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, axiosService } from '../axios';

class SystemConfigService {
  public getMessageConfigs(): Promise<Array<MessageConfigList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<MessageConfigList>>(`${ResourceUrlEnum.messageConfig}/list`)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public saveMessageConfig(configs: Array<SaveMessageConfig>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.messageConfig}/submit`, configs)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取系统配置低省市区信息
   * @returns
   */
  public getSystemRegion(query: RegionQuery): Promise<Array<Region>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<Region>>(`${ResourceUrlEnum.region}/list`, query, { repeat: true })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const systemConfigService = new SystemConfigService();
export default systemConfigService;
