import { LogisticsOrderResource } from './../../resource/model/logistics-management/logistics-order.d';
import { Paging } from '../base';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { ResourceUrlEnum } from '@/resource/enum';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';

class ProductionOrderLogisticsService {
  public getList(
    query: Partial<LogisticsOrderResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<LogisticsOrderResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<LogisticsOrderResource>>(`${ResourceUrlEnum.productionOrder}/listLogisticsOrder`, cloneQuery)
        .then((res: ApiResponse<Array<LogisticsOrderResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const productionOrderLogisticsService = new ProductionOrderLogisticsService();
export default productionOrderLogisticsService;
