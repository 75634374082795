export const enum ResourceUrlEnum {
  minio = 'minIo',
  /**
   * 认证模块
   */
  auth = 'auth',

  /**
   * 用户中心
   */
  userCenter = 'user/center',

  /**
   * 平台权限资源
   */
  platFormPermission = 'system/resources',

  /**
   * 供应商权限资源
   */
  supplierPermission = 'supplier/resources',
  /**
   * 部门
   */
  department = 'organize/department',
  /**
   * 角色
   */
  role = 'system/role',
  /**
   * 岗位
   */
  position = 'organize/position',
  /**
   * 人员管理
   */
  personnel = 'organize/user',
  /**
   * 产品/材料分类
   */
  classify = 'base/material/category',
  /**
   * 设备分类
   */
  deviceClassify = 'base/device/category',
  /**
   * 平台产品
   */
  product = 'base/product',
  /**
   * 输出工艺
   */
  output = 'base/print/crafts',
  /**
   * 后道工艺
   */
  backend = 'base/backend/crafts',
  /**
   * 印前工艺
   */
  prepressCrafts = 'base/prepress/crafts',
  /**
   * 平台输出材料
   */
  platformPrintingMaterial = 'base/printing/material',
  /**
   * 平台后道材料
   */
  platformBackendMaterial = 'base/backend/material',
  /**
   * 客户分类
   */
  customerClassify = 'customer/category',
  /**
   * 客户
   */
  customer = 'customer',
  /**
   * 客户开票信息
   */
  customerInvoice = 'customer/invoice',
  /**
   * 开票管理
   */
  invoice = 'base/invoice',
  /**
   * 税率管理（税收分类）
   */
  taxRate = 'base/tax/category',
  /**
   * 仓位关系
   */
  warehouseStore = 'wms/warehouseStore',
  /**
   * 仓库管理
   */
  warehouse = '/wms/warehouse',
  /**
   * 仓位管理
   */
  storeLocation = '/wms/storeLocation',
  /**
   * 存货类型管理
   */
  inventoryType = '/wms/inventoryType',
  /**
   * 物料列表
   */
  materialList = '/base/material',
  /**
   * 物料分类
   */
  materialClassification = '/base/material/category',
  /**
   * 物料清单
   */
  billOfMaterial = '/base/materialBom',
  /**
   * 客户产品（售价管理）
   */
  customerProduct = 'customer/product',
  /**
   * 客户管理（联系人管理）
   */
  customerContacts = '/company/contacts',
  /**
   * 门店标签
   */
  shopTag = 'customer/shopTag',
  /**
   * 点位标签
   */
  pointTag = 'customer/pointTag',
  /**
   * 客户门店
   */
  customerShop = 'customer/shop',
  /**
   * 客户点位
   */
  customerPoint = 'customer/point',
  /**
   * 供应商分类
   */
  supplierClassify = 'supplier/category',
  /**
   * 供应商级别
   */
  supplierLevel = 'system/supplier/grade',
  /**
   * 供应商管理
   */
  supplier = 'supplier',
  /**
   * 供应商角色管理
   */
  supplierRole = 'supplier/role',
  /**
   * 供应商部门管理
   */
  supplierDepartment = 'supplier/department',
  /**
   * 供应商岗位管理
   */
  supplierPosition = 'supplier/position',
  /**
   * 供应商人员管理
   */
  supplierPersonnel = 'supplier/user',
  /**
   * 供应商输出材料
   */
  printingMaterial = 'supplier/printing/material',
  /**
   * 供应商后道材料
   */
  backendMaterial = 'supplier/backend/material',
  /**
   * 供应商设备
   */
  supplierDevice = 'supplier/device',
  /**
   * 供应商产品
   */
  supplierProduct = 'supplier/product',
  /**
   * 项目
   */
  project = 'project',
  /**
   * 生产订单
   */
  productionOrder = 'production/order',
  /**
   * 生产订单产品详情
   */
  productionOrderProduct = 'production/orderItem',
  /**
   * 生产订单变更单
   */
  productionOrderChange = 'production/change',
  /**
   * 项目门店明细
   */
  projectShop = 'project/shop',
  /**
   * 项目产品明细
   */
  projectProduct = 'project/item',

  /**
   * 销售明细
   */
  saleDetail = 'project/sale',
  /**
   * 我的任务
   */
  mineTask = 'my/task',
  /**
   * 我的改色任务
   */
  mineChangeColorTask = 'my/color/task',
  /**
   * 我的排版任务
   */
  mineTypesettingTask = 'my/design/task',
  /**
   * 我的成品任务
   */
  mineFinishedProductTask = 'my/finishProduct/task',
  /**
   * 改色任务
   */
  changeColorTask = 'color/artis/task',
  /**
   * 完稿任务
   */
  completeManuscriptTask = 'design/task',
  /**
   * 改色样管理
   */
  colorChangeSample = 'color/change/manage',
  /**
   * 发货通知单
   */
  deliveryNotice = 'delivery/notice',
  /**
   * 安装通知单
   */
  installationNotice = 'install/notice',
  /**
   * 安装订单
   */
  installationOrder = 'install/order',

  /**
   * 安装单文件
   */
  mobileInstallationOrder = '/phone/install/order',

  /*
   * 物流单
   */
  logistics = 'logistics/order',
  /**
   * 结算单
   */
  billing = 'finance/bill',
  /**
   * 财务发票管理
   */
  financeInvoice = 'finance/invoice',
  /**
   * 交易明细
   */
  transaction = 'finance/write/off',
  /**
   * 待办事项
   */
  todo = 'finance/todo',
  /**
   * 消息中心
   */
  messageCenter = 'message/center',
  /**
   * 耗损管理
   */
  consume = 'project/attrition',
  /**
   * 消息配置
   */
  messageConfig = 'message/scene',
  /**
   * 项目设置
   */
  systemProject = 'system/project',
  /**
   * 反馈设置
   */
  systemFeedback = 'system/feedback',
  /**
   * HM模块-包装管理
   */
  hmPackaging = 'hm/packBox',

  /**
   * HM模块-订单管理
   */
  hmOrder = 'hm/order',
  /**
   * 省市区
   */
  region = 'system/region',
  /**
   * 数据字典
   */
  dataDictionary = 'base/dict',
  /**
   * 仪表盘
   */
  dashboard = 'dashboard',
  /**
   * 查询方案
   */
  queryPlan = 'system/query/template',

  /**
   * 建议反馈
   */
  feedback = 'feedback',

  /**
   * 计量单位分组
   */

  unitsMeasureGroups = 'base/unitOfMeasurementGroup',
  /**
   * 计量单位
   */

  unitsMeasure = 'base/unitOfMeasurement',
  /**
   * 币别管理
   */
  currency = 'base/currency',
  /**
   * 汇率管理
   */
  exchangeRate = 'base/exchangeRate',

  /**
   * 审批流管理
   */
  workflow = 'system/model',
  /**
   * 审批待办任务
   */
  todoTask = 'task/todo',
  /**
   * 任务中心 实例管理
   */
  instanceTask = 'task/instance',
  /**
   * 审批抄送任务
   */
  ccTask = 'task/makeCopyForTask',
  /**
   * 审批已办任务
   */
  completeTask = 'task/completeTask',
  /**
   * 审批我发起的任务
   */
  initiatedTask = 'task/myInitiate',
  /**
   * 安装工人
   */
  installEmployee = 'install/employee',
  /**
   * 服务项目
   */
  serviceItem = 'install/serveItems',
  /**
   * 常用标签
   */
  installTag = 'install/tag',
  /**
   * 印前统计
   */
  prepressTaskStatistics = '/prepress/task/statistics',
  /**
   * 文件管理
   */
  file = 'file',
  /**
   * 安装异常记录
   */
  installException = 'install/order/exception',
  /**
   * 单文件上传接口
   */
  upload = 'file/upload',
  /**
   * 多文件上传接口
   */
  batchUpload = 'file/batchUpload',

  downloadGroupZip = 'file/downloadGroupZip',
  downloadZip = 'file/downloadZip'
}
