/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const installationManagementRoutes: RouteConfig = {
  path: '/installation-management',
  component: Layout,
  redirect: 'installation-notice',
  name: 'InstallationManagement',
  children: [
    {
      path: '/installation-notice',
      name: 'InstallationNotice',
      component: async () =>
        import(
          /* webpackChunkName: "installation-notice" */ '@/views/installation-management/installation-notice/installation-notice.vue'
        ),
      meta: {
        title: 'route.installationNotice',
        id: 1565
      },
      children: [
        {
          path: '/installation-notice-detail',
          name: 'InstallationNoticeDetail',
          component: async () =>
            import(
              /* webpackChunkName: "installation-notice-detail" */ '@/views/installation-management/installation-notice/installation-notice-detail/installation-notice-detail.vue'
            ),
          meta: {
            title: 'route.installationNoticeDetail',
            id: 1575,
            hidden: true,
            parentId: 1565
          }
        },
        {
          path: '/add-installation-notice',
          name: 'AddInstallationNotice',
          component: async () =>
            import(
              /* webpackChunkName: "add-installation-notice" */ '@/views/installation-management/installation-notice/add-installation-notice/add-installation-notice.vue'
            ),
          meta: {
            title: 'route.addInstallationNotice',
            id: 1838,
            hidden: true,
            parentId: 1565
          }
        }
      ]
    },

    {
      path: '/installation-order',
      name: 'InstallationOrder',
      component: async () =>
        import(
          /* webpackChunkName: "installation-order" */ '@/views/installation-management/installation-order/installation-order.vue'
        ),
      meta: {
        title: 'route.installationOrder',
        id: 1566
      },
      children: [
        {
          path: '/add-installation-order',
          name: 'AddInstallationOrder',
          component: async () =>
            import(
              /* webpackChunkName: "add-installation-order" */ '@/views/installation-management/installation-notice/installation-notice-detail/add-installation-order/add-installation-order.vue'
            ),
          meta: {
            title: 'route.addInstallationOrder',
            hidden: true
          }
        },
        {
          path: '/edit-installation-order',
          name: 'EditInstallationOrder',
          component: async () =>
            import(
              /* webpackChunkName: "edit-installation-order" */ '@/views/installation-management/installation-order/edit-installation-order/edit-installation-order.vue'
            ),
          meta: {
            title: 'route.editInstallationOrder',
            hidden: true
          }
        },
        {
          path: '/installation-order-detail',
          name: 'InstallationOrderDetail',
          component: async () =>
            import(
              /* webpackChunkName: "installation-order-detail" */ '@/views/installation-management/installation-order/installation-order-detail/installation-order-detail.vue'
            ),
          meta: {
            title: 'route.installationOrderDetail',
            hidden: true
          }
        }
      ]
    },

    {
      path: '/batch-edit-price',
      name: 'BatchEditPrice',
      component: async () =>
        import(
          /* webpackChunkName: "batch-edit-price" */ '@/views/installation-management/installation-order/batch-edit-price/batch-edit-price.vue'
        ),
      meta: {
        title: 'route.batchEditPrice',
        id: 1682,
        hidden: true,
        parentId: 1566,
        noCache: true
      }
    },
    {
      path: '/install-employee',
      name: 'InstallEmployee',
      component: async () =>
        import(
          /* webpackChunkName: "install-employee" */ '@/views/installation-management/install-employee/install-employee.vue'
        ),
      meta: {
        title: 'route.installEmployee',
        id: 1832
      }
    },
    {
      path: '/service-item',
      name: 'ServiceItem',
      component: async () =>
        import(
          /* webpackChunkName: "install-employee" */ '@/views/installation-management/service-item/service-item.vue'
        ),
      meta: {
        title: 'route.serviceItem'
      }
    },
    {
      path: '/common-tags',
      name: 'CommonTags',
      component: async () =>
        import(/* webpackChunkName: "common-tags" */ '@/views/installation-management/common-tags/common-tags.vue'),
      meta: {
        title: 'route.commonTags'
      }
    },
    {
      path: '/installation-acceptance',
      name: 'InstallationAcceptance',
      component: async () =>
        import(
          /* webpackChunkName: "installation-acceptance" */ '@/views/installation-management/installation-acceptance/installation-acceptance.vue'
        ),
      meta: {
        title: 'route.installationAcceptance'
      }
    }
  ],
  meta: {
    title: 'route.installationManagement',
    icon: '#installation',
    id: 1564
  }
};
