/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

export const productManagementRoutes: RouteConfig = {
  path: '/product-management',
  component: Layout,
  name: 'ProductManagement',
  redirect: 'product',
  children: [
    {
      path: '/product',
      name: 'Product',
      component: async () => import(/* webpackChunkName: "product" */ '@/views/product-management/product/product.vue'),
      meta: {
        title: 'route.productList',
        id: 297
      }
    },
    {
      path: '/printing-material',
      name: 'PrintingMaterial',
      component: async () =>
        import(
          /* webpackChunkName: "printing-material" */ '@/views/product-management/printing-material/printing-material.vue'
        ),
      meta: {
        title: 'route.printingMaterial',
        id: 1642
      }
    },
    {
      path: '/backend-material',
      name: 'BackendMaterial',
      component: async () =>
        import(
          /* webpackChunkName: "backend-material" */ '@/views/product-management/backend-material/backend-material.vue'
        ),
      meta: {
        title: 'route.backendMaterial',
        id: 1643
      }
    }
  ],
  meta: {
    title: 'route.product',
    icon: '#product',
    id: 295
  }
};
