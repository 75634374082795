import { ResourceUrlEnum, InstallationOrderStatusEnum, ApproveStatusEnum } from '@/resource/enum';
import {
  CreateInstallationOrder,
  ExceptionRecordList,
  InstallationOrderInfo,
  InstallationOrderList,
  InstallationOrderListQuery,
  SelectInstallProductItem,
  SelectInstallProductQuery,
  UpdateInstallationOrder,
  UploadedFile
} from '@/resource/model';
import { NormalSelectOptions } from '@/resource/model/common';
import { handleDownload, translation } from '@/utils';
import { axiosRequest } from '@/utils/axios-request';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { axiosService, ApiResponse, ApiError } from '../axios';
import { Paging, SortOptions } from '../base';

class InstallationOrderService {
  public batchEditPrice(data: Array<{ id: number; amount: number }>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/editAmount`, data)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public reject(params: { id: number; reason: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/reject`, params)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public approved(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<null>(
          `${ResourceUrlEnum.installationOrder}/approved`,
          {},
          {
            params: { id }
          }
        )
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getApproveStatusI18Key(status: ApproveStatusEnum): string {
    return 'approveStatus.' + ApproveStatusEnum[status];
  }

  /**
   * 批量删除
   * @param idList 资源ID集合
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.installationOrder}`, { data: idList })
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载文件
   * @param idList 资源ID集合
   */
  public downloadZip(idList: Array<number>): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installationOrder}/downloadZip`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量取消
   * @param idList 资源ID集合
   */
  public batchCancel(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/batchCancel`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量推送
   * @param idList 资源ID集合
   */
  public batchPush(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/batchSubmit`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量完成
   * @param idList 资源ID集合
   */
  public batchFinish(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/batchFinish`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据ID获取详情(编辑使用)
   * @param id ID
   * @returns 数据对象
   */
  public getById(id: number): Promise<InstallationOrderInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<InstallationOrderInfo>(`${ResourceUrlEnum.installationOrder}`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据ID获取详情
   * @param id ID
   * @returns 数据对象
   */
  public getInfo(id: number): Promise<InstallationOrderInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<InstallationOrderInfo>(`${ResourceUrlEnum.installationOrder}/getInfo`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getList(
    query: Partial<InstallationOrderListQuery>,
    paging: Paging,
    sortOptions: SortOptions<InstallationOrderList>
  ): Promise<ApiResponse<Array<InstallationOrderList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InstallationOrderList>>(`${ResourceUrlEnum.installationOrder}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<InstallationOrderList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存资源
   * @param resource 资源对象
   */
  public post(resource: CreateInstallationOrder): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CreateInstallationOrder>(`${ResourceUrlEnum.installationOrder}`, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 保存资源
   * @param resource 资源对象
   */
  public saveAndPush(resource: CreateInstallationOrder): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CreateInstallationOrder>(`${ResourceUrlEnum.installationOrder}/saveAndPush`, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑
   * @param resource 资源对象
   */
  public put(resource: UpdateInstallationOrder): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}`, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public putAndPush(resource: UpdateInstallationOrder): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/push`, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: InstallationOrderStatusEnum): string {
    if (!InstallationOrderStatusEnum[status]) {
      return 'common.unknown';
    }

    return 'installationOrder.' + InstallationOrderStatusEnum[status];
  }

  /**
   * 根据状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: InstallationOrderStatusEnum): string {
    let className = '';
    switch (Number(status)) {
      case InstallationOrderStatusEnum.toBePushed:
        className = 'default-dot';
        break;
      case InstallationOrderStatusEnum.PendingOrders:
        className = 'warning-dot';
        break;
      case InstallationOrderStatusEnum.pendingAppointment:
        className = 'primary-dot';
        break;
      case InstallationOrderStatusEnum.inService:
        className = 'warning-dot';
        break;
      case InstallationOrderStatusEnum.acceptancePending:
        className = 'success-dot';
        break;
      case InstallationOrderStatusEnum.completed:
        className = 'success-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 获取安装状态下拉数据
   * @returns
   */
  public getStatusSelectableList(): NormalSelectOptions {
    const list = [];
    for (const key in InstallationOrderStatusEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        list.push({
          label: translation(this.getStatusI18Key(Number(key))),
          value: Number(key)
        });
      }
    }
    return list;
  }
  /**
   * 批量保存完工文件
   * @param fileIdList 文件id列表
   * @returns
   */
  public uploadFinishPhotos(id: number, fileIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installationOrder}/uploadFinishPhoto`, { id, fileIdList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取完工照片
   * @param id 安装单id
   * @returns
   */
  public getFinishPhotos(id: number): Promise<Array<UploadedFile>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<UploadedFile>>(`${ResourceUrlEnum.installationOrder}/getFinishPhoto`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 下载完工文件
   * @param id 安装单id
   * @returns
   */
  public downloadFinishPhotos(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get(`${ResourceUrlEnum.installationOrder}/downloadFinishPhoto`, {
          params: { id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 上传-开箱视屏
   * @param fileIdList 文件id列表
   * @returns
   */
  public uploadUnBoxVideo(id: number, fileIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installationOrder}/uploadUnBoxVideo`, { id, fileIdList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取-开箱视屏
   * @param id 安装单id
   * @returns
   */
  public getUnBoxVideo(id: number): Promise<Array<UploadedFile>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<UploadedFile>>(`${ResourceUrlEnum.installationOrder}/getUnBoxVideo`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 上传-安装前照片
   * @param fileIdList 文件id列表
   * @returns
   */
  public uploadBeforePhoto(id: number, fileIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installationOrder}/uploadBeforePhoto`, { id, fileIdList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取-安装前照片
   * @param fileIdList 文件id列表
   * @returns
   */
  public getBeforePhoto(id: number): Promise<Array<UploadedFile>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<UploadedFile>>(`${ResourceUrlEnum.installationOrder}/getBeforePhoto`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 上传-验收签名
   * @param fileIdList 文件id列表
   * @returns
   */
  public uploadSignPhoto(id: number, fileIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installationOrder}/uploadBeforePhoto`, { id, fileIdList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取-验收签名
   * @param fileIdList 文件id列表
   * @returns
   */
  public getSignPhoto(id: number): Promise<Array<UploadedFile>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<UploadedFile>>(`${ResourceUrlEnum.installationOrder}/getSignPhoto`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 上传-资质证照
   * @param fileIdList 文件id列表
   * @returns
   */
  public uploadAptitudePhoto(id: number, fileIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installationOrder}/uploadAptitudePhoto`, { id, fileIdList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取-资质证照
   * @param fileIdList 文件id列表
   * @returns
   */
  public getAptitudePhoto(id: number): Promise<Array<UploadedFile>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<UploadedFile>>(`${ResourceUrlEnum.installationOrder}/getAptitudePhoto`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改服务时间
   * @param idList
   * @param serveTime
   * @returns
   */
  public batchSetServeTime(idList: Array<number>, serveStartTime: string, serveEndTime: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installationOrder}/editServeTime`, { idList, serveStartTime, serveEndTime })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取安装通知单门店内产品项次
   * @param projectId 项目id
   * @param query 查询
   * @returns
   */
  public getInstallProductItem(
    query: Partial<SelectInstallProductQuery>
  ): Promise<ApiResponse<Array<SelectInstallProductItem>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SelectInstallProductItem>>(`${ResourceUrlEnum.installationOrder}/listShopItemRelPage`, query)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取异常记录
   * @param id
   * @returns
   */
  public getExceptionList(id: number): Promise<ApiResponse<Array<ExceptionRecordList>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ExceptionRecordList>>(`${ResourceUrlEnum.installationOrder}/getInstallLog`, { params: { id } })
        .then((res: ApiResponse<Array<ExceptionRecordList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获物流单
   * @param id
   * @returns
   */
  public getLogisticsList(id: number): Promise<ApiResponse<Array<any>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<any>>(`${ResourceUrlEnum.installationOrder}/getLogisticsOrder`, { params: { id } })
        .then((res: ApiResponse<Array<any>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取验收记录
   * @param id
   * @returns
   */
  public getApprovedLog(id: number): Promise<ApiResponse<Array<any>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<any>>(`${ResourceUrlEnum.installationOrder}/getApprovedLog`, { params: { id } })
        .then((res: ApiResponse<Array<any>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除文件
   * @param fileIdList
   * @returns
   */
  public deletePhoto(fileIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.installationOrder}/deleteFile`, { data: fileIdList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const installationOrderService = new InstallationOrderService();
export default installationOrderService;
