import { ResourceUrlEnum } from '@/resource/enum';
import { PrepressTaskList, PrepressTaskInfo, PrepressTaskListQuery } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload } from '@/utils';

class MineTypesettingTaskService {
  public getById(id: number): Promise<PrepressTaskInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<PrepressTaskInfo>(ResourceUrlEnum.mineTypesettingTask, { params: { id } })
        .then((res: ApiResponse<PrepressTaskInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<PrepressTaskListQuery>,
    paging: Paging,
    sortOptions: SortOptions<PrepressTaskList>
  ): Promise<ApiResponse<Array<PrepressTaskList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressTaskList>>(`${ResourceUrlEnum.mineTypesettingTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<PrepressTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public export(query?: Partial<PrepressTaskListQuery>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.mineTypesettingTask}/export`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 完稿制作完成
   * @returns
   */
  public artworkComplete(fileParams: Array<{ id: number; file?: string }>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/finishProductComplete`, fileParams)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 改色制作完成
   * @param fileParams
   * @returns
   */
  public changeColorComplete(fileParams: Array<{ id: number; file?: string }>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/colorArtisComplete`, fileParams)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 提交审稿
   * @returns
   */
  public submitFinishProductConfirm(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/submitFinishProductConfirm`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 取消审稿
   * @returns
   */
  public cancelFinishProductConfirm(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/cancelFinishProductConfirm`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  // /**
  //  * 完稿确稿  上传文件
  //  * @returns
  //  */
  // public artworkConfirmation(fileParams: Array<{ id: number; file?: string }>): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     axiosService
  //       .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/submitFinishProductConfirm`, fileParams)
  //       .then((res: ApiResponse<null>) => {
  //         if (res.code !== 0) {
  //           const errorObj: ApiError = getErrorObj(res.code);
  //           return reject(errorObj);
  //         }
  //         resolve();
  //       })
  //       .catch((errorRes: { code: number }) => {
  //         const errorObj: ApiError = getHttpErrorObj(errorRes.code);
  //         reject(errorObj);
  //       });
  //   });
  // }

  /**
   * 改色确稿
   * @returns
   */
  public changeColorConfirmation(fileParams: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/submitColorArtisConfirm`, fileParams)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 提交打样
   * @param ids
   * @returns
   */
  public submitPrintSample(ids: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.mineTypesettingTask}/batchSubmitPrintSample`, ids)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量取消印前服务
   * @param id 印前服务id
   * @returns
   */
  public batchCancelTask(ids: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.mineTypesettingTask}/batchCancel`, ids)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 文件上传校验
   * @param fieldNameList
   * @returns
   */
  public checkConfirmItem(fieldNameList: Array<string>): Promise<ApiResponse<Array<{ id: number; itemCode: string }>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineTypesettingTask}/checkConfirmItem`, fieldNameList)
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const mineTypesettingTaskService = new MineTypesettingTaskService();
export default mineTypesettingTaskService;
