/**
 * 印前样状态
 */

export enum PrepressSampleStatusEnum {
  /**
   * 待提交
   */
  waitSubmit = 1,
  /**
   * 生产中
   */
  inProduction = 30,

  /**
   * 已完成
   */
  completed = 40
}
