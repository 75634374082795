import { OrderChangeStatusEnum } from './../../resource/enum/order-change-status';
import { ProductionOrderChangeResource } from './../../resource/model/production-management/production-order-change.d';
import { Paging } from '../base';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { messageError, translation } from '@/utils';
import { ResourceUrlEnum } from '@/resource/enum';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ChangeTypeEnum } from '@/resource/enum/changeType';
import { ProductionOrderProductResource } from '@/resource/model';

class ProductionOrderChangeService {
  /**
   * 获取变更类型国际化key
   * @param type 变更类型
   */
  public getChangeTypeI18Key(type: ChangeTypeEnum): string {
    return 'changeType.' + ChangeTypeEnum[type];
  }

  /**
   * 批量删除
   * @param idList ID集合
   */
  public batchDelete(idList: Array<number>): Promise<ApiResponse<Array<{ id: number; itemCode: string }>>> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.productionOrderChange}`, { data: idList })
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 批量取消
   * @param idList ID集合
   */
  public batchCancel(idList: Array<number>): Promise<ApiResponse<Array<{ id: number; itemCode: string }>>> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrderChange}/batchCancel`, idList)
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量推送
   * @param idList ID集合
   */
  public batchPush(idList: Array<number>): Promise<ApiResponse<Array<{ id: number; itemCode: string }>>> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.productionOrderChange}/batchPlaceOrder`, idList)
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据ID获取详情
   * @param id ID
   * @returns 数据对象
   */
  public getById(id: number | null): Promise<ProductionOrderChangeResource> {
    if (!id) {
      throw new Error('arguments id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<ProductionOrderChangeResource>(`${ResourceUrlEnum.productionOrderChange}`, { params: { id } })
        .then((res: ApiResponse<ProductionOrderChangeResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getList(
    query: Partial<ProductionOrderChangeResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductionOrderChangeResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderChangeResource>>(`${ResourceUrlEnum.productionOrderChange}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProductionOrderChangeResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存
   * @param resource 保存对象
   */
  public post(resource: ProductionOrderChangeResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.productionOrderChange}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存并提交
   * @param resource 保存对象
   */
  public postAndSubmit(resource: ProductionOrderChangeResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.productionOrderChange}/saveAndSubmit`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑
   * @param resource 编辑对象
   */
  public put(resource: ProductionOrderChangeResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.productionOrderChange}`, resource)
        .then((res: ApiResponse<Object>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据状态获取class name
   * @param status 状态
   * @returns class name
   */
  public getStatusClass(status: OrderChangeStatusEnum): string {
    let className = '';
    switch (Number(status)) {
      case OrderChangeStatusEnum.new:
        className = 'default-dot';
        break;
      case OrderChangeStatusEnum.pendingConfirm:
        className = 'warning-dot';
        break;
      case OrderChangeStatusEnum.reject:
        className = 'danger-dot';
        break;
      case OrderChangeStatusEnum.finished:
        className = 'success-dot';
        break;
      default:
        className = '';
    }

    return className;
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: OrderChangeStatusEnum): string {
    return 'orderChangeStatus.' + OrderChangeStatusEnum[status];
  }

  /**
   * 获取订单可选状态集合
   * @returns
   */
  public getStatusSelectableList(): Array<any> {
    const list = [];
    for (const key in OrderChangeStatusEnum) {
      if (!isNaN(Number(key)) && typeof Number(key) === 'number') {
        list.push({
          label: translation(this.getStatusI18Key(Number(key))),
          value: key
        });
      }
    }

    return list;
  }

  /**
   * 根据条件获取变更单可修改的项次信息
   * @param query 查询参数
   * @param paging 分页参数
   * @returns 分页数据
   */
  public getProductionOrderItemList(
    query: Partial<ProductionOrderProductResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProductionOrderProductResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProductionOrderProductResource>>(
          `${ResourceUrlEnum.productionOrderChange}/listOrderItemPage`,
          cloneQuery
        )
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const productionOrderChangeService = new ProductionOrderChangeService();
export default productionOrderChangeService;
