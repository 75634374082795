import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { DeviceClassifyResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';

class DeviceClassifyService implements BaseService<DeviceClassifyResource> {
  public getById(id: number): Promise<DeviceClassifyResource> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<DeviceClassifyResource>(ResourceUrlEnum.deviceClassify, { params: { id } })
        .then((res: ApiResponse<DeviceClassifyResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: DeviceClassifyResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<DeviceClassifyResource>(ResourceUrlEnum.deviceClassify, resource)
        .then((res: ApiResponse<DeviceClassifyResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: DeviceClassifyResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<DeviceClassifyResource>(ResourceUrlEnum.deviceClassify, resource)
        .then((res: ApiResponse<DeviceClassifyResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.deviceClassify, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.deviceClassify}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query?: Partial<DeviceClassifyResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<DeviceClassifyResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<DeviceClassifyResource>>(`${ResourceUrlEnum.deviceClassify}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<DeviceClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查设备分类名称是否重复
   * @param name 分类名称
   * @param id 分类主键（修改时需传入）
   * @returns 为true则代表重复
   */
  public checkClassifyName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.deviceClassify}/checkNameRepeat`, { params: { name, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchUpdateClassifyStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.deviceClassify}/editStatus`, {
          idList,
          status
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询所有设备类型
   * @returns 所有设备类型
   */
  public listAll(): Promise<Array<DeviceClassifyResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DeviceClassifyResource>>(`${ResourceUrlEnum.deviceClassify}/listAll`)
        .then((res: ApiResponse<Array<DeviceClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询所有可用设备类型
   * @param companyId 企业ID
   * @returns 所有可用设备类型数据
   */
  public listAllEnable(): Promise<Array<DeviceClassifyResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<DeviceClassifyResource>>(`${ResourceUrlEnum.deviceClassify}/list`, {})
        .then((res: ApiResponse<Array<DeviceClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const deviceClassifyService = new DeviceClassifyService();
export default deviceClassifyService;
