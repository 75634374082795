/**
 * 交易状态
 */
export enum TransactionStatusEnum {
  /**
   * 待核销
   */
  toBeWrittenOff = 1,

  /**
   * 已核销
   */
  writtenOff
}

/**
 * 交易类型
 */
export enum TransactionTypeEnum {
  /**
   * 收入
   */
  income = 1,

  /**
   * 支出
   */
  expenditure
}
