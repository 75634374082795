import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { MessageResource } from '@/resource/model';
import { messageCenterService } from '@/api';
import { messageError } from '@/utils';
import { MessageStatusEnum, MessageTypeEnum } from '@/resource/enum';
import { Notification as ElNotification } from 'element-ui';

export interface IMessageCenterState {
  unReadTotal: number;
  notices: Array<MessageResource>;
  todos: Array<MessageResource>;
}

@Module({ dynamic: true, store, name: 'messageCenter' })
class MessageCenter extends VuexModule implements IMessageCenterState {
  public unReadTotal = 0;

  public notices: Array<MessageResource> = [];

  public todos: Array<MessageResource> = [];

  /**
   * 初始化navBar上的通知消息
   */
  @Action({ rawError: true })
  public InitNotices(): void {
    messageCenterService
      .getUnReadNotices()
      .then(res => {
        this.setInitNotices(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 初始化navBar上的待办消息
   */
  @Action({ rawError: true })
  public InitTodos(): void {
    messageCenterService
      .getUnReadTodos()
      .then(res => {
        this.setInitTodos(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 初始化未读的消息数量
   */
  @Action({ rawError: true })
  public InitUnReadTotal(): void {
    messageCenterService
      .getUnReadTotal()
      .then(res => {
        this.setUnReadTotal(res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 设置消息已读
   * @param message 消息对象
   */
  @Action({ rawError: true })
  public SetReaded(message: MessageResource): void {
    messageCenterService
      .setReadStatus(message.id)
      .then(() => {
        message.status = MessageStatusEnum.readed;
        this.reduceUnReadTotal();
        message.type === MessageTypeEnum.notice ? this.InitNotices() : this.InitTodos();
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 添加一条待办
   * @param todo 待办
   */
  @Action
  public AddTodo(todo: MessageResource): void {
    if (!this.todos.find(x => x.id === todo.id)) {
      this.unShiftTodo(todo);
      this.Notification(todo);
      this.BrowserNotification(todo);
    }
  }

  /**
   * 添加一条通知
   * @param notice 通知
   */
  @Action
  public AddNotice(notice: MessageResource): void {
    if (!this.notices.find(x => x.id === notice.id)) {
      this.unShiftNotice(notice);
      this.Notification(notice);
      this.BrowserNotification(notice);
    }
  }

  @Action
  public Notification(message: MessageResource): void {
    const notice = ElNotification({
      title: message.title,
      message: message.content,
      showClose: false,
      dangerouslyUseHTMLString: true,
      onClick: () => {
        this.SetReaded(message);
        notice.close();
      }
    });
  }

  @Action
  public BrowserNotification(message: MessageResource): void {
    if (Notification.permission === 'denied') {
      return;
    }
    if (Notification.permission !== 'granted') {
      // eslint-disable-next-line no-undef
      Notification.requestPermission().then((permission: NotificationPermission) => {
        if (permission === 'granted') {
          this.sendMessage(message);
        }
      });
    }
    this.sendMessage(message);
  }

  @Mutation
  public unShiftNotice(notice: MessageResource): void {
    this.notices.unshift(notice);
    this.unReadTotal++;
  }

  @Mutation
  public unShiftTodo(todo: MessageResource): void {
    this.todos.unshift(todo);
    this.unReadTotal++;
  }

  /**
   * 未读消息减1
   * @param notice 通知
   */
  @Mutation
  public reduceUnReadTotal(): void {
    this.unReadTotal--;
  }

  /**
   * 设置消息中心全部未读数量
   * @param total 数量
   */
  @Mutation
  private setUnReadTotal(total: number): void {
    this.unReadTotal = total;
  }

  /**
   * 设置mini消息中心的初始通知
   * @param total 数量
   */
  @Mutation
  private setInitNotices(notices: Array<MessageResource>): void {
    this.notices = notices;
  }

  /**
   *  设置mini消息中心的初始待办通知
   * @param total 数量
   */
  @Mutation
  private setInitTodos(todos: Array<MessageResource>): void {
    this.todos = todos;
  }

  @Action
  private sendMessage(message: MessageResource): void {
    const notification = new Notification(message.title);
    notification.onclick = (): void => {
      this.SetReaded(message);
      notification.close();
    };
  }
}

export const MessageCenterModule = getModule(MessageCenter);
