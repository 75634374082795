import { ProductList } from './../../resource/model/product-management/product.d';
import { ResourceUrlEnum } from '@/resource/enum';
import { ClassifyResource, ProjectProductList, SelectProjectProductQuery } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

/**
 * 项目产成品数据检索
 */
class ProductionProductSearchService {
  /**
   * 根据项目及产品分类查询平台产品数据
   * @param projectId 项目ID
   * @param categoryId 分类ID
   * @returns 平台产品数据集合
   */
  public listAllPlatformProductByCategory(projectId: number, categoryId: number | null): Promise<Array<ProductList>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ProductList>>(`${ResourceUrlEnum.productionOrder}/getPlatformProductList`, {
          params: { projectId, categoryId }
        })
        .then((res: ApiResponse<Array<ProductList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  public listAllProductCateogryByProject(projectId: number): Promise<Array<ClassifyResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ClassifyResource>>(`${ResourceUrlEnum.productionOrder}/getProductCategoryList`, {
          params: { projectId }
        })
        .then((res: ApiResponse<Array<ClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 分页查询
   * @param query 查询参数
   * @param paging 分页参数
   * @returns
   */
  public getList(
    query: Partial<SelectProjectProductQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProjectProductList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectProductList>>(`${ResourceUrlEnum.productionOrder}/getOrderDetailList`, cloneQuery)
        .then((res: ApiResponse<Array<ProjectProductList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const productionProductSearchService = new ProductionProductSearchService();
export default productionProductSearchService;
