/**
 * 工序状态
 */
export enum ProcessStatusEnum {
    /**
     * 未制作
     */
    new = 1,
    /**
     * 待制作
     */
    waiting = 2,
    /**
     * 制作中
     */
    processing = 3,
    /**
     * 已完成
     */
    completed = 4,
    /**
     * 不制作
     */
    closed = -1
}