import { ApproveStatusEnum, InstallationOrderStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { InstallationOrderList } from '@/resource/model';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { axiosService, ApiResponse, ApiError } from '../axios';

class MobileInstallationOrderService {
  public submit(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<null>(`${ResourceUrlEnum.mobileInstallationOrder}/finish`, null, {
          params: { id }
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public accept(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<null>(`${ResourceUrlEnum.mobileInstallationOrder}/orderTaking`, null, {
          params: { id }
        })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchSaveFileForMobile(list: any): Promise<Array<number>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<number>>(`${ResourceUrlEnum.mobileInstallationOrder}`, list)
        .then((res: ApiResponse<Array<number>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除文件
   * @param idList 文件ID
   * @returns
   */
  public batchDeleteFile(idList: Array<number>): Promise<void> {
    if (!idList || idList.length === 0) {
      throw new Error("argument 'idList' must not be null or empty");
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.mobileInstallationOrder}`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getApproveStatusI18Key(status: ApproveStatusEnum): string {
    return 'approveStatus.' + ApproveStatusEnum[status];
  }

  /**
   * 根据ID获取详情
   * @param id ID
   * @returns 数据对象
   */
  public getById(id: number): Promise<InstallationOrderList> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<InstallationOrderList>(`${ResourceUrlEnum.mobileInstallationOrder}`, { params: { id } })
        .then((res: ApiResponse<InstallationOrderList>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据状态获取国际化key
   * @param status 状态
   * @returns 状态i18n key
   */
  public getStatusI18Key(status: InstallationOrderStatusEnum): string {
    if (!InstallationOrderStatusEnum[status]) {
      return 'common.unknown';
    }

    return 'installationOrder.' + InstallationOrderStatusEnum[status];
  }
}

const mobileInstallationOrderService = new MobileInstallationOrderService();
export default mobileInstallationOrderService;
