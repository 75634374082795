import {
  BillingItemTypeEnum,
  BillingStatusEnum,
  CurrencyEnum,
  InvoiceColorEnum,
  InvoiceModeEnum,
  InvoiceTypeEnum,
  PaymentModeEnum,
  ResourceUrlEnum
} from '@/resource/enum';
import {
  SaveBilling,
  BillingList,
  BillingListQuery,
  InviceProjectProductItems,
  QueryInviceProjectProductItems,
  BillingDetail,
  ApprovalRecord,
  CustomerProjectQuery,
  CustomerProjectList,
  ImportRes,
  InvoiceProjectItemsForImport
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { handleDownload, messageError, translation } from '@/utils';
import { NormalSelectOptions } from '@/resource/model/common';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';

class BillingService {
  public getById(id: number): Promise<BillingDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<BillingDetail>(ResourceUrlEnum.billing, { params: { id } })
        .then((res: ApiResponse<BillingDetail>) => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  public post(resource: Partial<SaveBilling>): Promise<number> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(ResourceUrlEnum.billing, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            return reject(getErrorObj(res.code));
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 保存并提交结算单
   * @param resource
   * @returns
   */
  public saveAndSubmit(resource: Partial<SaveBilling>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.billing}/submit`, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 编辑保存并提交结算单
   * @param resource
   * @returns
   */
  public editAndSubmit(resource: Partial<SaveBilling>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.billing}/submit`, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  public put(resource: Partial<SaveBilling>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(ResourceUrlEnum.billing, resource)
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  public getList(query?: Partial<BillingListQuery>, paging?: Paging): Promise<ApiResponse<Array<BillingList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<BillingList>>(`${ResourceUrlEnum.billing}/listPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取客户相关所有项目
   * @param customerId 客户id
   * @returns
   */
  public getProjectsByCustomer(
    customerId: number
  ): Promise<Array<{ projectCode: string; projectName: string; id: number }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<{ projectCode: string; projectName: string; id: number }>>(
          `${ResourceUrlEnum.billing}/selectProject`,
          { params: { customerId } }
        )
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取客户相关所有项目
   * @param customerId 客户id
   * @returns
   */
  public getProjectsByQuery(
    query: Partial<CustomerProjectQuery>,
    paging: Paging
  ): Promise<ApiResponse<Array<CustomerProjectList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerProjectList>>(`${ResourceUrlEnum.project}/listComponentsPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取结算单状态
   * @returns
   */
  public getStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in BillingStatusEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`billingStatus.${key}`),
          value: BillingStatusEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取结算方式
   * @returns
   */
  public getPaymentModes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in PaymentModeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `paymentMode.${key}`,
          value: PaymentModeEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取结算币种
   * @returns
   */
  public getCurrencyTypes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in CurrencyEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `currencyTypes.${key}`,
          value: CurrencyEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取发票颜色
   * @returns
   */
  public getInvoiceColors(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in InvoiceColorEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `invoiceColor.${key}`,
          value: InvoiceColorEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取开票方式
   * @returns
   */
  public getInvoiceModes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in InvoiceModeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `invoiceMode.${key}`,
          value: InvoiceModeEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取发票类型
   * @returns
   */
  public getInvoiceTypes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in InvoiceTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: `invoiceType.${key}`,
          value: InvoiceTypeEnum[key]
        });
      }
    }
    return options;
  }

  // /**
  //  * 获取计价单位 已翻译
  //  * @returns
  //  */
  // public getPriceUnits(): NormalSelectOptions {
  //   const options: NormalSelectOptions = [];
  //   for (const key in ChargeUnitEnum) {
  //     if (isNaN(Number(key))) {
  //       options.push({
  //         label: translation(`chargeUnit.${key}`),
  //         value: ChargeUnitEnum[key]
  //       });
  //     }
  //   }
  //   return options;
  // }

  /**
   * 批量删除结算单
   * @param idList 门店发货明细id
   * @returns
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.billing, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 批量删除结算单详情
   * @param idList
   * @returns
   */
  public batchDeleteBillingDetails(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.billing}/deleteBillDetail`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 批量删除发票详情
   * @param idList 发票详情Id
   * @returns
   */
  public batchDeleteInvoiceDetail(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.billing}/deleteInvoiceDetail`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 批量删除开票记录
   * @param idList 发票详情Id
   * @returns
   */
  public batchDeleteInvoice(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.billing}/deleteInvoice`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取要开票的产品项次
   * @param query 查询条件
   * @param paging
   * @returns
   */
  public getInvoiceProjectProductItems(
    query?: Partial<QueryInviceProjectProductItems>,
    paging?: Paging
  ): Promise<ApiResponse<Array<InviceProjectProductItems>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InviceProjectProductItems>>(`${ResourceUrlEnum.billing}/selectDocument`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 批量废除结算单
   * @param id 结算单组件数组
   * @param reason 废除原因
   * @returns
   */
  public abolish(id: number, reason: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.billing}/repeal`, { id, reason })
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 批量提交结算单
   * @param idList 结算单组件数组
   * @returns
   */
  public submit(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.billing}/submitTask`, null, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 批量撤销结算单
   * @param idList
   * @returns
   */
  public batchCancel(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.billing}/withdrawn`, idList)
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 导出结算单
   * @param query
   * @returns
   */
  public export(query?: Partial<BillingListQuery>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.billing}/export`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 导出台账
   * @param query
   * @returns
   */
  public exportLedger(query?: Partial<BillingListQuery>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<Blob>(`${ResourceUrlEnum.billing}/exportLedger`, query, {
          responseType: 'blob'
        })
        .then(res => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 核销
   * @param billId 结算单id
   * @param writeOffIdList 要核销的交易明细
   * @returns
   */
  public writeOff(billId: number, writeOffIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.billing}/writeOff`, { billId, writeOffIdList })
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 反核销
   * @param billId 结算单id
   * @param writeOffIdList 要反销的交易明细
   * @returns
   */
  public antiWriteOff(billId: number, writeOffIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.billing}/cancelWriteOff`, { billId, writeOffIdList })
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取审批记录
   * @param id
   * @returns
   */
  public getApprovalRecords(id: number): Promise<Array<ApprovalRecord>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ApprovalRecord>>(`${ResourceUrlEnum.billing}/getApprovalRecord`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            return reject(getErrorObj(res.code));
          }
          resolve(res.data || []);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取审批流程图
   * @param id
   * @returns
   */
  public getApprovalFlowChart(id: number): Promise<Blob | null> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<Blob>(`${ResourceUrlEnum.billing}/getDiagramBytes`, {
          params: { id },
          responseType: 'blob'
        })
        .then(res => {
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          reject(getHttpErrorObj(errorRes.code));
        });
    });
  }

  /**
   * 获取结算单产品类型下拉数据
   * @returns
   */
  public getBillingItemTypes(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in BillingItemTypeEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`billingItemType.${key}`),
          value: BillingItemTypeEnum[key]
        });
      }
    }
    return options;
  }

  /**
   * 获取结算单产品类型下拉数据
   * @returns
   */
  public getIsRedoSelect(): NormalSelectOptions {
    return [
      {
        label: translation('common.yes'),
        value: 1
      },
      {
        label: translation('common.no'),
        value: 0
      }
    ];
  }

  /**
   * 下载制作明细导入模板
   * @returns 下载文件流
   */
  public downloadMakeTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.billing}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入制作明细
   * @param filePath
   * @returns
   */
  public importMakeDetails(
    filePath: string,
    customerId: number
  ): Promise<ApiResponse<{ dataList: Array<InvoiceProjectItemsForImport> } & ImportRes>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<{ dataList: Array<InvoiceProjectItemsForImport> } & ImportRes>(`${ResourceUrlEnum.billing}/importData`, {
          filePath,
          customerId
        })
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const billingService = new BillingService();
export default billingService;
