/**
 * 结算单状态
 */
export enum BillingStatusEnum {
  /**
   * 新建
   */
  new = 1,

  /**
   * 待审核
   */
  waitAudit,

  /**
   * 待开票
   */
  waitMakeInvoice,

  /**
   * 待核销
   */
  waitCancelAfterVerification,

  /**
   * 结算完成
   */
  complete,
  /**
   * 审核未通过
   */
  failedAudit,
  /**
   * 废结审核中
   */
  abolishAudit,
  /**
   * 废结审核未通过
   */
  failedAbolishAudit,
  /**
   * 已废除
   */
  abolished
}

/**
 * 结算项次类型
 */
export enum BillingItemTypeEnum {
  /**
   * 成品
   */
  finishedProduct = 1,
  /**
   * 改色
   */
  changeColor,
  /**
   * 排版
   */
  designArtis,
  /**
   * 服务
   */
  server,
  /**
   * 费用
   */
  cost
}
