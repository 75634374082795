export enum FeedbackSuggestionStatus {
  /**
   *  待处理
   */
  pendingProcessing = 1,
  /**
   * 处理中
   */
  processing = 10,
  /**
   * 已关闭
   */
  closed = 100
}
