import OsTableQuery from './os-table-query/os-table-query.vue';
import OsTable from './os-table/os-table.vue';
import SvgIcon from './svg-icon/svg-icon.vue';
import OsPagination from './os-pagination/os-pagination.vue';
import OsTableOperation from './os-table-operation/os-table-operation.vue';
import OsTags from './os-tags/os-tags.vue';
import OsFileUpload from './os-file-upload/os-file-upload.vue';
import OsTableCustom from './os-table-custom/os-table-custom.vue';
import OsAddress from './os-address/os-address.vue';
import OsTimePickerLinkage from './os-time-picker-linkage/os-time-picker-linkage.vue';
import SelectCustomer from './select-customer/select-customer.vue';
import OsQueryPlan from './os-query-plan/os-query-plan.vue';
import OsDragLayout from './os-drag-layout/os-drag-layout.vue';

export {
  OsTable,
  SvgIcon,
  OsTableQuery,
  OsPagination,
  OsTableOperation,
  OsTags,
  OsFileUpload,
  OsTableCustom,
  OsAddress,
  OsTimePickerLinkage,
  SelectCustomer,
  OsQueryPlan,
  OsDragLayout
};
