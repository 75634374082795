/**
 * 货币种类
 */
export enum CurrencyEnum {
  /**
   * 人民币
   */
  CNY = 1,
  /**
   * 美元
   */
  USD,
  /**
   * 港币
   */
  HKD
}
