import { DirectiveOptions } from 'vue';
import { DirectiveBinding } from 'vue/types/options';
import { VNode } from 'vue/types/umd';

export const blurTrim: DirectiveOptions = {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const that: any = vnode.context;
    const paramsList: any = binding.expression.split('.');
    // 元素失焦后去空格
    el.querySelector('input')?.addEventListener('blur', e => {
      if (paramsList.length === 1) {
        that[paramsList[0]] = (e.target as any).value.trim();
      } else if (paramsList.length === 2) {
        that[paramsList[0]][paramsList[1]] = (e.target as any).value.trim();
      } else if (paramsList.length === 3) {
        that[paramsList[0]][paramsList[1]][paramsList[2]] = (e.target as any).value.trim();
      }
    });
  }
};
