import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import {
  CreateInstallEmployee,
  InstallEmployeeList,
  InstallEmployeeListQuery,
  UpdateInstallEmployee
} from '@/resource/model';
import { handleDownload } from '@/utils';
import { axiosRequest } from '@/utils/axios-request';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';

export default class InstallEmployService {
  public static post(resource: CreateInstallEmployee): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<CreateInstallEmployee>(ResourceUrlEnum.installEmployee, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public static put(resource: UpdateInstallEmployee): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<UpdateInstallEmployee>(ResourceUrlEnum.installEmployee, resource)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public static getList(
    query: InstallEmployeeListQuery,
    paging: Paging
  ): Promise<ApiResponse<Array<InstallEmployeeList>>> {
    const params = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(params, query);
    }
    Object.assign(params, paging);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InstallEmployeeList>>(`${ResourceUrlEnum.installEmployee}/listPage`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载安装工人入模板
   * @returns
   */
  public static downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.installEmployee}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 导入安装工人
   * @param filePath 文件在minio上的路径
   * @returns
   */
  public static importData(filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post(`${ResourceUrlEnum.installEmployee}/importData`, {
          filePath
        })
        .then(res => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查安装工人手机号是否已存在
   * @param mobile 手机号
   * @param id 项目主键（修改时需要传入）
   * @returns 为true则代表重复
   */
  public static checkPhoneNumber(mobile: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.installEmployee}/checkData`, { params: { mobile, id } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量启用或禁用安装工人
   * @param idList
   * @param status
   * @returns
   */
  public static batchUpdateEmployeeStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.installEmployee}/editStatus`, {
          idList,
          status
        })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public static getInstallEmployeeList(
    query: InstallEmployeeListQuery,
    paging: Paging
  ): Promise<ApiResponse<Array<InstallEmployeeList>>> {
    const params = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(params, query);
    }
    Object.assign(params, paging);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InstallEmployeeList>>(`${ResourceUrlEnum.installEmployee}/listComponentsPage`, params)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
