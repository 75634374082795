/**
 * 结算单或发票的审批结果
 */
enum BusinessTypeEnum {
  /**
   * 安装
   */
  install = 1,
  /**
   * 测量
   */
  measure = 2,
  /**
   * 维修
   */
  repair = 3
}

export { BusinessTypeEnum };
