/**
 * 耗损类型
 */
export enum BrokenTypeEnum {
    /**
     * 平台耗损
     */
    platform = 1,
    /**
     * 内部耗损
     */
    inner = 2
}