import { ResourceUrlEnum } from '@/resource/enum';
import { SupplierPermissionResource } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService } from '../base';

class SupplierPermissionService implements BaseService<SupplierPermissionResource> {
  public getById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<SupplierPermissionResource>(ResourceUrlEnum.supplierPermission, { params: { id } })
        .then((res: ApiResponse<SupplierPermissionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query: Partial<SupplierPermissionResource>): Promise<Array<SupplierPermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierPermissionResource>>(`${ResourceUrlEnum.supplierPermission}/list`, query)
        .then((res: ApiResponse<Array<SupplierPermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public post(resource: SupplierPermissionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<SupplierPermissionResource>(ResourceUrlEnum.supplierPermission, resource)
        .then((res: ApiResponse<SupplierPermissionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: SupplierPermissionResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<SupplierPermissionResource>(ResourceUrlEnum.supplierPermission, resource)
        .then((res: ApiResponse<SupplierPermissionResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number, parentId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.supplierPermission, { params: { id, parentId } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检查资源名称是否重复
   * @param name 资源名称
   * @param id 资源主键（修改时需要传入）
   * @returns
   */
  public checkResourcesName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierPermission}/checkResourcesName`, {
          params: { id, name }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 检查资源名称是否重复
   * @param name 资源名称
   * @param id 资源主键（修改时需要传入）
   * @returns
   */
  public checkResourcesCode(code: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierPermission}/checkResourcesCode`, {
          params: { id, code }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取供应商所有的权限资源
   * @param status 资源状态
   * @param keywords 关键字
   * @returns
   */
  public getAllSupplierPermissions(): Promise<Array<SupplierPermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierPermissionResource>>(`${ResourceUrlEnum.supplierPermission}/listPermissions`)
        .then((res: ApiResponse<Array<SupplierPermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 根据供应商id获取供应商所拥有的权限
   * @param companyId 供应商id
   * @returns
   */
  public getSupplierPermissionsBySupplier(companyId: number): Promise<Array<SupplierPermissionResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierPermissionResource>>(`${ResourceUrlEnum.supplierPermission}/listPermissionsBySupplier`, {
          params: { companyId }
        })
        .then((res: ApiResponse<Array<SupplierPermissionResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierPermissionService = new SupplierPermissionService();
export default supplierPermissionService;
