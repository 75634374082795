import { render, staticRenderFns } from "./tags-view.vue?vue&type=template&id=46453536&scoped=true&"
import script from "./tags-view.ts?vue&type=script&lang=ts&"
export * from "./tags-view.ts?vue&type=script&lang=ts&"
import style0 from "./tags-view.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./tags-view.scoped.scss?vue&type=style&index=1&id=46453536&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46453536",
  null
  
)

export default component.exports