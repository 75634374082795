import { ResourceUrlEnum } from '@/resource/enum';
import { HmOrderList, HmOrderListQuery, HmOrderEdit, HmOrderSave } from '@/resource/model';
import { handleDownload } from '@/utils';
import { axiosRequest } from '@/utils/axios-request';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { axiosService, ApiResponse, ApiError } from '../axios';
import { Paging } from '../base';

/**
 * HM专项-包装管理service
 * @author chris chen 2021-10-31 11:36
 * @version 3.0.0
 */
class HmOrderService {
  /**
   * 下载订舱清单
   * @param id
   */
  public downloadBooking(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.hmOrder}/exportOrderBooking`, {
          params: { orderId: id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载托盘贴
   * @param id 资源ID
   */
  public downloadProductTag(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.hmOrder}/exportProductSticker`, {
          params: { orderId: id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载托盘贴
   * @param id 资源ID
   */
  public downloadPalletSticker(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.hmOrder}/exportDeliveryLabel`, {
          params: { orderId: id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载箱贴
   * @param id 资源ID
   */
  public downloadBoxSticker(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.hmOrder}/exportOutsideBoxSticker`, {
          params: { orderId: id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载托盘清单
   * @param id 资源ID
   * @returns
   */
  public downloadPSL(id: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.hmOrder}/exportOrderDetail`, {
          params: { orderId: id },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 编辑
   * @param resource 编辑资源对象
   * @returns
   */
  public put(resource: HmOrderEdit): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<HmOrderSave>(ResourceUrlEnum.hmOrder, resource)
        .then((res: ApiResponse<HmOrderSave>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存
   * @param resource 保存资源对象
   * @returns
   */
  public post(resource: HmOrderSave): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<HmOrderSave>(ResourceUrlEnum.hmOrder, resource)
        .then((res: ApiResponse<HmOrderSave>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 分页查询
   * @param query 查询条件
   * @param paging 分页条件
   * @returns 分页数据
   */
  public getList(query?: Partial<HmOrderListQuery>, paging?: Paging): Promise<ApiResponse<Array<HmOrderList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<HmOrderList>>(`${ResourceUrlEnum.hmOrder}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<HmOrderList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除
   * @param id 资源ID
   * @returns
   */
  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.hmOrder, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出发货明细
   * @param query
   * @returns
   */
  public exportShippingDetails(query?: Partial<HmOrderListQuery & { idList: Array<number> }>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${ResourceUrlEnum.hmOrder}/exportShippingDetails`, query, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}

const hmOrderService = new HmOrderService();

export default hmOrderService;
