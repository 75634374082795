import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { BaseService, Paging } from '../base';
import { SupplierClassifyResource } from '@/resource/model';

class SupplierClassifyService implements BaseService<SupplierClassifyResource> {
  /**
   * 获取所有供应商分类
   * @returns 所有分类数据
   */
  public getAll(): Promise<Array<SupplierClassifyResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierClassifyResource>>(`${ResourceUrlEnum.supplierClassify}/listAll`, {})
        .then((res: ApiResponse<Array<SupplierClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取所有可用分类数据
   * @returns 可用分类
   */
  public getAllEnableList(): Promise<Array<SupplierClassifyResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierClassifyResource>>(`${ResourceUrlEnum.supplierClassify}/listEnable`, {})
        .then((res: ApiResponse<Array<SupplierClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getById(id: number): Promise<SupplierClassifyResource> {
    if (!id) {
      throw new Error('id must not be null');
    }
    throw new Error('Method not implemented.');
  }

  /**
   * 分页查询分类数据
   * @param query 检索条件
   * @param paging 分页参数
   * @returns 查询结果
   */
  public getList(
    query?: Partial<SupplierClassifyResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierClassifyResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierClassifyResource>>(`${ResourceUrlEnum.supplierClassify}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierClassifyResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 新增分类
   * @param resource 分类数据对象
   */
  public post(resource: SupplierClassifyResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.supplierClassify}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 修改分类
   * @param resource 分类数据对象
   */
  public put(resource: SupplierClassifyResource): Promise<void> {
    if (!resource) {
      throw new Error('resource must not be null');
    }

    if (!resource.id) {
      throw new Error('supplier id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierClassify}`, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除分类
   * @param id 分类ID
   * @returns Promise
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierClassify}`, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除
   * @param idList ID集合
   * @returns Promise
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length === 0) {
      throw new Error('idList must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.supplierClassify}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量更新状态
   * @param idList id集合
   * @returns Promise
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.supplierClassify}/editStatus`, { idList, status })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 检测分类名称是否重复
   * @param name 分类名称
   * @param id 分类ID
   * @returns 检测结果
   */
  public checkRepeatName(name: string, id?: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${ResourceUrlEnum.supplierClassify}/checkNameRepeat`, {
          params: { name, id }
        })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const supplierClassifyService = new SupplierClassifyService();
export default supplierClassifyService;
