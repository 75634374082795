export enum RegionLevelEnum {
  /**
   * 省份
   */
  province = 1,
  /**
   * 市
   */
  city = 2,
  /**
   * 区/县
   */
  district = 3
}
