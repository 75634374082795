import {
  TodoTaskQuery,
  TodoTaskList,
  InitiatedTaskQuery,
  InitiatedTaskList,
  CcTaskQuery,
  CcTaskList,
  CompleteTaskQuery,
  CompleteTaskList,
  TaskCenterDetailse
} from '@/resource/model';
import { ApproveResultEnum, ResourceUrlEnum } from '@/resource/enum';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { ApiError, ApiResponse, axiosService } from '../axios';
import { Paging } from '../base';
import { NormalSelectOptions } from '@/resource/model/common';
import { messageError, translation } from '@/utils';

class TodoTaskService {
  /**
   * 获取待办列表
   * @param query
   * @param paging
   * @returns
   */
  public getTodoList(query?: Partial<TodoTaskQuery>, paging?: Paging): Promise<ApiResponse<Array<TodoTaskList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<TodoTaskList>>(`${ResourceUrlEnum.todoTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<TodoTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取我发起的列表
   * @param query
   * @param paging
   * @returns
   */
  public getIntiatedList(
    query?: Partial<InitiatedTaskQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<InitiatedTaskList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<InitiatedTaskList>>(`${ResourceUrlEnum.initiatedTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<InitiatedTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取已办列表
   * @param query
   * @param paging
   * @returns
   */
  public getCompleteList(
    query?: Partial<CompleteTaskQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<CompleteTaskList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CompleteTaskList>>(`${ResourceUrlEnum.completeTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CompleteTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 获取抄送列表
   * @param query
   * @param paging
   * @returns
   */
  public getCcList(query?: Partial<CcTaskQuery>, paging?: Paging): Promise<ApiResponse<Array<CcTaskList>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CcTaskList>>(`${ResourceUrlEnum.ccTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<CcTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取状态
   * @returns
   */
  public getApproveStatus(): NormalSelectOptions {
    const options: NormalSelectOptions = [];
    for (const key in ApproveResultEnum) {
      if (isNaN(Number(key))) {
        options.push({
          label: translation(`approveResult.${key}`),
          value: ApproveResultEnum[key]
        });
      }
    }
    return options;
  }

  public getById(id: number): Promise<TaskCenterDetailse> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<TaskCenterDetailse>(ResourceUrlEnum.instanceTask, { params: { id } })
        .then((res: ApiResponse<TaskCenterDetailse>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 同意
   * @param params
   * @returns
   */
  public submitTask(params: { content: string; id: number; pid: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.instanceTask}/submitTask`, params)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 驳回
   * @param params
   * @returns
   */
  public rejectTask(params: { content: string; id: number; pid: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.instanceTask}/rejectTask`, params)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 撤销
   * @param id
   * @returns
   */
  public revoke(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`task/myInitiate/withdrawn?id=${id}`, {})
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 撤销
   * @param params
   * @returns
   */
  public withdrawn(params: { businessId: number; tag: string }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.instanceTask}/withdrawn?businessId=${params.businessId}&tag=${params.tag}`, null)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const todoTaskService = new TodoTaskService();
export default todoTaskService;
