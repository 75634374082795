/**
 * 印前项次状态
 */
export enum PrepressStatusEnum {
  /**
   * 新建
   */
  new = 1,
  /**
   * 待接单
   */
  waitForProduction,
  /**
   * 制作中
   */
  inProduction,
  /**
   * 待确搞
   */
  waitForConfirm,
  /**
   *  已确稿
   */
  waitComplete,
  /**
   * 不制作
   */
  notMake = 100
}
