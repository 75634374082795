import { ResourceUrlEnum } from '@/resource/enum';
import {
  CustomerShopResource,
  ImportRes,
  ProjectProductListQuery,
  ProjectShopDetail,
  ProjectShopResource,
  SelectedProjectItem,
  SelectProjectShopQuery,
  ShopDeliveryDetail
} from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { handleDownload, messageError } from '@/utils';
import { AxiosResponse } from 'axios';
import { ProjectShopQuery } from '@/resource/model/project-management/project-shop';

class ProjectShopService {
  public getById(id: number): Promise<ProjectShopDetail> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<ProjectShopDetail>(ResourceUrlEnum.projectShop, { params: { id } })
        .then((res: ApiResponse<ProjectShopDetail>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 项目添加门店
   * @param projectId
   * @param shopIdList
   * @returns
   */
  public addShop(projectId: number, shopIdList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<ProjectShopResource>(`${ResourceUrlEnum.projectShop}/idList`, { projectId, shopIdList })
        .then((res: ApiResponse<ProjectShopResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 保存
   * @param projectId
   * @param shopIdList
   * @returns
   */
  public post(resource: ProjectShopResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<ProjectShopResource>(`${ResourceUrlEnum.projectShop}/write`, resource)
        .then((res: ApiResponse<ProjectShopResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public put(resource: ProjectShopResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<ProjectShopResource>(ResourceUrlEnum.projectShop, resource)
        .then((res: ApiResponse<ProjectShopResource>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public delete(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(ResourceUrlEnum.projectShop, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public batchDelete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.projectShop}/batchDelete`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as any));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(query?: Partial<ProjectShopQuery>, paging?: Paging): Promise<ApiResponse<Array<ProjectShopResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectShopResource>>(`${ResourceUrlEnum.projectShop}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProjectShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询客户门店
   * @param query 查询条件
   * @param paging 分页
   * @returns
   */
  public getCustomerShopList(
    query: Partial<ProjectShopQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<CustomerShopResource>>> {
    const params = {};
    Object.assign(params, query);
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(params, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<CustomerShopResource>>(`${ResourceUrlEnum.projectShop}/listShop`, params)
        .then((res: ApiResponse<Array<CustomerShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   *  查询项目内门店
   * @param query 查询条件
   * @param paging 分页条件
   * @returns
   */
  public getProjectShopList(
    query?: Partial<ProjectShopQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProjectShopResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectShopResource>>(`${ResourceUrlEnum.projectShop}/listProjectShop`, cloneQuery)
        .then((res: ApiResponse<Array<ProjectShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 获取项目下所有项次
   * @param projectId 项目id
   * @returns
   */
  public getProjectItems(
    query?: Partial<ProjectProductListQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SelectedProjectItem>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SelectedProjectItem>>(`${ResourceUrlEnum.projectShop}/listProjectItemPage`, cloneQuery)
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存门店出货明细
   * @param resource
   * @returns
   */
  public saveShopDeliveryDetails(
    shopId: number,
    projectItemShopRelRequestList: Array<ShopDeliveryDetail>
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put(`${ResourceUrlEnum.projectShop}/editShopItemRel`, { shopId, projectItemShopRelRequestList })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除已保存的出货明细项
   * @param itemShopRelId 出货明细项的id
   * @returns
   */
  public deleteDeliveryItem(itemShopRelId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${ResourceUrlEnum.projectShop}/deleteByItemShopRelId`, { params: { itemShopRelId } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 保存门店出货明细
   * @param resource
   * @returns
   */
  public getShopDeliveryDetails(id: number): Promise<Array<ShopDeliveryDetail>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<ShopDeliveryDetail>>(`${ResourceUrlEnum.projectShop}/getItem`, { params: { id } })
        .then(res => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载产品明细导入模板
   * @param customerId 客户id
   * @returns
   */
  public downloadPosterTemplate(customerId: number, projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.projectShop}/downloadPosterTemplate`, {
          params: { customerId, projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public downloadPopTemplate(customerId: number, projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.projectShop}/downloadPopTemplate`, {
          params: { customerId, projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入pop类数据
   * @param filePath 文件在minio上的路径
   * @param projectId 项目id
   * @returns
   */
  public importPop(params: { filePath: string; projectId: number; customerId: number }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.projectShop}/importPop`, {
          ...params
        })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导入海报类数据
   * @param filePath 文件在minio上的路径
   * @param projectId 项目id
   * @returns
   */
  public importPoster(params: { filePath: string; projectId: number; customerId: number }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.projectShop}/importPoster`, {
          ...params
        })
        .then((res: ApiResponse<ImportRes>) => {
          if (res.code !== 0) {
            reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出pop类发货明细
   * @param id 发货通知单id
   * @returns
   */
  public exportPop(projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.projectShop}/exportPop`, {
          params: { projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 导出海报类发货明细
   * @param id 发货通知单id
   * @returns
   */
  public exportPoster(projectId: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${ResourceUrlEnum.projectShop}/exportPoster`, {
          params: { projectId },
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          return handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   *  查询项目内门店
   * @param query 查询条件
   * @param paging 分页条件
   * @returns
   */
  public getProjectShops(
    query?: Partial<SelectProjectShopQuery>,
    paging?: Paging
  ): Promise<ApiResponse<Array<ProjectShopResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<ProjectShopResource>>(`${ResourceUrlEnum.projectShop}/listComponentsPage`, cloneQuery)
        .then((res: ApiResponse<Array<ProjectShopResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const projectShopService = new ProjectShopService();
export default projectShopService;
