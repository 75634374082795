export enum LayoutStatusEnum {
  /**
   * 未生成
   */
  init = 0,
  /**
   * 已生成
   */
  generated = 1,
  /**
   * 生成中(备用)
   */
  generating = 2,
  /**
   * 生成失败(备用)
   */
  generation_failed = 3
}