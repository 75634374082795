/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Layout from '@/layout/layout.vue';
import { RouteConfig } from 'vue-router';

/**
 * 建议反馈理路由配置
 */
export const feedbackSuggestionRoutes: RouteConfig = {
  path: '/feedback-suggestion',
  component: Layout,
  redirect: 'feedback-setting',
  name: 'FeedbackSuggestion',
  children: [
    {
      path: '/feedback-setting',
      name: 'feedback-setting',
      component: async () =>
        import(
          /* webpackChunkName: "feedback-setting" */ '@/views/feedback-suggestion/feedback-setting/feedback-setting.vue'
        ),
      meta: {
        title: 'route.feedbackSetting',
        id: 1925,
        parentId: 1924
      }
    },
    {
      path: '/feedback-suggestions-list',
      name: 'FeedbackSuggestionList',
      component: async () =>
        import(
          /* webpackChunkName: "feedback-suggestions-list" */ '@/views/feedback-suggestion/feedback-suggestions-list/feedback-suggestions-list.vue'
        ),
      meta: {
        title: 'route.feedbackSuggestionList',
        id: 1927,
        parentId: 1924
      }
    },
    {
      path: '/my-feedback-suggestion',
      name: 'MyFeedbackSuggestiont',
      component: async () =>
        import(
          /* webpackChunkName: "feedback-suggestions-list" */ '@/views/feedback-suggestion/my-feedback-suggestion/my-feedback-suggestion.vue'
        ),
      meta: {
        title: 'route.myFeedbackSuggestiont'
      }
    },
    {
      path: '/add-feedback-suggestion',
      name: 'AddFeedbackSuggestion',
      component: async () =>
        import(
          /* webpackChunkName: "add-feedback-suggestion" */ '@/views/feedback-suggestion/add-feedback-suggestion/add-feedback-suggestion.vue'
        ),
      meta: {
        title: 'route.addFeedbackSuggestion',
        id: 1926,
        parentId: 1924
      }
    }
  ],
  meta: {
    title: 'route.feedbackSuggestion',
    icon: '#finance',
    id: 1924
  }
};
