import { ResourceUrlEnum } from '@/resource/enum';
import { PrepressTaskList, PrepressTaskInfo, PrepressTaskListQuery, TakeOrderParam } from '@/resource/model';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getBatchOperationErrorObjs, getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { Paging, SortOptions } from '../base';
import { axiosRequest } from '@/utils/axios-request';
import { AxiosResponse } from 'axios';
import { handleDownload, messageError } from '@/utils';

class CompleteManuscriptTaskService {
  public getById(id: number): Promise<PrepressTaskInfo> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<PrepressTaskInfo>(ResourceUrlEnum.completeManuscriptTask, { params: { id } })
        .then((res: ApiResponse<PrepressTaskInfo>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public getList(
    query: Partial<PrepressTaskListQuery>,
    paging: Paging,
    sortOptions: SortOptions<PrepressTaskList>
  ): Promise<ApiResponse<Array<PrepressTaskList>>> {
    const cloneQuery = {};
    if (Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }
    Object.assign(cloneQuery, paging);
    Object.assign(cloneQuery, sortOptions);
    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<PrepressTaskList>>(`${ResourceUrlEnum.completeManuscriptTask}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<PrepressTaskList>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  public export(query?: Partial<PrepressTaskListQuery>, idList?: Array<number>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(
          `${ResourceUrlEnum.completeManuscriptTask}/export`,
          { ...query, idList },
          {
            responseType: 'blob'
          }
        )
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 接单
   * @returns
   */
  public receivingDesignOrder(parames: {
    factor: number;
    idList: Array<number>;
    prepressCraftsId: string;
    prepressCraftsName: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.completeManuscriptTask}/receivingDesignOrder`, parames)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 接单
   * @returns
   */
  public receivingFinishOrder(param: TakeOrderParam): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.completeManuscriptTask}/receivingFinishOrder`, param)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 预览layout单
   * @returns
   */
  public previewLayout(ids: Array<number>): Promise<Array<{ id: number; url: string }>> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${ResourceUrlEnum.completeManuscriptTask}/previewLayout`, ids)
        .then((res: ApiResponse<Array<{ id: number; url: string }>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
  /**
   * 归档
   * @returns
   */
  public archiveComplete(idList: Array<number>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineFinishedProductTask}/finishProductComplete`, idList)
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            return reject(getBatchOperationErrorObjs(res.data));
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 上传确稿图
   * @returns
   */
  public submitConfirmFile(fileParams: Array<{ id: number; file?: string }>): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineFinishedProductTask}/submitConfirmFile`, fileParams)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 文件上传校验
   * @param fieldNameList
   * @returns
   */
  public checkConfirmItem(fieldNameList: Array<string>): Promise<ApiResponse<Array<{ id: number; itemCode: string }>>> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.mineFinishedProductTask}/checkConfirmItem`, fieldNameList)
        .then((res: ApiResponse<Array<{ id: number; itemCode: string }>>) => {
          if (res.code !== 0) {
            if (res.code === -5) {
              return reject(getBatchOperationErrorObjs(res.data as Array<any>));
            }
            const errorObj: ApiError = getErrorObj(res.code);
            messageError(errorObj);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 转派
   * @returns
   */
  public redeploy(idList: Array<number>, userId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${ResourceUrlEnum.completeManuscriptTask}/reassignment`, { idList, userId })
        .then((res: ApiResponse<any>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const completeManuscriptTaskService = new CompleteManuscriptTaskService();
export default completeManuscriptTaskService;
