import { axiosRequest } from '@/utils/axios-request';
import { ResourceStatusEnum, ResourceUrlEnum } from '@/resource/enum';
import { ApiError, ApiResponse, axiosService } from '@/api/axios';
import { getErrorObj, getHttpErrorObj } from '@/utils/handle-request-error';
import { AxiosResponse } from 'axios';
import { BaseService, Paging } from '../base';
import { SupplierMaterialResource } from '@/resource/model';
import { handleDownload } from '@/utils';

class PrintingMaterialService implements BaseService<SupplierMaterialResource> {
  private apiUrl = ResourceUrlEnum.platformPrintingMaterial;

  public getById(id: number): Promise<SupplierMaterialResource> {
    if (!id) {
      throw new Error('id must not be null');
    }
    throw new Error('Method not implemented.');
  }

  /**
   * 分页查询
   * @param query 查询条件
   * @param paging 分页参数
   * @returns
   */
  public getList(
    query: Partial<SupplierMaterialResource>,
    paging?: Paging
  ): Promise<ApiResponse<Array<SupplierMaterialResource>>> {
    const cloneQuery = {};
    if (query && Object.keys(query).length > 0) {
      Object.assign(cloneQuery, query);
    }

    if (paging && Object.keys(paging).length > 0) {
      Object.assign(cloneQuery, paging);
    }

    return new Promise((resolve, reject) => {
      axiosService
        .post<Array<SupplierMaterialResource>>(`${this.apiUrl}/listPage`, cloneQuery)
        .then((res: ApiResponse<Array<SupplierMaterialResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 新增材料
   * @param resource 新增数据对象
   */
  public post(resource: SupplierMaterialResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(this.apiUrl, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 编辑材料
   * @param resource 编辑数据对象
   */
  public put(resource: SupplierMaterialResource): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(this.apiUrl, resource)
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 删除材料
   * @param id 材料ID
   */
  public delete(id: number): Promise<void> {
    if (!id) {
      throw new Error('id must not be null');
    }
    return new Promise((resolve, reject) => {
      axiosService
        .delete(this.apiUrl, { params: { id } })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量删除材料
   * @param idList 材料ID集合
   */
  public batchDelete(idList: Array<number>): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .delete(`${this.apiUrl}/batch`, { data: idList })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询材料名称是否重复
   * @param id 材料ID
   * @param name 材料名称
   */
  public checkMaterialNameRepeat(id: number, name: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${this.apiUrl}/checkNameRepeat`, { params: { id, name } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询材料编号是否重复
   * @param id 材料ID
   * @param name 材料名称
   */
  public checkMaterialCodeRepeat(id: number, materialCode: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<boolean>(`${this.apiUrl}/checkCodeRepeat`, { params: { id, materialCode } })
        .then((res: ApiResponse<boolean>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 下载导入模板
   */
  public downloadTemplate(): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .get<any>(`${this.apiUrl}/downloadTemplate`, {
          responseType: 'blob'
        })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导入
   * @param companyId 企业ID
   * @param filePath 导入文件地址
   */
  public import(companyId: number, filePath: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axiosService
        .post<any>(`${this.apiUrl}/importData`, { companyId, filePath })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            return reject(res);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 数据导出
   */
  public export(query: Partial<any>): Promise<Blob> {
    return new Promise((resolve, reject) => {
      axiosRequest.instance
        .post<any>(`${this.apiUrl}/export`, query, { responseType: 'blob' })
        .then((res: AxiosResponse<Blob>) => {
          handleDownload(res)
            .then(blob => {
              resolve(blob);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 批量修改状态
   * @param idList 材料ID集合
   * @param status 状态
   */
  public batchUpdateStatus(idList: Array<number>, status: ResourceStatusEnum): Promise<void> {
    if (!idList || idList.length <= 0) {
      throw new Error('idList must not be null or empty');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .put<any>(`${this.apiUrl}/editStatus`, { idList, status })
        .then((res: ApiResponse<null>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve();
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询所有材料
   * @param companyId 企业ID
   */
  public listAll(companyId: number): Promise<Array<SupplierMaterialResource>> {
    if (!companyId) {
      throw new Error('companyId must not be null');
    }

    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierMaterialResource>>(`${this.apiUrl}/listAll`, { params: { companyId } })
        .then((res: ApiResponse<Array<SupplierMaterialResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }

  /**
   * 查询企业启用的材料
   */
  public listEnable(): Promise<Array<SupplierMaterialResource>> {
    return new Promise((resolve, reject) => {
      axiosService
        .get<Array<SupplierMaterialResource>>(`${this.apiUrl}/listEnable`)
        .then((res: ApiResponse<Array<SupplierMaterialResource>>) => {
          if (res.code !== 0) {
            const errorObj: ApiError = getErrorObj(res.code);
            return reject(errorObj);
          }
          resolve(res.data);
        })
        .catch((errorRes: { code: number }) => {
          const errorObj: ApiError = getHttpErrorObj(errorRes.code);
          reject(errorObj);
        });
    });
  }
}
const platformPrintingMaterialService = new PrintingMaterialService();
export default platformPrintingMaterialService;
