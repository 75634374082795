/**
 * 审核状态
 */
export enum ApproveStatusEnum {
  /**
   * 未提交
   */
  notCommit = 1,
  /**
   * 待审核
   */
  waitApprove = 2,
  /**
   * 审核通过
   */
  passed = 3,
  /**
   * 审核未通过
   */
  failed = 4
}
export enum PublicApproveStatusEnum {
  /**
   * 待审核
   */
  waitApprove = 1,
  /**
   * 审核通过
   */
  passed = 2
}
